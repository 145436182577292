import { Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const ModalMessage = (props) => {
  const { message, route, buttonTitle } = props;
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(route);
  };
  return (
    <Modal
      centered
      open={true}
      onCancel={handleNavigation}
      footer={
        <div style={{ textAlign: "center" }}>
          <button
            className="primary_btn btn"
            onClick={() => handleNavigation()}
          >
            Back to {buttonTitle}
          </button>
        </div>
      }
    >
      <div className="message-modal">
        <img
          src="https://cdn1.iconfinder.com/data/icons/round-ui/134/27-512.png"
          alt="warning"
          className="warning-image"
        />
        <div className="modal-message">
          <p>{message}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ModalMessage;
