import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance  from "../../authServices/axiosInstance";

export const userRegister = createAsyncThunk(
  "register",
  async ( data) => {
    try {
      const response = await AxiosInstance.post(`${API_URL}/merchant/users/register`, 
        data,
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const registerUserSlice = createSlice({
  name: "register",
  initialState: {
    isLoading: false,
    registerUserDetails: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [userRegister.pending]: (state) => {
      state.isLoading = true;
    },
    [userRegister.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.registerUserDetails = action.payload;
    },
    [userRegister.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default registerUserSlice.reducer;
