import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { adminClaim } from "../../../../../redux/slice/adminClaimListSlice";
import { adminNewClaim } from "../../../../../redux/slice/adminNewClaimSlice";
import { adminSearchOrder } from "../../../../../redux/slice/adminSearchOrderSlice";
import { merchantList } from "../../../../../redux/slice/merchantListSlice";
import Loading from "../../../../common/Loading/Loading";
import AdminClaimsPage from "./claims";

const AdminClaim = (props) => {
  const {
    merchantList,
    callSearchOrder,
    searchedOrder,
    callcreateNewClaim,
    adminNewClaim,
    callGetAdminClaims,
    adminClaimList,
    callGetMerchantList,
  } = props;
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedDates, setSelectedDates] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState([]);
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    if (params.size <= 0) {
      callGetAdminClaims({});
    }
    callGetMerchantList();
  }, []);
  
  useEffect(() => {
    const selectedOption = params.get("selected_option");
    const start_date = params.get("start_date");
    const end_date = params.get("end_date");
    const days = params.get("days");

    if (days) {
      setIsActive(days);
    }

    if (
      selectedOption ||
      start_date ||
      end_date ||
      currentPage ||
      searchValue ||
      status
    ) {
      setSelectedMerchant(selectedOption ? selectedOption : null);
      setSelectedDates([
        start_date ? dayjs(start_date, "M-DD-YYYY") : null,
        end_date ? dayjs(end_date, "M-DD-YYYY") : null,
      ]);

      const payload = {
        merchant: selectedOption,
        start_date: start_date || null,
        end_date: end_date || null,
        search: searchValue || null,
        status: status || null,
      };
      if(searchValue){
        const timeOut = setTimeout(() => {
          callGetAdminClaims({ payload, currentPage });
        }, 1000);
        return () => clearTimeout(timeOut);
      }else{
        callGetAdminClaims({ payload, currentPage });
      }
    }
  }, [location.search, currentPage, searchValue,status]);

  return (
    <div>
      {adminClaimList.isLoading && (
        <Loading type="overlay" className="loader-icon" />
      )}
      {adminClaimList.adminClaimList && (
        <AdminClaimsPage
          adminClaimList={adminClaimList.adminClaimList}
          merchantList={merchantList.merchantList}
          callGetAdminClaims={callGetAdminClaims}
          searchData={searchedOrder.adminSearchOrder}
          callSearchOrder={callSearchOrder}
          callcreateNewClaim={callcreateNewClaim}
          adminNewClaimLoading={adminNewClaim.isLoading}
          selectedMerchant={selectedMerchant}
          setSelectedMerchant={setSelectedMerchant}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isActive={isActive}
          setIsActive={setIsActive}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          status={status}
          setStatus={setStatus}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    merchantList: state.merchantList,
    searchedOrder: state.adminSearchedOrder,
    adminNewClaim: state.adminNewClaim,
    adminClaimList: state.adminClaimList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetMerchantList: () => dispatch(merchantList()),
    callSearchOrder: (...props) => dispatch(adminSearchOrder(...props)),
    callcreateNewClaim: (data) => dispatch(adminNewClaim(data)),
    callGetAdminClaims: (data) => dispatch(adminClaim(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminClaim);
