import React from "react";
import { useNavigate } from "react-router-dom";
import blankImg from "../../../../../assets/images/claimStatus/blankImg.svg";
import dotIcon from "../../../../../assets/images/claimStatus/dot.svg";
import editIcon from "../../../../../assets/images/claimStatus/edit.svg";
import left_arrow from "../../../../../assets/images/claimStatus/left_arrow.svg";
import locationIcon from "../../../../../assets/images/claimStatus/location.svg";
import titleDot from "../../../../../assets/images/claimStatus/titleDot.svg";
import { dateConvert } from "../../../../../utils/helper";

const OrderDetailComponent = (props) => {
  const { OrderDetails } = props;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="order-detail-main">
      <div className="order-detail-container">
        <div className="order-detail-header">
          <button className="arrow-box" onClick={handleBack}>
            <img src={left_arrow} alt="left_arrow" className="arrow-image" />
          </button>
          <div className="order-detail-header-content">
            <p className="header-content-title">order / Order Details</p>
            <h2 className="header-content-heading">
              Order #{" "}
              {OrderDetails?.data?.order_number
                ? OrderDetails?.data?.order_number
                : "-"}
            </h2>
          </div>
        </div>
        <div className="order-content-detail">
          <div className="order-title-head">
            <div className="order-header">
              <h2 className="order-content-title">
                Order #{" "}
                {OrderDetails?.data?.order_number
                  ? OrderDetails?.data?.order_number
                  : "-"}
              </h2>
              <div className="status-content">
                <p className="status">Unfulfilled</p>
              </div>
            </div>
            <h2 className="order-merchant-name">
              {OrderDetails?.data?.merchant_name
                ? OrderDetails?.data?.merchant_name
                : null}
            </h2>
          </div>
          <div className="order-status">
            <div className="order-status-placed">
              <span className="ordrer-title">Order Placed : </span>
              <span className="ordrer-date">
                {" "}
                {dateConvert(OrderDetails?.data?.order_createdAt)}
              </span>
            </div>
            {/* <div className="order-status-placed">
              <span className="ordrer-title">Updated : </span>
              <span className="ordrer-date">
                {dateConvert(OrderDetails?.data?.updatedAt)}
              </span>
            </div> */}
          </div>
        </div>
        <div class="order-detail-section">
          <div class="orde-info-container">
            <div className="order-detail-left">
              <div class="order-detail-top">
                {OrderDetails?.data?.line_items.map((item, index) => {
                  return (
                    <div class="order-list" key={index}>
                      <div class="order-list-left">
                        <img src={blankImg} alt="Order item image" />
                        <div class="order-list-content">
                          <span class="product-name">
                            {item.name ? item.name : "-"}
                          </span>
                        </div>
                      </div>
                      <div class="order-list-center">
                        <span>
                          ${item.price} x {item.quantity}
                        </span>
                      </div>
                      <div class="order-list-right">
                        <span>${item.price * item.quantity}</span>
                      </div>
                    </div>
                  );
                })}
                <div class="btn-bottom">
                  <div class="btn-container">
                    <button class="Shipping-btn">
                      <p class="Shipping-btn-text">Create Shipping Label</p>
                    </button>
                  </div>
                </div>
              </div>

              <div className="order-detail-bottom">
                <div className="paid-tag">
                  <img src={dotIcon} />
                  <span className="paid-text">
                    {OrderDetails?.data?.financial_status}
                  </span>
                </div>
                <div className="paid-content">
                  <div className="paid-item">
                    <span>Subtotal</span>
                    <span>{OrderDetails?.data?.line_items.length} Items</span>
                    <span>${OrderDetails?.data?.total_price}</span>
                  </div>
                  {/* <div className="paid-item">
                    <span>Discount</span>
                    <span>2 Items</span>
                    <span>$451.85</span>
                  </div> */}
                  <div className="paid-item-total">
                    <span>Total</span>
                    <span>${OrderDetails?.data?.total_price}</span>
                  </div>
                </div>
                <div className="paid-item-paid">
                  <span>Paid</span>
                  <span>${OrderDetails?.data?.total_price}</span>
                </div>
              </div>
            </div>
            <div className="order-detail-right">
              <div className="order-detail-top">
                <h2 className="customer-details">Customer Details</h2>
                <div className="edit-name">
                  <img src={editIcon} alt="editIcon"></img>
                  <span>
                    {OrderDetails?.data?.customer?.first_name
                      ? OrderDetails?.data?.customer?.first_name
                      : "John"}{" "}
                    {OrderDetails?.data?.customer?.last_name
                      ? OrderDetails?.data?.customer?.last_name
                      : "Doe"}
                  </span>
                </div>
                <div className="contact-info">
                  <img src={titleDot} alt="dot-icon" />
                  <h3 className="contact-title">Contact Information</h3>
                </div>
                <div className="edit-name">
                  <img src={editIcon} alt="editIcon"></img>
                  <a
                    href={`mailto:${OrderDetails?.data?.customer?.email}`}
                    className="edit-mail"
                  >
                    {OrderDetails?.data?.customer?.email
                      ? OrderDetails?.data?.customer?.email
                      : " john.doe@example.com"}
                  </a>
                </div>
                <div className="contact-info">
                  <img src={titleDot} alt="dot-icon" />
                  <h3 className="contact-title">Shipping Address</h3>
                </div>
                <div className="shipping-address">
                  <img src={locationIcon} alt="locationIcon"></img>
                  <span className="address">
                    {OrderDetails?.data?.shipping_address
                      ? OrderDetails?.data?.shipping_address?.address1
                      : "-"}
                  </span>
                </div>
              </div>
              <div
                className="order-detail-bottom"
                style={
                  OrderDetails?.data?.tags != ""
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h2 className="tag-title">Tags</h2>
                <div className="tags">
                  {OrderDetails?.data?.tags?.map((item, index) =>
                    item.split(",").map((tag, idx) => (
                      <span key={`${index}-${idx}`} className="tag">
                        {tag.trim()}
                      </span>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailComponent;
