import React from "react";
import { Form, Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo/LogoBlack.png";
import Loading from "../common/Loading/Loading";
import backgroundVideo from "../../../src/assets/Main _BG_Login.mp4";

const ResetPasswordPage = (props) => {
  const {
    formErrors,
    linkExpired,
    validateResetPassword,
    handleInputChange,
    confirmPassword,
    confirmNewPassword,
    handleResetPassword,
    onResetPasswordLoading,
  } = props;
  const navigate = useNavigate();
  return (
    <>
      <Modal
        centered
        open={linkExpired}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => navigate("/")}
      >
        <div className="invalid-token-model">
          <div className="invalid-token-model-title">
            <span>Invalid Link</span>
          </div>
          <div className="invalid-token-model-desc">
            <p>{validateResetPassword.errorMessage}</p>
          </div>
          <div>
            <button className="primary_btn btn" onClick={() => navigate("/")}>
              Okay
            </button>
          </div>
        </div>
      </Modal>
      <div className="login-page">
        <div className="login-page-container">
          <div className="page-title">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </div>
          <div className="login-right-side">
            <div className="login-title">Reset Password</div>
            <div className="login-form">
                <Form>
                  <div className="login-form-input">
                    <Form.Item
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter New Password",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input.Password
                        name="newPassword"
                        value={confirmPassword}
                        placeholder="New Password"
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                    {formErrors.confirmPassword && (
                      <div className="resetPassword-error">
                        {formErrors.confirmPassword}
                      </div>
                    )}
                    <Form.Item
                      name="confirmNewPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter confirm New Password",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input.Password
                        name="confirmNewPassword"
                        value={confirmNewPassword}
                        placeholder="Confirm New Password"
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                    {formErrors.confirmNewPassword && (
                      <div className="resetPassword-error">
                        {formErrors.confirmNewPassword}
                      </div>
                    )}
                    <div className="login-button reset-password-btn">
                      <button
                        className="primary_btn btn login-button-btn"
                        htmlType="submit"
                        type="submit"
                        onClick={handleResetPassword}
                        disabled={onResetPasswordLoading}
                      >
                        {onResetPasswordLoading ? (
                          <Loading type="spinner" color="#fff" />
                        ) : (
                          "Reset Password"
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
            </div>
          </div>
        </div>
        <video autoPlay muted loop id="background-video">
          <source src={backgroundVideo} />
        </video>
      </div>
    </>
  );
};

export default ResetPasswordPage;
