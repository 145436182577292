import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeaderComponent from "../../common/dashboardHeader/dashboradHeader";
import MessageModal from "./modal/messageModal";
import { DashboardIn } from "../../../../assets/images/sidebar";
import ProtectData from "./ProtectData";
// import TrackData from "./TrackData";
import { getMonthName, rangePresets } from "../../../../utils/helper";
import dayjs from "dayjs";
import claim_icon from "../../../../assets/images/claimStatus/claim_icon.svg";
import right_arrow from "../../../../assets/images/claimStatus/right_arrow.svg";
import DatePreset from "../../../common/DatePreset/datePreset";

const OnboardPage = (props) => {
  const {
    data,
    loading,
    merchantRecentClaim,
    protectData,
    revenueData,
    callGetProtect,
    monthlyClaim,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWidgetModalOpen, setIsWidgetModalOpen] = useState(false);
  const [taskDone, setTaskDone] = useState(false);
  const navigate = useNavigate();
  const [isDate, setIsDate] = useState(false);
  const [isActive, setIsActive] = useState("Today");
  const [selectedRange, setSelectedRange] = useState([
    dayjs().startOf("day"),
    dayjs().endOf("day"),
  ]);

  const { RangePicker } = DatePicker;

  const handleCancel = () => {
    setIsWidgetModalOpen(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (
      data?.install_task.done &&
      data?.onboard_task.done &&
      data?.partner_task.done &&
      data?.billing_task.done &&
      data?.widget_task.done
    ) {
      setTaskDone(true);
    } else {
      setTaskDone(false);
    }
  }, [
    data?.install_task.done,
    data?.onboard_task.done,
    data?.partner_task.done,
    data?.billing_task.done,
    data?.widget_task.done,
  ]);

  const overallRevenue = Object.values(revenueData[0]);
  const revenueRecord = overallRevenue.every((record) => record === 0);
  const overallRevenueLabel = ["Fees collected", "Net revenue", "Paid out"];

  const currentData = new Date().toISOString().split("T")[0];

  const dateFormat = "YYYY/MM/DD";

  const axisCategories =
    monthlyClaim?.data?.map((entry) =>
      getMonthName(Number(entry.month), Number(entry.year))
    ) || [];

  const seriesData = monthlyClaim?.data?.map((entry) => entry.count) || [];

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: axisCategories,
    },
    colors: ["rgb(227, 250, 162)"],
    dataLabels: {
      style: {
        colors: ["#1a1e22"],
      },
    },
  };
  const series = [
    {
      name: "Sales",
      data: seriesData,
    },
  ];

  const handleOnDateChange = async (dates) => {
    if (dates === null) {
      setIsActive("");
      setSelectedRange(null);
      setIsDate(false);
      await callGetProtect({});
    } else {
      if (selectedRange || dates) {
        const formattedStartDate = dates[0]?.format("YYYY-MM-DD");
        const formattedEndDate = dates[1]?.format("YYYY-MM-DD");

        await callGetProtect({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      }

      const preset = rangePresets.find(
        (p) =>
          p.value[0].isSame(dates[0], "day") &&
          p.value[1].isSame(dates[1], "day")
      );
      if (preset) {
        setIsActive(preset.label);
      } else {
        setIsActive("");
      }
    }
  };

  const handlePresetClick = (preset) => {
    setIsActive(preset.label);
    setSelectedRange(preset.value);
    handleOnDateChange(preset.value);
    setIsDate(false);
  };


  return (
    <div className="onBoard-main">
      <div className="onBoard-container">
        <DashboardHeaderComponent title="Dashboard" Logo={DashboardIn} />
        <div className="onBoard-main-section">
          <div className="merchant-dashboard-bottom">
            <div className={`analytics-section fullWidth}`}>
              <div className="merchant-graph-container">
                {/* <div
                  className={`revenue-chart ${taskDone && "taskDone-width"}`}
                >
                  <p className="revenue-chart-title">Revenue chart</p>
                  {!revenueRecord ? (
                    <Chart
                      options={{
                        labels: overallRevenueLabel,
                        colors: ["#E3FAA2", "#EDC6ED", "#9BF5F5"],
                        dataLabels: {
                          style: {
                            colors: ["#1a1e22"],
                          },
                        },
                      }}
                      series={overallRevenue}
                      type="donut"
                      cccc
                    />
                  ) : (
                    <div className="no-record-found">No Record Found</div>
                  )}
                </div> */}

                <div
                  className={`merchant-new-claim ${
                    taskDone && "taskDone-width"
                  }`}
                >
                  <p className="new-claim-title">New claim</p>
                  <div className="recent-activities-container">
                    {merchantRecentClaim?.length > 0 ? (
                      merchantRecentClaim?.map((event, index) => {
                        return (
                          <div
                            className="claim-content-view"
                            key={index}
                            onClick={() =>
                              navigate(`/dashboard/claims/${event._id}`)
                            }
                          >
                            <div className="claim-view">
                              <div className="claim-profile">
                                <img src={claim_icon} alt="" />
                              </div>
                              <div className="claim-data">
                                <label className="claim-label">
                                  Order Name :{" "}
                                  <span className="order-name">
                                    {" "}
                                    {event.order_name}
                                  </span>
                                </label>
                                <Link
                                  to={`/dashboard/claims/${event._id}`}
                                  className="claim-data-id id"
                                >
                                  {event._id}
                                </Link>
                              </div>
                            </div>
                            <div className="right-arrow-icon">
                              <img src={right_arrow} alt="" />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-record-found">No Record Found</div>
                    )}
                  </div>
                </div>
                <div className={`spline-graph setWidth`}>
                  <p className="spline-chart-title">Monthly Claims</p>
                  {seriesData.length > 0 ? (
                    <Chart
                      options={options}
                      series={series}
                      type="bar"
                      width={"100%"}
                      height={"300px"}
                    />
                  ) : (
                    <div className="no-record-found">No Record Found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`data-range `}>
            <div className="date-range">
              <span>Data Range: </span>
              <RangePicker
                onClick={() => setIsDate(true)}
                open={isDate}
                onOpenChange={(open) => {
                  if (!open) setIsDate(open);
                }}
                renderExtraFooter={() => (
                  <DatePreset
                    isActive={isActive}
                    setIsActive={setIsActive}
                    selectedRange={selectedRange}
                    setSelectedRange={setSelectedRange}
                    handlePresetClick={handlePresetClick}
                  />
                )}
                onChange={handleOnDateChange}
                format="YYYY-MM-DD"
                value={selectedRange}
                defaultValue={[
                  dayjs(currentData, dateFormat),
                  dayjs(currentData, dateFormat),
                ]}
              />
            </div>
          </div>
          <ProtectData loading={loading} protectData={protectData} />
          {/* <TrackData loading={loading} protectData={protectData} /> */}

          <MessageModal
            open={isModalOpen}
            label={data?.partner_task.label}
            popupText={data?.partner_task.popupText}
            onCancel={handleCancel}
          />
          <MessageModal
            open={isWidgetModalOpen}
            label={data?.widget_task.label}
            popupText={data?.widget_task.popupText}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};
export default OnboardPage;
