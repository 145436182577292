import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import OnboardPage from "./onboardPage";
import Loading from "../../../common/Loading/Loading";
import AdminOnboard from "../adminPages/onboard";
import { merchantStatistics } from "../../../../redux/slice/merchantStatisticSlice";
import { getMerchantNewClaim } from "../../../../redux/slice/getMerchantNewClaimSlice";
import { getProtect } from "../../../../redux/slice/getProtectSlice";
import { getRevenue } from "../../../../redux/slice/getRevenueSlice";
import { claimStatistic } from "../../../../redux/slice/claimStatisticSlice";

const Onboard = (props) => {
  const cookies = new Cookies();
  const role = cookies.get("role");
  const {
    loginData,
    callGetMerchantStatistics,
    merchantStatistic,
    callGetMerchantNewClaim,
    merchantRecentClaim,
    callGetProtect,
    protect,
    callRevenue,
    revenue,
    callClaimStatistic,
    claimStatistic,
  } = props;
  const currentData = new Date().toISOString().split("T")[0];
  useEffect(() => {
    if (role === "merchant") {
      callGetMerchantStatistics();
      callGetMerchantNewClaim();
      callRevenue();
      callClaimStatistic();
      callGetProtect({
        startDate: currentData,
        endDate: currentData,
      });
    }
  }, [
    callGetMerchantStatistics,
    callGetMerchantNewClaim,
    callGetProtect,
    callRevenue,
    callClaimStatistic,
    role,
  ]);
  const data =
    loginData.loginData &&
    merchantStatistic?.merchantStatistics?.data &&
    revenue.revenue?.data;
  const loading =
    loginData.isLoading || merchantStatistic.isLoading || revenue.isLoading;
  return role === "merchant" ? (
    <>
      {loading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : (
        data && (
          <OnboardPage
            data={loginData.loginData?.data?.dashboardTask}
            merchantStatistic={merchantStatistic?.merchantStatistics?.data}
            loading={loading}
            merchantRecentClaim={merchantRecentClaim?.getMerchantNewClaim?.data}
            protectData={protect?.protect?.data}
            revenueData={revenue.revenue?.data}
            callGetProtect={callGetProtect}
            monthlyClaim={claimStatistic?.claimStatistic}
          />
        )
      )}
    </>
  ) : (
    <>
      <AdminOnboard />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    merchantStatistic: state.merchantStatistic,
    merchantRecentClaim: state.merchantRecentClaim,
    protect: state.protect,
    revenue: state.revenue,
    claimStatistic: state.claimStatistic,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetMerchantStatistics: () => dispatch(merchantStatistics()),
    callGetMerchantNewClaim: () => dispatch(getMerchantNewClaim()),
    callGetProtect: (props) => dispatch(getProtect(props)),
    callRevenue: () => dispatch(getRevenue()),
    callClaimStatistic: () => dispatch(claimStatistic()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboard);
