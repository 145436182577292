import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Input, Modal, Select, Table } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loading from "../../../common/Loading/Loading";
import DashboardHeaderComponent from "../../common/dashboardHeader/dashboradHeader";
import approveIcon from "../../../../assets/images/approve-icon.svg";
import processedIcon from "../../../../assets/images/processed-icon.svg";
import { ClaimIn } from "../../../../assets/images/sidebar";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import { useTranslation } from "react-i18next";
import resolvedIcon from "../../../../assets/images/resolved-icon.svg";
import { dateConvert, rangePresets } from "../../../../utils/helper";
import dayjs from "dayjs";
import DatePreset from "../../../common/DatePreset/datePreset";
import { RiRefund2Line } from "react-icons/ri";
import { TbReorder } from "react-icons/tb";
import { TbProgress } from "react-icons/tb";

const ClaimsPage = (props) => {
  const {
    claimListData,
    handleSearchOrder,
    search,
    searchData,
    handleNavigateDetails,
    setSearch,
    handleCreateNewClaim,
    newClaim,
    setNewClaim,
    handleSubmitNewClaim,
    formErrors,
    setFormErrors,
    getClaimList,
    isLoading,
    createClaimModal,
    setCreateClaimModal,
    merchantNewClaimLoading,
    selectedDates,
    setSelectedDates,
    currentPage,
    setCurrentPage,
    isActive,
    setIsActive,
    searchValue,
    setSearchValue,
  } = props;

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const [isDate, setIsDate] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const { Search } = Input;

  const columns = [
    {
      title: "Order #",
      dataIndex: "order",
      key: "order",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Order #",
          },
        };
      },
    },
    {
      title: "Customer Email",
      dataIndex: "email",
      key: "email",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Customer Email",
          },
        };
      },
    },
    {
      title: "Claim Date",
      dataIndex: "claimDate",
      key: "claimDate",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Claim Date",
          },
        };
      },
    },
    {
      title: "Resolution Preference",
      dataIndex: "refundStatus",
      key: "refundStatus",
      render: (_, { refundStatus }) => {
        return {
          children: (
            <>
              <div
                style={{
                  color:
                    refundStatus === "processing"
                      ? "#89BA00"
                      : refundStatus === "reorder"
                      ? "#00B9B9"
                      : refundStatus === "refund"
                      ? "#02b574"
                      : refundStatus === "closed"
                      ? "#ee5d50"
                      : "#1a1e22",
                  fontWeight: 500,
                  fontSize: 16,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  textTransform: "uppercase",
                }}
              >
                {refundStatus === "processing" ? (
                  <TbProgress />
                ) : refundStatus === "reorder" ? (
                  <TbReorder />
                ) : refundStatus === "refund" ? (
                  <RiRefund2Line />
                ) : refundStatus === "closed" ? (
                  <img src={closeIcon} alt="" height={16} width={16} />
                ) : null}
                {refundStatus}
              </div>
            </>
          ),
          props: {
            "data-label": "Refund Status",
          },
        };
      },
    },
    {
      title: "Current Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        return {
          children: (
            <>
              {status.map((tag) => {
                return (
                  <div
                    key={tag}
                    style={{
                      color:
                        tag === "APPROVED"
                          ? "#89BA00"
                          : tag === "REVIEWING"
                          ? "#00B9B9"
                          : tag === "CLOSED"
                          ? "#ee5d50"
                          : tag === "RESOLVED"
                          ? "#02b574"
                          : "#1a1e22",
                      fontWeight: 500,
                      fontSize: 16,
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {tag === "APPROVED" ? (
                      <img src={approveIcon} alt="" height={16} width={16} />
                    ) : tag === "REVIEWING" ? (
                      <img src={processedIcon} alt="" height={16} width={16} />
                    ) : tag === "CLOSED" ? (
                      <img src={closeIcon} alt="" height={16} width={16} />
                    ) : tag === "RESOLVED" ? (
                      <img src={resolvedIcon} alt="" height={16} width={16} />
                    ) : null}
                    {tag.toUpperCase()}
                  </div>
                );
              })}
            </>
          ),
          props: {
            "data-label": "Current Status",
          },
        };
      },
    },
  ];
  
  const data =
    Array.isArray(claimListData?.data.response) &&
    claimListData?.data?.response?.map((item, index) => ({
      key: (index + 1).toString(),
      _id: item._id,
      order: item.orderId,
      email: item.orders.customer.email,
      claimDate: item.createdAt,
      refundStatus: item.refund_status,
      status: [item.status],
    }));

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  useEffect(() => {
    if (search !== "") {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }, [search]);
  const handleCancel = () => {
    setIsModalOpen(false);
    setCreateClaimModal(false);
    setSearch("");
  };
  const handleCreateClaimCancel = () => {
    setFormErrors("");
    setCreateClaimModal(false);
    setSearch("");
  };
  const handleOpenCreateClaim = (id) => {
    setIsModalOpen(false);
    setCreateClaimModal(true);
    const ClaimOrder = searchData?.data?.find((item) => item._id === id);
    setSelectedOrder(ClaimOrder);
    setNewClaim((prev) => ({
      ...prev,
      mid: ClaimOrder.merchant,
      oid: ClaimOrder._id,
    }));
  };
  const handleBackModal = () => {
    setFormErrors("");
    setCreateClaimModal(false);
    setIsModalOpen(true);
  };
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const handleOnDateChange = async (dates) => {
    const formattedStartDate = dates && dates[0]?.format("M-DD-YYYY");
    const formattedEndDate = dates && dates[1]?.format("M-DD-YYYY");
    if (formattedStartDate) {
      params.set("start_date", formattedStartDate);
    } else {
      params.delete("start_date");
      params.delete("days");
    }

    if (formattedEndDate) {
      params.set("end_date", formattedEndDate);
    } else {
      params.delete("end_date");
      params.delete("days");
    }
    setSelectedDates(dates);

    if (dates === null) {
      setIsActive("");
      setSelectedRange(null);
      setIsDate(false);
      // await getClaimList({});
    } else {
      const payload = {
        date: {
          startDate: formattedStartDate || null,
          endDate: formattedEndDate || null,
        },
      };
      // await getClaimList({ payload, currentPage });
      const preset = rangePresets.find(
        (p) =>
          p.value[0].isSame(dates[0], "day") &&
          p.value[1].isSame(dates[1], "day")
      );
      if (preset) {
        params.set("days", preset.label);
        setIsActive(preset.label);
      } else {
        setIsActive("");
        params.delete("days");
      }
    }
    navigate(`?${params.toString()}`);
  };
  const handlePresetClick = (preset) => {
    setIsActive(preset.label);
    setSelectedRange(preset.value);
    handleOnDateChange(preset.value);
    setIsDate(false);
  };
  // useEffect(() => {
  //   if (currentPage !== null) {
  //     getClaimList({ page: currentPage });
  //   }
  // }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isLoading || merchantNewClaimLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : null}
      <div className="claim-main">
        <div className="claim-container">
          <DashboardHeaderComponent title="Claims" Logo={ClaimIn} />
          <div className="claim-main-section">
            <div className="claim-Head">
              <div className="claim-d">
                <div className="claim-filters">
                  <RangePicker
                    onClick={() => setIsDate(true)}
                    open={isDate}
                    onOpenChange={(open) => {
                      if (!open) setIsDate(open);
                    }}
                    renderExtraFooter={() => (
                      <DatePreset
                        isActive={isActive}
                        setIsActive={setIsActive}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                        handlePresetClick={handlePresetClick}
                      />
                    )}
                    onChange={handleOnDateChange}
                    format="M-DD-YYYY"
                    value={selectedDates}
                  />
                  <Search
                    placeholder="Search..."
                    onChange={handleSearchChange}
                    enterButton
                    value={searchValue}
                  />
                </div>
                <div className="claim-head-button">
                  <button
                    className="primary_btn btn"
                    onClick={() => {
                      setIsModalOpen(!isModalOpen);
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        padding: 9,
                      }}
                    >
                      Add Claim
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div className="claim-body">
              <div className="claim-box 777">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={{
                    current: currentPage,
                    pageSize: 25,
                    total: claimListData?.data.totalRecords,
                    onChange: handlePageChange,
                  }}
                  locale={{ emptyText: "No claim found" }}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        handleNavigateDetails(record._id);
                      },
                    };
                  }}
                />
              </div>
            </div>
            <Modal
              centered
              open={isModalOpen}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              onCancel={handleCancel}
              className="ant-modal-si"
            >
              <div className="onBoard-Model">
                <div className="onBoard-title">
                  <span>Create Claim</span>
                </div>
                <div className="create-claim-body">
                  <Input
                    size="large"
                    placeholder="Search order"
                    onChange={handleSearchOrder}
                    value={search}
                  />
                  <span>Search by email, name, or order number</span>
                </div>
                <div className="create-claim-searched">
                  {showSearch ? (
                    searchData?.message === "No Records found" ? (
                      <p> No Records found</p>
                    ) : (
                      searchData?.data?.map((item, index) => {
                        var color;
                        switch (item.swipe) {
                          case true:
                            color = "green";
                            break;
                          case false:
                            color = "red";
                            break;

                          default:
                            color = "grey";
                            break;
                        }
                        return (
                          <div
                            className="create-claim-searched-inner"
                            key={index}
                          >
                            {color === "green" ? (
                              item.claims.length > 0 ? (
                                <Link to={`/dashboard/claims/${item.claims}`}>
                                  <div
                                    className="create-claim-searched-order"
                                    style={{
                                      borderColor: color,
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div className="create-claim-search-id">
                                      <p>{item.name}</p>
                                    </div>
                                    <div className="create-claim-search-fav">
                                      <p>{item.order_number}</p>
                                    </div>
                                    <div className="create-claim-search-email">
                                      <p>{item.customer_email}</p>
                                    </div>
                                    <div className="create-claim-search-link">
                                      {item.claims.length > 0 ||
                                      color === "red" ? (
                                        <p>Click here to view claim!</p>
                                      ) : (
                                        <>{item.total_price}</>
                                      )}
                                    </div>
                                    {color === "red" && (
                                      <div
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          margin: "0",
                                          marginTop: "5px",
                                        }}
                                      >
                                        <p>{t("CLAIM_THIS_ORDER")}</p>
                                      </div>
                                    )}
                                  </div>
                                </Link>
                              ) : (
                                <div
                                  className="create-claim-searched-order"
                                  style={{
                                    borderColor: color,
                                    marginTop: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleOpenCreateClaim(item._id)
                                  }
                                >
                                  <div className="create-claim-search-id">
                                    <p>{item.name}</p>
                                  </div>
                                  <div className="create-claim-search-fav">
                                    <p>{item.order_number}</p>
                                  </div>
                                  <div className="create-claim-search-email">
                                    <p>{item.customer_email}</p>
                                  </div>
                                  <div className="create-claim-search-link">
                                    {item.claims.length > 0 ||
                                    color === "red" ? (
                                      <p>Click here to view claim!</p>
                                    ) : (
                                      <>{item.total_price}</>
                                    )}
                                  </div>
                                  {color === "red" && (
                                    <div
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        margin: "0",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <p>{t("CLAIM_THIS_ORDER")}</p>
                                    </div>
                                  )}
                                </div>
                              )
                            ) : (
                              <div
                                className="create-claim-searched-order"
                                style={{
                                  borderColor: color,
                                  marginTop: "20px",
                                  cursor: "default",
                                }}
                              >
                                <div className="create-claim-search-id">
                                  <p>{item.name}</p>
                                </div>
                                <div className="create-claim-search-fav">
                                  <p>{item.order_number}</p>
                                </div>
                                <div className="create-claim-search-email">
                                  <p>{item.customer_email}</p>
                                </div>
                                <div className="create-claim-search-link">
                                  {item.total_price}
                                </div>
                                {color === "red" && (
                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      margin: "0",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <p>{t("CLAIM_THIS_ORDER")}</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })
                    )
                  ) : null}
                </div>

                <div className="onBoard-button">
                  <button className="primary_btn btn" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              open={createClaimModal}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              onCancel={handleCreateClaimCancel}
            >
              <div className="onBoard-Model">
                <div className="onBoard-title">
                  <span>Create Claim</span>
                </div>
                <div className="claim-info">
                  <div className="claim-info-title">
                    <p>Claim Information</p>
                  </div>
                  <div
                    className="create-claim-searched-order"
                    style={{
                      borderColor: "green",
                    }}
                  >
                    <div className="create-claim-search-id">
                      <p>{selectedOrder.name}</p>
                    </div>
                    <div className="create-claim-search-fav">
                      <p>{selectedOrder.order_number}</p>
                    </div>
                    <div className="create-claim-search-email">
                      <p>{selectedOrder.customer_email}</p>
                    </div>
                    <div className="create-claim-search-link">
                      {selectedOrder.total_price}
                    </div>
                  </div>
                  <div className="claim-info-inputs">
                    <label htmlFor="select">Claim Type</label>
                    <Select
                      size="large"
                      className="ant-select"
                      name="type"
                      placeholder="Select type"
                      value={newClaim.type || undefined}
                      onChange={(value) => {
                        setNewClaim((prev) => ({
                          ...prev,
                          type: value,
                        }));
                        setFormErrors((prev) => ({
                          ...prev,
                          type: "",
                        }));
                      }}
                    >
                      <Option value="Stolen">Stolen</Option>
                      <Option value="Lost/Missing">Lost/Missing</Option>
                      <Option value="Damaged">Damaged</Option>
                      <Option value="Returned to Sender">
                        Returned to Sender
                      </Option>
                      <Option value="Other">Other</Option>
                    </Select>
                    {formErrors.type && (
                      <div className="field-error error">{formErrors.type}</div>
                    )}
                    <label htmlFor="select">User Preference</label>
                    <Select
                      size="large"
                      className="ant-select"
                      placeholder="Select Preference"
                      name="type"
                      value={newClaim.preference || undefined}
                      onChange={(value) => {
                        setNewClaim((prev) => ({
                          ...prev,
                          preference: value,
                        }));
                        setFormErrors((prev) => ({
                          ...prev,
                          preference: "",
                        }));
                      }}
                    >
                      <Option value="Refund">Refund</Option>
                      <Option value="Replace">Replace</Option>
                    </Select>
                    {formErrors.preference && (
                      <div className="field-error error">
                        {formErrors.preference}
                      </div>
                    )}
                    <label htmlFor="textarea">Detailed Explanation</label>
                    <textarea
                      value={newClaim.description}
                      className="ant-textarea"
                      name="description"
                      onChange={handleCreateNewClaim}
                      placeholder="Explain any additional info..."
                    />
                    {formErrors.description && (
                      <div className="field-error error">
                        {formErrors.description}
                      </div>
                    )}
                  </div>
                  <div className="submit-back-buttons">
                    <button
                      className="primary_btn btn"
                      onClick={handleBackModal}
                    >
                      Back
                    </button>
                    <button
                      className="primary_btn btn"
                      onClick={handleSubmitNewClaim}
                      disabled={merchantNewClaimLoading}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimsPage;
