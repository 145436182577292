import React from "react";

const DashboardHeaderComponent = (props) => {
  const { title } = props;
  return (
    <>
      <div className="dashboard-header-container">
        <div className="dashboard-header-main">
          <div>
            <div className="label-title">
              <span>{title}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeaderComponent;
