import React from "react";
import SetupGuideBtn from "../../../../common/setupGuide/setupGuideBtn";
import ResourceCardBox from "../../../../common/setupGuide/ResourceCardBox";
import { GoVideo } from "react-icons/go";
import { BsFileEarmarkText } from "react-icons/bs";
import { ReactComponent as PartHorn } from "../../../../../assets/images/party-horn.svg";
import { ReactComponent as CheckIcon } from "../../../../../assets/images/checkmark.svg";
import { Collapse, Badge } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { useTranslation } from "react-i18next";
import { splitAndMap } from "../../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { BsArrowRight } from "react-icons/bs";
import { openExternalLink } from "../../../../../utils/helper";
const ProtectResolveSetupGuide = ({
  handleNavigate,
  handleUpdateSetup,
  data,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <div className="welcome-tab-main-section">
        <div className="tab-container">
          <div className="cong resolve">
            <div className="protect">
              <div className="party-icons">
                <PartHorn height={20} width={20} fill="#1a1e22" />
                <PartHorn height={20} width={20} fill="#1a1e22" />
                <PartHorn height={20} width={20} fill="#1a1e22" />
              </div>
              <p className="great-texts">{t("PROTECT_STRING_NICE_WORK")}</p>
            </div>
            <SetupGuideBtn
              title={t("CONTINUE_ONBOARDING_BTN")}
              onClick={() => handleNavigate("widget")}
            />
          </div>
          <p className="protect-head">Protect & Resolve</p>
          <div className="bordered-line"></div>
          <div className="welcome-tab-desc">
            <p>{t("PROTECT_STRING_THIS_SECTION")}</p>
          </div>
          <div className="protect-plan-cards-box">
            {data?.implement_swipe_protect_widget === "Blank" ? (
              <div className="protect-plan-cards bg-yellow">
                <FaAngleRight
                  height={12}
                  width={12}
                  style={{ margin: "20px 32px auto 0px" }}
                />
                <div>
                  <div className="plan-card-dv1 bottom-border">
                    <div className="plan-card-dv2">
                      <div className="plan-content1">
                        <div className="margin-top margin-bottom">
                          <div className="text-main-1">
                            <p>Implement the Swipe Protect Widget</p>
                            <Badge
                              className="site-badge-count-109"
                              count={"REQUIRED"}
                              style={{
                                backgroundColor: "black",
                              }}
                            />
                          </div>
                          <span className="text-main-2">
                            Get the Swipe protect widget up and running on your
                            site.{" "}
                            <b className="color-black">
                              To complete this task, let us know when the
                              protect widget is working to your satisfaction on
                              your site.
                            </b>
                          </span>
                        </div>
                      </div>
                      <div className="plan-content2">
                        <span className="plan-content2-text">
                          <SetupGuideBtn
                            title="Approve Your Implementation"
                            onClick={() =>
                              handleUpdateSetup(
                                "/protect_and_resolve/implement_swipe_protect_widget/Completed"
                              )
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="plan-card-dv3 bottom-border margin-top">
                    <GoDotFill />{" "}
                    <div className="plan-card-dv3-content">
                      <p>
                        <b>
                          Instructions for Shopify Free Expert Implementation:
                        </b>{" "}
                        For those not working with a Swipe sales representative,
                        we have sent you a “Welcome to Swipe email to connect
                        you with a Swipe representative who will help you with
                        expert implementation, as well as other tips
                      </p>
                      <p>
                        If you are working with a Swipe sales representative,
                        they can guide you through the process.
                      </p>
                      <p className="margin-bottom">
                        <b>
                          {" "}
                          If you want to implement the protect widget yourself,
                          follow the instructions
                        </b>{" "}
                        <a
                          target="_blank"
                          href="https://swipe.ai/article/shopify-plus-self-install-swipe-package-protection-on-your-store/"
                        >
                          HERE.
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="plan-card-dv3 bottom-border margin-top">
                    <GoDotFill />
                    <div className="plan-card-dv3-content">
                      <p>
                        <b>Shopify Plus Implementation:</b> If you are working
                        with a Swipe representative, they can guide you through
                        the process. Per Shopify’s policies, it is recommended
                        that Shopify Plus merchants place the Swipe protect
                        widget in both checkout and cart.
                      </p>
                      <p>
                        <b>
                          {" "}
                          To self-implement the protect widget on your Shopify
                          Extensibility checkout, follow the instructions{" "}
                          <a
                            target="_blank"
                            href="https://swipe.ai/article/adding-the-swipe-widget-in-checkout-extensibility/"
                          >
                            HERE.
                          </a>{" "}
                        </b>
                      </p>

                      <p className="margin-bottom">
                        <b>
                          {" "}
                          To self-implement the protect widget for non-Shopify
                          Extensibility checkout, follow the instructions{" "}
                          <a
                            target="_blank"
                            href="https://swipe.ai/article/adding-the-swipe-widget-in-checkout-extensibility/"
                          >
                            HERE.
                          </a>{" "}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className=" plan-card-dv3 margin-top">
                    <GoDotFill />{" "}
                    <div className="plan-card-dv3-content">
                      <p>
                        <b>If you have any questions or problems,</b> contact
                        our support team through our live chat in the bottom
                        right corner. You can also view our{" "}
                        <a
                          target="_blank"
                          href="https://swipe.ai/article/shopify-implementation-guide/"
                        >
                          Implementation Guides
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="protect-plan-cards">
                <div className="plan-card-dv1">
                  <div className="plan-card-dv2">
                    <div className="plan-content1">
                      <CheckIcon
                        height={12}
                        width={12}
                        style={{ margin: "auto 32px auto 0px" }}
                      />
                      <div>
                        <div className="text-main-1">
                          <p>{t("PROTECT_STR_IMPLEMENT")}</p>
                          <Badge
                            className="site-badge-count-109"
                            count={"REQUIRED"}
                            style={{
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                        <span className="text-main-2">
                          {t("PROTECT_STR_GET_THE")}
                        </span>
                      </div>
                    </div>
                    <div className="plan-content2">
                      <span className="plan-content2-text">
                        {t("PROTECT_STR_GREAT_YOU")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="protect-plan-cards bg-yellow">
              <FaAngleRight
                height={12}
                width={12}
                style={{ margin: "20px 32px auto 0px" }}
              />
              <div>
                <div className="plan-card-dv1 bottom-border">
                  <div className="plan-card-dv2">
                    <div className="plan-content1">
                      <div className="margin-top margin-bottom">
                        <div className="text-main-1">
                          <p>Implement the Swipe Protect Widget</p>
                          <Badge
                            className="site-badge-count-109"
                            count={"REQUIRED"}
                            style={{
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                        <span className="text-main-2">
                          Get the Swipe protect widget up and running on your
                          site.{" "}
                          <b className="color-black">
                            To complete this task, let us know when the protect
                            widget is working to your satisfaction on your site.
                          </b>
                        </span>
                      </div>
                    </div>
                    <div className="plan-content2">
                      <span className="plan-content2-text">
                        <SetupGuideBtn
                          title="Approve Your Implementation"
                          onClick={() =>
                            navigate("/dashboard/engage?modal=true")
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="plan-card-dv3 bottom-border margin-top">
                  <GoDotFill />{" "}
                  <div className="plan-card-dv3-content">
                    <p>
                      <b>
                        Instructions for Shopify Free Expert Implementation:
                      </b>{" "}
                      For those not working with a Swipe sales representative,
                      we have sent you a “Welcome to Swipe email to connect you
                      with a Swipe representative who will help you with expert
                      implementation, as well as other tips
                    </p>
                    <p>
                      If you are working with a Swipe sales representative, they
                      can guide you through the process.
                    </p>
                    <p className="margin-bottom">
                      <b>
                        {" "}
                        If you want to implement the protect widget yourself,
                        follow the instructions
                      </b>{" "}
                      <a href="#">HERE.</a>{" "}
                    </p>
                  </div>
                </div>
                <div className="plan-card-dv3 bottom-border margin-top">
                  <GoDotFill />
                  <div className="plan-card-dv3-content">
                    <p>
                      <b>Shopify Plus Implementation:</b> If you are working
                      with a Swipe representative, they can guide you through
                      the process. Per Shopify’s policies, it is recommended
                      that Shopify Plus merchants place the Swipe protect widget
                      in both checkout and cart.
                    </p>
                    <p>
                      <b>
                        {" "}
                        To self-implement the protect widget on your Shopify
                        Extensibility checkout, follow the instructions{" "}
                        <a href="#">HERE.</a>{" "}
                      </b>
                    </p>

                    <p className="margin-bottom">
                      <b>
                        {" "}
                        To self-implement the protect widget for non-Shopify
                        Extensibility checkout, follow the instructions{" "}
                        <a href="#">HERE.</a>{" "}
                      </b>
                    </p>
                  </div>
                </div>
                <div className=" plan-card-dv3 margin-top">
                  <GoDotFill />{" "}
                  <div className="plan-card-dv3-content">
                    <p>
                      <b>If you have any questions or problems,</b> contact our
                      support team through our live chat in the bottom right
                      corner. You can also view our{" "}
                      <a href="#">Implementation Guides</a>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            {/* code for after complete */}

            <div
              className={`protect-plan-cards ${
                data?.setup_shipping_protection_banner === "Blank"
                  ? "bg-yellow"
                  : " "
              }`}
            >
              <div className="plan-card-dv1">
                <div className="plan-card-dv2">
                  <div className="plan-content1">
                    {data?.setup_shipping_protection_banner === "Blank" ? (
                      <FaAngleRight
                        height={12}
                        width={12}
                        style={{ margin: "20px 32px auto 0px" }}
                      />
                    ) : (
                      <CheckIcon
                        height={12}
                        width={12}
                        style={{ margin: "auto 32px auto 0px" }}
                      />
                    )}

                    <div>
                      <div className="text-main-1">
                        <p>{t("PROTECT_STR_SETUP")}</p>
                      </div>
                      <span className="text-main-2">
                        {t("PROTECT_STR_PROMOTE_FREE")}
                      </span>
                    </div>
                  </div>
                  <div className="plan-content2">
                    <span className="plan-content2-text">
                      {/* intial text */}
                      {data?.setup_shipping_protection_banner === "Blank" && (
                        <>
                          <button
                            className="skip-btn"
                            onClick={() =>
                              handleUpdateSetup(
                                "/protect_and_resolve/setup_shipping_protection_banner/Dismiss"
                              )
                            }
                          >
                            <BsArrowRight
                              viewBox="0 0 15 12"
                              strokeWidth={1}
                              width={12}
                              height={12}
                              style={{
                                fill: "black",
                              }}
                            />
                            <span>Dismiss this task</span>
                          </button>
                          <SetupGuideBtn
                            title="Set Up Shipping & Protection Banner"
                            onClick={() =>
                              navigate("/dashboard/admin/configbar")
                            }
                          />
                        </>
                      )}

                      {/* complete text*/}
                      {data?.setup_shipping_protection_banner === "Completed" &&
                        t("PROTECT_STR_THIS_STEP")}
                      {/* {} */}
                    </span>
                  </div>
                  {/* dismiss text */}
                  {data?.setup_shipping_protection_banner === "Dismiss" && (
                    <div className="skip-text">
                      <p>This step was skipped.</p>
                      <p>
                        You can manage your free shipping and protection bar at
                        any time{" "}
                        <span
                          onClick={() => navigate("/dashboard/admin/configbar")}
                        >
                          HERE.
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bordered-line"></div>
          <div className="resources-sec">
            <p>Resources</p>
            <div className="box-df">
              <ResourceCardBox
                title={<BsFileEarmarkText />}
                extra={
                  <SetupGuideBtn
                    title={t("SEE_HOW_BTN")}
                    onClick={() => {
                      openExternalLink(
                        "https://swipe.ai/article/shopify-implementation-guide/"
                      );
                    }}
                  />
                }
                details={<span>{t("RESOURCE_PROTECT_1")}</span>}
              />
              <ResourceCardBox
                title={<GoVideo />}
                extra={<SetupGuideBtn title={t("WATCH_VIDEO_BTN")} />}
                details={<span>{t("RESOURCE_PROTECT_2")}</span>}
              />
              <ResourceCardBox
                title={<GoVideo />}
                extra={<SetupGuideBtn title={t("WATCH_VIDEO_BTN")} />}
                details={<span>{t("RESOURCE_PROTECT_3")}</span>}
              />
              <ResourceCardBox
                title={<BsFileEarmarkText />}
                extra={
                  <SetupGuideBtn
                    title={t("CLAIM_POLICIES_BTN")}
                    // onClick={}
                    onClick={() => {
                      openExternalLink(
                        "https://swipe.ai/article/lesson-5-policies-shipping-legality/"
                      );
                    }}
                    // title2={t("INSURANCE_POLICIES_BTN")}
                    // onClick2={}
                  />
                }
                details={<span>{t("RESOURCE_PROTECT_4")}</span>}
              />

              <ResourceCardBox
                title={<BsFileEarmarkText />}
                extra={
                  <SetupGuideBtn
                    title={t("SEE_HOW_BTN")}
                    onClick={() => {
                      openExternalLink(
                        "https://swipe.ai/article/issues-with-shopify-store-since-the-swipe-asset-was-installed/"
                      );
                    }}
                  />
                }
                details={<span>{t("RESOURCE_PROTECT_5")}</span>}
              />
            </div>
          </div>
          <div className="bordered-line"></div>

          <div className="faq-box">
            <p className="texts-accordion">FAQs</p>
            <Collapse
              size="large"
              accordion={true}
              className="collapse-tab-out"
            >
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_1")}
                className="collapse-tab-in"
                key="1"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_1"))}</p>
              </CollapsePanel>
              {/* <CollapsePanel
                header={t("FAQ_PROTECT_Q_2")}
                className="collapse-tab-in"
                key="2"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_2"))}</p>
                <SetupGuideBtn title={t("SWIPE_INSURANCE_POLICY_BTN")} />
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_3")}
                key="3"
              >
                <p>{t("FAQ_PROTECT_ANS_3")}</p>
                <SetupGuideBtn title={t("SWIPE_INSURANCE_POLICY_BTN")} />
              </CollapsePanel> */}
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_4")}
                className="collapse-tab-in"
                key="4"
              >
                <p>{t("FAQ_PROTECT_ANS_4")}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_5")}
                className="collapse-tab-in"
                key="5"
              >
                <p>{t("FAQ_PROTECT_ANS_5")}</p>
                <SetupGuideBtn
                  title={t("LEARN_MORE_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/why-does-swipe-need-my-billing-information/"
                    );
                  }}
                  title2={t("WATCH_VIDEO_BTN")}
                />
              </CollapsePanel>
              <CollapsePanel
                header="
How do I hide Swipe Protection as a product on my Shopify store"
                className="collapse-tab-in"
                key="37"
              >
                <p>
                  If you currently have a navigatable collection on your store
                  which references all products in your store, you’ll need to do
                  some things to hide Swipe Protection as a product in your
                  store.
                </p>
                <SetupGuideBtn
                  title={t("LEARN_MORE_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/how-to-hide-swipe-package-protection-as-a-product-in-my-store/"
                    );
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_6")}
                className="collapse-tab-in"
                key="6"
              >
                <p>{t("FAQ_PROTECT_ANS_6")}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_7")}
                key="7"
              >
                <p>
                  {splitAndMap(t("FAQ_PROTECT_ANS_7"), ["/dashboard/claims"])}
                </p>
                <SetupGuideBtn
                  title={t("SWIPE_TERMS_BTN")}
                  onClick={() => {
                    openExternalLink("https://swipe.ai/terms-and-conditions/");
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_8")}
                key="8"
              >
                <p>{t("FAQ_PROTECT_ANS_8")}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_9")}
                className="collapse-tab-in"
                key="9"
              >
                <p>{t("FAQ_PROTECT_ANS_9")}</p>
                <SetupGuideBtn
                  title={t("DASHBOARD_BTN")}
                  title2="Claims"
                  onClick={() => navigate("/dashboard")}
                  onClick2={() => navigate("/dashboard/claims")}
                />
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_10")}
                key="10"
              >
                <p>{t("FAQ_PROTECT_ANS_10")}</p>
                <SetupGuideBtn
                  title={t("ORDERS_BTN")}
                  onClick={() => navigate("/dashboard/orders")}
                />
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_11")}
                key="11"
              >
                <p>{t("FAQ_PROTECT_ANS_11")}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_12")}
                key="12"
              >
                <p>
                  {splitAndMap(t("FAQ_PROTECT_ANS_12"), [
                    "https://swipe.ai/file-a-claim/",
                  ])}
                </p>
                <SetupGuideBtn
                  title={t("FILE_BTN")}
                  onClick={() => {
                    openExternalLink("https://swipe.ai/file-a-claim/");
                  }}
                  title2={t("APP_BTN")}
                  onClick2={() => {
                    openExternalLink("https://swipe.ai");
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_14")}
                className="collapse-tab-in"
                key="14"
              >
                <p>{t("FAQ_PROTECT_ANS_14")}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_15")}
                key="15"
              >
                <p>{t("FAQ_PROTECT_ANS_15")}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_13")}
                key="13"
              >
                <p>
                  {splitAndMap(t("FAQ_PROTECT_ANS_13"), [
                    "https://swipe.ai/file-a-claim/",
                  ])}
                </p>
                <SetupGuideBtn
                  title={t("FAQ_WELCOME_ANS_4_B")}
                  onClick={() => {
                    openExternalLink("https://swipe.ai/swipe-ai-hc-page/");
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_16")}
                key="16"
              >
                <p>{t("FAQ_PROTECT_ANS_16")}</p>
                <SetupGuideBtn title={t("POLICY_S_BTN")} />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_17")}
                className="collapse-tab-in"
                key="17"
              >
                <p>{t("FAQ_PROTECT_ANS_17")}</p>
                <SetupGuideBtn
                  title={t("LOST_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/where-is-my-order/"
                    );
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_18")}
                className="collapse-tab-in"
                key="18"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_18"))}</p>
                <SetupGuideBtn
                  title={t("DAMAGED_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/what-type-of-damage-is-covered/"
                    );
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_19")}
                className="collapse-tab-in"
                key="19"
              >
                <p>{t("FAQ_PROTECT_ANS_19")}</p>
                <SetupGuideBtn
                  title={t("STOLEN_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/a-package-is-missing-is-it-lost-or-stolen/"
                    );
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_20")}
                key="20"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_20"))}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_21")}
                className="collapse-tab-in"
                key="21"
              >
                <p>{t("FAQ_PROTECT_ANS_21")}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_22")}
                className="collapse-tab-in"
                key="22"
              >
                <p>
                  {splitAndMap(t("FAQ_PROTECT_ANS_22"), [
                    "https://swipe.ai/article/swipe-package-protection-policies/",
                  ])}
                </p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_23")}
                key="23"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_23"))}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_24")}
                className="collapse-tab-in"
                key="24"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_24"))}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_25")}
                className="collapse-tab-in"
                key="25"
              >
                <p>{t("FAQ_PROTECT_ANS_25")}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_26")}
                key="26"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_26"))}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_27")}
                className="collapse-tab-in"
                key="27"
              >
                <p>{t("FAQ_PROTECT_ANS_27")}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_28")}
                className="collapse-tab-in"
                key="28"
              >
                <p>{t("FAQ_PROTECT_ANS_28")}</p>
                <SetupGuideBtn
                  title={t("INTERNATIONAL_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/does-swipe-work-internationally/"
                    );
                  }}
                />
              </CollapsePanel>

              <CollapsePanel
                header={t("FAQ_PROTECT_Q_29")}
                className="collapse-tab-in"
                key="29"
              >
                <p>{t("FAQ_PROTECT_ANS_29")}</p>
                <SetupGuideBtn
                  title={t("SWIPE_TERMS_BTN")}
                  onClick={() => {
                    openExternalLink("https://swipe.ai/terms-and-conditions/");
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_30")}
                className="collapse-tab-in"
                key="30"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_30"))}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_31")}
                key="31"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_31"))}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_32")}
                className="collapse-tab-in"
                key="32"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_32"))}</p>
                <SetupGuideBtn
                  title={t("HIDE_SWIPE_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/how-to-hide-swipe-package-protection-as-a-product-in-my-store/"
                    );
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_33")}
                className="collapse-tab-in"
                key="33"
              >
                <p>{t("FAQ_PROTECT_ANS_33")}</p>
                <SetupGuideBtn
                  title={t("HOW_HIDE_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/how-to-hide-swipe-package-protection-as-a-product-in-my-store/"
                    );
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_PROTECT_Q_34")}
                className="collapse-tab-in"
                key="34"
              >
                <p>{t("FAQ_PROTECT_ANS_34")}</p>
              </CollapsePanel>
              {/* <CollapsePanel
                header={t("FAQ_PROTECT_Q_35")}
                className="collapse-tab-in"
                key="35"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_35"), ["/dashboard"])}</p>
                <SetupGuideBtn title={t("HOW_SWIPE_BTN")} />
              </CollapsePanel> */}
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_PROTECT_Q_36")}
                key="36"
              >
                <p>{splitAndMap(t("FAQ_PROTECT_ANS_36"))}</p>
              </CollapsePanel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectResolveSetupGuide;
