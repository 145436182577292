import React from "react";
import SetupGuideBtn from "../../../../common/setupGuide/setupGuideBtn";
import ResourceCardBox from "../../../../common/setupGuide/ResourceCardBox";
import { BsFileEarmarkText } from "react-icons/bs";
import { Collapse, Badge } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { useTranslation } from "react-i18next";
import { openExternalLink, splitAndMap } from "../../../../../utils/helper";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../../../../assets/images/checkmark.svg";
import { BsArrowRight } from "react-icons/bs";
const AdminSetupGuide = ({ data, handleUpdateSetup }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <div className="welcome-tab-main-section">
        <div className="tab-container">
          <p className="protect-head track-head">Admin</p>
          <div className="bordered-line"></div>
          <div className="welcome-tab-desc track-pg-w">
            <p>{t("ADMIN_STR_THIS")}</p>
          </div>
          <div className="protect-plan-cards-box">
            <div
              className={`protect-plan-cards ${
                data?.merchant_communication_email === "Blank"
                  ? "bg-yellow"
                  : ""
              }`}
            >
              <div className="plan-card-dv1">
                <div className="plan-card-dv2">
                  <div className="plan-content1">
                    {data?.merchant_communication_email === "Blank" ? (
                      <FaAngleRight
                        height={12}
                        width={12}
                        style={{ margin: "20px 32px auto 0px" }}
                      />
                    ) : (
                      <CheckIcon
                        height={12}
                        width={12}
                        style={{ margin: "auto 32px auto 0px" }}
                      />
                    )}
                    <div>
                      <div className="text-main-1">
                        <p>{t("ADMIN_STR_ADD_MER")}</p>
                        <Badge
                          className="site-badge-count-109"
                          count={"REQUIRED"}
                          style={{
                            backgroundColor: "black",
                          }}
                        />
                      </div>
                      <span className="text-main-2">{t("ADMIN_STR_WE")}</span>
                    </div>
                  </div>
                  <div className="plan-content2">
                    <span className="plan-content2-text">
                      {data?.merchant_communication_email === "Blank" ? (
                        <SetupGuideBtn
                          title={t("ADD_EMAIL_BTN")}
                          onClick={() => navigate("/dashboard/admin")}
                        />
                      ) : (
                        <p>This step has been completed.</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`protect-plan-cards ${
                data?.add_your_team === "Blank" ? "bg-yellow" : ""
              }`}
            >
              <div className="plan-card-dv1">
                <div className="plan-card-dv2">
                  <div className="plan-content1">
                    {data?.add_your_team === "Blank" ? (
                      <FaAngleRight
                        height={12}
                        width={12}
                        style={{ margin: "20px 32px auto 0px" }}
                      />
                    ) : (
                      <CheckIcon
                        height={12}
                        width={12}
                        style={{ margin: "auto 32px auto 0px" }}
                      />
                    )}

                    <div>
                      <div className="text-main-1">
                        <p>{t("ADMIN_STR_ADD_YR")}</p>
                      </div>
                      <span className="text-main-2">
                        {t("ADMIN_STR_ADD_TEAM")}
                      </span>
                    </div>
                  </div>
                  <div className="plan-content2">
                    <span className="plan-content2-text">
                      {/* intial set */}
                      {data?.add_your_team === "Blank" && (
                        <>
                          <button
                            className="skip-btn"
                            onClick={() =>
                              handleUpdateSetup("/admin/add_your_team/Dismiss")
                            }
                          >
                            <BsArrowRight
                              viewBox="0 0 15 12"
                              strokeWidth={1}
                              width={12}
                              height={12}
                              style={{
                                fill: "black",
                              }}
                            />
                            <span>Dismiss this task</span>
                          </button>
                          <SetupGuideBtn
                            title={t("ADD_NEW_USER")}
                            onClick={() =>
                              navigate("/dashboard/admin?tab=user")
                            }
                          />
                        </>
                      )}
                      {data?.add_your_team === "Completed" && (
                        <p>This step has been completed.</p>
                      )}
                    </span>
                  </div>
                  {data?.add_your_team === "Dismiss" && (
                    <div className="skip-text">
                      <p>This step was skipped.</p>
                      <p>
                        You can manage your users at any time{" "}
                        <span
                          onClick={() => navigate("/dashboard/admin?tab=user")}
                        >
                          HERE
                        </span>
                        .
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bordered-line"></div>
          <div className="resources-sec">
            <p>Resources</p>
            <div className="box-df">
              <ResourceCardBox
                title={<BsFileEarmarkText />}
                extra={
                  <SetupGuideBtn
                    title={t("LEARN_MORE_BTN")}
                    onClick={() => {
                      openExternalLink(
                        "https://swipe.ai/article/five-steps-to-set-up-your-merchant-portal/"
                      );
                    }}
                  />
                }
                details={<span>{t("RESOURCE_ADMIN_1")}</span>}
              />
              <ResourceCardBox
                title={<BsFileEarmarkText />}
                extra={
                  <SetupGuideBtn
                    title={t("LEARN_MORE_BTN")}
                    onClick={() => {
                      openExternalLink(
                        "https://swipe.ai/article/user-roles-and-permissions/"
                      );
                    }}
                  />
                }
                details={<span>{t("RESOURCE_ADMIN_2")}</span>}
              />
            </div>
          </div>
          <div className="bordered-line"></div>

          <div className="faq-box">
            <p className="texts-accordion">FAQs</p>
            <Collapse
              size="large"
              accordion={true}
              className="collapse-tab-out"
            >
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_ADMIN_1")}
                key="1"
              >
                <p>
                  {splitAndMap(t("FAQ_ADMIN_ANS_1"), [
                    "/dashboard/finance?tab=payments",
                    "/dashboard",
                  ])}
                </p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_ADMIN_2")}
                key="2"
              >
                <p>{splitAndMap(t("FAQ_ADMIN_ANS_2"))}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_ADMIN_3")}
                key="3"
              >
                <p>{splitAndMap(t("FAQ_ADMIN_ANS_3"))}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_ADMIN_4")}
                key="4"
              >
                <p>{splitAndMap(t("FAQ_ADMIN_ANS_4"))}</p>
              </CollapsePanel>

              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_ADMIN_5")}
                key="5"
              >
                <p>{splitAndMap(t("FAQ_ADMIN_ANS_5"))}</p>
              </CollapsePanel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSetupGuide;
