import { Modal } from "antd";

const MessageModal = ({ open, label, popupText, onCancel }) => {
  return (
    <Modal
      centered
      open={open}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={onCancel}
    >
      <div className="onBoard-Model">
        <div className="onBoard-title">
          <span>{label}</span>
        </div>
        <div className="onBoard-welcome">
          <p>{popupText}</p>
        </div>
        <div className="onBoard-form">
          <div className="onBoard-form-input"></div>
          <div className="onBoard-button">
            <button className="primary_btn btn" onClick={onCancel}>
              Got It!
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MessageModal;
