import { Button, Input, InputNumber, Modal, Select, Timeline } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClaimStatus, RefundOption } from "../../../../../../utils/commonUtils";
import TextArea from "antd/es/input/TextArea";
import { ReactComponent as CustomerMail } from "../../../../../../assets/images/claimStatus/sms.svg";
import { ReactComponent as CustomerAddress } from "../../../../../../assets/images/claimStatus/gps.svg";
import Notify from "../../../../../common/Notify/notify";
import { BsTelephone } from "react-icons/bs";
import Loading from "../../../../../common/Loading/Loading";
import DashboardHeaderComponent from "../../../../common/dashboardHeader/dashboradHeader";
import { ClaimIn } from "../../../../../../assets/images/sidebar";
import { claimTimeLine } from "../../../claimDetail/helper";
import { useTranslation } from "react-i18next";
import { ReactComponent as ApprovePopupIcon } from "../../../../../../assets/images/approvePopupIcon.svg";
import { ReactComponent as RejectPopupIcon } from "../../../../../../assets/images/rejectPopupIcon.svg";
import { ReactComponent as ReviewPopupIcon } from "../../../../../../assets/images/reviewPopupIcon.svg";
import { ReactComponent as RefundPopup } from "../../../../../../assets/images/refundPopup.svg";
import { ReactComponent as ReOrderPopup } from "../../../../../../assets/images/reOrderPopup.svg";
import { API_URL } from "../../../../../../environment";
import indicator from "ordinal/indicator";
import { dateConvert } from "../../../../../../utils/helper";
import left_arrow from "../../../../../../assets/images/claimStatus/left_arrow.svg";

const DetailsPage = (props) => {
  const {
    ClaimDetails,
    callAddEvents,
    callChangeClaimStatus,
    getMerchantClaimDetails,
    callOnApproved,
    onApprovedLoading,
    claimEventsLoading,
    handleAddNote,
    handleNoteChange,
    note,
    notes,
  } = props;
  const { t } = useTranslation();
  const { id } = useParams();
  const [comments, setComments] = useState("");
  const [error, setError] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [changedStatus, setChangedStatus] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [refund, setRefund] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [confirmRefundModal, setConfirmRefundModal] = useState(false);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  const onChangeStatus = async (value) => {
    setConfirmModal(true);
    setChangedStatus(value);
  };
  const onConfirmChangeStatus = async () => {
    const data = {
      status: {
        status: changedStatus,
      },
      id: ClaimDetails.Claims._id,
    };
    const response = await callChangeClaimStatus(data);
    if (response.type === "claims/fulfilled") {
      setConfirmModal(false);
      Notify("success", response.payload, "");
      getMerchantClaimDetails(id);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
        setConfirmModal(false);
      }
    }
  };

  const handleClick = (trackingNumber) => {
    const url = ClaimDetails.Claims.tracking_urls.find((url) =>
      url.includes(trackingNumber)
    );
    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleAddComments = async () => {
    if (comments === "" || comments.length < 8) {
      setError(true);
    } else {
      setError(false);
      const data = {
        content: comments,
        order: ClaimDetails.Claims.orders._id,
        merchant: ClaimDetails.Claims.merchant._id,
        claim: ClaimDetails.Claims._id,
      };
      const response = await callAddEvents(data);
      if (response.type === "add/fulfilled") {
        Notify("success", response.payload, "");
        getMerchantClaimDetails(id);
        setComments("");
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
          setConfirmModal(false);
        }
      }
    }
  };
  const onWriteCommnet = (event) => {
    setComments(event.target.value);
  };

  const onChangeRefund = (value) => {
    setRefundAmount(value);
  };

  useEffect(() => {
    if (ClaimDetails.Claims && ClaimDetails.Claims.total.refund_total > 0) {
      setSelectedValue("REFUND");
      setRefund(true);
      setDisabled(true);
      setRefundAmount(ClaimDetails.Claims.total.refund_total);
    } else if (
      ClaimDetails.Claims &&
      ClaimDetails.Claims.total.reorder_total > 0
    ) {
      setSelectedValue("REORDER");
      setRefund(false);
      setDisabled(true);
      setRefundAmount(ClaimDetails.Claims.total.reorder_total);
      setReOrderId(ClaimDetails.Claims.reorder_id);
    } else {
      setDisabled(false);
      setSelectedValue(null);
      setRefund(false);
      setRefundAmount("");
      setReOrderId(null);
    }
  }, [ClaimDetails, getMerchantClaimDetails]);

  const onSelectRefundStatus = (value) => {
    setSelectedValue(value);
    if (value === "REFUND") {
      setRefund(true);
    } else {
      if (value === "REORDER") {
        setRefund(false);
      }
    }
  };
  const [reOrderId, setReOrderId] = useState(null);
  const [refundNote, setRefundNote] = useState(null);
  const onChangeReOrder = (e) => {
    setReOrderId(e.target.value);
  };
  const onChangeRefundNote = (e) => {
    setRefundNote(e.target.value);
  };

  const onRefundSubmit = async () => {
    if (
      !selectedValue ||
      !refundAmount ||
      (selectedValue === "REORDER" && !reOrderId)
    ) {
      Notify("error", "Please Enter amount and select value", "");
    } else {
      setConfirmRefundModal(true);
    }
  };

  const handleRefundSubmit = async () => {
    const refundBody = {
      orderId: ClaimDetails.Claims.orders._id,
      claimId: ClaimDetails.Claims._id,
      is_refund: refund,
      claim_total: refundAmount,
      refund_total: refundAmount,
      refund_count: "1",
      refund_status:"refund",
    };
    const reOrderBody = {
      orderId: ClaimDetails.Claims.orders._id,
      claimId: ClaimDetails.Claims._id,
      is_refund: refund,
      claim_total: refundAmount,
      reorder_total: refundAmount,
      reorder_count: "1",
      reorder_id: reOrderId,
      refund_status:'reorder',
    };

    const adminOrderRefund = {
      order_id: ClaimDetails.Claims.orders.id,
      shop_id: ClaimDetails.Claims.merchant.domain,
      note: refundNote,
    };

    const adminOrderReorder = {
      order_id: ClaimDetails.Claims.orders.id,
      shop_id: ClaimDetails.Claims.merchant.domain,
    };

    const response = await callOnApproved(refund ? refundBody : reOrderBody);
    if (response.type === "approved/fulfilled") {
      Notify("success", response.payload.message, "");
      setConfirmRefundModal(false);
      getMerchantClaimDetails(id);
      try {
        const apiUrl = `${API_URL}/admin/order/${
          refund ? "refund" : "reorder"
        }`;
        const requestOrderRefund = refund
          ? adminOrderRefund
          : adminOrderReorder;

        const result = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestOrderRefund),
        });
        const responseData = await result.json();
        setResult(responseData);
      } catch (error) {
        Notify("error", error, "");
      }
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
        setConfirmRefundModal(false);
      }
    }
  };

  const popUpIcon = {
    APPROVED: <ApprovePopupIcon />,
    REVIEWING: <ReviewPopupIcon />,
    CLOSED: <RejectPopupIcon />,
    REFUND: <RefundPopup />,
    REORDER: <ReOrderPopup />,
  };

  const claimInfoItem = [
    {
      children: (
        <div className="order-details">
          <div>
            <p>
              <strong>Order Created</strong>
            </p>
            <p className="claim-date">
              {dateConvert(ClaimDetails.Claims.orders.order_createdAt)}
            </p>
          </div>
          <div className="original-order">
            <Link
              to={`https://${ClaimDetails.Claims.merchant?.domain}/admin/orders/${ClaimDetails.Claims.orders.id}`}
            >
              Original Order
            </Link>
          </div>
        </div>
      ),
      dot: <div className="order-dots"></div>,
    },
    {
      children: (
        <div className="order-details">
          <div>
            <p>
              <strong>Claim Created</strong>
            </p>
            <p className="claim-date">
              {" "}
              {dateConvert(ClaimDetails.Claims.claim_createdAt)}
            </p>
          </div>
        </div>
      ),
      dot: <div className="order-dots"></div>,
    },
  ];

  if (ClaimDetails.Claims?.resolved_date) {
    claimInfoItem.push({
      children: (
        <div className="order-details">
          <div>
            <p>
              <strong>Claim Resolved</strong>
            </p>
            <p className="claim-date">
              {dateConvert(ClaimDetails.Claims?.resolved_date)}
            </p>
          </div>
          {!ClaimDetails.Claims?.is_refund && (
            <div className="original-order">
              <Link
                to={`https://${ClaimDetails.Claims.merchant?.domain}/admin/orders/${ClaimDetails.Claims?.reorder_id}`}
              >
                Claim Order
              </Link>
            </div>
          )}
        </div>
      ),
      dot: ClaimDetails.Claims?.resolved_date && (
        <div className="order-dots"></div>
      ),
    });
  }

  return (
    <>
      {onApprovedLoading || claimEventsLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : null}
      <div className="claim-detail-main">
        <div className="claim-detail-container">
          <div className="order-detail-header">
            <button className="arrow-box" onClick={() => navigate(-1)}>
              <img src={left_arrow} alt="left_arrow" className="arrow-image" />
            </button>
            <div className="order-detail-header-content">
              <p className="header-content-title">Claim / Claim Details</p>
              <h2 className="header-content-heading">
                Claim #{" "}
                {ClaimDetails.Claims.orders.name
                  ? ClaimDetails.Claims.orders.name
                  : "-"}
              </h2>
            </div>
          </div>
          <div className="claim-details-main-section">
            <div className="claim-details-df">
              <div className="card-details-heading">
                <div className="Heading-left">
                  <div className="orde-no-id">
                    <h2>{ClaimDetails.Claims.orders.name}</h2>
                  </div>
                </div>
                <div className="name">
                  <h2 className="merchantName">
                    {ClaimDetails?.Claims?.merchant?.name}
                  </h2>
                </div>
                <div className="Heading-right">
                  <Select
                    size="large"
                    showSearch
                    value={ClaimDetails.Claims.status}
                    placeholder="Select status"
                    onChange={onChangeStatus}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={ClaimStatus}
                    style={{ width: 180 }}
                  />
                </div>
              </div>
              <div className="card-details-left">
                <div className="card-details-top">
                  <div className="box">
                    <div className="latest-Head">Product Name</div>
                    {ClaimDetails.Claims.orders.line_item.map((item, index) => {
                      return (
                        <div className="tab" key={index}>
                          <div className="tab-left-section">
                            <div className="bg-number">{item.quantity}</div>
                            <div>
                              <h3>{item.name}</h3>
                              <p className="variant-title">
                                {item.variant_title}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="claim-price">$ {item.price}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="box">
                    <div className="latest-Head">Latest Update</div>
                    <div className="latest-time">
                      <p>
                        <strong>CLAIM :</strong> {ClaimDetails.Claims.status}
                      </p>
                      <p className="latest-claim-date">
                        {dateConvert(ClaimDetails.Claims.last_update)}
                      </p>
                    </div>
                    <div className="latest-que">
                      <span className="latest-que-title">Questions?</span>
                      <Link to="mailto:swipe@swipe.ai">
                        <span>Reach out to your Merchant Success Manager</span>
                      </Link>
                    </div>
                  </div>
                </div>
                {ClaimDetails.Claims.status === "APPROVED" && (
                  <div className="card-details-bottom">
                    <div className="box">
                      <div className="refund-section">
                        <div className="Heading-refund">Refund</div>
                        <div className="refund-main">
                          <div className="refund-section-main">
                            <Select
                              size="large"
                              value={selectedValue}
                              placeholder="Select status.."
                              onChange={onSelectRefundStatus}
                              options={RefundOption}
                              style={{ width: 180 }}
                              disabled={disabled}
                            />
                            <InputNumber
                              size="large"
                              value={refundAmount}
                              onChange={onChangeRefund}
                              placeholder="Enter amount"
                              type="number"
                              style={{ width: 130 }}
                              min={1}
                              controls={false}
                              disabled={disabled}
                            />
                            {selectedValue === "REORDER" && (
                              <div className="reorder-input">
                                <Input
                                  size="large"
                                  className="ReorderInput"
                                  value={reOrderId}
                                  onChange={onChangeReOrder}
                                  placeholder="Enter Re-Order Id"
                                  style={{ width: 200 }}
                                  min={1}
                                  controls={false}
                                  disabled={disabled}
                                />
                              </div>
                            )}
                            {selectedValue === "REFUND" && (
                              <div className="reorder-input">
                                <Input
                                  size="large"
                                  className="ReorderInput"
                                  value={refundNote}
                                  onChange={onChangeRefundNote}
                                  placeholder="Enter Refund Note"
                                  style={{ width: '100%' }}
                                  min={1}
                                  controls={false}
                                  disabled={disabled}
                                />
                              </div>
                            )}
                          </div>
                          {!disabled && (
                            <button
                              className="primary_btn btn"
                              onClick={onRefundSubmit}
                              disabled={onApprovedLoading}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="box">
                  <div className="Heading-Timeline">Timeline</div>
                  {ClaimDetails.Claims.status !== "APPROVED" && (
                    <div>
                      <TextArea
                        allowClear
                        value={comments}
                        style={{
                          height: 120,
                          marginBottom: 24,
                          resize: "none",
                        }}
                        onChange={onWriteCommnet}
                        placeholder="Write your comments..."
                      />
                      {error && (
                        <div className="field-error">{t("KINDLY_PROVIDE")}</div>
                      )}
                      <div className="comments-buttons">
                        <button
                          className="primary_btn btn"
                          onClick={handleAddComments}
                          disabled={claimEventsLoading}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="timeline-body">
                    <Timeline
                      mode={"left"}
                      items={ClaimDetails.Claims.events.map((item) => {
                        return claimTimeLine(item);
                      })}
                      reverse={true}
                    />
                  </div>
                </div>
              </div>
              <div className="card-details-right">
                <div className="box">
                  <div className="claim-info">Claim Info</div>
                  <div className="claim-info-details">
                    <div className="claim-align-outer">
                      <div className="claim-align-inner">
                        <p className="claim-title-reason">
                          <span>
                            <strong>Reason : </strong>
                            {ClaimDetails.Claims.reason}
                          </span>
                          <p
                            className={
                              ClaimDetails.Claims.refund_status &&
                              "refund-status"
                            }
                          >
                            {ClaimDetails.Claims.refund_status}
                          </p>
                        </p>
                        <div className="claim-reason">
                          <strong>Description : </strong>
                          {ClaimDetails.Claims.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Timeline items={claimInfoItem} />
                </div>
                <div className="box">
                  <div className="claim-info">
                    Claim Count :{" "}
                    {ClaimDetails?.Claims?.customerClaim?.total_count}
                  </div>
                  <div className="claim-info-details">
                    <div className="claim-count">
                      {ClaimDetails?.Claims?.customerClaim?.merchants?.length >
                      0 ? (
                        ClaimDetails?.Claims?.customerClaim?.merchants?.map(
                          (item, index) => {
                            return (
                              <div key={index} className="merchant-outer">
                                <div className="merchant-name-container">
                                  <div className="merchant-left">
                                    <div className="dot"></div>
                                    <div className="merchant-name">
                                      <strong className="merchant-right">
                                        {item.count}
                                      </strong>{" "}
                                      claims for{" "}
                                      <strong className="merchantName">
                                        {item.name}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <p className="no-record-found">No records found</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="claim-info">Claim Logs</div>
                  <div className="note-timeline">
                    {notes?.notes?.data?.length > 0 ? (
                      <Timeline
                        items={notes?.notes?.data?.map((item, index) => {
                          return {
                            children: (
                              <div className="claim-note-details" key={index}>
                                <div>
                                  <p>
                                    <strong>{item.content}</strong>
                                  </p>
                                  <div className="claim-note">
                                    <span className="claim-date">
                                      {dateConvert(item.ts * 1000)}
                                    </span>
                                    <span>
                                      By:{" "}
                                      {item.created_by ? item.created_by : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ),
                            dot: (
                              <div
                                className="order-dots"
                                key={`dot-${index}`}
                              ></div>
                            ),
                          };
                        })}
                      />
                    ) : (
                      <p className="no-record-found">No records found</p>
                    )}
                  </div>
                </div>
                <div className="box">
                  <div className="claim-info">
                    Leave Notes for Internal team
                  </div>
                  <div className="detail-box">
                    <div className="note-section">
                      <TextArea
                        placeholder="Write a Notes..."
                        value={note}
                        onChange={handleNoteChange}
                        rows={5}
                      />
                    </div>
                    <div className="note-button">
                      <button
                        className="primary_btn btn"
                        onClick={handleAddNote}
                      >
                        Leave Notes
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="claim-info">Customer</div>
                  <div className="claim-info-details-main">
                    <div className="claim-info-details-left">
                      <div className="claim-info-details">
                        <p className="claim-info-details-title">
                          {ClaimDetails.Claims.orders.customer_name}
                        </p>
                        <div>
                          <div className="claim-info-mail">
                            <CustomerMail height={20} width={20} />
                            <Link
                              to={`mailto:${ClaimDetails.Claims.orders.customer_email}`}
                            >
                              {ClaimDetails.Claims.orders.customer_email}
                            </Link>
                          </div>
                          {ClaimDetails.Claims.orders.address?.phone && (
                            <div className="claim-info-phone">
                              <BsTelephone color="#000" size={18} />
                              <p>{ClaimDetails.Claims.orders.address?.phone}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {ClaimDetails.Claims.orders.address && (
                        <div className="claim-info-address">
                          <CustomerAddress height={20} width={20} />
                          <div className="text-address">
                            <p>
                              {ClaimDetails.Claims.orders.address?.address1}
                            </p>
                            <p>
                              {ClaimDetails.Claims.orders.address?.address2}
                            </p>
                            <p>
                              {ClaimDetails.Claims.orders.address?.city}{" "}
                              {ClaimDetails.Claims.orders.address?.zip}{" "}
                              {ClaimDetails.Claims.orders.address?.province}{" "}
                              {ClaimDetails.Claims.orders.address?.country}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="customer-total-claims">
                      <div className="number-total">
                        <span>{ClaimDetails?.Claims?.total_claim_no}</span>
                        <sup>
                          {indicator(ClaimDetails?.Claims?.total_claim_no)}
                        </sup>
                      </div>
                      <p className="number-claim">Claim</p>
                    </div>
                  </div>
                </div>
                {ClaimDetails.Claims.tracking_numbers &&
                  ClaimDetails.Claims.tracking_numbers.length > 0 && (
                    <div className="box">
                      <div className="track-order-info">
                        <div className="track-order">Track order</div>
                        <div>
                          {ClaimDetails.Claims.tracking_numbers?.map(
                            (numbers) => (
                              <div>
                                <p
                                  onClick={() => handleClick(numbers)}
                                  style={{
                                    color: "#00ccff",
                                    cursor: "pointer",
                                  }}
                                >
                                  {numbers}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                        <div className="tracking-company">
                          Tracking Company :
                          {ClaimDetails.Claims.tracking_company.map(
                            (company) => (
                              <div>
                                <p>{company}</p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                <div className="box">
                  <div className="claim-info">Totals</div>
                  <div className="claim-info-Total">
                    <div className="total-df">
                      <p>Claim Total</p>
                      <p>$ {ClaimDetails.Claims.total.claim_total}</p>
                    </div>
                    <div className="total-df">
                      <p>Refund Total</p>
                      <p>$ {ClaimDetails.Claims.total.refund_total}</p>
                    </div>
                    <div className="total-df">
                      <p>Reorder Total</p>
                      <p>$ {ClaimDetails.Claims.total.reorder_total}</p>
                    </div>
                  </div>
                  <div className="claim-info-address">
                    <p className="total-info">{t("THIS_AMOUNT_WLL")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        open={confirmModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setConfirmModal(false)}
        className="approve-popup"
      >
        <div className="user-delete-confirm-model">
          <div className="image-container">{popUpIcon[changedStatus]}</div>
          <div className="user-delete-text">
            Are you sure you want to make this claim{" "}
            {changedStatus === "CLOSED" ? "REJECTED" : changedStatus}
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={() => setConfirmModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => onConfirmChangeStatus()}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={confirmRefundModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setConfirmRefundModal(false)}
        className="approve-popup"
      >
        <div className="user-delete-confirm-model">
          <div className="image-container">{popUpIcon[selectedValue]}</div>
          <div className="user-delete-text">
            Are you sure you want to proceed with a {selectedValue} of{" "}
            {refundAmount} amount ?
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={() => setConfirmRefundModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => handleRefundSubmit()}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DetailsPage;
