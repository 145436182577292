import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance  from "../../authServices/axiosInstance";

export const merchantList = createAsyncThunk("storelist", async () => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const response = await AxiosInstance.get(`${API_URL}/admin/merchant/storelist`, {
      headers: { "x-access-token": token },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
});

const merchantListSlice = createSlice({
  name: "storelist",
  initialState: {
    isLoading: false,
    merchantList: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [merchantList.pending]: (state) => {
      state.isLoading = true;
    },
    [merchantList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.merchantList = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [merchantList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.claimList = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default merchantListSlice.reducer;
