import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import StatementPage from "./statement";
import Loading from "../../../../common/Loading/Loading";
import { previewStatements } from "../../../../../redux/slice/previewStatementsSlice";
import { adminStatement } from "../../../../../redux/slice/adminStatementSlice";
import { merchantList } from "../../../../../redux/slice/merchantListSlice";
import { statementOverview } from "../../../../../redux/slice/statementOverviewSlice";

const AdminStatement = (props) => {
  const {
    callGetAdminStatements,
    adminStatements,
    callPreviewStatements,
    callGetAdminmerchantList,
    merchantList,
    callStatementOverview,
    statementOverview,
  } = props;

  useEffect(() => {
    callGetAdminmerchantList();
  }, [callGetAdminmerchantList]);

  const [filter, setFilter] = useState({
    month: 0,
    year: 0,
    merchantId: 0,
  });
  useEffect(() => {
    let month = filter.month === "all" ? 0 : filter.month;
    let year = filter.year === "all" ? 0 : filter.year;
    let merchantId = filter.merchantId === "all" ? 0 : filter.merchantId;
    if (month !== 0 || year !== 0 || merchantId !== 0) {
      callGetAdminStatements({ month, year, merchantId });
    } else {
      callGetAdminStatements({});
    }
  }, [filter, callGetAdminStatements]);

  return (
    <div>
      {adminStatements.isLoading && (
        <Loading type="overlay" className="loader-icon" />
      )}
      {adminStatements.adminStatement && merchantList && (
        <StatementPage
          adminStatementsList={adminStatements.adminStatement}
          callPreviewStatements={callPreviewStatements}
          merchantList={merchantList.merchantList}
          filter={filter}
          setFilter={setFilter}
          callStatementOverview={callStatementOverview}
          statementOverview={statementOverview.statementOverview}
          statementLoading={statementOverview.isLoading}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adminStatements: state.adminStatments,
    adminStatmentsData: state.adminStatments,
    merchantList: state.merchantList,
    statementOverview: state.statementOverview,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callPreviewStatements: (props) => dispatch(previewStatements(props)),
    callGetAdminStatements: (props) => dispatch(adminStatement(props)),
    callGetAdminmerchantList: () => dispatch(merchantList()),
    callStatementOverview: (props) => dispatch(statementOverview(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStatement);
