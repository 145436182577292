import React from "react";
import { Tabs } from "antd";
import WidgetComponents from "./widget";
import ConfigTabComponent from "./configTab";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";

const ConfigBarPage = ({ data, handleWidgetSave, editWidget,adminMerchantId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: "Configure Bar",
      children: (
        <ConfigTabComponent configInfo={data} editWidget={editWidget} adminMerchantId={adminMerchantId} />
      ),
    },
    {
      key: "2",
      label: "Widget",
      children: (
        <WidgetComponents
          widgetInfo={data}
          handleWidgetSave={handleWidgetSave}
        />
      ),
    },
  ];
  const { id } = useParams();
  const cookies = new Cookies();
  const role = cookies.get("role");

  const handleBack = () => {
    if (role === "merchant") {
      navigate(`/dashboard/admin`);
    } else {
      navigate(`/dashboard/storeDetail/${id}`);
    }
  };

  return (
    <>
      <div className="onBoard-main">
        <div className="onBoard-container">
          <div className="onBoard-main-section">
            <div className="config-main">
              <p className="backToSetting-icon" onClick={handleBack}>
                <i className="fa-solid fa-arrow-left"></i>{" "}
                {role === "merchant" ? t("CONFIGBAR_STR_BACK") : "Back to Store Details"}
              </p>
              <p> {t("CONFIGBAR_STR_FREE")}</p>
              <p className="config-contain">
                {t("CONFIGBAR_STR_REWARD")} <br />
                {t("CONFIGBAR_STR_COVER")}
              </p>
            </div>

            <div className="childConfig-container">
              <div className="general-styleContainer">
                <div className="firstChildContainer">
                  <Tabs type="card" items={items} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigBarPage;
