import React, { useState } from "react";
import { ReactComponent as Edit } from "../../../../assets/images/editIcons.svg";
import MerchantProfile from "./drawer/MerchantProfile";
import MerchantCategory from "./drawer/MerchantCategory";
import CompanyContact from "./drawer/CompanyContact";
import SupportContact from "./drawer/SupportContact";
import ReturnContact from "./drawer/ReturnContact";
import { useLocation, useNavigate } from "react-router-dom";

const BrandedContentTab = ({
  formData,
  setFormData,
  handleSubmit,
  defaultData,
  handleClose,
}) => {
  const [open, setOpen] = useState([]);

  const handleSave = (id, open) => {
    handleSubmit(formData);
    toggleDrawer(id, open);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const queryParams = new URLSearchParams(location.search);
  const modalValue = queryParams.get("modal");
  if (modalValue === "profile") {
    open[0] = modalValue;
  } else if (modalValue === "category") {
    open[1] = modalValue;
  }

  const toggleDrawer = (id, target) => {
    setOpen((p) => {
      p[id] = target;
      return [...p];
    });
    handleClose();
    navigate(currentPath);
  };

  return (
    <>
      <div className="engage-content">
        <div className="left-section">
          <div className="engage-body">
            <div className="engage-box">
              <div className="engage-box-title">
                <span className="title">In-App Merchant Profile</span>{" "}
                <div
                  className="edit-round"
                  onClick={() => toggleDrawer(0, true)}
                >
                  <Edit height={20} width={20} />
                </div>
              </div>
              <div className="engage-body-detail">
                <div className="engage-detail-title">Store Name:</div>
                <div className="engage-detail-content">
                  {defaultData?.store_name ? defaultData.store_name : "--"}
                </div>
              </div>
              <div className="engage-body-detail">
                <div className="engage-detail-title">Website:</div>
                <div className="engage-detail-content">
                  {defaultData?.website ? defaultData.website : "--"}
                </div>
              </div>
              <div className="engage-body-detail">
                <div className="engage-detail-title">Bio:</div>
                <div className="engage-detail-content">
                  {defaultData?.bio ? defaultData.bio : "--"}
                </div>
              </div>
              <div className="engage-body-detail">
                <div className="engage-detail-title">Merchant Causes:</div>
                <div className="engage-detail-content">
                  {defaultData?.causes.length > 0
                    ? defaultData.causes.join(", ")
                    : "--"}
                </div>
              </div>
              <div className="engage-body-detail">
                <div className="engage-detail-title">Logo:</div>
                <div className="engage-detail-content">
                  {defaultData?.logo ? (
                    <img
                      src={defaultData.logo}
                      className="preview-logo"
                      alt=""
                    />
                  ) : (
                    "--"
                  )}
                </div>
              </div>
              <div className="engage-body-detail">
                <div className="engage-detail-title">Cover Image:</div>
                <div className="engage-detail-content">
                  {defaultData?.cover_image ? (
                    <img
                      src={defaultData.cover_image}
                      className="preview-cover"
                      alt=""
                    />
                  ) : (
                    "--"
                  )}
                </div>
              </div>
            </div>
            <div className="engage-box">
              <div className="engage-box-title">
                <span className="title">Merchant Categories</span>{" "}
                <div
                  className="edit-round"
                  onClick={() => toggleDrawer(1, true)}
                >
                  <Edit height={20} width={20} />
                </div>
              </div>

              <div className="engage-body-detail">
                <div className="engage-detail-title">Categories</div>
                <div className="engage-detail-content">
                  {defaultData?.merchant_categories.length > 0
                    ? defaultData.merchant_categories.join(", ")
                    : "--"}
                </div>
              </div>
              <div className="engage-body-detail">
                <div className="engage-detail-title">Age</div>
                <div className="engage-detail-content">
                  {}
                  {defaultData?.merchant_categories_age.length > 0
                    ? defaultData.merchant_categories_age.join(", ")
                    : "--"}
                </div>
              </div>
              <div className="engage-body-detail">
                <div className="engage-detail-title">Gender</div>
                <div className="engage-detail-content">
                  {defaultData?.merchant_categories_gender.length > 0
                    ? defaultData.merchant_categories_gender.join(", ")
                    : "--"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="engage-body">
            <div className="engage-box">
              <div className="engage-contact-box-title">
                Contact Info: Customer-Facing
              </div>
              <div className="contact-comm">
                <div className="contact-detail-row">
                  <span className="title">Company Contact</span>{" "}
                  <div
                    className="edit-round"
                    onClick={() => toggleDrawer(2, true)}
                  >
                    <Edit height={20} width={20} />
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Email </div>
                  <div className="contact-detail-row-value">
                    {" "}
                    {defaultData?.company_contact_email
                      ? defaultData.company_contact_email
                      : "--"}
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Phone</div>
                  <div className="contact-detail-row-value">
                    {defaultData?.company_contact_phone
                      ? defaultData.company_contact_phone
                      : "--"}
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Link </div>
                  <div className="contact-detail-row-value">
                    {defaultData?.company_contact_link
                      ? defaultData.company_contact_link
                      : "--"}
                  </div>
                </div>
              </div>
              <div className="contact-comm">
                <div className="contact-detail-row">
                  <span className="title">Support Contact</span>{" "}
                  <div
                    className="edit-round"
                    onClick={() => toggleDrawer(3, true)}
                  >
                    <Edit height={20} width={20} />
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Email </div>
                  <div className="contact-detail-row-value">
                    {" "}
                    {defaultData?.support_contact_email
                      ? defaultData.support_contact_email
                      : "--"}
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Phone</div>
                  <div className="contact-detail-row-value">
                    {defaultData?.support_contact_phone
                      ? defaultData.support_contact_phone
                      : "--"}
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Link </div>
                  <div className="contact-detail-row-value">
                    {defaultData?.support_contact_link
                      ? defaultData.support_contact_link
                      : "--"}
                  </div>
                </div>
              </div>
              <div className="contact-comm">
                <div className="contact-detail-row">
                  <span className="title">Returns Contact</span>{" "}
                  <div
                    className="edit-round"
                    onClick={() => toggleDrawer(4, true)}
                  >
                    <Edit height={20} width={20} />
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Email </div>
                  <div className="contact-detail-row-value">
                    {" "}
                    {defaultData?.returns_contact_email
                      ? defaultData.returns_contact_email
                      : "--"}
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Phone</div>
                  <div className="contact-detail-row-value">
                    {defaultData?.returns_contact_phone
                      ? defaultData.returns_contact_phone
                      : "--"}
                  </div>
                </div>
                <div className="contact-detail-row">
                  <div className="contact-detail-row-title">Link </div>
                  <div className="contact-detail-row-value">
                    {defaultData?.returns_contact_link
                      ? defaultData.returns_contact_link
                      : "--"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MerchantProfile
        onClose={() => toggleDrawer(0, false)}
        open={open[0]}
        formData={formData}
        setFormData={setFormData}
        handleSave={handleSave}
        defaultData={defaultData}
      />
      <MerchantCategory
        onClose={() => toggleDrawer(1, false)}
        open={open[1]}
        formData={formData}
        setFormData={setFormData}
        handleSave={handleSave}
      />
      <CompanyContact
        onClose={() => toggleDrawer(2, false)}
        open={open[2]}
        formData={formData}
        setFormData={setFormData}
        handleSave={handleSave}
      />
      <SupportContact
        onClose={() => toggleDrawer(3, false)}
        open={open[3]}
        formData={formData}
        setFormData={setFormData}
        handleSave={handleSave}
      />
      <ReturnContact
        onClose={() => toggleDrawer(4, false)}
        open={open[4]}
        formData={formData}
        setFormData={setFormData}
        handleSave={handleSave}
      />
    </>
  );
};

export default BrandedContentTab;
