import { Form, Input, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { BiUser } from "react-icons/bi";
import Loading from "../common/Loading/Loading";
import { BsShieldLockFill } from "react-icons/bs";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Logo from "../../../src/assets/images/logo/LogoBlack.png";
import { validEmail } from "../../utils/regexs";
import backgroundVideo from "./../../assets/Main _BG_Login.mp4";
import { useTranslation } from "react-i18next";

const LoginComponent = (props) => {
  const {
    dashboardLogin,
    resetPasswordLinkLoading,
    handleOnChange,
    handleResetPassword,
    handleSubmit,
    handleEmailChange,
    resetPasswordEmail,
    setResetPasswordEmail,
    formData,
  } = props;
  const { t } = useTranslation();
  const [forgetPasswordPage, setForgetPasswordPage] = useState(false);
  const [role, setRole] = useState(true);
  const handleUserChange = () => {
    setRole(!role);
  };

  return (
    <div className="login-page">
      <div className="login-page-container">
        <div className="page-title">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <p className="user-role">
            {role ? " Merchant Dashboard" : " Admin Dashboard"}
          </p>
        </div>

        {forgetPasswordPage ? (
          <div className="login-right-side">
            <div className="login-title">Forgot Password?</div>
            <div className="forgot-text">{t("LOGIN_ENT")}</div>
            <div className="login-form">
              <Form>
                <div className="login-form-input">
                  <Form.Item
                    name="resetEmail"
                    rules={[
                      {
                        pattern: validEmail,
                        required: true,
                        message: t("MSG_EMAIL"),
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="resetEmail"
                      placeholder="Email"
                      prefix={<BiUser />}
                      onChange={handleEmailChange}
                      value={resetPasswordEmail}
                      suffix={
                        <Tooltip title={t("TOOLTIP_MAIL")}>
                          <AiOutlineInfoCircle />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </div>
                <div className="login-button">
                  <button
                    className="primary_btn btn login-button-btn"
                    type="submit"
                    onClick={handleResetPassword}
                    disabled={resetPasswordLinkLoading}
                  >
                    {resetPasswordLinkLoading ? (
                      <Loading type="spinner" color="#fff" />
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </div>
              </Form>
              <div
                className="sign-in"
                onClick={() => {
                  setForgetPasswordPage(false);
                  setResetPasswordEmail("");
                }}
              >
                Sign In
              </div>
            </div>
          </div>
        ) : (
          <div className="login-right-side">
            <div className="login-title">Sign in</div>
            <div className="login-form">
              <Form>
                <div className="login-form-input">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        pattern: validEmail,
                        required: true,
                        message: t("MSG_EMAIL"),
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      prefix={<BiUser />}
                      name="email"
                      value={formData.email}
                      placeholder="Email"
                      onChange={handleOnChange}
                    />
                  </Form.Item>
                  <Space direction="vertical">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter password",
                        },
                      ]}
                    >
                      <Input.Password
                        name="password"
                        value={formData.password}
                        placeholder="Password"
                        prefix={<BsShieldLockFill />}
                        onChange={handleOnChange}
                        className="login-password"
                      />
                    </Form.Item>
                  </Space>
                  <div
                    className="login-forget"
                    onClick={() => setForgetPasswordPage(true)}
                  >
                    Forgot Password?
                  </div>
                </div>

                <div className="login-button">
                  <button
                    className="primary_btn btn login-button-btn"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={dashboardLogin.isLoading}
                  >
                    {dashboardLogin.isLoading ? (
                      <Loading type="spinner" color="#fff" />
                    ) : (
                      "Sign in"
                    )}
                  </button>
                </div>
              </Form>
            </div>
            <div className="account">Don't have a login?</div>
            <div className="account" onClick={handleUserChange}>
              Want to login as <strong> {role ? "Admin" : "Merchant"}</strong>
            </div>
            <div className="login-termCond">
              <p>
                By continuing, you are agreeing to our{" "}
                <a
                  href="https://swipe.ai/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://swipe.ai/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        )}
      </div>

      <video autoPlay muted loop id="background-video">
        <source src={backgroundVideo} />
      </video>
    </div>
  );
};

export default LoginComponent;
