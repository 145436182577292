import { ReactComponent as CLAIM_APPROVED } from "../../../../assets/images/Icons_CLAIM_APPROVED.svg";
import { ReactComponent as CLAIM_CLOSED } from "../../../../assets/images/Icons_CLAIM_CLOSED.svg";
import { ReactComponent as CLAIM_CREATED } from "../../../../assets/images/Icons_CLAIM_CREATED.svg";
import { ReactComponent as CLAIM_REJECTED } from "../../../../assets/images/Icons_CLAIM_REJECTED.svg";
import { ReactComponent as COMMENT } from "../../../../assets/images/Icons_COMMENT.svg";
import { ReactComponent as ORDER_CREATED } from "../../../../assets/images/Icons_ORDER_CREATED.svg";
import { ReactComponent as SEND_INITIAL_EMAIL } from "../../../../assets/images/Icons_SEND_INITIAL_EMAIL.svg";
import { dateConvert } from "../../../../utils/helper";
export const claimTimeLine = (item) => {
  const label = (
    <>
      {item.ts && (
        <div
          style={{
            marginRight: 20,
            fontWeight: 500,
            fontSize: 14,
            color: "#767676",
          }}
        >
          <span>{dateConvert(item.ts * 1000)} </span>
        </div>
      )}
    </>
  );
  let children = "";
  let dot = null;
  if (item.type === "ACTION") {
    if (item.sub_type === "ORDER_CREATED") {
      children = (
        <div
          style={{
            marginLeft: 10,
            fontSize: 16,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Order Created
          {label}
        </div>
      );
      dot = (
        <div className="timeline-circle">
          <ORDER_CREATED />
        </div>
      );
    } else if (item.sub_type === "CLAIM_CREATED") {
      children = (
        <div
          style={{
            marginLeft: 10,
            fontSize: 16,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Claim Created
          {label}
        </div>
      );
      dot = (
        <div className="timeline-circle">
          <CLAIM_CREATED />
        </div>
      );
    } else if (item.sub_type === "CLAIM_APPROVED") {
      children = (
        <div
          style={{
            marginLeft: 10,
            fontSize: 16,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Claim Approved
          {label}
        </div>
      );
      dot = (
        <div className="timeline-circle approved">
          <CLAIM_APPROVED />
        </div>
      );
    } else if (item.sub_type === "CLAIM_CLOSED") {
      children = (
        <div
          style={{
            marginLeft: 10,
            fontSize: 16,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Claim Closed
        </div>
      );
      dot = (
        <div className="timeline-circle closed">
          <CLAIM_CLOSED />
        </div>
      );
    } else if (item.sub_type === "CLAIM_REJECTED") {
      children = (
        <div
          style={{
            marginLeft: 10,
            fontSize: 16,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Claim Closed
        </div>
      );
      dot = (
        <div className="timeline-circle closed">
          <CLAIM_REJECTED />
        </div>
      );
    }
  } else if (item.type === "COMMENT") {
    if (item.content === "Send Initial Email") {
      children = (
        <div
          style={{
            marginLeft: 10,
            fontSize: 16,
            fontWeight: 500,
            color: "#000",
          }}
        >
          {item.content}
          {label}
        </div>
      );
      dot = (
        <div className="timeline-circle">
          <SEND_INITIAL_EMAIL />
        </div>
      );
    } else {
      children = (
        <div
          style={{
            marginLeft: 10,
            fontSize: 16,
            fontWeight: 500,
            color: "#000",
          }}
        >
          {item.content}
          {label}
        </div>
      );
      dot = (
        <div className="timeline-circle">
          <COMMENT />
        </div>
      );
    }
  }
  return {
    children,
    dot,
  };
};
