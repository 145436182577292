import React from "react";
import RightDrawer from "../../../../common/drawer/drawer";
import { Form, Input } from "antd";
import { validEmail } from "../../../../../utils/regexs";
import { useTranslation } from "react-i18next";
import { splitAndMap } from "../../../../../utils/helper";

const SupportContact = ({
  onClose,
  open,
  formData,
  setFormData,
  handleSave,
}) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <RightDrawer
      title="Support Contact"
      onClose={onClose}
      visible={open}
      onSubmit={() => handleSave(3, false)}
      buttonTitle="Save"
    >
      <div className="company-contact-drawer">
        <p className="info">{splitAndMap(t("SUPPORTCONTACT_STR_PROVIDE"))}</p>
        <div className="form-container">
          <Form
            name="basic"
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  pattern: validEmail,
                  message: t("MSG_EMAIL"),
                },
              ]}
            >
              <Input
                placeholder="support_contact_email"
                name="support_contact_email"
                onChange={handleChange}
                value={formData?.support_contact_email}
                defaultValue={formData?.support_contact_email}
              />
            </Form.Item>
            <Form.Item
              label="Phone"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                type="number"
                placeholder="Phone"
                name="support_contact_phone"
                onChange={handleChange}
                value={formData?.support_contact_phone}
                defaultValue={formData?.support_contact_phone}
              />
            </Form.Item>
            <Form.Item
              label="Link"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                placeholder="Link"
                name="support_contact_link"
                onChange={handleChange}
                value={formData?.support_contact_link}
                defaultValue={formData?.support_contact_link}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </RightDrawer>
  );
};

export default SupportContact;
