import React from "react";
import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const RightDrawer = (props) => {
  const {
    title,
    visible,
    children,
    onClose,
    onSubmit,
    buttonTitle,
    width = "378",
  } = props;
  return (
    <Drawer
      title={
        <div className="drawer-header">
          <span>{title}</span>
          <span style={{ float: "right" }}>
            <CloseOutlined onClick={onClose} className="drawer-close-icon" />
          </span>
        </div>
      }
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      width={width}
      footer={
        <div className="drawer-footer">
          <button className="primary_btn btn" type="submit" onClick={onClose}>
            Cancel
          </button>
          <button className="primary_btn btn" type="submit" onClick={onSubmit}>
            {buttonTitle}
          </button>
        </div>
      }
    >
      {children}
    </Drawer>
  );
};

export default RightDrawer;
