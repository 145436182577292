import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance from "../../authServices/axiosInstance";
import { Cookies } from "react-cookie";

export const updateEngageContent = createAsyncThunk(
  "updateEngageContent",
  async (data) => {
    const { finalData, merchantId } = data;
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const role = cookies.get("role");
      const merchantAPI = `${API_URL}/merchant/engage/upsert`;
      const adminAPI = `${API_URL}/admin/engage/upsert/${merchantId}`;
      const url = role === "admin" ? adminAPI : merchantAPI;
      const response = await AxiosInstance.post(url, finalData, {
        headers: { "x-access-token": token },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const updateEngageContentSlice = createSlice({
  name: "updateEngageContent",
  initialState: {
    isLoading: false,
    upldateEngageContent: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [updateEngageContent.pending]: (state) => {
      state.isLoading = true;
    },
    [updateEngageContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.upldateEngageContent = action.payload;
    },
    [updateEngageContent.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default updateEngageContentSlice.reducer;
