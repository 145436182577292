import { Collapse, Switch, Tooltip } from 'antd';
import React, {useState} from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { MdOutlineInfo } from 'react-icons/md';
import { SlWrench } from 'react-icons/sl';
import { useTranslation } from 'react-i18next';

const ShippingNotificationTab = ({
  engageNoticationData,
  handleNotificationSubmit
}) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();

  const [notificationData, setEngageNotificationData] = useState({
    order_created_email: engageNoticationData?.order_created_email,
    order_created_sms: engageNoticationData?.order_created_sms,
    order_created_notifications:
      engageNoticationData?.order_created_notifications,
    shipping_email: engageNoticationData?.shipping_email,
    shipping_sms: engageNoticationData?.shipping_sms,
    shipping_notifications: engageNoticationData?.shipping_notifications,
    shipment_deley_email: engageNoticationData?.shipment_deley_email,
    shipment_deley_sms: engageNoticationData?.shipment_deley_sms,
    shipment_deley_notifications:
      engageNoticationData?.shipment_deley_notifications,
    in_transit_email: engageNoticationData?.in_transit_email,
    in_transit_sms: engageNoticationData?.in_transit_sms,
    in_transit_notifications: engageNoticationData?.in_transit_notifications,
    carrier_delay_email: engageNoticationData?.carrier_delay_email,
    carrier_delay_sms: engageNoticationData?.carrier_delay_sms,
    out_for_delivery_email: engageNoticationData?.out_for_delivery_email,
    out_for_delivery_sms: engageNoticationData?.out_for_delivery_sms,
    out_for_delivery_notifications:
      engageNoticationData?.out_for_delivery_notifications,
    delivered_email: engageNoticationData?.delivered_email,
    delivered_sms: engageNoticationData?.delivered_sms,
    delivered_notifications: engageNoticationData?.delivered_notifications
  });

  const onChange = async (checked, name) => {
    setEngageNotificationData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: checked
      };
      handleNotificationSubmit(updatedData);
      return updatedData;
    });
  };

  const preShipmentNotificationData = [
    {
      header: 'Order Confirmation/Created',
      title: 'NOTIFICATION TYPES',
      rowData: [
        {
          title: 'E-mail',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'order_created_email');
                }}
                checked={notificationData.order_created_email}
                disabled={false}
                name="order_created_email"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'SMS',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'order_created_sms');
                }}
                disabled={false}
                checked={notificationData.order_created_sms}
                name="order_created_sms"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'App Notifications',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'order_created_notifications');
                }}
                disabled={false}
                checked={notificationData.order_created_notifications}
                name="order_created_notifications"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        }
      ],
      settingEnble: true
    },
    {
      header: 'Shipping',
      title: 'NOTIFICATION TYPES',
      rowData: [
        {
          title: 'E-mail',
          enable: true,
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'shipping_email');
                }}
                disabled={false}
                checked={notificationData.shipping_email}
                name="shipping_email"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'SMS',
          enable: true,
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'shipping_sms');
                }}
                disabled={false}
                checked={notificationData.shipping_sms}
                name="shipping_sms"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'App Notifications',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'shipping_notifications');
                }}
                disabled={false}
                checked={notificationData.shipping_notifications}
                name="shipping_notifications"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        }
      ],
      settingEnble: false
    },
    {
      header: 'Shipment Pickup Delay',
      title: 'NOTIFICATION TYPES',
      rowData: [
        {
          title: 'E-mail',

          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'shipment_deley_email');
                }}
                disabled={false}
                checked={notificationData.shipment_deley_email}
                name="shipment_deley_email"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'SMS',

          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'shipment_deley_sms');
                }}
                disabled={false}
                checked={notificationData.shipment_deley_sms}
                name="shipment_deley_sms"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'App Notifications',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'shipment_deley_notifications');
                }}
                disabled={false}
                checked={notificationData.shipment_deley_notifications}
                name="shipment_deley_notifications"
              />
            </>
          )
        }
      ],
      settingEnble: false
    }
  ];
  const trackingNotificationData = [
    {
      header: 'In Transit',
      title: 'NOTIFICATION TYPES',
      rowData: [
        {
          title: 'E-mail',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'in_transit_email');
                }}
                disabled={false}
                checked={notificationData.in_transit_email}
                name="in_transit_email"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'SMS',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'in_transit_sms');
                }}
                disabled={false}
                checked={notificationData.in_transit_sms}
                name="in_transit_sms"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'App Notifications',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'in_transit_notifications');
                }}
                disabled={false}
                checked={notificationData.in_transit_notifications}
                name="in_transit_notifications"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        }
      ],
      settingEnble: false
    },
    {
      header: 'Carrier Delay',
      title: 'NOTIFICATION TYPES',
      rowData: [
        {
          title: 'E-mail',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'carrier_delay_email');
                }}
                disabled={false}
                checked={notificationData.carrier_delay_email}
                name="carrier_delay_email"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'SMS',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'carrier_delay_sms');
                }}
                disabled={false}
                checked={notificationData.carrier_delay_sms}
                name="carrier_delay_sms"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        }
      ],
      settingEnble: false
    },
    {
      header: 'Out for Delivery',
      title: 'NOTIFICATION TYPES',
      rowData: [
        {
          title: 'E-mail',
          enable: true,
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'out_for_delivery_email');
                }}
                disabled={false}
                checked={notificationData.out_for_delivery_email}
                name="out_for_delivery_email"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'SMS',
          enable: true,
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'out_for_delivery_sms');
                }}
                disabled={false}
                checked={notificationData.out_for_delivery_sms}
                name="out_for_delivery_sms"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'App Notifications',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'out_for_delivery_notifications');
                }}
                disabled={false}
                checked={notificationData.out_for_delivery_notifications}
                name="out_for_delivery_notifications"
              />
            </>
          )
        }
      ],
      settingEnble: false
    },
    {
      header: 'Delivered',
      title: 'NOTIFICATION TYPES',
      rowData: [
        {
          title: 'E-mail',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'delivered_email');
                }}
                disabled={false}
                checked={notificationData.delivered_email}
                name="delivered_email"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'SMS',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'delivered_sms');
                }}
                disabled={false}
                checked={notificationData.delivered_sms}
                name="delivered_sms"
              />
              <Tooltip title={t('TITLE_VIEW')}>
                <MdOutlineInfo size={24} />
              </Tooltip>
            </>
          )
        },
        {
          title: 'App Notifications',
          content: (
            <>
              <Switch
                onChange={(checked) => {
                  onChange(checked, 'delivered_notifications');
                }}
                disabled={false}
                checked={notificationData.delivered_notifications}
                name="delivered_notifications"
              />
            </>
          )
        }
      ],
      settingEnble: false
    }
  ];
  return (
    <div className="shipping-notification-tab">
      <Collapse
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 270} />
        )}
      >
        <Panel header="Pre-Shipment Notifications: 3" key="1">
          <div className="panel-content">
            <div className="left-content">
              <p className="title">Description</p>
              <p>{t('SHIP_NOTIFICATION')}</p>
              <button className="primary_btn btn">Learn more</button>
            </div>
            <div className="right-content">
              {preShipmentNotificationData.map((item, index) => (
                <div className="notification-box" key={index}>
                  <div className="notification-box-content">
                    <div className="notification-box-header">{item.header}</div>
                    <div className="notification-box-body">
                      <div className="notification-box-body-title">
                        {item.title}
                      </div>
                      {item.rowData.map((rowItem) => (
                        <div
                          className="notification-box-body-row"
                          key={rowItem.title}
                        >
                          <div className="row-title">{rowItem.title}</div>
                          <div className="row-content">{rowItem.content}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="notification-box-submit">
                    <button
                      type="button"
                      className={` ${
                        item.settingEnble ? 'primary_btn btn' : 'disable-btn'
                      }`}
                      disabled={item.settingEnble}
                    >
                      <SlWrench />
                      Settings
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Panel>
        <Panel header="Tracking Notifications: 4" key="2">
          <div className="panel-content">
            <div className="left-content">
              <p className="title">Description</p>
              <p>{t('TRACK_NOTIFICATION')}</p>
              <button className="primary_btn btn">Learn more</button>
            </div>
            <div className="right-content">
              {trackingNotificationData.map((item, index) => (
                <div className="notification-box" key={index}>
                  <div className="notification-box-content">
                    <div className="notification-box-header">{item.header}</div>
                    <div className="notification-box-body">
                      <div className="notification-box-body-title">
                        {item.title}
                      </div>
                      {item.rowData.map((rowItem) => (
                        <div
                          className="notification-box-body-row"
                          key={rowItem.title}
                        >
                          <div className="row-title">{rowItem.title}</div>
                          <div className="row-content">{rowItem.content}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="notification-box-submit">
                    <button
                      type="button"
                      className={` ${
                        item.settingEnble ? 'primary_btn btn' : 'disable-btn'
                      }`}
                      disabled={item.settingEnble}
                    >
                      <SlWrench />
                      Settings
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ShippingNotificationTab;
