import React, { useEffect, useState } from "react";
import { Checkbox, Form, Input } from "antd";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import RightDrawer from "../../../../common/drawer/drawer";
import imageCompression from "browser-image-compression";
import { merchantCauses } from "../../../../../utils/commonUtils";

const MerchantProfile = ({
  onClose,
  open,
  formData,
  setFormData,
  handleSave,
  defaultData,
}) => {
  const [fileList, setFileList] = useState([]);
  const [fileListLogo, setFileListLogo] = useState([]);

  const previousCoverImage = formData?.cover_image;
  const previousLogoImage = formData?.logo;

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = image.width;
        let height = image.height;

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(image, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };
    });
  };

  const dataURLtoFile = (dataURL, fileName) => {
    const arr = dataURL.split(",");

    if (!arr[0]) {
      console.error("Invalid dataURL format");
      return null;
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      console.error("Invalid mime type in dataURL");
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  useEffect(() => {
    if (previousCoverImage?.startsWith("data:image")) {
      const fileCoverImage = dataURLtoFile(
        previousCoverImage,
        "PreviousCoverImage.jpeg"
      );

      setFileList([
        {
          uid: "-1",
          name: "Previous Cover Image",
          status: "done",
          originFileObj: fileCoverImage,
        },
      ]);

      setFormData({
        ...formData,
        cover_image: previousCoverImage,
      });
    } else {
      setFileList([]);
    }
    if (previousLogoImage?.startsWith("data:image")) {
      const fileLogoImage = dataURLtoFile(
        previousLogoImage,
        "PreviousLogoImage.jpeg"
      );

      setFileListLogo([
        {
          uid: "-1",
          name: "Previous Logo Image",
          status: "done",
          originFileObj: fileLogoImage,
        },
      ]);

      setFormData({
        ...formData,
        logo: previousLogoImage,
      });
    } else {
      setFileListLogo([]);
    }
  }, [defaultData]);

  const onChangeCover = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      const resizedBlob = await resizeImage(
        newFileList[0].originFileObj,
        400,
        300
      );

      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({
          ...formData,
          cover_image: event.target.result,
        });
      };
      reader.readAsDataURL(resizedBlob);
    } else {
      setFormData({
        ...formData,
        cover_image: null,
      });
    }
  };

  const onPreviewCover = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChangeLogo = async ({ fileList: newFileListLogo }) => {
    setFileListLogo(newFileListLogo);
    if (newFileListLogo.length > 0) {
      const resizedBlob = await resizeImage(
        newFileListLogo[0].originFileObj,
        300,
        300
      );

      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({
          ...formData,
          logo: event.target.result,
        });
      };
      reader.readAsDataURL(resizedBlob);
    } else {
      setFormData({
        ...formData,
        logo: null,
      });
    }
  };

  const onPreviewLogo = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectionChange = (selectedOptions) => {
    setFormData({
      ...formData,
      causes: selectedOptions,
    });
  };

  return (
    <RightDrawer
      title="In-App Merchant Profile"
      onClose={onClose}
      visible={open}
      buttonTitle="Save"
      onSubmit={() => handleSave(0, false)}
      width={1000}
    >
      <div className="engage-drawer">
        <div className="update-company-details">
          <div className="update-company-details-mobile-preview">
            <div className="mobile-device-container">
              <div className="mobile-device-container-header">
                <p>MOBILE APP PREVIEW</p>
                <p>(WHAT YOUR CUSTOMER SEES)</p>
              </div>
              <div className="mobile-container">
                <div className="mobile-background">
                  <img
                    className="mobile-outline"
                    src="https://dw8h88voq03ih.cloudfront.net/static/media/phone-template.4ce0c31a.svg"
                    alt="mobile"
                  />
                  <img
                    className="mobile-content"
                    src="https://dw8h88voq03ih.cloudfront.net/static/media/merchant-profile-page.66d30130.svg"
                    alt="mobile"
                  />
                  <img
                    className="mobile-content-nav-bar"
                    src="https://dw8h88voq03ih.cloudfront.net/static/media/mobile-nav-bar.e7619b6b.svg"
                    alt="mobile"
                  />
                  <img
                    className="phone-cover-image"
                    src={
                      formData?.cover_image
                        ? formData.cover_image
                        : "https://res.cloudinary.com/route/image/upload/v1698058819/store-hero/uvT961uZHK.png"
                    }
                    alt="mobile"
                  />
                  <img
                    className="phone-logo-image-default"
                    src={
                      formData?.logo
                        ? formData.logo
                        : "https://dw8h88voq03ih.cloudfront.net/static/media/empty-logo.ae998205.svg"
                    }
                    alt="mobile"
                  />
                  <span className="phone-company-name">
                    {formData?.storename ? formData.storename : "@COMPANY"}
                  </span>
                  <span className="phone-company-domain">
                    {formData?.website ? formData.website : "@DOMAIN"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-merchantProfile">
            <p>GENERAL INFO</p>
            <Form
              name="basic"
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: 600 }}
              autoComplete="off"
            >
              <Form.Item
                label="Store Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  placeholder="Store Name"
                  name="store_name"
                  onChange={handleChange}
                  value={formData?.store_name}
                  defaultValue={formData?.store_name}
                />
              </Form.Item>
              <Form.Item
                label="Website"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  placeholder="Website"
                  name="website"
                  onChange={handleChange}
                  value={formData?.website}
                  defaultValue={formData?.website}
                />
              </Form.Item>
              <Form.Item
                label="Bio"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.TextArea
                  showCount
                  maxLength={300}
                  placeholder="Bio"
                  name="bio"
                  onChange={handleChange}
                  value={formData?.bio}
                  defaultValue={formData?.bio}
                  rows={8}
                />
              </Form.Item>
              <Form.Item
                label="Cover Image"
                className="cover-image"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <ImgCrop rotationSlider className="cover-image-preview">
                  <Upload
                    action=""
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChangeCover}
                    onPreview={onPreviewCover}
                    maxCount={1}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Form.Item
                label="Logo"
                className="logo-image"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <ImgCrop rotationSlider className="logo-image-preview">
                  <Upload
                    action=""
                    listType="picture-card"
                    fileList={fileListLogo}
                    onChange={onChangeLogo}
                    onPreview={onPreviewLogo}
                    maxCount={1}
                  >
                    {fileListLogo.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Form.Item
                label="Mearchant Causes"
                className="merchant-cause"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Checkbox.Group
                  options={merchantCauses}
                  className="merchant-checkbox"
                  onChange={handleSelectionChange}
                  value={formData?.causes}
                  name="causes"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </RightDrawer>
  );
};

export default MerchantProfile;
