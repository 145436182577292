import React, { useState } from "react";
import { Form } from "antd";
import { connect } from "react-redux";
import AccountPage from "./accountPage";
import Loading from "../../../common/Loading/Loading";
import { resetPassword } from "../../../../redux/slice/resetPasswordSlice";
import Notify from "../../../common/Notify/notify";
import { validPassword } from "../../../../utils/regexs";

const Account = (props) => {
  const { loginData, resetPassword, passwordresult } = props;
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const onChangeSetFormData = (event) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setFormErrors({});
    setFormData({
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    });
    setIsModalOpen(false);
  };
  const handleClickResetPassword = async () => {
    const errors = {};

    if (!formData.currentpassword) {
      errors.currentpassword = "Please enter your current password.";
    }
    if (!validPassword.test(formData.newpassword)) {
      if (!formData.newpassword) {
        errors.newpassword = "Please enter new password.";
      } else {
        errors.newpassword = "Please Create strong password.";
      }
    }
    if (!validPassword.test(formData.confirmpassword)) {
      if (!formData.confirmpassword) {
        errors.confirmpassword = "Please enter confirm new password.";
      } else {
        errors.confirmpassword = "Please Create strong password.";
      }
    }
    if (
      formData.newpassword &&
      formData.confirmpassword &&
      formData.newpassword !== formData.confirmpassword
    ) {
      Notify("error", "New password and confirm new password are not same", "");
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const result = await resetPassword(formData);
      if (result.type === "accountResetPassword/rejected") {
        Notify("error", "Opss!!!", result.error.message);
      }
      if (result.type === "accountResetPassword/fulfilled") {
        Notify("success", "Great", result.payload);
        handleCancel();
        setFormData({
          currentpassword: "",
          newpassword: "",
          confirmpassword: "",
        });
      }
    }
  };

  return (
    <div>
      {loginData.isLoading && (
        <Loading type="overlay" className="loader-icon" />
      )}
      {loginData.loginData && (
        <AccountPage
          data={loginData.loginData}
          handleClickResetPassword={handleClickResetPassword}
          onChangeSetForm={onChangeSetFormData}
          handleCancel={handleCancel}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          formErrors={formErrors}
          setFormData={setFormData}
          formData={formData}
          form={form}
          resetPasswordLoading={passwordresult.isLoading}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    passwordresult: state.resetPassword,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (props) => dispatch(resetPassword(props)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Account);
