import React, { useEffect, useState } from "react";
import RegisterComponent from "../../components/register/register";
import { userRegister } from "../../redux/slice/userRegisterSlice";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import Notify from "../../components/common/Notify/notify";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { validEmail, validPassword } from "../../utils/regexs";
import { Form } from "antd";
import { loginData } from "../../redux/slice/getLoginDataSlice";

const Register = (props) => {
  const { callUserRegister, registerUser, callLoginData } = props;
  const navigate = useNavigate();
  const [decodedToken, setDecodedToken] = useState(null);
  const [isLoadedContent, setIsLoadedContent] = useState(false);
  const cookies = new Cookies();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    siteUrl: "",
    email: decodedToken?.email,
    password: "",
    merchantId: decodedToken?.merchantId,
  });
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [form] = Form.useForm();
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormErrors((prev) => ({
      ...prev,
      emailpass: "",
      [name]: "",
    }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleRegister = async () => {
    const errors = {};
    try {
      await form.validateFields();
      // Perform registration logic here
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }

    if (formData.email !== "" && formData.password !== "") {
      if (!validEmail.test(formData.email)) {
        errors.email = "Please Enter Valid Email";
      }
      if (!validPassword.test(formData.password)) {
        errors.password = "Please create strong password";
      }
    } else {
      errors.emailpass = "Please Enter Email And Password";
    }
    if (
      formData.phoneNumber.length !== 10 &&
      formData.phoneNumber.length !== 0
    ) {
      errors.phoneNumber = "Please enter a 10-digit Phone number";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const response = await callUserRegister(formData);
      if (response.type === "register/fulfilled") {
        Notify("success", response.payload.message, "");
        cookies.set("token", response.payload.data.token, {
          path: "/",
          sameSite: true,
        });
        cookies.set("role", response.payload.data.role, {
          path: "/",
          sameSite: true,
        });
        cookies.set("userName", response.payload.data.display_name, {
          path: "/",
          sameSite: true,
        });
        cookies.set("userEmail", response.payload.data.email, {
          path: "/",
          sameSite: true,
        });
        await callLoginData(response.payload.data.token);
        navigate("/onboarding");
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
        }
      }
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    try {
      const decoded = jwt_decode(tokenParam);
      setDecodedToken(decoded);
      setFormData((prevState) => ({
        ...prevState,
        merchantId: decoded.merchantId,
        email: decoded?.email,
      }));
      setIsLoadedContent(true);
    } catch (error) {
      setError(true);
    }
  }, []);
  return (
    <RegisterComponent
      formErrors={formErrors}
      formData={formData}
      handleOnChange={handleOnChange}
      handleRegister={handleRegister}
      registerUser={registerUser}
      tokenError={error}
      decodedToken={decodedToken}
      isLoadedContent={isLoadedContent}
      form={form}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    registerUser: state.registerUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUserRegister: (data) => dispatch(userRegister(data)),
    callLoginData: (data) => dispatch(loginData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
