import { Tabs } from "antd";
import React from "react";
import DashboardHeaderComponent from "../../../common/dashboardHeader/dashboradHeader";
import StoreDetails from "../store/storeDetails";
import SettingsTab from "../../adminTab/settingsTab";
import Engage from "../../engage";

const AdminStoreTab = () => {
  const items = [
    {
      key: "1",
      label: "Store",
      children: <StoreDetails />,
    },
    {
      key: "2",
      label: "Merchant Settings",
      children: <SettingsTab/>,
    },
    {
        key: "3",
        label: "Engage",
        children: <Engage/>,
      },
  ];
  return (
    <div className="admin-tab-main">
      <div className="admin-tab-container">
        <DashboardHeaderComponent title="Store Detail" />
        <div className="tabs">
          <Tabs defaultActiveKey="1" items={items} type="card" />
        </div>
      </div>
    </div>
  );
};

export default AdminStoreTab;
