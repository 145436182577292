import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance from "../../authServices/axiosInstance";
import { Cookies } from "react-cookie";

export const engageContent = createAsyncThunk(
  "engageContent",
  async (merchantId) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const role = cookies.get("role");
      const merchantEngageUrl = `${API_URL}/merchant/engage/list`;
      const singleMerchantEngageUrl = `${API_URL}/admin/engage/list/${merchantId}`;
      const url =
        role === "admin" ? singleMerchantEngageUrl : merchantEngageUrl;
      const response = await AxiosInstance.get(url, {
        headers: { "x-access-token": token },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const engageContentSlice = createSlice({
  name: "engageContent",
  initialState: {
    isLoading: false,
    engageContentList: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [engageContent.pending]: (state) => {
      state.isLoading = true;
    },
    [engageContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.engageContentList = action.payload;
    },
    [engageContent.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default engageContentSlice.reducer;
