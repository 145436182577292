import React from "react";
import { useTranslation } from "react-i18next";

const FinanceSetupGuide = (props) => {
  const { sendBilling, isBilling } = props;
  const { t } = useTranslation();
  return (
    <div>
      <div className="welcome-tab-main-section">
        <div className="tab-container">
          <p className="protect-head track-head">Finance</p>
          <div className="bordered-line"></div>
          <div className="send-billing-box">
            <div className="send-billing-title">{t("FINANCE_TITLE")}</div>
            <div className="billing-content">{t("FINANCE_STR_OUR_BILL")}</div>
            {isBilling ? (
              <div className="disable-button">
                <button className="primary_btn btn">
                  {t("FINANCE_BTN_APPROVE")}
                </button>
              </div>
            ) : (
              <button className="primary_btn btn" onClick={() => sendBilling()}>
                {t("FINANCE_BTN_SEND")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceSetupGuide;
