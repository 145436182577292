import React, { useEffect, useState } from "react";
import RightDrawer from "../../../../../common/drawer/drawer";
import { Divider, Radio } from "antd";
import { useTranslation } from "react-i18next";

const Widget = (props) => {
  const { onClose, visible, data, updateAndGetData } = props;
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState("enable");

  const check = data.protection_widget === "on" ? "enable" : "disable";
  useEffect(() => {
    setSelectedOption(check);
  }, [data.protection_widget, check]);

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleCancel = () => {
    setSelectedOption(check);
    onClose();
  };

  const handleSave = () => {
    const payload = {
      protection_widget: selectedOption === "enable" ? "on" : "off",
    };
    updateAndGetData(payload);
    onClose();
  };
  return (
    <>
      <RightDrawer
        title="Protection Default View"
        onClose={handleCancel}
        visible={visible}
        buttonTitle="Change"
        onSubmit={() => handleSave()}
      >
        <div className="widget-drawer">
          <div className="option">
            <Radio
              className="checkbox"
              value="enable"
              onChange={handleRadioChange}
              checked={selectedOption === "enable"}
            >
              {t("PROTECTION_ON_1")}
            </Radio>
            <p className="checkbox-content">{t("PROTECTION_ON_2")}</p>
            <Radio
              className="checkbox"
              value="disable"
              onChange={handleRadioChange}
              checked={selectedOption === "disable"}
            >
              {t("PROTECTION_OFF_1")}
            </Radio>
            <p className="checkbox-content">{t("PROTECTION_OFF_2")}</p>
          </div>
          <Divider />
          <p>{t("PROTECTION_CHANGING_THE")}</p>
        </div>
      </RightDrawer>
    </>
  );
};

export default Widget;
