import React, { useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo/Swipe_logo_black.svg";
import { ReactComponent as Calendly } from "../../assets/images/calendly-image.svg";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { onBoardingSchedule } from "../../redux/slice/onBoardingScheduleSlice";
import { connect } from "react-redux";
import Notify from "../common/Notify/notify";
import moment from "moment";
import { loginData } from "../../redux/slice/getLoginDataSlice";
import { CALENDLY_URL } from "../../environment";

const Onboarding = (props) => {
  const { callAuthenticate, callOnBoardingSchedule } = props;
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("token");

  // useEffect(() => {
  //   if (token) {
  //     callAuthenticate(token).then((res) => {
  //       console.log("res", res);
  //       if (res.payload.data.merchantInfo.is_onboarding) {
  //         console.log('final');
  //         navigate("/dashboard");
  //       }
  //     });
  //   }
  // }, []);

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      const { event, payload } = e.data;
      if (event === "calendly.event_scheduled") {
        console.log("payload",payload);
        const data = {
          event_url: payload.event.uri,
          invitee_url: payload.invitee.uri,
        };
        const response = await callOnBoardingSchedule(data);
        if (response.type === "onBoardingSchedule/fulfilled") {
          Notify("success", response.payload.message, "");
          navigate("/onboard-success");
        }else {
          if (response.error && response.error.message) {
            Notify("error", response.error.message, "");
            navigate("/onboarding");
          }
        }
      }
    },
  });

  return (
    <div className="onboarding-main">
      <div className="onboarding-header">
        <div className="logo">
          <Logo />
        </div>
      </div>
      <div className="onboarding-main-section">
        <div className="onboarding-inner">
          <div className="left-side">
            <Calendly />
            <div className="onboard-heading">
              <h1>Schedule Your Installation Call</h1>
            </div>
            <div className="onboard-title">
              <p>
                Let's take a quick tour to show how Swipe can help protect your
                packages.
              </p>
            </div>
          </div>
          <div className="right-side">
            <div className="right-content">
              <p>
                Please choose a convenient date & time for a brief installation
                call.
              </p>
            </div>
            <div className="calendly-div">
              <InlineWidget
                url={CALENDLY_URL}
                styles={{ height: "600px" }}
                pageSettings={{
                  backgroundColor: "fffffff",
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: true,
                  primaryColor: "#fed101",
                  textColor:'#1a1e22',
                  color:'#1a1e22'
                }}
              />
            </div>
            {/* <div className="btn-div">
              <button className="primary_btn btn">Schedule Call</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callAuthenticate: (data) => dispatch(loginData(data)),
    callOnBoardingSchedule: (data) => dispatch(onBoardingSchedule(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
