import React, { useEffect, useState } from "react";
import OrdersPage from "./ordersPage";
import { connect } from "react-redux";
import { orderList } from "../../../../redux/slice/getOrderSlice";
import Loading from "../../../common/Loading/Loading";
import { merchantList } from "../../../../redux/slice/merchantListSlice";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { rangePresets } from "../../../../utils/helper";
import { exportOrderList } from "../../../../redux/slice/getExportOrderSlice";
import moment from "moment";

const Orders = (props) => {
  const {
    callGetOrderList,
    orderList,
    merchantList,
    callGetMerchantList,
    exportOrderList,
    callGetExportOrderList,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [client_id, setClient_id] = useState();
  const [isProtect, setisProtect] = useState(false);
  const [SelectedOption, setSelectedOption] = useState(null);
  const [dates, setDates] = useState([null, null]);
  const [currentPage, setCurrentPage] = useState(1);
  const params = new URLSearchParams(location.search);
  const [isActive, setIsActive] = useState("");
  const [isDate, setIsDate] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // if (params.size <= 0) {
    //   callGetOrderList({});
    // }
    callGetMerchantList();
  }, []);

  const handleOnDateChange = async (dates) => {
    const formattedStartDate = dates && dates[0]?.format("M-DD-YYYY");
    const formattedEndDate = dates && dates[1]?.format("M-DD-YYYY");

    if (formattedStartDate) {
      params.set("start_date", formattedStartDate);
    } else {
      params.delete("start_date");
      params.delete("days");
    }

    if (formattedEndDate) {
      params.set("end_date", formattedEndDate);
    } else {
      params.delete("end_date");
      params.delete("days");
    }

    setDates(dates);

    if (dates === null) {
      setIsActive("");
      setSelectedRange(null);
      setIsDate(false);
      // await callGetOrderList({});
    } else {
      const payload = {
        id: client_id?.id || null,
        protect: isProtect ? true : null,
        date: {
          start_date: formattedStartDate || null,
          end_date: formattedEndDate || null,
        },
        currentPage: currentPage,
      };
      // await callGetOrderList({ payload });
      const preset = rangePresets.find(
        (p) =>
          p.value[0].isSame(dates[0], "day") &&
          p.value[1].isSame(dates[1], "day")
      );
      if (preset) {
        params.set("days", preset.label);
        setIsActive(preset.label);
      } else {
        setIsActive("");
        params.delete("days");
      }
    }
    setIsDate(false);
    navigate(`?${params.toString()}`);
  };
  const handlePresetClick = (preset) => {
    setIsActive(preset.label);
    setSelectedRange(preset.value);
    handleOnDateChange(preset.value);
    setIsDate(false);
  };

  useEffect(() => {
    const SelectedOption = params.get("selected_option");
    const startDate = params.get("start_date");
    const endDate = params.get("end_date");
    const protect = params.get("protect");
    const days = params.get("days");
    if (days) {
      setIsActive(days);
    }

    if (protect) setisProtect({ protect: protect });
    if (startDate || endDate) {
      setDates([
        startDate ? dayjs(startDate, "M-DD-YYYY") : null,
        endDate ? dayjs(endDate, "M-DD-YYYY") : null,
      ]);
    }
    if (SelectedOption) {
      setClient_id({ id: SelectedOption });
    }

    if (
      SelectedOption ||
      startDate ||
      endDate ||
      protect ||
      !protect ||
      searchValue ||
      currentPage
    ) {
      setSelectedOption(SelectedOption ? SelectedOption : null);
      const payload = {
        id: SelectedOption,
        protect: protect ? protect : null,
        date: {
          start_date: startDate || moment(new Date()).format('M-DD-YYYY'),
          end_date: endDate || moment(new Date()).format('M-DD-YYYY'),
        },
        search: searchValue,
        currentPage: currentPage,
      };
      if (searchValue) {
        const timeOut = setTimeout(() => {
          callGetOrderList({ payload });
        }, 1000);
        return () => clearTimeout(timeOut);
      } else {
        callGetOrderList({ payload });
      }
    }
  }, [location.search, callGetOrderList, currentPage, searchValue]);

  const handleNavigateDetails = (id) => {
    navigate(`/dashboard/orders/${id}`);
  };

  return (
    <div>
      {(orderList.isLoading || exportOrderList.isLoading) && (
        <Loading type="overlay" className="loader-icon" />
      )}
      {orderList?.orderList && (
        <OrdersPage
          isProtect={isProtect}
          orders={orderList?.orderList?.data}
          handleOnDateChange={handleOnDateChange}
          merchantList={merchantList.merchantList}
          callGetOrderList={callGetOrderList}
          setClient_id={setClient_id}
          setisProtect={setisProtect}
          setDates={setDates}
          SelectedOption={SelectedOption}
          setSelectedOption={setSelectedOption}
          dates={dates}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleNavigateDetails={handleNavigateDetails}
          isActive={isActive}
          setIsActive={setIsActive}
          handlePresetClick={handlePresetClick}
          isDate={isDate}
          setIsDate={setIsDate}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          callGetExportOrderList={callGetExportOrderList}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orderList: state.orderList,
    merchantList: state.merchantList,
    adminNewClaim: state.adminNewClaim,
    exportOrderList: state.exportOrderList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetOrderList: (...props) => dispatch(orderList(...props)),
    callGetMerchantList: () => dispatch(merchantList()),
    callGetExportOrderList: (...props) => dispatch(exportOrderList(...props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
