import { Form, Input, Modal, Space } from 'antd';
import React from 'react';
import Loading from '../../../common/Loading/Loading';
import DashboardHeaderComponent from '../../common/dashboardHeader/dashboradHeader';
import { Account } from '../../../../assets/images/sidebar';

const AccountPage = (props) => {
  const {
    data,
    handleClickResetPassword,
    onChangeSetForm,
    isModalOpen,
    setIsModalOpen,
    handleCancel,
    formData,
    formErrors,
    resetPasswordLoading
  } = props;
  return (
    <>
      {resetPasswordLoading && (
        <Loading type="overlay" className="loader-icon" />
      )}
      <div className="admin-home-main">
        <div className="admin-home-container">
          <DashboardHeaderComponent title="Account" Logo={Account} />
          <div className="account-main-section">
            <div className="account-information">
              <div className="account-merchant-box">
                <div className="merchant-title">Your Information</div>
                <div className="merchant-body">
                  <div className="account-merchant-details">
                    <Form>
                      <Form.Item
                        label="Full Name:"
                        name="fullname"
                        // initialValue={data.data.display_name}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          // value={data.data.display_name}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        label="Display Name:"
                        name="name"
                        initialValue={data.data.display_name}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input value={data.data.display_name} disabled />
                      </Form.Item>
                      <Form.Item
                        label="Email:"
                        name="email"
                        initialValue={data.data.email}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input value={data.data.email} disabled />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="account-reset-btn">
              <button
                className="primary_btn btn"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                }}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        open={isModalOpen}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
      >
        <div className="onBoard-Model">
          <div className="onBoard-title">
            <span>Reset Password</span>
          </div>
          <div className="reset-password-model-body">
            <Space direction="vertical">
              <Input.Password
                type="password"
                placeholder="Current Password"
                name="currentpassword"
                value={formData.currentpassword}
                onChange={onChangeSetForm}
              />
            </Space>
            {formErrors.currentpassword && (
              <div className="field-error">{formErrors.currentpassword}</div>
            )}
            <Space direction="vertical">
              <Input.Password
                type="password"
                placeholder="New Password"
                name="newpassword"
                value={formData.newpassword}
                onChange={onChangeSetForm}
              />
            </Space>
            {formErrors.newpassword && (
              <div className="field-error">{formErrors.newpassword}</div>
            )}
            <Space direction="vertical">
              <Input.Password
                type="password"
                value={formData.confirmpassword}
                placeholder="Confirm New Password"
                name="confirmpassword"
                onChange={onChangeSetForm}
              />
            </Space>
            {formErrors.confirmpassword && (
              <div className="field-error">{formErrors.confirmpassword}</div>
            )}
          </div>

          <div className="reset-password-model-button">
            <button className="primary_btn btn" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="primary_btn btn"
              type="submit"
              onClick={handleClickResetPassword}
              disabled={resetPasswordLoading}
            >
              Reset
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountPage;
