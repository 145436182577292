import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AdminStoreComponent from "./store";
import { getStoreData } from "../../../../../redux/slice/getStoreDataSlice";
import Loading from "../../../../common/Loading/Loading";
import { merchantList } from "../../../../../redux/slice/merchantListSlice";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const AdminStore = (props) => {
  const { storeData, getStoreData, callGetMerchantList, merchantList } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedDates, setSelectedDates] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isActive, setIsActive] = useState("");


  useEffect(() => {
    if (!params.size) {
      getStoreData({});
    }
    callGetMerchantList({});
  }, []);

  useEffect(() => {
    const selectedOption = params.get("selected_option");
    const start_date = params.get("start_date");
    const end_date = params.get("end_date");
    const days = params.get("days");
    if (days) {
      setIsActive(days);
    }
    if (selectedOption || start_date || end_date || currentPage) {
      setSelectedMerchant(selectedOption ? selectedOption : null);
      setSelectedDates([
        start_date ? dayjs(start_date, "M-DD-YYYY") : null,
        end_date ? dayjs(end_date, "M-DD-YYYY") : null,
      ]);
      const payload = {
        merchant: selectedOption,
        start_date: start_date || null,
        end_date: end_date || null,
      };
      getStoreData({ payload, currentPage});
    }
  }, [location.search]);

  return (
    <>
      {storeData.isLoading && (
        <Loading type="overlay" className="loader-icon" />
      )}
      {storeData.details && (
        <AdminStoreComponent
          storeData={storeData.details}
          getStoreData={getStoreData}
          selectedMerchant={selectedMerchant}
          setSelectedMerchant={setSelectedMerchant}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          currentPage={currentPage}
           setCurrentPage={setCurrentPage}
          merchantList={merchantList?.merchantList}
          isActive={isActive}
          setIsActive={setIsActive}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    storeData: state.storeData,
    merchantList: state.merchantList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStoreData: (data) => dispatch(getStoreData(data)),
    callGetMerchantList: () => dispatch(merchantList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStore);
