import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AllUserData = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const columns = [
    {
      title: "UserId",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNo",
      key: "mobileNo",
    },
  ];

  const dataUser = [
    {
      _id: 1,
      email: "ayush1@gmail.com",
      firstName: "ayush1",
      mobileNo: 789456123,
    },
    {
      _id: 2,
      email: "ayush2@gmail.com",
      firstName: "ayush2",
      mobileNo: 789456123,
    },
    {
      _id: 3,
      email: "ayush3@gmail.com",
      firstName: "ayush3",
      mobileNo: 789456123,
    },
    {
      _id: 4,
      email: "ayush4@gmail.com",
      firstName: "ayush4",
      mobileNo: 789456123,
    },
    {
      _id: 5,
      email: "ayush5@gmail.com",
      firstName: "ayush5",
      mobileNo: 789456123,
    },
    {
      _id: 6,
      email: "ayush6@gmail.com",
      firstName: "ayush6",
      mobileNo: 789456123,
    },
    {
      _id: 7,
      email: "ayush7@gmail.com",
      firstName: "ayush7",
      mobileNo: 789456123,
    },
    {
      _id: 8,
      email: "ayush8@gmail.com",
      firstName: "ayush8",
      mobileNo: 789456123,
    },
    {
      _id: 9,
      email: "ayush9@gmail.com",
      firstName: "ayush9",
      mobileNo: 789456123,
    },
    {
      _id: 10,
      email: "ayush10@gmail.com",
      firstName: "ayush10",
      mobileNo: 789456123,
    },
    {
      _id: 11,
      email: "ayush11@gmail.com",
      firstName: "ayush11",
      mobileNo: 789456123,
    },
    {
      _id: 12,
      email: "ayush12@gmail.com",
      firstName: "ayush12",
      mobileNo: 789456123,
    },
    {
      _id: 13,
      email: "ayush13@gmail.com",
      firstName: "ayush13",
      mobileNo: 789456123,
    },
    {
      _id: 14,
      email: "ayush14@gmail.com",
      firstName: "ayush14",
      mobileNo: 789456123,
    },
    {
      _id: 15,
      email: "ayush15@gmail.com",
      firstName: "ayush15",
      mobileNo: 789456123,
    },
    {
      _id: 16,
      email: "ayush16@gmail.com",
      firstName: "ayush16",
      mobileNo: 789456123,
    },
    {
      _id: 17,
      email: "ayush17@gmail.com",
      firstName: "ayush17",
      mobileNo: 789456123,
    },
    {
      _id: 18,
      email: "ayush18@gmail.com",
      firstName: "ayush18",
      mobileNo: 789456123,
    },
    {
      _id: 19,
      email: "ayush19@gmail.com",
      firstName: "ayush19",
      mobileNo: 789456123,
    },
    {
      _id: 20,
      email: "ayush20@gmail.com",
      firstName: "ayush20",
      mobileNo: 789456123,
    },
    {
      _id: 21,
      email: "ayush21@gmail.com",
      firstName: "ayush21",
      mobileNo: 789456123,
    },
    {
      _id: 22,
      email: "ayush22@gmail.com",
      firstName: "ayush22",
      mobileNo: 789456123,
    },
    {
      _id: 23,
      email: "ayush23@gmail.com",
      firstName: "ayush23",
      mobileNo: 789456123,
    },
    {
      _id: 24,
      email: "ayush24@gmail.com",
      firstName: "ayush24",
      mobileNo: 789456123,
    },
    {
      _id: 25,
      email: "ayush25@gmail.com",
      firstName: "ayush25",
      mobileNo: 789456123,
    },
    {
      _id: 26,
      email: "ayush26@gmail.com",
      firstName: "ayush26",
      mobileNo: 789456123,
    },
    {
      _id: 27,
      email: "ayush27@gmail.com",
      firstName: "ayush27",
      mobileNo: 789456123,
    },
    {
      _id: 28,
      email: "ayush28@gmail.com",
      firstName: "ayush28",
      mobileNo: 789456123,
    },
    {
      _id: 29,
      email: "ayush29@gmail.com",
      firstName: "ayush29",
      mobileNo: 789456123,
    },
    {
      _id: 30,
      email: "ayush30@gmail.com",
      firstName: "ayush30",
      mobileNo: 789456123,
    },
    {
      _id: 31,
      email: "ayush31@gmail.com",
      firstName: "ayush31",
      mobileNo: 789456123,
    },
    {
      _id: 32,
      email: "ayush32@gmail.com",
      firstName: "ayush32",
      mobileNo: 789456123,
    },
    {
      _id: 33,
      email: "ayush33@gmail.com",
      firstName: "ayush33",
      mobileNo: 789456123,
    },
    {
      _id: 34,
      email: "ayush34@gmail.com",
      firstName: "ayush34",
      mobileNo: 789456123,
    },
    {
      _id: 35,
      email: "ayush35@gmail.com",
      firstName: "ayush35",
      mobileNo: 789456123,
    },
    {
      _id: 36,
      email: "ayush36@gmail.com",
      firstName: "ayush36",
      mobileNo: 789456123,
    },
    {
      _id: 37,
      email: "ayush37@gmail.com",
      firstName: "ayush37",
      mobileNo: 789456123,
    },
    {
      _id: 38,
      email: "ayush38@gmail.com",
      firstName: "ayush38",
      mobileNo: 789456123,
    },
    {
      _id: 39,
      email: "ayush39@gmail.com",
      firstName: "ayush39",
      mobileNo: 789456123,
    },
    {
      _id: 40,
      email: "ayush40@gmail.com",
      firstName: "ayush40",
      mobileNo: 789456123,
    },
  ];

  useEffect(() => {
    const page = params.get("page");
    if (page) {
      setCurrentPage(page);
    }
  }, [location.search]);

  const data = dataUser?.map((x, i) => ({
    _id: x._id,
    email: x.email,
    firstName: x.firstName,
    mobileNo: x.mobileNo,
  }));

  const handlePageChange = (page) => {
    if (page) {
      params.set("page", page == null ? 1 : page);
    }
    navigate(`?${params.toString()}`);
    setCurrentPage(page);
  };
  return (
    <div style={{ background: "#fdf399", height: "100vh",padding:'20px' }}>
      <div style={{background:'white',padding:'20px',borderRadius:'20px'}}>  
        <Table
          columns={columns}
          dataSource={data}
          locale={{ emptyText: "No Data found" }}
          pagination={{
            current: currentPage,
            pageSize: 5,
            total: 40,
            onChange: handlePageChange,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate("user-details");
              },
            };
          }}
        />
        <div className="another-table-pagination">
          <Table
            columns={columns}
            dataSource={data}
            locale={{ emptyText: "No Data found" }}
            pagination={{
              current: currentPage,
              pageSize: 5,
              total: 40,
              onChange: handlePageChange,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate("user-details");
                },
              };
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AllUserData;
