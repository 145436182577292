import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance from "../../authServices/axiosInstance";
import { Cookies } from "react-cookie";

export const adminClaim = createAsyncThunk("list", async ({payload, currentPage=1}) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const response = await AxiosInstance.post(
      `${API_URL}/admin/claims/list?limit=25&page=${currentPage}`,
      payload,
      {
        headers: { "x-access-token": token },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
});

const adminClaimListSlice = createSlice({
  name: "list",
  initialState: {
    isLoading: false,
    adminClaimList: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [adminClaim.pending]: (state) => {
      state.isLoading = true;
    },
    [adminClaim.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.adminClaimList = action.payload;
    },
    [adminClaim.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default adminClaimListSlice.reducer;
