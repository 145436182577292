import React, { useEffect, useState } from "react";
import ResetPasswordPage from "../../components/resetPassword/resetPassword";
import { validateUserPassword } from "../../redux/slice/validateResetPasswordSlice";
import { connect } from "react-redux";
import Notify from "../../components/common/Notify/notify";
import { onResetPassword } from "../../redux/slice/onResetPasswordSlice";
import { useNavigate } from "react-router-dom";
import { validPassword } from "../../utils/regexs";

const ResetPassWord = (props) => {
  const {
    callValidatePassword,
    validateResetPassword,
    callOnResetPassword,
    onResetPassword,
  } = props;
  const [linkExpired, setLinkExpired] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const url = new URL(window.location.href);
  const key = url.pathname.split("/").pop();
  const navigate = useNavigate();
  useEffect(() => {
    callValidatePassword({ key: key });
  }, [callValidatePassword, key]);
  useEffect(() => {
    if (validateResetPassword.isError && validateResetPassword.errorMessage) {
      setLinkExpired(true);
    }
  }, [validateResetPassword]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "newPassword") {
      setConfirmPassword(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmNewPassword: "",
      }));
    }
  };

  const handleResetPassword = async () => {
    const errors = {};
    if (!confirmPassword || !confirmNewPassword) {
      errors.pass = "Please enter both passwords";
    } else if (confirmPassword !== confirmNewPassword) {
      errors.confirmNewPassword =
        "New password and confirm new password do not match";
    } else {
      if (!validPassword.test(confirmPassword)) {
        errors.confirmPassword = "Please create a strong password";
      }
      if (!validPassword.test(confirmNewPassword)) {
        errors.confirmNewPassword = "Please create a strong password";
      }
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const value = {
        key: key,
        password: confirmPassword,
      };
      try {
        const response = await callOnResetPassword(value);
        if (response.type === "reset-password/fulfilled") {
          Notify("success", response.payload.message, "");
          navigate("/");
        } else {
          if (response.error && response.error.message) {
            Notify("error", response.error.message, "");
          }
        }
      } catch (error) {
        Notify("error", "An error occurred while resetting the password", "");
      }
    }
  };

  return (
    <div>
      <ResetPasswordPage
        formErrors={formErrors}
        linkExpired={linkExpired}
        validateResetPassword={validateResetPassword}
        handleInputChange={handleInputChange}
        confirmPassword={confirmPassword}
        confirmNewPassword={confirmNewPassword}
        handleResetPassword={handleResetPassword}
        onResetPasswordLoading={onResetPassword.isLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    validateResetPassword: state.validateResetPassword,
    onResetPassword: state.onResetPassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callValidatePassword: (key) => dispatch(validateUserPassword(key)),
    callOnResetPassword: (data) => dispatch(onResetPassword(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassWord);
