import React, { useEffect, useState } from "react";
import ClaimDetailComponent from "./claimDetail";
import { connect } from "react-redux";
import { getMerchantClaimDetails } from "../../../../redux/slice/getMerchantClaimDetailsSlice";
import { useParams } from "react-router-dom";
import Loading from "../../../common/Loading/Loading";
import { addNote } from "../../../../redux/slice/addNoteSlice";
import Notify from "../../../common/Notify/notify";
import { getNotes } from "../../../../redux/slice/getNotesSlice";

const ClaimDetail = (props) => {
  const {
    ClaimDetails,
    getMerchantClaimDetails,
    callAddNote,
    callGetNotes,
    notes,
    addNote,
  } = props;
  const [note, setNote] = useState("");
  const { id } = useParams();
  useEffect(() => {
    getMerchantClaimDetails(id);
    callGetNotes(id);
  }, [id, getMerchantClaimDetails, callGetNotes]);

  const handleNoteChange = (e) => {
    const value = e.target.value;
    setNote(value);
  };
  const handleAddNote = async () => {
    if (note.trim() === "") {
      return;
    }
    const payload = {
      claim: id,
      content: note,
    };
    const response = await callAddNote(payload);
    if (response.type === "comment/fulfilled") {
      Notify("success", response.payload.message, "");
      await callGetNotes(id);
      setNote("");
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };
  return (
    <>
      {(ClaimDetails.isLoading || addNote.isLoading || notes.isLoading) && (
        <Loading type="overlay" className="loader-icon" />
      )}
      {ClaimDetails.details && (
        <ClaimDetailComponent
          ClaimDetails={ClaimDetails.details}
          note={note}
          handleNoteChange={handleNoteChange}
          handleAddNote={handleAddNote}
          notes={notes}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ClaimDetails: state.merchantClaimDetail,
    notes: state.notes,
    addNote: state.addNote,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMerchantClaimDetails: (id) => dispatch(getMerchantClaimDetails(id)),
    callAddNote: (props) => dispatch(addNote(props)),
    callGetNotes: (props) => dispatch(getNotes(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDetail);
