import React from "react";
import { SlGraduation } from "react-icons/sl";
import { AiOutlineHeart } from "react-icons/ai";
import { BsFileEarmarkText } from "react-icons/bs";
import { ReactComponent as PartHorn } from "../../../../../assets/images/party-horn.svg";
import SetupGuideBtn from "../../../../common/setupGuide/setupGuideBtn";
import ResourceCardBox from "../../../../common/setupGuide/ResourceCardBox";
import { Collapse } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { useTranslation } from "react-i18next";
import { openExternalLink, splitAndMap } from "../../../../../utils/helper";

const WelcomeSetupGuide = (props) => {
  const { handleNavigate } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="welcome-tab-main-section">
        <div className="tab-container">
          <div className="cong">
            <div className="protect">
              <div className="party-icons">
                <PartHorn height={20} width={20} fill="#1a1e22" />
                <PartHorn height={20} width={20} fill="#1a1e22" />
                <PartHorn height={20} width={20} fill="#1a1e22" />
              </div>
              <p className="great-texts">{t("WELCOME_STRING_GREAT")}</p>
            </div>
            <p className="lets-texts">{t("WELCOME_STRING_LETS_GET")}</p>
          </div>
          <div className="bordered-line"></div>
          <div className="welcome-tab-desc">
            <p>
              {t("WELCOME_STRING_THE_SETUP_GUIDE_WILL")}
              <span></span>
            </p>
            <div className="start-btn">
              <button
                className="start-btn-cont"
                onClick={() => handleNavigate("Protect_Resolve")}
              >
                Begin Setup
              </button>
            </div>
          </div>
          <div className="bordered-line"></div>
          <div className="resources-sec">
            <p>Resources</p>
            <div className="box-df">
              <ResourceCardBox
                title={<SlGraduation />}
                extra={
                  <SetupGuideBtn
                    title={t("HELP_CENTER_BTN")}
                    onClick={() => {
                      openExternalLink("https://swipe.ai/swipe-ai-hc-page/");
                    }}
                  />
                }
                details={<span>{t("RESOURCE_WELCOME_1")}</span>}
              />
              <ResourceCardBox
                title={<AiOutlineHeart />}
                details={<span>{t("RESOURCE_WELCOME_2")}</span>}
              />
              <ResourceCardBox
                title={<BsFileEarmarkText />}
                extra={
                  <SetupGuideBtn
                    title={t("VIEW_CASE_STUDIES_BTN")}
                    onClick={() => {
                      openExternalLink("https://swipe.ai/case-studies/");
                    }}
                  />
                }
                details={<span>{t("RESOURCE_WELCOME_3")}</span>}
              />
              <ResourceCardBox
                title={<BsFileEarmarkText />}
                extra={
                  <SetupGuideBtn
                    title={t("VIEW_LEARNING_GUIDE_BTN")}
                    onClick={() => {
                      openExternalLink(
                        "https://swipe.ai/article/introduction-choose-your-journey/"
                      );
                    }}
                  />
                }
                details={<span>{t("RESOURCE_WELCOME_4")}</span>}
              />
            </div>
          </div>
          <div className="bordered-line"></div>
          <div className="faq-box">
            <p className="texts-accordion">FAQs</p>
            <Collapse
              size="large"
              accordion={true}
              className="collapse-tab-out"
            >
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_WELCOME_Q_1")}
                key="1"
              >
                <p>{t("FAQ_WELCOME_ANS_1")}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_WELCOME_Q_2")}
                key="2"
              >
                <p>{splitAndMap(t("FAQ_WELCOME_ANS_2"))}</p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_WELCOME_Q_3")}
                key="3"
              >
                <p>
                  <p>{t("FAQ_WELCOME_ANS_3")}</p>
                </p>
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_WELCOME_Q_4")}
                key="4"
              >
                <p>
                  {splitAndMap(t("FAQ_WELCOME_ANS_4"), [
                    "https://swipe.ai/swipe-ai-hc-page/",
                  ])}
                </p>
                <SetupGuideBtn
                  title={t("FAQ_WELCOME_ANS_4_A")}
                  onClick={() => {
                    openExternalLink("https://swipe.ai/swipe-ai-hc-page/");
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_WELCOME_Q_5")}
                className="collapse-tab-in"
                key="5"
              >
                <p>{splitAndMap(t("FAQ_WELCOME_ANS_5"))}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_WELCOME_Q_6")}
                className="collapse-tab-in"
                key="6"
              >
                <p>{splitAndMap(t("FAQ_WELCOME_ANS_6"))}</p>
              </CollapsePanel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSetupGuide;
