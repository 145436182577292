import { Tabs } from "antd";
import React from "react";
import DashboardHeaderComponent from "../../common/dashboardHeader/dashboradHeader";
import SettingsTab from "./settingsTab";
import Users from "../users";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const AdminTab = () => {
  const state = useSelector((state) => state.loginData);
  const isOptionAllowed = (option) => {
    return state.loginData?.userTabPermission.includes(option);
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const tab = queryParams.get("tab");
  let activeTab = "1";
  if (tab === "user") {
    activeTab = "2";
  }

  const items = [
    {
      key: "1",
      label: "Settings",
      children: <SettingsTab />,
    },
    isOptionAllowed("users") && {
      key: "2",
      label: "Users",
      children: <Users />,
    },
  ];
  return (
    <div className="admin-tab-main">
      <div className="admin-tab-container">
        <DashboardHeaderComponent title="Admin" />
        <div className="tabs">
          <Tabs defaultActiveKey={activeTab} items={items} type="card" />
        </div>
      </div>
    </div>
  );
};

export default AdminTab;
