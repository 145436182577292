import React, { useEffect, useState } from "react";
import LoginComponent from "../../components/login/login";
import { connect } from "react-redux";
import { dashboardLogin } from "../../redux/slice/dashboardLoginSlice";
import { resetPasswordLink } from "../../redux/slice/resetPasswordLinkSlice";
import Notify from "../../components/common/Notify/notify";
import { Cookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { validEmail } from "../../utils/regexs";
import jwt_decode from "jwt-decode";
import { loginData } from "../../redux/slice/getLoginDataSlice";

const Login = (props) => {
  const {
    dashboardLogin,
    callDashboardLogin,
    callResetPasswordLink,
    resetPasswordLink,
    callAuthenticate
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState("");

  useEffect(() => {
    setRedirectPath(location.search);
  }, [location.pathname]);

  const [formData, setFormData] = useState({
    email: null,
    password: null
  });
  const cookies = new Cookies();
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    if (
      formData.email !== null &&
      formData.email !== "" &&
      validEmail.test(formData.email)
    ) {
      if (formData.password !== null && formData.password !== "") {
        const result = await callDashboardLogin(formData);
        if (result.type === "login/fulfilled") {
          await callAuthenticate(result.payload.data.token)
          const is_onboarding = result.payload.data.merchantInfo.is_onboarding
          Notify("success", result.payload.message, "");
          cookies.set("token", result.payload.data.token, {
            path: "/",
            sameSite: true
          });
          cookies.set("role", result.payload.data.role, {
            path: "/",
            sameSite: true
          });
          cookies.set("userName", result.payload.data.display_name, {
            path: "/",
            sameSite: true
          });
          cookies.set("userEmail", result.payload.data.email, {
            path: "/",
            sameSite: true
          });

          result.payload.data.role !== "admin" &&
            cookies.set(
              "merchantName",
              result.payload.data.merchantInfo &&
                result.payload.data.merchantInfo.name,
              {
                path: "/",
                sameSite: true
              }
            );
            if(!is_onboarding){
              navigate("/onboarding");
            }else if(!redirectPath){
              navigate('/dashboard')
            }else {
            navigate(decodeURIComponent(redirectPath.split("?redirect=")[1]));
            }
        } else {
          if (result.error && result.error.message) {
            Notify("error", result.error.message, "");
          }
        }
      }
    }
  };

  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const handleEmailChange = (event) => {
    const { value } = event.target;
    setResetPasswordEmail(value);
  };
  const handleResetPassword = async () => {
    if (
      resetPasswordEmail !== null &&
      resetPasswordEmail !== "" &&
      validEmail.test(resetPasswordEmail)
    ) {
      const response = await callResetPasswordLink(resetPasswordEmail);
      if (response.type === "send-reset-password/fulfilled") {
        Notify("success", response.payload.message, "");
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
        }
      }
    }
  };
  return (
    <LoginComponent
      dashboardLogin={dashboardLogin}
      callDashboardLogin={callDashboardLogin}
      callResetPasswordLink={callResetPasswordLink}
      resetPasswordLinkLoading={resetPasswordLink.isLoading}
      handleOnChange={handleOnChange}
      handleSubmit={handleSubmit}
      handleEmailChange={handleEmailChange}
      handleResetPassword={handleResetPassword}
      resetPasswordEmail={resetPasswordEmail}
      setResetPasswordEmail={setResetPasswordEmail}
      formData={formData}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardLogin: state.dashboardLogin,
    resetPasswordLink: state.resetPasswordLink
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callDashboardLogin: (data) => dispatch(dashboardLogin(data)),
    callResetPasswordLink: (...props) => dispatch(resetPasswordLink(...props)),
    callAuthenticate: (data) => dispatch(loginData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
