import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance  from "../../authServices/axiosInstance";

export const merchantNewClaim = createAsyncThunk(
  "merchantNewClaim",
  async (data) => {
    try {
      const response = await AxiosInstance.post(`${API_URL}/v1/claims`, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const merchantNewClaimSlice = createSlice({
  name: "merchantNewClaim",
  initialState: {
    isLoading: false,
    merchantNewClaim: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [merchantNewClaim.pending]: (state) => {
      state.isLoading = true;
    },
    [merchantNewClaim.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.merchantNewClaim = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [merchantNewClaim.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.merchantNewClaim = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default merchantNewClaimSlice.reducer;
