import React, { useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo/Swipe_logo_black.svg";
import { ReactComponent as Success } from "../../assets/images/success.svg";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { onBoardingSchedule } from "../../redux/slice/onBoardingScheduleSlice";
import { Cookies } from "react-cookie";
import Notify from "../common/Notify/notify";
import Loading from "../common/Loading/Loading";
import { loginData } from "../../redux/slice/getLoginDataSlice";

const OnboardSuccess = (props) => {
  const {
    callOnBoardingSchedule,
    onboardingStatus,
    callAuthenticate,
    setCanNavigate,
    loginData,
  } = props;
  const navigate = useNavigate();

  const cookies = new Cookies();
  const token = cookies.get("token");

  // const handleData = async () => {
  //   // const response = await callOnBoardingSchedule(token);
  //   // if (response.type === "onBoardingSchedule/fulfilled") {
  //   //  await callAuthenticate(token)
  //   if (loginData?.loginData?.data?.merchantInfo?.is_onboarding)
  //     // Notify("success", response.payload.message, "");
  //   setTimeout(() => {
  //     setCanNavigate(true);
  //     navigate("/dashboard");
  //   }, 3000);
  //   // }
  // };

  const handleData = async () => {
    if (token) {
      callAuthenticate(token).then((res) => {
        if (res.payload.data.merchantInfo.is_onboarding) {
          setTimeout(() => {
            setCanNavigate(true);
            navigate("/dashboard");
          }, 3000);
        }
      });
    }
  };

  useEffect(() => {
    handleData();
  }, [token]);

  return (
    <div className="onboarding-main">
      {onboardingStatus.isLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : (
        <>
          <div className="onboarding-header">
            <div className="logo">
              <Logo className="logo-img" />
            </div>
          </div>
          <div className="onboarding-success-section">
            <div className="inner">
              <div className="success-img">
                <Success />
              </div>
              <div className="success-container">
                <div className="success-heading">
                  <h1>Call is Scheduled Successfully</h1>
                </div>
                <div className="success-text">
                  <p>
                    Thank you for scheduling a call with us for your app
                    installation procedure. We're excited to help you{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboardingStatus: state.onBoardingSchedule,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callOnBoardingSchedule: (data) => dispatch(onBoardingSchedule(data)),
    callAuthenticate: (data) => dispatch(loginData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardSuccess);
