import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React from "react";

const LivePreviewComponent = (props) => {
  return (
    <>
      <div className="livePreview">
        <div className="mobilDesktopContainer">
          <div className="setWindow">
            <Tabs>
              <TabPane
                tab={<span className="mobile">Mobile Preview</span>}
                key="1"
              >
                <div>
                  <div>
                    <div></div>
                    <div className="BodyOfScreen">
                      <div className="mobile-screenDiv">
                        <img
                          src="https://dw8h88voq03ih.cloudfront.net/static/media/banner-mobile-body.5c1cac48.svg"
                          className="imgOfScreen"
                          alt=""
                        />
                      </div>
                      <div className="screenContainer">
                        <div>
                          <img
                            src="https://dw8h88voq03ih.cloudfront.net/static/media/banner-mobile-content.e8711916.svg"
                            alt=""
                            className={`${
                              props.location === "bottom" ||
                              props.location === ""
                                ? "underScreen"
                                : "imgContentDiv"
                            }`}
                          />
                        </div>
                        <div>
                          <div
                            className={`${
                              props.location === "bottom" ||
                              props.location === ""
                                ? "shipping-optimal"
                                : "shipping-optical2"
                            }`}
                            style={{
                              backgroundColor: props.color,
                            }}
                          >
                            <span
                              className="sc-la-Dzhv llhwqp"
                              style={{
                                fontSize: `${props.fonts}px`,
                                color: `${props.fontcolor}`,
                                fontFamily: `${props.fontfamily}`,
                              }}
                            >
                              {props.check}
                            </span>
                            <br />
                            <span className="sc-ijBzsJ fvQZwL"></span>{" "}
                            <span
                              style={{
                                fontSize: `${props.fonts}px`,
                                color: `${props.fontcolor}`,
                              }}
                            >
                              Instant Shipping Issue Resolution
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane
                tab={<span className="desktopText">Desktop preview</span>}
                key="2"
              >
                <div className="desktopSit-Container">
                  <div className="setDesktopContainer">
                    <div className="sc-fudrna">
                      <div className="desktop">
                        <img
                          src="https://dw8h88voq03ih.cloudfront.net/static/media/banner-web-preview.d6fec846.svg"
                          alt=""
                        />
                      </div>
                      <div className="sc-bVhZWL">
                        <div></div>
                        <img
                          src="https://dw8h88voq03ih.cloudfront.net/static/media/banner-web-content.e546f450.svg"
                          alt=""
                        />

                        <div
                          className={`${
                            props.location === "bottom" || props.location === ""
                              ? "setBottomPartDiv"
                              : "bottomPartset"
                          }`}
                          style={{ backgroundColor: `${props.color}` }}
                        >
                          <span
                            style={{
                              fontSize: `${props.fonts}px`,
                              color: `${props.fontcolor}`,
                              fontFamily: `${props.fontfamily}`,
                            }}
                          >
                            {props.check}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default LivePreviewComponent;
