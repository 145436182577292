import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance  from "../../authServices/axiosInstance";

export const downLoadStatements = createAsyncThunk("download", async (data) => {
  const { merchantId, value } = data;
  const cookies = new Cookies();
  const token = cookies.get("token");
  const response = await AxiosInstance.get(
    `${API_URL}/v1/statement/download?merchantId=${merchantId}&month=${value}`,
    {
      headers: { "x-access-token": token },
      responseType: "blob",
    }
  );
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${value}.pdf`);
  document.body.appendChild(link);
  link.click();
  return;
});

const downLoadStatementsSlice = createSlice({
  name: "download",
  initialState: {
    isLoading: false,
    downLoadStatements: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [downLoadStatements.pending]: (state) => {
      state.isLoading = true;
    },
    [downLoadStatements.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.downLoadStatements = action.payload;
    },
    [downLoadStatements.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default downLoadStatementsSlice.reducer;
