import { List } from "antd";
import React from "react";
import SwipeCartBlock from "./../../../../../assets/swipe-cart-block.mp4";
import { widgetStep } from "../../../../../utils/commonUtils";

const WidgetPage = () => {

  return (
    <div className="welcome-tab-main-section">
      <div className="tab-container">
        <p className="protect-head track-head">Onboarding for app blocks</p>
        <div className="bordered-line"></div>
        <div className="widgetStep">
          <List
            itemLayout="horizontal"
            dataSource={widgetStep}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<>{index + 1}</>}
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </div>
        <video src={SwipeCartBlock} className="widget-video" controls></video>
      </div>
    </div>
  );
};

export default WidgetPage;
