import { Timeline } from "antd";
import React from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
import { claimTimeLine } from "./helper";
import DashboardHeaderComponent from "../../common/dashboardHeader/dashboradHeader";
import { ReactComponent as ClaimLogo } from "../../../../assets/images/sidebar/claim.svg";
import TextArea from "antd/es/input/TextArea";
import { ReactComponent as CustomerMail } from "../../../../assets/images/claimStatus/sms.svg";
import { ReactComponent as CustomerAddress } from "../../../../assets/images/claimStatus/gps.svg";
import { useTranslation } from "react-i18next";
import indicator from "ordinal/indicator";
import { dateConvert } from "../../../../utils/helper";
import left_arrow from '../../../../assets/images/claimStatus/left_arrow.svg'

const ClaimDetailComponent = (props) => {
  const { ClaimDetails, handleAddNote, handleNoteChange, note, notes } = props;
  const { t } = useTranslation();
  const navigate=useNavigate()
  const claimInfoItem = [
    {
      children: (
        <div className="order-details">
          <div>
            <p>
              <strong>Order Created</strong>
            </p>
            <p className="claim-date">
              {dateConvert(ClaimDetails.Claims.orders.order_createdAt)}
            </p>
          </div>
          <div className="original-order">
            <Link
              to={`https://${ClaimDetails.Claims.merchant?.domain}/admin/orders/${ClaimDetails.Claims.orders.id}`}
            >
              Original Order
            </Link>
          </div>
        </div>
      ),
      dot: <div className="order-dots"></div>,
    },
    {
      children: (
        <div className="order-details">
          <div>
            <p>
              <strong>Claim Created</strong>
            </p>
            <p className="claim-date">
              {" "}
              {dateConvert(ClaimDetails.Claims.claim_createdAt)}
            </p>
          </div>
        </div>
      ),
      dot: <div className="order-dots"></div>,
    },
  ];
  if (ClaimDetails.Claims?.resolved_date) {
    claimInfoItem.push({
      children: (
        <div className="order-details">
          <div>
            <p>
              <strong>Claim Resolved</strong>
            </p>
            <p className="claim-date">
              {dateConvert(ClaimDetails.Claims?.resolved_date)}
            </p>
          </div>
          {!ClaimDetails.Claims?.is_refund && (
            <div className="original-order">
              <Link
                to={`https://${ClaimDetails.Claims.merchant?.domain}/admin/orders/${ClaimDetails.Claims?.reorder_id}`}
              >
                Claim Order
              </Link>
            </div>
          )}
        </div>
      ),
      dot: ClaimDetails.Claims?.resolved_date && (
        <div className="order-dots"></div>
      ),
    });
  }
  return (
    ClaimDetails.Claims && (
      <div className="claim-detail-main">
        <div className="claim-detail-container">
          <div className="order-detail-header">
            <button
              className="arrow-box"
              onClick={() => navigate(-1)}
            >
              <img src={left_arrow} alt="left_arrow" className="arrow-image" />
            </button>
            <div className="order-detail-header-content">
              <p className="header-content-title">claim / Claim Details</p>
              <h2 className="header-content-heading">
                Claim #{" "}
                {ClaimDetails.Claims.orders.name
                  ? ClaimDetails.Claims.orders.name
                  : "-"}
              </h2>
            </div>
          </div>
          <div className="claim-details-main-section">
            <div className="claim-details-df">
              <div className="card-details-heading">
                <div className="Heading-left">
                  <div className="orde-no-id">
                    <h2>{ClaimDetails.Claims.orders.name}</h2>
                  </div>
                </div>
                <div className="Heading-right">
                  <div className="status-btn">
                    <button
                      className={`new-button ${
                        ClaimDetails.Claims.status === "APPROVED" &&
                        "status-btn-approved"
                      } ${
                        ClaimDetails.Claims.status === "REVIEWING" &&
                        "status-btn-reviewing"
                      } ${
                        ClaimDetails.Claims.status === "CLOSED" &&
                        "status-btn-closed"
                      } ${
                        ClaimDetails.Claims.status === "RESOLVED" &&
                        "status-btn-resolved"
                      }`}
                    >
                      <p>{ClaimDetails.Claims.status}</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-details-left">
                <div className="box">
                  {ClaimDetails.Claims.orders.line_item.map((item, index) => {
                    return (
                      <div className="tab" key={index}>
                        <div className="tab-left-section">
                          <div className="bg-number">{item.quantity}</div>
                          <div>
                            <h3>{item.name}</h3>
                            <p className="variant-title">
                              {item.variant_title}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="claim-price">$ {item.price}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="box">
                  <div className="latest-Head">Latest Update</div>
                  <div className="latest-time">
                    <p>
                      <strong>CLAIM :</strong> {ClaimDetails.Claims.status}
                    </p>
                    <p className="latest-claim-date">
                      {dateConvert(ClaimDetails.Claims.last_update)}
                    </p>
                  </div>
                  <div className="latest-que">
                    <span className="latest-que-title">Questions?</span>
                    <Link to="mailto:swipe@swipe.ai">
                      <span>Reach out to your Merchant Success Manager</span>
                    </Link>
                  </div>
                </div>
                <div className="box">
                  <div className="Heading-Timeline">Timeline</div>
                  <div className="timeline-body">
                    <Timeline
                      mode={"left"}
                      items={ClaimDetails.Claims.events.map((item) => {
                        return claimTimeLine(item);
                      })}
                      reverse={true}
                    />
                  </div>
                </div>
              </div>
              <div className="card-details-right">
                <div className="box">
                  <div className="claim-info">Claim Info</div>
                  <div className="claim-info-details">
                    <div className="claim-align-outer">
                      <div className="claim-align-inner">
                        <p className="claim-title-reason">
                          <span>
                            <strong>Reason : </strong>
                            {ClaimDetails.Claims.reason}
                          </span>
                        </p>
                        <div className="claim-reason">
                          <strong>Description : </strong>
                          {ClaimDetails.Claims.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Timeline items={claimInfoItem} />
                </div>
                <div className="box">
                  <div className="claim-info">Claim Logs</div>
                  <div className="note-timeline">
                    {notes?.notes?.data?.length > 0 ? (
                      <Timeline
                        items={notes?.notes?.data?.map((item, index) => {
                          return {
                            children: (
                              <div className="claim-note-details" key={index}>
                                <div>
                                  <p>
                                    <strong>{item.content}</strong>
                                  </p>
                                  <div className="claim-note">
                                    <span className="claim-date">
                                      {dateConvert(item.ts * 1000)}
                                    </span>
                                    <span>
                                      By:{" "}
                                      {item.created_by ? item.created_by : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ),
                            dot: (
                              <div
                                className="order-dots"
                                key={`dot-${index}`}
                              ></div>
                            ),
                          };
                        })}
                      />
                    ) : (
                      <p className="no-record-found">No records found</p>
                    )}
                  </div>
                </div>
                <div className="box">
                  <div className="claim-info">
                    Leave Notes for Internal team
                  </div>
                  <div className="detail-box">
                    <div className="note-section">
                      <TextArea
                        placeholder="Write a Notes..."
                        value={note}
                        onChange={handleNoteChange}
                        rows={5}
                      />
                    </div>
                    <div className="note-button">
                      <button
                        className="primary_btn btn"
                        onClick={handleAddNote}
                      >
                        Leave Notes
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="claim-info">Customer</div>
                  <div className="claim-info-details-main">
                    <div className="claim-info-details-left">
                      <div className="claim-info-details">
                        <p className="claim-info-details-title">
                          {ClaimDetails.Claims.orders.customer_name}
                        </p>
                        <div>
                          <div className="claim-info-mail">
                            <CustomerMail height={20} width={20} />
                            <Link
                              to={`mailto:${ClaimDetails.Claims.orders.customer_email}`}
                            >
                              {ClaimDetails.Claims.orders.customer_email}
                            </Link>
                          </div>
                          {ClaimDetails.Claims.orders.address?.phone && (
                            <div className="claim-info-phone">
                              <BsTelephone color="#000" size={18} />
                              <p>{ClaimDetails.Claims.orders.address?.phone}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {ClaimDetails.Claims.orders.address && (
                        <div className="claim-info-address">
                          <CustomerAddress height={20} width={20} />
                          <div className="text-address">
                            <p>
                              {ClaimDetails.Claims.orders.address?.address1}
                            </p>
                            <p>
                              {ClaimDetails.Claims.orders.address?.address2}
                            </p>
                            <p>
                              {ClaimDetails.Claims.orders.address?.city}{" "}
                              {ClaimDetails.Claims.orders.address?.zip}{" "}
                              {ClaimDetails.Claims.orders.address?.province}{" "}
                              {ClaimDetails.Claims.orders.address?.country}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="customer-total-claims">
                      <div className="number-total">
                        <span>{ClaimDetails?.Claims?.customer_claim_no}</span>
                        <sup>
                          {indicator(ClaimDetails?.Claims?.customer_claim_no)}
                        </sup>
                      </div>
                      {/* <span className="number-ordinal">{indicator(1)}</span> */}
                      <p className="number-claim">Claim</p>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="claim-info">Totals</div>
                  <div className="claim-info-Total">
                    <div className="total-df">
                      <p>Claim Total</p>
                      <p> $ {ClaimDetails.Claims.total?.claim_total}</p>
                    </div>
                    <div className="total-df">
                      <p>Refund Total</p>
                      <p>$ {ClaimDetails.Claims.total?.refund_total}</p>
                    </div>
                    <div className="total-df">
                      <p>Reorder Total</p>
                      <p>$ {ClaimDetails.Claims.total?.reorder_total}</p>
                    </div>
                  </div>
                  <div className="claim-info-address">
                    <p className="total-info">{t("THIS_AMOUNT_WLL")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ClaimDetailComponent;
