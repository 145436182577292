import React from "react";
import { TbPointFilled } from "react-icons/tb";
import Chart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Inreview } from "../../../../../assets/images/claimStatus/inreview.svg";
import { ReactComponent as Closed } from "../../../../../assets/images/claimStatus/closed.svg";
import { ReactComponent as Total } from "../../../../../assets/images/claimStatus/total.svg";
import { ReactComponent as Approved } from "../../../../../assets/images/claimStatus/approved.svg";
import { DashboardIn } from "../../../../../assets/images/sidebar";
import DashboardHeaderComponent from "../../../common/dashboardHeader/dashboradHeader";
import { dateConvert, getMonthName } from "../../../../../utils/helper";
import { IoArrowForward } from "react-icons/io5";

const AdminOnboardPage = (props) => {
  const { statistics, adminActivity } = props;
  const { claims, events } = adminActivity;

  const { claim_count, monthly, total } = statistics;
  const statusData = [
    {
      title: "In review",
      count: claim_count.in_review,
      Icon: Inreview,
      backgroundColor: "#9BF5F5",
    },
    {
      title: "Closed",
      count: claim_count.closed,
      Icon: Closed,
      backgroundColor: "#E3FAA2",
    },
    {
      title: "Approved",
      count: claim_count.approved,
      Icon: Approved,
      backgroundColor: "#EDC6ED",
    },
    {
      title: "Total",
      count: claim_count.total,
      Icon: Total,
      backgroundColor: "#FDF399",
    },
  ];
  const overallRevenue = [
    total.fees_collected,
    total.net_revenue,
    total.paid_out,
  ];
  const overallRevenueLabel = ["Fees collected", "Net revenue", "Paid out"];

  const compareData = {
    categories: monthly.map((entry) => getMonthName(entry.month, entry.year)),
    series: [
      {
        name: "Fees collected",
        data: monthly.map((entry) => entry.fees_collected),
      },
      {
        name: "Net revenue",
        data: monthly.map((entry) => entry.net_revenue),
      },
      {
        name: "Paid out",
        data: monthly.map((entry) => entry.paid_out),
      },
    ],
  };

  const compareGraphOption = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: compareData.categories,
    },
    colors: ["#E3FAA2", "#EDC6ED", "#9BF5F5"],
    dataLabels: {
      enabled: false,
    },
  };

  const newInstallOption = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: monthly.map((entry) => getMonthName(entry.month)),
    },
    colors: ["#EDC6ED"],
  };
  const newInstallSeries = [
    {
      name: "New Installation",
      data: monthly.map((entry) => entry.new_install),
    },
  ];

  const statusCount = (count) => {
    if (count < 10) {
      return `0${count}`;
    } else {
      return count;
    }
  };
  const navigate = useNavigate();
  return (
    <div className="admin-home-main">
      <div className="admin-home-container">
        <DashboardHeaderComponent title="Dashboard" Logo={DashboardIn} />
        <div className="main-content">
          <div className="left-content">
            <div className="box-section">
              {statusData.map((status, index) => (
                <div className="first-section" key={index}>
                  <div className="content-view">
                    <div>
                      <h3>{status.title}</h3>
                      <p>{statusCount(status.count)}</p>
                    </div>
                    <div
                      className="icon-view"
                      style={{ backgroundColor: status.backgroundColor }}
                    >
                      <status.Icon height={18} width={18} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="graph-container">
              {/* <div className="round-chart">
                <div className="round-chart-content">
                  <p>Overall Revenue</p>
                  <Chart
                    options={{
                      labels: overallRevenueLabel,
                      colors: ["#E3FAA2", "#EDC6ED", "#9BF5F5"],
                      dataLabels: {
                        style: {
                          colors: ["#1a1e22"],
                        },
                      },
                    }}
                    series={overallRevenue}
                    type="donut"
                  />
                </div>
              </div> */}
              <div className="bar-chart">
                <div className="bar-chart-content">
                  <p>New Installation</p>

                  <Chart
                    options={newInstallOption}
                    series={newInstallSeries}
                    type="area"
                    height={350}
                  />
                </div>
              </div>
            </div>
            <div className="spline-graph">
              <p>Monthly Revenue</p>
              <Chart
                options={compareGraphOption}
                series={compareData.series}
                type="bar"
                height={350}
              />
            </div>
          </div>
          <div className="right-content">
            <div className="right-container">
              <div className="recent-activities">
                <div className="left-title">Recent Activity</div>
                <div className="recent-activities-container">
                  {events.map((event, index) => {
                    return (
                      <div
                        className={`activities-content-view ${
                          event.type === "COMMENT" && "cursor"
                        }`}
                        key={index}
                        onClick={() =>
                          event.type === "COMMENT"
                            ? navigate(`/dashboard/claims/${event.claim}`)
                            : ""
                        }
                      >
                        <div className="activities-title-view">
                          <div className="round">
                            <TbPointFilled color="#000" size={15} />
                          </div>
                          <p className="activities-title">{event.title}</p>
                        </div>
                        <div className="activities-time-view">
                          <p className="activities-time">
                            {dateConvert(event.ts * 1000)}
                          </p>
                          {event.type === "COMMENT" && (
                            <IoArrowForward
                              size={20}
                              color="#19acff"
                              opacity={0.7}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="right-container">
              <div className="recent-activities">
                <div className="left-title">New Claims</div>
                <div className="recent-activities-container">
                  {claims.map((event, index) => {
                    return (
                      <div className="claim-content-view" key={index}>
                        <div className="claim-view">
                          <div className="claim-data">
                            <label className="claim-label">Claim</label>
                            <Link
                              to={`/dashboard/claims/${event._id}`}
                              className="claim-data-id id"
                            >
                              {event._id}
                            </Link>
                          </div>
                          <div className="claim-data">
                            <label className="claim-label">Created At</label>
                            <label className="claim-data-id">
                              {dateConvert(event.createdAt)}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOnboardPage;
