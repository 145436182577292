import React from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const ProtectData = ({ loading, protectData }) => {
  const { t } = useTranslation();
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <>
      <div className="data-container-protect">
        <div className="data-container-title">
          <span className="block"></span>
          <span className="text">Protect</span>
        </div>
        <div className={`data-container`}>
          <div className="data-container-box">
            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView1 ? "visible" : ""}`
              }
            ></div>
            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView1 ? "visible" : ""
                }`
              }
            >
              {protectData?.resolved}
            </div>

            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView1 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_1")}
            </div>

            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView1 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_1_A")}
            </div>
          </div>
          <div className="data-container-box">
            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView1 ? "visible" : ""}`
              }
            ></div>

            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView1 ? "visible" : ""
                }`
              }
            >
              {protectData?.approved}
            </div>

            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView1 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_2")}
            </div>

            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView1 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_2_A")}
            </div>
          </div>
          <div className="data-container-box">
            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView1 ? "visible" : ""}`
              }
            ></div>

            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView1 ? "visible" : ""
                }`
              }
            >
              {protectData?.saved_revenue}
            </div>

            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView1 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_3")}
            </div>

            <div
              ref={ref1}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView1 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_3_A")}
            </div>
          </div>
        </div>
        <div className="data-container">
          <div className="data-container-box">
            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView2 ? "visible" : ""}`
              }
            ></div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView2 ? "visible" : ""
                }`
              }
            >
              {protectData?.protected_order}
            </div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView2 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_4")}
            </div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView2 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_4_A")}
            </div>
          </div>
          <div className="data-container-box">
            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView2 ? "visible" : ""}`
              }
            ></div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView2 ? "visible" : ""
                }`
              }
            >
              {protectData?.protected_order_percentage}
            </div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView2 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_5")}
            </div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView2 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_5_A")}
            </div>
          </div>
          <div className="data-container-box">
            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView2 ? "visible" : ""}`
              }
            ></div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView2 ? "visible" : ""
                }`
              }
            >
              {protectData?.total_swipe_protected}
            </div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView2 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_6")}
            </div>

            <div
              ref={ref2}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView2 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_6_A")}
            </div>
          </div>
        </div>
        <div className="data-container">
          <div className="data-container-box">
            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView3 ? "visible" : ""}`
              }
            ></div>

            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView3 ? "visible" : ""
                }`
              }
            >
              {protectData?.atfr}
            </div>

            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView3 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_7")}
            </div>

            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView3 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_7_A")}
            </div>
          </div>
          <div className="data-container-box">
            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView3 ? "visible" : ""}`
              }
            ></div>

            <div
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView3 ? "visible" : ""
                }`
              }
            >
              {protectData?.total_order_amount}
            </div>

            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView3 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_8")}
            </div>

            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView3 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_8_A")}
            </div>
          </div>
          <div className="data-container-box">
            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-border"} ${inView3 ? "visible" : ""}`
              }
            ></div>

            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-number-data"} ${
                  inView3 ? "visible" : ""
                }`
              }
            >
              {protectData?.csat}
            </div>

            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-title-data"} ${inView3 ? "visible" : ""}`
              }
            >
              {t("DESBO_PROT_9")}
            </div>

            <div
              ref={ref3}
              className={
                !loading &&
                `${"data-container-box-description"} ${
                  inView3 ? "visible" : ""
                }`
              }
            >
              {t("DESBO_PROT_9_A")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtectData;
