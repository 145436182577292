import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance  from "../../authServices/axiosInstance";

export const resetPassword = createAsyncThunk(
  "accountResetPassword",
  async (form) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const response = await AxiosInstance.post(
        `${API_URL}/v1/users/resetpassword`,
        form,
        {
          headers: { "x-access-token": token },
        }
      );
      return response.data.message;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const resetPasswordSlice = createSlice({
  name: "accountResetPassword",
  initialState: {
    isLoading: false,
    result: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [resetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.result = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [resetPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.result = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default resetPasswordSlice.reducer;
