import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance from "../../authServices/axiosInstance";
import { Cookies } from "react-cookie";

export const AdminActivity = createAsyncThunk("activity", async (merchantId) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const url = merchantId
      ? `${API_URL}/admin/dashboard/activity/${merchantId}`
      : `${API_URL}/admin/dashboard/activity`;

    const response = await AxiosInstance.get(url, {
      headers: { "x-access-token": token },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

const adminActivitySlice = createSlice({
  name: "activity",
  initialState: {
    isLoading: false,
    adminActivity: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [AdminActivity.pending]: (state) => {
      state.isLoading = true;
    },
    [AdminActivity.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.adminActivity = action.payload;
    },
    [AdminActivity.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default adminActivitySlice.reducer;
