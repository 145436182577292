import React, { useEffect } from "react";
import FinanceSetupGuide from "./finance";
import { connect } from "react-redux";
import { checkMerchantBilling } from "../../../../../redux/slice/checkMerchantBilingSlice";
import Loading from "../../../../common/Loading/Loading";

const FinanceTab = (props) => {
  const {
    callCheckMerchantBilling,
    checkMerchantBilling,
    loginData,
    sendBilling,
  } = props;

  const isBilling = checkMerchantBilling?.checkMerchantBilling?.billing;
  useEffect(() => {
    const payload = {
      shop_id: loginData?.loginData?.data?.merchantInfo?.shop_id,
    };
    callCheckMerchantBilling(payload);
  }, [
    callCheckMerchantBilling,
    loginData?.loginData?.data?.merchantInfo?.shop_id,
  ]);
  return (
    <>
      {checkMerchantBilling.isLoading && (
        <Loading type="overlay" className="loader-icon" />
      )}

      <FinanceSetupGuide isBilling={isBilling} sendBilling={sendBilling} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    checkMerchantBilling: state.checkMerchantBilling,
    loginData: state.loginData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callCheckMerchantBilling: (props) => dispatch(checkMerchantBilling(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceTab);
