import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance  from "../../authServices/axiosInstance";
import { Cookies } from "react-cookie";

export const getUsers = createAsyncThunk("users", async (page) => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  try {
    const response = await AxiosInstance.post(`${API_URL}/merchant/users/list?limit=25&page=${page}`, {} ,{
        headers: { "x-access-token": token },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

const getUsersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    allUsers: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allUsers = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default getUsersSlice.reducer;
