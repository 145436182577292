import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance  from "../../authServices/axiosInstance";

export const adminEditUser = createAsyncThunk(
  "adminUserEdit",
  async ({ id, form }) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const response = await AxiosInstance.put(`${API_URL}/admin/users/${id}`, form, {
        headers: { "x-access-token": token },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const adminEditUserSlice = createSlice({
  name: "adminUserEdit",
  initialState: {
    isLoading: false,
    adminEditUser: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [adminEditUser.pending]: (state) => {
      state.isLoading = true;
    },
    [adminEditUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.adminEditUser = action.payload;
    },
    [adminEditUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default adminEditUserSlice.reducer;
