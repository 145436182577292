import React, { useState } from "react";
import { Button, Checkbox, Input, Modal, Table, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import EditUserComponent from "./editUser/editUser";
import Loading from "../../../../../../common/Loading/Loading";
import Notify from "../../../../../../common/Notify/notify";
import { ReactComponent as DeleteUser } from "../../../../../../../assets/images/deleteUserPopup.svg";
import { ReactComponent as Edit } from "../../../../../../../assets/images//edit.svg";
import { ReactComponent as Password } from "../../../../../../../assets/images/password.svg";
import { ReactComponent as Delete } from "../../../../../../../assets/images/delete.svg";

const UsersPage = (props) => {
  const {
    users,
    handleCreate,
    handleCheckboxChange,
    handleInputChange,
    displayName,
    email,
    password,
    permissions,
    isModalOpen,
    setIsModalOpen,
    formErrors,
    handleCancel,
    callEditUserDetails,
    editUserDetails,
    callEditUser,
    callAdminStoreDetails,
    callDeleteUser,
    callResetPasswordLink,
    merchantId,
    addMerchantUserLoading,
    deleteMerchantUserLoading,
  } = props;
  const userId = editUserDetails?.editUserDetail?.data._id;
  const { id } = useParams();
  const [onDeleteConfirmation, setOnDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");

  const handleDeleteIconClick = (id) => {
    setDeleteUserId(id);
    setOnDeleteConfirmation(true);
  };

  const handleDeleteUser = async () => {
    if (userId && !deleteUserId) {
      const response = await callDeleteUser({
        merchantId: merchantId,
        userId: userId,
      });
      if (response.type === "deleteMerchantUser/fulfilled") {
        Notify("success", response.payload.message, "");
        callAdminStoreDetails(id);
        setEditModel(false);
        setOnDeleteConfirmation(false);
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
        }
      }
    } else {
      const response = await callDeleteUser({
        merchantId: merchantId,
        userId: deleteUserId,
      });
      if (response.type === "deleteMerchantUser/fulfilled") {
        Notify("success", response.payload.message, "");
        callAdminStoreDetails(id);
        setEditModel(false);
        setOnDeleteConfirmation(false);
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
        }
      }
    }
  };

  const permissionStatic = ["billing", "claims", "dashboard", "users"];
  const permissionNames = {
    billing: "Billing",
    claims: "Claims",
    dashboard: "Dashboard",
    users: "Users",
  };

  const sendResetPasswordLink = async (userEmail) => {
    const email = editUserDetails.editUserDetail?.data.email;
    const response = await callResetPasswordLink(email || userEmail);
    if (response.type === "send-reset-password/fulfilled") {
      Notify("success", response.payload.message, "");
      setEditModel(false);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };
  const [editModel, setEditModel] = useState(false);
  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "No",
          },
        };
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text) => {
        return {
          children: (
            <div className="user-table-fl">
              <div className="user-logo">
                {text
                  .split(/\s/)
                  .slice(0, 2)
                  .map((word) => word.slice(0, 1))
                  .join("")}
              </div>
              {text}
            </div>
          ),
          props: {
            "data-label": "User",
          },
        };
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Email",
          },
        };
      },
    },
    ...permissionStatic.map((permission) => ({
      title: permissionNames[permission],
      dataIndex: "permissions",
      key: permission,
      render: (permissions) => {
        return {
          children: (
            <Checkbox
              checked={permissions.includes(permission)}
              disabled={true}
            ></Checkbox>
          ),
          props: {
            "data-label": permissionNames[permission],
          },
        };
      },
    })),
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return {
          children: (
            <div className="user-action-row">
              <Tooltip title="Edit user">
                <div onClick={() => handleRowClick(record.id)}>
                  <Edit size={22} />
                </div>
              </Tooltip>
              <div>
                <Tooltip title="Please provide a reset password link upon clicking the designated icon.">
                  <Password
                    size={21}
                    onClick={() => sendResetPasswordLink(record.email)}
                  />
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Delete user">
                  <Delete
                    size={22}
                    onClick={() => handleDeleteIconClick(record.id)}
                  />
                </Tooltip>
              </div>
            </div>
          ),
          props: {
            "data-label": "Actions",
          },
        };
      },
    },
  ];

  const data =
    Array.isArray(users) &&
    users?.map((user, index) => ({
      key: (index + 1).toString(),
      user: user.display_name,
      email: user.email,
      id: user._id,
      permissions: user.permissions,
    }));

  const handleRowClick = async (record) => {
    await callEditUserDetails(record);
    setEditModel(true);
  };

  return (
    <>
      {addMerchantUserLoading || deleteMerchantUserLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : null}
      <div className="user-main-section" style={{ padding: "0" }}>
        <div className="user-store-header">
          <div className="store-user-title">
            <span>User</span>
          </div>
          <button
            className="primary_btn btn"
            onClick={() => {
              setIsModalOpen(!isModalOpen);
            }}
          >
            Create New User
          </button>
        </div>
        <div className="user-body">
          <div className="user-box">
            <Table
              columns={columns}
              dataSource={data}
              pagination={users?.length >= 10 ? true : false}
              style={{ cursor: "pointer" }}
              locale={{ emptyText: "No user found" }}
            />
          </div>
        </div>
        <Modal
          open={isModalOpen}
          centered
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={handleCancel}
        >
          <div className="onBoard-Model">
            <div className="onBoard-title">
              <span>New User</span>
            </div>
            <div className="user-modal-body">
              <Input
                name="displayName"
                placeholder="Display Name"
                value={displayName}
                onChange={handleInputChange}
              />
              {formErrors.displayName && (
                <div className="field-error">{formErrors.displayName}</div>
              )}
              <Input
                name="email"
                placeholder="Email"
                type="email"
                value={email}
                onChange={handleInputChange}
              />
              {formErrors.email && (
                <div className="field-error">{formErrors.email}</div>
              )}
              <Input
                name="password"
                placeholder="Password"
                type="password"
                value={password}
                onChange={handleInputChange}
              />
              {formErrors.password && (
                <div className="field-error">{formErrors.password}</div>
              )}
            </div>
            <div className="user-modal-permission">
              <div className="user-modal-permission-title">
                <span>Permissions</span>
              </div>
              <div className="user-modal-permission-option">
                <Checkbox
                  value="account_manager"
                  checked={permissions.includes("account_manager")}
                  onChange={() => handleCheckboxChange("account_manager")}
                >
                  Account Management
                </Checkbox>
                <Checkbox
                  value="billing"
                  checked={permissions.includes("billing")}
                  onChange={() => handleCheckboxChange("billing")}
                >
                  Billing
                </Checkbox>
                <Checkbox
                  value="claims"
                  checked={permissions.includes("claims")}
                  onChange={() => handleCheckboxChange("claims")}
                >
                  Claims
                </Checkbox>
                <Checkbox
                  value="dashboard"
                  checked={permissions.includes("dashboard")}
                  onChange={() => handleCheckboxChange("dashboard")}
                >
                  Dashboard
                </Checkbox>
                <Checkbox
                  value="users"
                  checked={permissions.includes("users")}
                  onChange={() => handleCheckboxChange("users")}
                >
                  Users
                </Checkbox>
              </div>
            </div>
            <div className="onBoard-button">
              <button
                className="primary_btn btn"
                onClick={handleCreate}
                disabled={addMerchantUserLoading}
              >
                Create
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        centered
        open={onDeleteConfirmation}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setOnDeleteConfirmation(false)}
        className="approve-popup"
      >
        <div className="user-delete-confirm-model">
          <div className="image-container">
            <DeleteUser />
          </div>

          <div className="user-delete-text">
            Are you sure you want to delete this user ?
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={() => setOnDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => handleDeleteUser()}
              disabled={deleteMerchantUserLoading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      {editModel && (
        <EditUserComponent
          isOpen={editModel}
          onClose={() => setEditModel(false)}
          editUserDetails={editUserDetails}
          callEditUser={callEditUser}
          callAdminStoreDetails={callAdminStoreDetails}
          setEditModel={setEditModel}
          callDeleteUser={callDeleteUser}
          callResetPasswordLink={callResetPasswordLink}
          sendResetPasswordLink={sendResetPasswordLink}
          handleDeleteUser={handleDeleteUser}
        />
      )}
    </>
  );
};

export default UsersPage;
