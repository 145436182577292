import React, { useEffect, useState } from "react";
import DashboardHeaderComponent from "../../common/dashboardHeader/dashboradHeader";
import { Tabs } from "antd";
import WelcomeSetupGuide from "./welcome/welcome";
import ProtectResolveSetupGuide from "./protectResolve/protectResolve";
// import TrackSetupGuide from "./track/track";
import BrandingProfileSetupGuide from "./brandingProfile/brandingProfile";
import AdminSetupGuide from "./admin/admin";
import { ReactComponent as SetupIcon } from "../../../../assets/images/sidebar/setup.svg";
import WidgetPage from "./widgets/widget";
import { useLocation, useNavigate } from "react-router-dom";
import { validateSetupGuideTab } from "../../../../utils/commonUtils";
import FinanceTab from "./finance";
import { GoDotFill } from "react-icons/go";
import { FaCheck } from "react-icons/fa6";

const SetupGuideComponent = (props) => {
  const { sendBilling, setupGuide, handleUpdateSetup } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("welcome");
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");
    const defaultTab = validateSetupGuideTab.includes(tabParam)
      ? tabParam
      : "welcome";
    navigate(`/dashboard/setup-guide?tab=${defaultTab}`);
    setActiveTab(defaultTab);
  }, [location.search, navigate]);

  const generateSetupTab = (key, label, children, complete) => {
    return {
      key,
      label: (
        <>
          {label}{" "}
          {complete ? (
            <FaCheck style={{ color: "fed101", fontSize: "25px" }} />
          ) : (
            <GoDotFill style={{ color: "fed101", fontSize: "25px" }} />
          )}
        </>
      ),
      children,
    };
  };

  const setUpGuideTab = [
    {
      key: "welcome",
      label: "Welcome",
      children: <WelcomeSetupGuide />,
    },
    generateSetupTab(
      "Protect_Resolve",
      "Protect & Resolve",
      <ProtectResolveSetupGuide
        handleUpdateSetup={handleUpdateSetup}
        data={setupGuide.protect_and_resolve}
      />,
      setupGuide.protect_and_resolve?.done
    ),
    // generateSetupTab("track", "Track", <TrackSetupGuide />, setupGuide.track?.done),
    generateSetupTab("widget", "Widget", <WidgetPage />),
    generateSetupTab(
      "branding_profile",
      "Branding/Profile",
      <BrandingProfileSetupGuide
        handleUpdateSetup={handleUpdateSetup}
        data={setupGuide.branding_and_profile}
      />,
      setupGuide.branding_and_profile?.done
    ),
    generateSetupTab(
      "finance",
      "Finance",
      <FinanceTab />,
      setupGuide.finance?.done
    ),
    generateSetupTab(
      "admin",
      "Admin",
      <AdminSetupGuide
        handleUpdateSetup={handleUpdateSetup}
        data={setupGuide.admin}
      />,
      setupGuide.admin?.done
    ),
  ];

  const handleTabChange = (key) => {
    navigate(`/dashboard/setup-guide?tab=${key}`);
    setActiveTab(key);
  };

  const handleNavigate = (key) => {
    setActiveTab(key);
    navigate(`/dashboard/setup-guide?tab=${key}`);
  };

  return (
    <div className="setup-guide-main">
      <div className="setup-guide-container">
        <DashboardHeaderComponent title="Setup Guide" Logo={SetupIcon} />
        <div className="setup-guide-section">
          <Tabs
            defaultActiveKey={activeTab}
            onChange={handleTabChange}
            className="setup-tabs-layout"
            activeKey={activeTab}
          >
            {setUpGuideTab.map((item) => (
              <Tabs.TabPane key={item.key} tab={item.label}>
                {React.cloneElement(item.children, {
                  handleNavigate,
                  sendBilling,
                })}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default SetupGuideComponent;
