import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const orderList = createAsyncThunk(
  "orderList",
  async ({ payload}) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const role = cookies.get("role");
      let url = `${API_URL}/${role}/order/list?limit=25&page=${payload?.currentPage}`;
      const queryParams = [];

      if (
        payload?.protect !== undefined &&
        payload?.protect !== null &&
        payload?.protect !== false
      ) {
        queryParams.push(`is_protected=${encodeURIComponent(payload.protect)}`);
      }
      if (payload?.id) {
        queryParams.push(`merchant=${encodeURIComponent(payload.id)}`);
      }

      if (payload?.date.start_date && payload?.date.end_date) {
        queryParams.push(
          `start_date=${encodeURIComponent(payload.date.start_date)}`
        );
        queryParams.push(
          `end_date=${encodeURIComponent(payload.date.end_date)}`
        );
      }
      if (payload?.search) {
        queryParams.push(`search=${encodeURIComponent(payload.search)}`);
      }

      if (queryParams.length > 0) {
        url += "&" + queryParams.join("&");
      }
      const response = await AxiosInstance.get(url, {
        headers: { "x-access-token": token },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const getOrderListSlice = createSlice({
  name: "orderList",
  initialState: {
    isLoading: false,
    orderList: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [orderList.pending]: (state) => {
      state.isLoading = true;
    },
    [orderList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.orderList = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [orderList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.orderList = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default getOrderListSlice.reducer;
