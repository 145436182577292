import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import StatementPage from "./statementsPage";
import { getMerchantStatements } from "../../../../../redux/slice/getMerchantStatementsSlice";
import { downLoadStatements } from "../../../../../redux/slice/downloadStatementsSlice";
import { previewStatements } from "../../../../../redux/slice/previewStatementsSlice";
import Loading from "../../../../common/Loading/Loading";

const Statement = (props) => {
  const {
    callGetMerchantStatements,
    merchantStatements,
    loginData,
    callDownLoadStatements,
    callPreviewStatements,
    previewStatments,
  } = props;
  const merchantId = loginData?.loginData?.data?.merchantInfo?._id;
  const cookies = new Cookies();
  const role = cookies.get("role");

  const [filter, setFilter] = useState({
    month: 0,
    year: 0,
  });
  useEffect(() => {
    let month = filter.month === "all" ? 0 : filter.month;
    let year = filter.year === "all" ? 0 : filter.year;
    const params = {
      id: merchantId,
      month: month,
      year: year,
    };
    role === "merchant" && callGetMerchantStatements(params);
  }, [filter, callGetMerchantStatements, merchantId, role]);
  return (
    <>
      <div>
        {merchantStatements.isLoading && (
          <Loading type="overlay" className="loader-icon" />
        )}
        {merchantStatements.merchantStatements && (
          <StatementPage
            statements={merchantStatements.merchantStatements}
            callDownLoadStatements={callDownLoadStatements}
            merchantId={merchantId}
            callPreviewStatements={callPreviewStatements}
            previewStatments={previewStatments}
            setFilter={setFilter}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    merchantStatements: state.merchantStatements,
    loginData: state.loginData,
    downloadStatements: state.downloadStatements,
    previewStatments: state.previewStatments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetMerchantStatements: (props) =>
      dispatch(getMerchantStatements(props)),
    callDownLoadStatements: (props) => dispatch(downLoadStatements(props)),
    callPreviewStatements: (props) => dispatch(previewStatements(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statement);
