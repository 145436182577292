import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance  from "../../authServices/axiosInstance";

export const adminStatement = createAsyncThunk(
  "adminStatement",
  async ({ month, year, merchantId }) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");

      let apiUrl = `${API_URL}/v1/statement/list`;
      if (month) {
        apiUrl += `?month=${month}`;
      }
      if (year) {
        apiUrl += `${month ? "&" : "?"}year=${year}`;
      }
      if (merchantId) {
        apiUrl += `${month || year ? "&" : "?"}merchantId=${merchantId}`;
      }
      const response = await AxiosInstance.get(apiUrl, {
        headers: { "x-access-token": token },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const adminStatementSlice = createSlice({
  name: "adminStatement",
  initialState: {
    isLoading: false,
    adminStatement: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [adminStatement.pending]: (state) => {
      state.isLoading = true;
    },
    [adminStatement.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.adminStatement = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [adminStatement.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.claimList = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default adminStatementSlice.reducer;
