import React from "react";
import { rangePresets } from "../../../utils/helper";

const DatePreset = ({ isActive,handlePresetClick }) => {

    return (
        <div className="ant-picker-presets">
            <ul>
                {rangePresets.map((preset) => (
                    <li
                        key={preset.label}
                        className={preset.label === isActive ? 'ant-picker-preset-active' : ''}
                        onClick={() => handlePresetClick(preset)}
                    >
                        {preset.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DatePreset;
