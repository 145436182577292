import { Modal } from "antd";
import React, { useState } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import MultiStepProgressBar from "../../../common/MultiStepProgressBar/MultiStepProgressBar";
import { ReactComponent as StepBottom } from "../../../../assets/images/stepBottom.svg";
import Logo from "../../../../assets/images/logo/LogoBlack.png";
import { useTranslation } from "react-i18next";

const TaskPage = (props) => {
  const { data, username } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWidgetModalOpen, setIsWidgetModalOpen] = useState(false);
  const navigate = useNavigate();
  const steps = [
    {
      label: "Step 1",
      content: (
        <div className={`step-content`}>
          <h1>Welcome! {username}</h1>
          {/* <Checkbox checked={data.install_task.done}>
            {data.install_task.done ? (
              <strike>{data.install_task.label}</strike>
            ) : (
              data.install_task.label
            )}
          </Checkbox>
          {data.install_task.done ? (
            ""
          ) : (
            <Link to={data.install_task.url}>
              <strong>{data.install_task.actionLabel}</strong>
            </Link>
          )} */}
        </div>
      ),
    },
    {
      label: "Step 2",
      content: (
        <div className={`step-content`}>
          <h1> {t("TASK_GAIN")}</h1>
          {/* <Checkbox checked={data.onboard_task.done}>
            {data.onboard_task.done ? (
              <strike>{data.onboard_task.label}</strike>
            ) : (
              data.onboard_task.label
            )}
          </Checkbox>
          {data.onboard_task.done ? (
            ""
          ) : (
            <Link
              to={"https://calendly.com/angelifmgx/30min?month=2023-07"}
              target="_blank"
            >
              <strong>{data.onboard_task.actionLabel}</strong>
            </Link>
          )} */}
        </div>
      ),
    },
    {
      label: "Step 3",
      content: (
        <div className={`step-content`}>
          <h1>{t("TASK_GRANT")}</h1>
          {/* <Checkbox checked={data?.partner_task.done}>
            {data?.partner_task.done ? (
              <strike>{data?.partner_task.label}</strike>
            ) : (
              data?.partner_task.label
            )}
          </Checkbox>
          {data?.partner_task.done ? (
            ""
          ) : (
            <strong onClick={() => setIsModalOpen(!isModalOpen)}>
              {data?.partner_task.actionLabel}
            </strong>
          )} */}
        </div>
      ),
    },
    {
      label: "Step 4",
      content: (
        <div className={`step-content`}>
          <h1>{t("TASK_CONF")}</h1>
          {/* <Checkbox checked={data.billing_task.done}>
            {data.billing_task.done ? (
              <strike>{data.billing_task.label}</strike>
            ) : (
              data.billing_task.label
            )}
          </Checkbox>
          {data.billing_task.done ? (
            ""
          ) : (
            <strong>{data.billing_task.actionLabel}</strong>
          )} */}
        </div>
      ),
    },
    {
      label: "Step 5",
      content: (
        <div className={`step-content`}>
          <h1>{t("TASK_INTEGRA")}</h1>
          {/* <Checkbox checked={data.widget_task.done}>
            {data.widget_task.done ? (
              <strike>{data.widget_task.label}</strike>
            ) : (
              data.widget_task.label
            )}
          </Checkbox>
          {data.widget_task.done ? (
            ""
          ) : (
            <strong
              onClick={() => {
                setIsWidgetModalOpen(!isWidgetModalOpen);
              }}
            >
              {data.widget_task.actionLabel}
            </strong>
          )} */}
        </div>
      ),
    },
  ];
  const handleCancel = () => {
    setIsWidgetModalOpen(false);
    setIsModalOpen(false);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = parseInt(searchParams.get("step"));

  const [currentStep, setCurrentStep] = useState(paramValue);
  const handleNext = () => {
    if (currentStep <= steps.length) {
      setCurrentStep(currentStep + 1);
      navigate(`/task?step=${currentStep + 1}`);
    }
  };
  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      navigate(`/task?step=${currentStep - 1}`);
    }
  };

  const handleFinish = () => {
    navigate("/dashboard/setup-guide");
  };

  const nextPageNumber = (pageNumber) => {
    switch (pageNumber) {
      case 1:
        setCurrentStep(1);
        navigate(`/task?step=1`);
        break;
      case 2:
        setCurrentStep(2);
        navigate(`/task?step=2`);
        break;
      case 3:
        setCurrentStep(3);
        navigate(`/task?step=3`);
        break;
      case 4:
        setCurrentStep(4);
        navigate(`/task?step=4`);
        break;
      case 5:
        setCurrentStep(5);
        navigate(`/task?step=5`);
        break;
      default:
        setCurrentStep(0);
    }
  };

  return (
    <div className="task">
      <div className="left-side">
        <div className="top-logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="bottom-logo">
          <StepBottom />
        </div>
      </div>
      <div className="right-side">
        <div className="onBoard-main-section-step">
          <div className="onBoard-body">
            <div className={`onBoard-box-step`}>
              <div className="onBoard-body-title-step">
                <p>
                  <strong>Tasks</strong>
                </p>
              </div>
              <div className="onBoard-body-desc-step">
                <MultiStepProgressBar
                  page={currentStep}
                  onPageNumberClick={nextPageNumber}
                  data={data}
                />
                {steps[currentStep - 1].content}

                <div className="button-container">
                  {currentStep !== 1 && (
                    <span
                      className="btn-step"
                      onClick={handlePrevious}
                      disabled={currentStep === 1}
                    >
                      <BsArrowLeft /> Prev
                    </span>
                  )}

                  {currentStep < steps.length && (
                    <span
                      className="btn-step btn-right"
                      onClick={handleNext}
                      disabled={currentStep === steps.length}
                    >
                      Next <BsArrowRight />
                    </span>
                  )}

                  {currentStep === steps.length && (
                    <span
                      className="btn-finish btn-right"
                      onClick={handleFinish}
                    >
                      Finish
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Approve Shopify Partner Request POPUP */}
          <Modal
            centered
            open={isModalOpen}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={handleCancel}
          >
            <div className="onBoard-Model">
              <div className="onBoard-title">
                <span>{data?.partner_task.label}</span>
              </div>
              <div className="onBoard-welcome">
                <p>{data?.partner_task.popupText}</p>
              </div>
              <div className="onBoard-form">
                <div className="onBoard-form-input"></div>
                <div className="onBoard-button">
                  <button className="primary_btn btn" onClick={handleCancel}>
                    Got It!
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            centered
            open={isWidgetModalOpen}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={handleCancel}
          >
            <div className="onBoard-Model">
              <div className="onBoard-title">
                <span>{data.widget_task.label}</span>
              </div>
              <div className="onBoard-welcome">
                <p>{data.widget_task.popupText}</p>
              </div>
              <div className="onBoard-form">
                <div className="onBoard-form-input"></div>
                <div className="onBoard-button">
                  <button className="primary_btn btn" onClick={handleCancel}>
                    Got It!
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default TaskPage;
