import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const addManagerAssign = createAsyncThunk(
  "addManagerAssign",
  async ({ id, data }) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const response = await AxiosInstance.post(
        `${API_URL}/admin/dashboard/account-manager/assign/${id}`,
        data,
        {
          headers: { "x-access-token": token },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const addManagerAssignSlice = createSlice({
  name: "addManagerAssign",
  initialState: {
    isLoading: false,
    addManagerAssign: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [addManagerAssign.pending]: (state) => {
      state.isLoading = true;
    },
    [addManagerAssign.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.addManagerAssign = action.payload;
    },
    [addManagerAssign.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default addManagerAssignSlice.reducer;
