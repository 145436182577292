import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainDashboard from "../containers/dashboard/index";
import Sidebar from "../components/dashboard/common/sidebar";
import { Cookies } from "react-cookie";
import NotFound from "../components/notFound/notFound";
import Register from "../containers/register";
import ResetPassWord from "../containers/resetPassword";
import Login from "../containers/login";
import TaskPage from "../components/dashboard/pages/task";
import Onboarding from "../components/onboarding/onboarding";
import OnboardSuccess from "../components/onboarding/onBoardSuccess";
import { connect, useSelector } from "react-redux";
import resetPassword from "../containers/resetPassword";
import { loginData } from "../redux/slice/getLoginDataSlice";
import Loading from "../components/common/Loading/Loading";
import AllUserData from "../components/Testing/AllUserData";
import UserDetails from "../components/Testing/UserDetails";

const MainRoutes = (props) => {
  const { callLoginData } = props;
  const [dashboard, setDashboard] = useState();
  const [registerPage, setRegisterPage] = useState(false);
  const [resetPasswordPage, setResetPasswordPage] = useState(false);
  const [canNavigate, setCanNavigate] = useState(false);
  const location = useLocation();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const token = cookies.get("token");
  const [loading, setLoading] = useState(true);
  const [isApiCalled, setIsApiCalled] = useState(false);

  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "reset-password") {
      setResetPasswordPage(true);
    }
  }, [location]);

  useEffect(() => {
    setDashboard(
      token && window.location.pathname.split("/")[1] === "dashboard"
    );
  }, [location, token]);

  useEffect(() => {
    if (!isApiCalled) {
      setLoading(true);
      if (token) {
        callLoginData(token)
          .then((res) => {
            const is_onboarding = res.payload.data.merchantInfo.is_onboarding;
            if (token && is_onboarding) {
              if (
                window.location.pathname === "/" ||
                window.location.pathname === "/sign-in" ||
                window.location.pathname === "/onboarding" ||
                (window.location.pathname === "/onboard-success" && canNavigate)
              ) {
                navigate("/dashboard");
              }
            } else if (token && !is_onboarding) {
              navigate("/onboarding");
            }
          })
          .catch((error) => {
            console.error("Error fetching authentication data:", error);
          })
          .finally(() => setLoading(false));
        setIsApiCalled(true);
      } else {
        if (
          window.location.pathname.split("/")[1] === "register" &&
          location.search.includes("token")
        ) {
          setRegisterPage(true);
        } else {
          if (location.pathname.split("/")[1] !== "sign-in") {
            navigate(
              `/sign-in?redirect=${encodeURIComponent(location?.pathname)}`
            );
          }
        }
        setLoading(false);
      }
    }
  }, [token, location.search, navigate, canNavigate, isApiCalled]);

  useEffect(() => {
    if (
      window.location.pathname.split("/")[1] === "register" &&
      location.search.includes("token")
    ) {
      setRegisterPage(true);
    }
  }, [location]);

  if (loading) {
    return <Loading type="overlay" className="loader-icon" />;
  }

  return (
    <div style={{ display: "flex" }}>
      {dashboard && <Sidebar />}
      <div style={{ width: "100%" }}>
        <Routes>
          {registerPage && <Route path="/register" element={<Register />} />}
          {resetPasswordPage && (
            <Route path="/reset-password/*" element={<ResetPassWord />} />
          )}
          <Route path="/sign-in" element={<Login />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/all-user-data" element={<AllUserData />} />
          <Route path="/all-user-data/user-details" element={<UserDetails />} />
          <Route
            path="/onboard-success"
            element={<OnboardSuccess setCanNavigate={setCanNavigate} />}
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/task" element={<TaskPage />} />
          {token && (
            <React.Fragment>
              <Route path="/dashboard" element={<MainDashboard />} />
              <Route path="/dashboard/*" element={<MainDashboard />} />
            </React.Fragment>
          )}
          {token && <Route path="*" element={<NotFound />} />}
        </Routes>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (props) => dispatch(resetPassword(props)),
    callLoginData: (data) => dispatch(loginData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
