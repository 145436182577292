import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Modal } from "antd";
import { useParams } from "react-router-dom";
import Notify from "../../../../../../../common/Notify/notify";
import Loading from "../../../../../../../common/Loading/Loading";
import { ReactComponent as DeleteUser } from "../../../../../../../../assets/images/deleteUserPopup.svg";

const EditUser = (props) => {
  const {
    isOpen,
    onClose,
    editUserDetails,
    callEditUser,
    callAdminStoreDetails,
    setEditModel,
    sendResetPasswordLink,
    handleDeleteUser,
  } = props;
  const editId = editUserDetails?.editUserDetail?.data._id;
  const [confirmModal, setConfirmModal] = useState(false);

  const [displayName, setDisplayName] = useState(
    editUserDetails.editUserDetail?.data?.display_name
  );
  const [permissions, setPermissions] = useState(
    editUserDetails.editUserDetail?.data?.permissions
  );
  const [active, setActive] = useState(
    editUserDetails.editUserDetail?.data.disabled
  );
  const { id } = useParams();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "displayName") {
      setDisplayName(value);
    }
  };
  const handleCheckboxChange = (value) => {
    const updatedPermissions = permissions.includes(value)
      ? permissions.filter((permission) => permission !== value)
      : [...permissions, value];
    setPermissions(updatedPermissions);
  };

  const handleActiveCheckboxChanged = (value) => {
    setActive(value);
  };
  const handleCreate = async () => {
    const errors = {};
    if (!displayName) {
      errors.displayName = "Name is required";
    }

    if (Object.keys(errors).length === 0) {
      const userData = {
        display_name: displayName,
        permissions,
        disabled: active,
        merchant: editUserDetails.editUserDetail?.data.merchant,
      };
      const response = await callEditUser({ id: editId, form: userData });
      if (response.type === "editMerchantUser/fulfilled") {
        Notify("success", response.payload.message, "");
        callAdminStoreDetails(id);
        setEditModel(false);
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
        }
      }
    }
  };
  return (
    <div>
      {editUserDetails.isLoading && <Loading type="overlay" />}
      <Modal
        open={isOpen}
        centered
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={onClose}
      >
        <Form
          initialValues={{
            displayName: editUserDetails.editUserDetail?.data.display_name,
          }}
        >
          <div className="edit-user-model">
            <div className="edit-user-model-title">Edit User</div>
            <div className="edit-user-model-button">
              <Button
                type="primary"
                danger
                className="delete-user-button"
                onClick={() => setConfirmModal(true)}
              >
                Delete User
              </Button>
              <Button
                type="primary"
                className="resetpassword-link-button"
                onClick={() => sendResetPasswordLink()}
              >
                Send Password Reset Link
              </Button>
            </div>
            <div className="edit-user-model-field-section">
              <Form.Item
                name="displayName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Name",
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  name="displayName"
                  placeholder="Display Name"
                  value={displayName}
                  defaultValue={
                    editUserDetails.editUserDetail?.data.display_name
                  }
                  onChange={handleInputChange}
                  className="edit-user-model-field"
                />
              </Form.Item>
              <Form.Item
                name="email"
                initialValue={editUserDetails.editUserDetail?.data.email}
                labelCol={{ span: 8 }}
              >
                <Input
                  name="email"
                  value={editUserDetails.editUserDetail?.data.email}
                  placeholder="E-mail"
                  disabled
                  className="edit-user-model-field"
                />
              </Form.Item>
            </div>
            <div className="edit-permission-title">Permisson</div>
            <div className="edit-model-bottom-section">
              <div className="edit-user-permission">
                <Checkbox
                  value="account_manager"
                  checked={permissions.includes("account_manager")}
                  onChange={() => handleCheckboxChange("account_manager")}
                >
                  Account Management
                </Checkbox>
                <Checkbox
                  value="billing"
                  checked={permissions.includes("billing")}
                  onChange={() => handleCheckboxChange("billing")}
                >
                  Billing
                </Checkbox>
                <Checkbox
                  value="claims"
                  checked={permissions.includes("claims")}
                  onChange={() => handleCheckboxChange("claims")}
                >
                  Claims
                </Checkbox>
                <Checkbox
                  value="dashboard"
                  checked={permissions.includes("dashboard")}
                  onChange={() => handleCheckboxChange("dashboard")}
                >
                  Dashboard
                </Checkbox>
                <Checkbox
                  value="users"
                  checked={permissions.includes("users")}
                  onChange={() => handleCheckboxChange("users")}
                >
                  Users
                </Checkbox>
              </div>
              <div className="edit-user-permission">
                <Checkbox
                  value="users"
                  checked={!active}
                  onChange={() => handleActiveCheckboxChanged(!active)}
                  defaultValue={editUserDetails.editUserDetail?.data.disabled}
                >
                  Active
                </Checkbox>
              </div>
            </div>
            <div className="edit-user-update-button">
              <button className="primary_btn btn" onClick={handleCreate}>
                Update
              </button>
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        centered
        open={confirmModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setConfirmModal(false)}
        className="approve-popup"
      >
        <div className="user-delete-confirm-model">
          <div className="image-container">
            <DeleteUser />
          </div>
          <div className="user-delete-text">
            Are you sure you want to delete this user.
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={() => setConfirmModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => handleDeleteUser()}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditUser;
