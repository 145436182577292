import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../environment';
import AxiosInstance from '../../authServices/axiosInstance';
import { Cookies } from 'react-cookie';

export const getEngageNotification = createAsyncThunk(
  'getEngageNotification',
  async (merchantId) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const role = cookies.get("role");
      const merchantNotificationUrl = `${API_URL}/merchant/shippingnotification/list`;
      const singleMerchantNotificationUrl = `${API_URL}/admin/shippingnotification/list/${merchantId}`;
      const url =
        role === "admin" ? singleMerchantNotificationUrl : merchantNotificationUrl;
      const response = await AxiosInstance.get(url,
        {
          headers: { 'x-access-token': token },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const getEngageNotificationSlice = createSlice({
  name: 'getEngageNotification',
  initialState: {
    isLoading: false,
    notificationData: null,
    isError: false,
    errorMessage: '',
  },
  extraReducers: {
    [getEngageNotification.pending]: (state) => {
      state.isLoading = true;
    },
    [getEngageNotification.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notificationData = action.payload;
    },
    [getEngageNotification.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default getEngageNotificationSlice.reducer;
