import React from "react";
import SetupGuideBtn from "../../../../common/setupGuide/setupGuideBtn";
import ResourceCardBox from "../../../../common/setupGuide/ResourceCardBox";
import { BsFileEarmarkText } from "react-icons/bs";
import { ReactComponent as CheckIcon } from "../../../../../assets/images/checkmark.svg";
import { Collapse, Badge } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { useTranslation } from "react-i18next";
import { openExternalLink, splitAndMap } from "../../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { BsArrowRight } from "react-icons/bs";

const BrandingProfileSetupGuide = ({ data, handleUpdateSetup }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div>
      <div className="welcome-tab-main-section">
        <div className="tab-container">
          <p className="protect-head track-head">Branding & Profile</p>
          <div className="bordered-line"></div>
          <div className="welcome-tab-desc track-pg-w">
            <p>{t("BRAND_STR_THIS_SEC")}</p>
          </div>
          <div className="protect-plan-cards-box">
            <div
              className={`protect-plan-cards ${
                data?.branding_and_merchant_profile === "Blank"
                  ? "bg-yellow"
                  : ""
              }`}
            >
              <div className="plan-card-dv1">
                <div className="plan-card-dv2">
                  <div className="plan-content1">
                    {data?.branding_and_merchant_profile === "Blank" ? (
                      <FaAngleRight
                        height={12}
                        width={12}
                        style={{ margin: "20px 32px auto 0px" }}
                      />
                    ) : (
                      <CheckIcon
                        height={12}
                        width={12}
                        style={{ margin: "auto 32px auto 0px" }}
                      />
                    )}
                    <div>
                      <div className="text-main-1">
                        <p>{t("BRAND_STR_SETUP")}</p>
                        <Badge
                          className="site-badge-count-109"
                          count={"REQUIRED"}
                          style={{
                            backgroundColor: "black",
                          }}
                        />
                      </div>

                      <span className="text-main-2">
                        {t("BRAND_STR_SETUP_YOUR")}
                      </span>
                    </div>
                  </div>
                  <div className="plan-content2">
                    <span className="plan-content2-text">
                      {data?.branding_and_merchant_profile === "Blank" ? (
                        <SetupGuideBtn
                          title="Set Up Branding & Merchant Profile"
                          onClick={() =>
                            navigate("/dashboard/engage?modal=profile")
                          }
                        />
                      ) : (
                        <p>{t("BRAND_STR_THIS_STEP")}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`protect-plan-cards ${
                data?.setup_merchant_categories === "Blank" ? "bg-yellow" : ""
              }`}
            >
              <div className="plan-card-dv1">
                <div className="plan-card-dv2">
                  <div className="plan-content1">
                    {data?.setup_merchant_categories === "Blank" ? (
                      <FaAngleRight
                        height={12}
                        width={12}
                        style={{ margin: "20px 32px auto 0px" }}
                      />
                    ) : (
                      <CheckIcon
                        height={12}
                        width={12}
                        style={{ margin: "auto 32px auto 0px" }}
                      />
                    )}

                    <div>
                      <div className="text-main-1">
                        <p>{t("BRAND_STR_SETUP_MERCHANT")}</p>
                      </div>
                      <span className="text-main-2">
                        {t("BRAND_STR_SELECT")}
                      </span>
                    </div>
                  </div>
                  <div className="plan-content2">
                    <span className="plan-content2-text">
                      {/* initail text */}
                      {data?.setup_merchant_categories === "Blank" && (
                        <>
                          <button
                            className="skip-btn"
                            onClick={() =>
                              handleUpdateSetup(
                                "/branding_and_profile/setup_merchant_categories/Dismiss"
                              )
                            }
                          >
                            <BsArrowRight
                              viewBox="0 0 15 12"
                              strokeWidth={1}
                              width={12}
                              height={12}
                              style={{
                                fill: "black",
                              }}
                            />
                            <span>Dismiss this task</span>
                          </button>
                          <SetupGuideBtn
                            title={t("MERCHANT_CAT_SET_BTN")}
                            onClick={() =>
                              navigate("/dashboard/engage?modal=category")
                            }
                          />
                        </>
                      )}

                      {/* complete text */}
                      {data?.setup_merchant_categories === "Completed" && (
                        <p>This step has been completed.</p>
                      )}
                    </span>
                  </div>
                  {data?.setup_merchant_categories === "Dismiss" && (
                    <div className="skip-text">
                      <p>This step was skipped.</p>
                      <p>
                        You can manage your merchant categories at any time{" "}
                        <span
                          onClick={() =>
                            navigate("/dashboard/engage?modal=category")
                          }
                        >
                          HERE
                        </span>
                        .
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bordered-line"></div>
          <div className="resources-sec">
            <p>Resources</p>
            <div className="box-df">
              <ResourceCardBox
                title={
                  <BsFileEarmarkText
                    style={{ width: "30px", height: "30px", fill: "#1a1e22" }}
                  />
                }
                extra={
                  <SetupGuideBtn
                    title={t("SEE_HOW_BTN")}
                    onClick={() => {
                      openExternalLink(
                        "https://swipe.ai/article/set-up-your-in-app-merchant-profile/"
                      );
                    }}
                  />
                }
                details={t("RESOURCE_BRAND_1")}
              />
            </div>
          </div>
          <div className="bordered-line"></div>

          <div className="faq-box">
            <p className="texts-accordion">FAQs</p>
            <Collapse
              size="large"
              accordion={true}
              className="collapse-tab-out"
            >
              <CollapsePanel
                header={t("FAQ_BRAND_1")}
                className="collapse-tab-in"
                key="1"
              >
                <p>{splitAndMap(t("FAQ_BRAND_ANS_1"))}</p>

                <SetupGuideBtn
                  title={t("LEARN_MOR_ABOU_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/set-up-your-in-app-merchant-profile/"
                    );
                  }}
                />
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_BRAND_2")}
                key="2"
              >
                <p>{splitAndMap(t("FAQ_BRAND_ANS_2"))}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_BRAND_3")}
                className="collapse-tab-in"
                key="3"
              >
                <p>{splitAndMap(t("FAQ_BRAND_ANS_3"))}</p>
              </CollapsePanel>
              <CollapsePanel
                header={t("FAQ_BRAND_4")}
                className="collapse-tab-in"
                key="4"
              >
                <p>{splitAndMap(t("FAQ_BRAND_ANS_4"))}</p>
                <SetupGuideBtn
                  title={t("LEARN_MOR_ABOU_BTN")}
                  onClick={() => {
                    openExternalLink(
                      "https://swipe.ai/article/set-up-your-in-app-merchant-profile/"
                    );
                  }}
                />
              </CollapsePanel>

              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_BRAND_5")}
                key="5"
              >
                <p>{splitAndMap(t("FAQ_BRAND_ANS_5"))}</p>
                <SetupGuideBtn
                  title={t("MERCHNT_CAT")}
                  onClick={() => navigate("/dashboard/engage")}
                />
              </CollapsePanel>
              <CollapsePanel
                className="collapse-tab-in"
                header={t("FAQ_BRAND_6")}
                key="6"
              >
                <p>{splitAndMap(t("FAQ_BRAND_ANS_6"))}</p>
              </CollapsePanel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandingProfileSetupGuide;
