import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const claimStatistic = createAsyncThunk("claim-graph", async () => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const response = await AxiosInstance.get(
      `${API_URL}/merchant/dashboard/claim/graph`,
      {
        headers: { "x-access-token": token },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const claimStatisticSlice = createSlice({
  name: "claim-graph",
  initialState: {
    isLoading: false,
    claimStatistic: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [claimStatistic.pending]: (state) => {
      state.isLoading = true;
    },
    [claimStatistic.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.claimStatistic = action.payload;
      state.errorMessage = "";
      state.isError = false;
    },
    [claimStatistic.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.claimStatistic = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default claimStatisticSlice.reducer;
