import React, { useEffect, useState } from "react";
import { Checkbox, DatePicker, Input, Select, Table } from "antd";
import moment from "moment";
import { OrdersIn } from "../../../../assets/images/sidebar";
import DashboardHeaderComponent from "../../common/dashboardHeader/dashboradHeader";
import { dateConvert, rangePresets } from "../../../../utils/helper";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import DatePreset from "../../../common/DatePreset/datePreset";
import exportIcon from "../../../../assets/images/export.svg";
import dayjs from "dayjs";
import Notify from "../../../common/Notify/notify";

const OrdersPage = (props) => {
  const {
    orders,
    handleOnDateChange,
    merchantList,
    setClient_id,
    setisProtect,
    handlePageChange,
    currentPage,
    handleNavigateDetails,
    callGetOrderList,
    SelectedOption,
    setSelectedOption,
    dates,
    isProtect,
    handlePresetClick,
    selectedRange,
    setSelectedRange,
    isDate,
    setIsDate,
    isActive,
    setIsActive,
    callGetExportOrderList,
    setDates,
    searchValue,
    setSearchValue,
  } = props;
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cookies = new Cookies();
  const role = cookies.get("role");
  const { Search } = Input;
  let locale = {
    emptyText: "No protected orders",
  };
  const columns = [
    {
      title: "Order #",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (text) => {
        return {
          children: (
            <>
              <div className="protect-container">
                <span>{text.order_number}</span>
                {"   "}
                {text.is_protected === true && (
                  <IoMdCheckmarkCircleOutline className="protected-true" />
                )}
              </div>
            </>
          ),
          props: {
            "data-label": "Order #",
          },
        };
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Email",
          },
        };
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Created Date",
          },
        };
      },
    },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    //   render: (text) => {
    //     return {
    //       children: moment(text).format("MM-DD-YYYY"),
    //       props: {
    //         "data-label": "Date",
    //       },
    //     };
    //   },
    // },
  ];

  const data =
    Array.isArray(orders?.response) &&
    orders?.response?.map((item, index) => ({
      key: (index + 1).toString(),
      _id: item._id,
      orderNumber: item,
      email: item.email,
      // date: item.date,
      createdAt: item.createdAt,
    }));

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  let merchantOption = [];
  if (Array.isArray(merchantList?.data?.response)) {
    merchantOption = merchantList.data?.response.map((item) => ({
      value: item._id,
      label: item.name,
    }));
  }

  const merchantOptionsWithAll = [...merchantOption];

  const handleMerchantSelect = (value) => {
    if (value) {
      params.set("selected_option", value);
    } else {
      params.delete("selected_option");
    }

    navigate(`?${params.toString()}`);
    setSelectedOption(value);
    setClient_id({ id: value });
  };

  const handelProtected = (event) => {
    if (event.target.checked) {
      setisProtect({ protect: true });
      params.set("protect", true);
      navigate(`?${params.toString()}`);
    } else {
      setisProtect({ protect: false });
      params.delete("protect");
      navigate(`?${params.toString()}`);
    }
  };
  // const handleCancel = () => {
  //   // Logic for handling cancel action
  //   setIsDate(false);
  // };

  const handleExportData = async () => {
    try {
      const SelectedOption = params.get("selected_option");
      const startDate = params.get("start_date");
      const endDate = params.get("end_date");
      const protect = params.get("protect");
      const days = params.get("days");
      if (days) {
        setIsActive(days);
      }

      if (protect) setisProtect({ protect: protect });
      if (startDate || endDate) {
        setDates([
          startDate ? dayjs(startDate, "M-DD-YYYY") : null,
          endDate ? dayjs(endDate, "M-DD-YYYY") : null,
        ]);
      }
      if (SelectedOption) {
        setClient_id({ id: SelectedOption });
      }

      if (
        SelectedOption ||
        startDate ||
        endDate ||
        protect ||
        !protect ||
        searchValue
      ) {
        setSelectedOption(SelectedOption ? SelectedOption : null);
        const payload = {
          id: SelectedOption,
          protect: protect ? protect : null,
          date: {
            start_date: startDate || null,
            end_date: endDate || null,
          },
          search: searchValue,
        };

        const exportResult = await callGetExportOrderList({ payload });
        
        if (exportResult.type === "exportOrderList/fulfilled") {
          const pdfBlob = new Blob([exportResult.payload], {
            type: "text/csv",
          });

          const url = window.URL.createObjectURL(pdfBlob);

          const tempLink = document.createElement("a");
          tempLink.href = url;

          document.body.appendChild(tempLink);
          tempLink.click();

          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(url);
        }else{
          Notify("error", exportResult.error.message, "");
        }
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <div className="onBoard-main">
        <div className="onBoard-container">
          <DashboardHeaderComponent title="Orders" Logo={OrdersIn} />
          <div className="onBoard-main-section">
            <div className="order-header">
              <div className="order-filter">
                {role === "admin" ? (
                  <Select
                    value={SelectedOption}
                    showSearch
                    size="large"
                    allowClear
                    placeholder="Select merchant"
                    onChange={handleMerchantSelect}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={merchantOptionsWithAll}
                    style={{ width: 240 }}
                  />
                ) : null}
                {/* <RangePicker
                onChange={handleOnDateChange}
                format="M-DD-YYYY"
                presets={rangePresets}
                value={dates}
              /> */}

                <RangePicker
                  onClick={() => setIsDate(true)}
                  open={isDate}
                  onOpenChange={(open) => {
                    if (!open) setIsDate(open);
                  }}
                  renderExtraFooter={() => (
                    <DatePreset
                      isActive={isActive}
                      setIsActive={setIsActive}
                      selectedRange={selectedRange}
                      setSelectedRange={setSelectedRange}
                      handlePresetClick={handlePresetClick}
                    />
                  )}
                  onChange={handleOnDateChange}
                  format="M-DD-YYYY"
                  value={dates}
                />
                <Search
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  enterButton
                  value={searchValue}
                  style={{ marginRight: 8 }}
                />
                <Checkbox
                  className="isProtected"
                  onChange={handelProtected}
                  checked={isProtect.protect ? true : isProtect.protect}
                >
                  Protected
                </Checkbox>
              </div>
              <button className="export_btn" onClick={handleExportData}>
                <img
                  src={exportIcon}
                  alt="export-icon"
                  className="export-icon"
                ></img>
                <p>Export</p>
              </button>
            </div>
            <div className="order-body">
              <div className="order-box 123">
                <Table
                  columns={columns}
                  dataSource={data}
                  locale={locale}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        handleNavigateDetails(record._id);
                      },
                    };
                  }}
                  pagination={{
                    current: currentPage,
                    pageSize: 25,
                    total: orders?.totalRecords,
                    onChange: handlePageChange,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersPage;
