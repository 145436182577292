import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";
import { ReactComponent as Logo } from "../../../../assets/images/logo/Swipe_logo_black.svg";
import { ReactComponent as Hamburger } from "../../../../assets/images/sidebar/hamburger-icon.svg";
import { ReactComponent as SetupIcon } from "../../../../assets/images/sidebar/setup.svg";
import { removeLoginData } from "../../../../redux/slice/getLoginDataSlice";
import Loading from "../../../common/Loading/Loading";

import {
  Account,
  Claim,
  Dashboard,
  Engage,
  Orders,
  Statements,
  Store,
  Users,
} from "../../../../assets/images/sidebar";

const SidebarComponent = (props) => {
  const { user, role } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onClickOption = (path) => {
    return location.pathname.includes(path);
  };
  const isOptionAllowed = (option) => {
    return user?.userTabPermission.includes(option);
  };

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const handleLogout = () => {
    setLoading(true);
    const cookies = new Cookies();
    cookies.remove("token", { path: "/" });
    cookies.remove("userEmail", { path: "/" });
    cookies.remove("userName", { path: "/" });
    cookies.remove("role", { path: "/" });
    cookies.remove("merchantName", { path: "/" });
    navigate("/sign-in");

    setTimeout(() => {
      dispatch(removeLoginData());
    }, [2000]);
  };

  const logoStyle = {
    width: "150px",
    height: "80px",
  };
  const [isActive, setIsActive] = useState(false);

  const handleNavigate = (route) => {
    navigate(route);
    setIsActive(false);
  };
  const sidebarRef = useRef(null);
  const iconRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      !sidebarRef.current?.contains(event.target) &&
      !iconRef.current?.contains(event.target)
    ) {
      setIsActive(false);
    }
  };
  document.body.addEventListener("click", handleOutsideClick);

  const accountData = user.data.account_manager;
  const words = accountData && accountData.display_name.split(" ");

  const firstLetters = words && words?.map((word) => word.charAt(0));

  return (
    <>
      {loading && <Loading type="overlay" className="loader-icon" />}
      <div className="dashboard-sidebar-container">
        <div className="dashboard-top">
          <span
            className={isActive ? "active" : ""}
            onClick={() => setIsActive(!isActive)}
          >
            <i
              className={
                isActive ? "desktop-menu-icon active" : "desktop-menu-icon"
              }
              ref={iconRef}
            >
              <Hamburger />
            </i>
          </span>
          <div className="dashboard-logo">
            <Link to="/dashboard">
              <Logo style={logoStyle} />
            </Link>
          </div>
          <p className="store-name">{user?.data.display_name}</p>
        </div>
        <div
          className={isActive ? "dashboard-option active" : "dashboard-option"}
          ref={sidebarRef}
        >
          <div className="dashboard-menu">
            {role === "merchant" && (
              <div
                className={
                  onClickOption("setup-guide")
                    ? "single-selected-option"
                    : "single-option"
                }
                onClick={() => handleNavigate("/dashboard/setup-guide")}
              >
                <div className="option-icon">
                  {onClickOption("setup-guide") ? (
                    <SetupIcon size={24} height={24} width={24} />
                  ) : (
                    <SetupIcon size={24} height={24} width={24} />
                  )}
                </div>
                <div>Setup Guide</div>
              </div>
            )}
            {isOptionAllowed("onboard") && (
              <div
                className={
                  !window.location.pathname.split("/")[2]
                    ? "single-selected-option"
                    : "single-option"
                }
                onClick={() => handleNavigate("/dashboard")}
              >
                <div className="option-icon">
                  {!window.location.pathname.split("/")[2] ? (
                    <Dashboard size={28} height={24} width={24} />
                  ) : (
                    <Dashboard size={28} height={24} width={24} />
                  )}
                </div>
                <div>Dashboard</div>
              </div>
            )}

            {isOptionAllowed("claims") && (
              <div
                className={
                  onClickOption("claims")
                    ? "single-selected-option"
                    : "single-option"
                }
                onClick={() => handleNavigate("/dashboard/claims")}
              >
                <div className="option-icon">
                  {onClickOption("claims") ? (
                    <Claim size={24} height={24} width={24} />
                  ) : (
                    <Claim size={24} height={24} width={24} />
                  )}
                </div>
                <div>Claims</div>
              </div>
            )}
            {isOptionAllowed("statements") && (
              <div
                className={
                  onClickOption("finance")
                    ? "single-selected-option"
                    : "single-option"
                }
                onClick={() => handleNavigate("/dashboard/finance")}
              >
                <div className="option-icon">
                  {onClickOption("finance") ? (
                    <Statements size={24} height={24} width={24} />
                  ) : (
                    <Statements size={24} height={24} width={24} />
                  )}
                </div>
                <div>Finance</div>
              </div>
            )}
            <div
              className={
                onClickOption("orders")
                  ? "single-selected-option"
                  : "single-option"
              }
              onClick={() => handleNavigate("/dashboard/orders")}
            >
              <div className="option-icon">
                {onClickOption("orders") ? (
                  <Orders size={24} height={24} width={24} />
                ) : (
                  <Orders size={24} height={24} width={24} fill={"none"} />
                )}
              </div>
              <div>Orders</div>
            </div>
            {role === "merchant" && (
              <div
                className={
                  onClickOption("engage")
                    ? "single-selected-option"
                    : "single-option"
                }
                onClick={() => handleNavigate("/dashboard/engage")}
              >
                <div className="option-icon">
                  {onClickOption("engage") ? (
                    <Engage size={24} height={24} width={24} />
                  ) : (
                    <Engage size={24} height={24} width={24} fill="none" />
                  )}
                </div>
                <div>Engage</div>
              </div>
            )}

            {isOptionAllowed("users") && role === "admin" && (
              <div
                className={
                  onClickOption("users")
                    ? "single-selected-option"
                    : "single-option"
                }
                onClick={() => handleNavigate("/dashboard/users")}
              >
                <div className="option-icon">
                  <Users
                    size={24}
                    height={24}
                    width={24}
                    fill={onClickOption("users") ? "none" : "none"}
                  />
                </div>
                <div>Users</div>
              </div>
            )}
            {role === "admin" && (
              <div
                className={
                  onClickOption("store")
                    ? "single-selected-option"
                    : "single-option"
                }
                onClick={() => handleNavigate("/dashboard/store")}
              >
                <div className="option-icon">
                  <Store
                    size={24}
                    height={24}
                    width={24}
                    fill={onClickOption("store") ? "#1A1E22" : "#1A1E22"}
                  />
                </div>
                <div>Store</div>
              </div>
            )}
            {role === "merchant" && (
              <div
                className={
                  onClickOption("admin")
                    ? "single-selected-option"
                    : "single-option"
                }
                onClick={() => handleNavigate("/dashboard/admin")}
              >
                <div className="option-icon">
                  <Account
                    size={24}
                    height={19}
                    width={19}
                    fill={onClickOption("admin") ? "#1A1E22" : "#1A1E22"}
                  />
                </div>
                <div>Admin</div>
              </div>
            )}
          </div>
          {user?.data?.account_manager &&
          role === "merchant" &&
          user.data.account_manager ? (
            <div className="account-manager-container">
              <div className="main-contaier">
                <span className="account-title">Your Account Manager</span>
                <div className="child-container">
                  <div className="profile-img">
                    {" "}
                    {user.data.account_manager.image ? (
                      <img
                        src={user.data.account_manager.image}
                        alt="Profile"
                      />
                    ) : (
                      <span>
                        {firstLetters[0]}
                        {firstLetters[1]}
                      </span>
                    )}
                  </div>
                  <div className="profile-name">
                    <span>{user.data.account_manager.display_name}</span>

                    <p>{user.data.account_manager.email}</p>
                  </div>
                </div>
                <Link
                  className="contact-btn"
                  to={`mailto:${user.data.account_manager.email}`}
                >
                  Contact
                </Link>
              </div>
            </div>
          ) : null}

          <div className="logout-option">
            <div className="profile-detail">
              <span>SIGNED IN AS</span>
              <span>{user?.data.display_name}</span>
            </div>
            <div className="logout-button" onClick={handleLogout}>
              Log Out
            </div>
            {role === "merchant" && (
              <div
                className="logout-button"
                onClick={() => handleNavigate("/dashboard/admin")}
              >
                <div>Profile</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarComponent;
