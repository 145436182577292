import React, { useEffect } from "react";
import { Form, Input, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import Loading from "../common/Loading/Loading";
import Logo from "../../../src/assets/images/logo/LogoBlack.png";
import { validEmail, validPassword } from "../../utils/regexs";
import { useTranslation } from "react-i18next";
import { GoInfo } from "react-icons/go";

const RegisterComponent = (props) => {
  const {
    formErrors,
    formData,
    handleOnChange,
    handleRegister,
    tokenError,
    decodedToken,
    isLoadedContent,
    registerUser,
    form,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const counters = document.querySelectorAll(".counter");
    counters.forEach((counter) => {
      const updateCounter = () => {
        const targetValue = parseInt(counter.getAttribute("data-target"));
        const delay = 10;
        const duration = 9;

        const increment = targetValue / ((duration * 1000) / delay);
        let currentValue = 0;

        const counterInterval = setInterval(() => {
          currentValue += increment;
          counter.textContent = Math.floor(currentValue);

          if (currentValue >= targetValue) {
            counter.textContent = targetValue;
            clearInterval(counterInterval);
          }
        }, delay);
      };

      updateCounter();
    });
  }, []);
  const tooltipContent = (
    <div>
      <ul style={{ margin: 0, paddingLeft: '20px' }}>
        <li>Password should contain at least 8 characters</li>
        <li>Upper and Lowercase letters</li>
        <li>At least one number and one special character</li>
      </ul>
    </div>
  );
  return (
    <>
      {tokenError && (
        <Modal
          centered
          open={tokenError}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={() => navigate("/")}
        >
          <div className="invalid-token-model">
            <div className="invalid-token-model-title">
              <span>Invalid Token</span>
            </div>
            <div className="invalid-token-model-desc">
              <p>Your token is invalid Please try again.</p>
            </div>
            <div>
              <button
                className="primary_btn btn"
                onClick={() => navigate("/sign-in")}
              >
                Okay
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className="register-header">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="help-section">
          <div className="need-help">
            <span>Need Help? </span>
            <a
              href="https://swipe.ai/"
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Contact Support
            </a>
          </div>
          <div className="need-help">
            <span>Started or completed onboarding? </span>
            <span
              className="link"
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              Log In
            </span>
          </div>
        </div>
      </div>
      <div className="register-page-container">
        <div className="register-left-side">
          <div className="data-container">
            <p className="title">
              <p>Welcome.</p> You're on the way to growing your business with
              Swipe.
            </p>
            <p className="description">
              Swipe is the easiest way to help your customers track packages,
              protect orders, resolve issues, and discover new products all in
              one place.
            </p>
            <div className="statics-data">
              <div className="number-data">
                <span className="counter" data-target="1500">
                  0
                </span>
                +
              </div>
              <span className="counter-name">Merchants</span>
            </div>
            <div className="statics-data">
              <div className="number-data">
                <span className="counter" data-target="120">
                  0
                </span>{" "}
                MILLION+
              </div>
              <span className="counter-name">Orders protected</span>
            </div>
            <div className="statics-data">
              <div className="number-data">
                <span className="counter" data-target="38">
                  0
                </span>{" "}
                MILLION+
              </div>
              <span className="counter-name">Orders tracked</span>
            </div>
          </div>
        </div>
        <div className="register-right-side">
          <div className="register-form-section">
            <div className="register-title">create your account</div>
            {isLoadedContent && (
              <Form form={form} initialValues={{ email: formData.email }}>
                <div className="register-form-view">
                  <Form.Item
                    initialValue={decodedToken?.email}
                    labelCol={{ span: 8 }}
                  >
                    <Input
                      value={decodedToken?.name}
                      placeholder="Full Name"
                      disabled
                      className="form-input"
                    />
                  </Form.Item>
                  <div className="name-email">
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter firstName",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name="firstName"
                        value={formData.firstName}
                        placeholder="First Name"
                        onChange={handleOnChange}
                        className="form-input-name-email"
                      />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter lastName",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name="lastName"
                        value={formData.lastName}
                        placeholder="Last Name"
                        onChange={handleOnChange}
                        className="form-input-name-email"
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Phone number.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="phoneNumber"
                      type="number"
                      value={formData.phoneNumber}
                      placeholder="Phone Number"
                      onChange={handleOnChange}
                      className="form-input"
                    />
                  </Form.Item>
                  {formErrors.phoneNumber && (
                    <div className="register-error">
                      {formErrors.phoneNumber}
                    </div>
                  )}
                  <Form.Item
                    name="siteUrl"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your site URL.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="siteUrl"
                      type="url"
                      value={formData.siteUrl}
                      placeholder="Site URL"
                      onChange={handleOnChange}
                      className="form-input"
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "regexp",
                        pattern: validEmail,
                        message: t("MSG_EMAIL"),
                      },
                      {
                        required: true,
                        message: t("MSG_EMAIL"),
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="email"
                      defaultValue={formData.email}
                      value={formData.email}
                      placeholder="Email"
                      onChange={handleOnChange}
                      className="form-input"
                    />
                  </Form.Item>
                  {formErrors.email && (
                    <div className="register-error">{formErrors.email}</div>
                  )}
                  <Form.Item
                    name="password"
                    rules={[
                      // {
                      //   type: "regexp",
                      //   pattern: validPassword,
                      //   message: t("STRONG_PASS"),
                      // },
                      {
                        required: true,
                        message: t("STRONG_PASS"),
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input.Password
                      name="password"
                      value={formData.password}
                      placeholder="Password"
                      onChange={handleOnChange}
                      className="form-input"
                    />{" "}
                    <Tooltip
                      placement="topRight"
                      title={tooltipContent}
                    >
                      <GoInfo size={20} color="#1a1e22" />
                    </Tooltip>
                  </Form.Item>
                  {formErrors.password && (
                    <div className="register-error">{formErrors.password}</div>
                  )}
                </div>
              </Form>
            )}
            <div className="register-termCond">
              <div>Already have an account? </div>
              <span
                className="link"
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                LOG IN here
              </span>
            </div>
            <div className="register-termCond">
              <p>
                By continuing, you are agreeing to our{" "}
                <a
                  href="https://swipe.ai/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://swipe.ai/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
          <div className="register-button">
            <button
              className="btn"
              htmlType="submit"
              type="submit"
              onClick={handleRegister}
              disabled={registerUser.isLoading}
            >
              {registerUser.isLoading ? (
                <Loading type="spinner" color="#fff" />
              ) : (
                "Create Account"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterComponent;
