import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import dashboardLoginSlice from "../slice/dashboardLoginSlice";
import getLoginDataSlice from "../slice/getLoginDataSlice";
import getUsers from "../slice/getUsersSlice";
import addUser from "../slice/addUserSlice";
import resetPasswordSlice from "../slice/resetPasswordSlice";
import getClaimListSlice from "../slice/getClaimListSlice";
import getSearchOrderSlice from "../slice/getSearchOrderSlice";
import getMerchantClaimDetailsSlice from "../slice/getMerchantClaimDetailsSlice";
import userRegister from "../slice/userRegisterSlice";
import editUserDetails from "../slice/getUserDetailsSlice";
import editUser from "../slice/editUserSlice";
import deleteuserSlice from "../slice/deleteuserSlice";
import resetPasswordLinkSlice from "../slice/resetPasswordLinkSlice";
import validateResetPasswordSlice from "../slice/validateResetPasswordSlice";
import onResetPasswordSlice from "../slice/onResetPasswordSlice";
import merchantListSlice from "../slice/merchantListSlice";
import getStoreDataSlice from "../slice/getStoreDataSlice";
import addEventsSlice from "../slice/addEventsSlice";
import changeClaimStatusSlice from "../slice/changeClaimStatusSlice";
import getAdminUsersSlice from "../slice/getAdminUsersSlice";
import createAdminUserSlice from "../slice/createAdminUserSlice";
import approvedSlice from "../slice/approvedSlice";
import adminEditUserSlice from "../slice/adminEditUserSlice";
import adminDeleteUserSlice from "../slice/adminDeleteUserSlice";
import editTaskSlice from "../slice/editTaskSlice";
import getMerchantStatementsSlice from "../slice/getMerchantStatementsSlice";
import downloadStatementsSlice from "../slice/downloadStatementsSlice";
import addMerchantUserSlice from "../slice/addMerchantUserSlice";
import editMerchantUserSlice from "../slice/editMerchantUserSlice";
import deleteMerchantUserSlice from "../slice/deleteMerchantUserSlice";
import previewStatementsSlice from "../slice/previewStatementsSlice";
import adminStatementSlice from "../slice/adminStatementSlice";
import adminSearchOrderSlice from "../slice/adminSearchOrderSlice";
import adminNewClaimSlice from "../slice/adminNewClaimSlice";
import merchantNewClaimSlice from "../slice/merchantNewClaimSlice";
import adminStoreDetailsSlice from "../slice/adminStoreDetailsSlice";
import sendBillingSlice from "../slice/sendBillingSlice";
import statementOverviewSlice from "../slice/statementOverviewSlice";
import adminClaimListSlice from "../slice/adminClaimListSlice";
import Statistics from "../slice/statisticsSlice";
import adminActivitySlice from "../slice/adminActivitySlice";
import merchantStatisticSlice from "../slice/merchantStatisticSlice";
import engageContentSlice from "../slice/engageContentSlice";
import updateEngageContentSlice from "../slice/updateEngageContentSlice";
import getEngageNotificationSlice from "../slice/getEngageNotificationSlice";
import updateEngageNotificationSlice from "../slice/updateEngageNotificationSlice";
import updateSettingsSlice from "../slice/updateSettingsSlice";
import getSettingSlice from "../slice/getSettingSlice";
import widgetSettingSlice from "../slice/widgetSettingSlice";
import getMerchantNewClaimSlice from "../slice/getMerchantNewClaimSlice";
import getOrderSlice from "../slice/getOrderSlice";
import updateWidgetSlice from "../slice/updateWidgetSlice";
import addNoteSlice from "../slice/addNoteSlice";
import getNotesSlice from "../slice/getNotesSlice";
import getProtectSlice from "../slice/getProtectSlice";
import saveCardSlice from "../slice/saveCardSlice";
import getSaveCardSlice from "../slice/getSaveCardSlice";
import saveBankDetailSlice from "../slice/saveBankDetailSlice";
import getMerchantOrderSlice from "../slice/getMerchantOrderSlice";
import getCurrentPaymentMethodSlice from "../slice/getCurrentPaymentMethodSlice";
import merchantSendBillingSlice from "../slice/merchantSendBillingSlice";
import checkMerchantBilingSlice from "../slice/checkMerchantBilingSlice";
import getSetupGuideSlice from "../slice/getSetupGuideSlice";
import updateSetupGuideSlice from "../slice/updateSetupGuideSlice";
import getRevenueSlice from "../slice/getRevenueSlice";
import merchantClaimStatisticSlice from "../slice/merchantClaimStatisticSlice";
import claimStatisticSlice from "../slice/claimStatisticSlice";
import merchantRevenueSlice from "../slice/merchantRevenueSlice";
import getMerchantOrderDetailsSlice from "../slice/getMerchantOrderDetailsSlice";
import onBoardingScheduleSlice from "../slice/onBoardingScheduleSlice";
import adminManagerListSlice from "../slice/adminManagerList.js";
import addManagerAssignSlice from "../slice/addManagerAssignSlice.js";
import exportOrderListSlice from '../slice/getExportOrderSlice.js';

const store = configureStore({
  reducer: {
    dashboardLogin: dashboardLoginSlice,
    loginData: getLoginDataSlice,
    users: getUsers,
    addUser: addUser,
    resetPassword: resetPasswordSlice,
    claimList: getClaimListSlice,
    searchedOrder: getSearchOrderSlice,
    merchantClaimDetail: getMerchantClaimDetailsSlice,
    registerUser: userRegister,
    editUserDetails: editUserDetails,
    editUser: editUser,
    deleteUser: deleteuserSlice,
    resetPasswordLink: resetPasswordLinkSlice,
    validateResetPassword: validateResetPasswordSlice,
    onResetPassword: onResetPasswordSlice,
    merchantList: merchantListSlice,
    storeData: getStoreDataSlice,
    claimEvents: addEventsSlice,
    changeClaimStatus: changeClaimStatusSlice,
    adminUsers: getAdminUsersSlice,
    createAdminUsers: createAdminUserSlice,
    onApproved: approvedSlice,
    adminEditUser: adminEditUserSlice,
    adminDeleteUser: adminDeleteUserSlice,
    editTask: editTaskSlice,
    merchantStatements: getMerchantStatementsSlice,
    downloadStatements: downloadStatementsSlice,
    addMerchantUser: addMerchantUserSlice,
    editMerchantUser: editMerchantUserSlice,
    deleteMerchantUser: deleteMerchantUserSlice,
    previewStatments: previewStatementsSlice,
    adminStatments: adminStatementSlice,
    adminSearchedOrder: adminSearchOrderSlice,
    adminNewClaim: adminNewClaimSlice,
    merchantNewClaim: merchantNewClaimSlice,
    adminStoreDetails: adminStoreDetailsSlice,
    sendBilling: sendBillingSlice,
    statementOverview: statementOverviewSlice,
    adminClaimList: adminClaimListSlice,
    statistics: Statistics,
    adminActivity: adminActivitySlice,
    merchantStatistic: merchantStatisticSlice,
    engageContent: engageContentSlice,
    updateEngage: updateEngageContentSlice,
    engageNotification: getEngageNotificationSlice,
    updateSetting: updateSettingsSlice,
    settings: getSettingSlice,
    updateNotification: updateEngageNotificationSlice,
    widgetSetting: widgetSettingSlice,
    merchantRecentClaim: getMerchantNewClaimSlice,
    orderList: getOrderSlice,
    merchantOrderDetail: getMerchantOrderDetailsSlice,
    updateWidget: updateWidgetSlice,
    addNote: addNoteSlice,
    notes: getNotesSlice,
    protect: getProtectSlice,
    saveCard: saveCardSlice,
    getSaveCard: getSaveCardSlice,
    saveBankDetail: saveBankDetailSlice,
    merchantOrder: getMerchantOrderSlice,
    currentPaymentMethod: getCurrentPaymentMethodSlice,
    merchantSendBilling: merchantSendBillingSlice,
    checkMerchantBilling: checkMerchantBilingSlice,
    setupGuide: getSetupGuideSlice,
    updateSetupGuide: updateSetupGuideSlice,
    revenue: getRevenueSlice,
    merchantClaimStatistics: merchantClaimStatisticSlice,
    claimStatistic: claimStatisticSlice,
    merchantRevenue: merchantRevenueSlice,
    onBoardingSchedule: onBoardingScheduleSlice,
    accountManagerList: adminManagerListSlice,
    addManagerAssign: addManagerAssignSlice,
    exportOrderList:exportOrderListSlice,
  },
  middleware: [thunk],
});

export default store;
