import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

const SetupGuideBtn = (props) => {
  const { title, onClick, title2, onClick2 } = props;
  return (
    <div className="btn-content">
      <div className="btn-content-block">
        <button type="button" className="setup-guide-btn" onClick={onClick}>
          <BsArrowRight
            viewBox="0 0 15 12"
            strokeWidth={1}
            width={12}
            height={12}
            style={{
              fill: 'black'
            }}
          />
          <span>{title}</span>
        </button>
        {title2 && (
          <button type="button" className="setup-guide-btn" onClick={onClick2}>
            <BsArrowRight
              viewBox="0 0 15 12"
              strokeWidth={1}
              width={12}
              height={12}
              style={{
                fill: 'black'
              }}
            />
            <span>{title2}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default SetupGuideBtn;
