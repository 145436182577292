import { Button, Form, Input, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { validEmail } from "../../../../../../utils/regexs";
import { useTranslation } from "react-i18next";

const EditCommunication = (props) => {
  const { isModalOpen, setIsModalOpen, data, updateAndGetData, editTitle } =
    props;
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (editTitle === "BILLING CONTACT") {
      setEmail(data?.billing_contact_email || "");
    } else if (editTitle === "CLAIMS CONTACT") {
      setEmail(data?.claims_contact_email || "");
    } else if (editTitle === "REIMBURSEMENTS CONTACT") {
      setEmail(data?.reimbursement_contact_email || "");
    }
  }, [data, editTitle]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSave = () => {
    if (
      email !== null &&
      email !== "" &&
      validEmail.test(email)
    ) {
      const payload = {
        billing_contact_email:
          editTitle === "BILLING CONTACT" ? email : data?.billing_contact_email,
        claims_contact_email:
          editTitle === "CLAIMS CONTACT" ? email : data?.claims_contact_email,
        reimbursement_contact_email:
          editTitle === "REIMBURSEMENTS CONTACT"
            ? email
            : data?.reimbursement_contact_email,
      };
      updateAndGetData(payload);
      setIsModalOpen(false);
    }
  };

  return (
    <Modal
      title={editTitle}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      centered
    >
      <div className="option">
        <Form>
          <Form.Item
            name="Email"
            rules={[
              {
                pattern: validEmail,
                required: true,
                message: t("MSG_EMAIL"),
              },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              size="large"
              prefix={<BiUser />}
              name="email"
              value={email}
              placeholder="Email"
              onChange={handleEmailChange}
              suffix={
                <Tooltip title={t("TOOLTIP_MAIL")}>
                  <AiOutlineInfoCircle />
                </Tooltip>
              }
            />
          </Form.Item>
          <div style={{ textAlign: "right" }}>
            <button onClick={handleSave} className="edit-submit">
              {t("Submit")}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditCommunication;
