import React from "react";
import "./MultiStepProgressBar.scss";
import { ProgressBar, Step } from "react-step-progress-bar";

const MultiStepProgressBar = ({ page, onPageNumberClick, data }) => {
  var stepPercentage = 0;
  if (page === 1) {
    stepPercentage = 13;
  } else if (page === 2) {
    stepPercentage = 38;
  } else if (page === 3) {
    stepPercentage = 63;
  } else if (page === 4) {
    stepPercentage = 88;
  } else if (page === 5) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    <ProgressBar percent={stepPercentage}>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null} ${
              data.install_task.done ? "done" : null
            }`}
            onClick={() => onPageNumberClick(1)}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null} ${
              data.onboard_task.done ? "done" : null
            }`}
            onClick={() => onPageNumberClick(2)}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null} ${
              data?.partner_task.done ? "done" : null
            }`}
            onClick={() => onPageNumberClick(3)}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null} ${
              data.billing_task.done ? "done" : null
            }`}
            onClick={() => onPageNumberClick(4)}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null} ${
              data.widget_task.done ? "done" : null
            }`}
            onClick={() => onPageNumberClick(5)}
          >
            {index + 1}
          </div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
