import React, { useEffect } from "react";
import EngagePage from "./engagePage";
import { connect } from "react-redux";
import { engageContent } from "../../../../redux/slice/engageContentSlice";
import { updateEngageContent } from "../../../../redux/slice/updateEngageContentSlice";
import { getEngageNotification } from "../../../../redux/slice/getEngageNotificationSlice";
import { updateEngageNotification } from "../../../../redux/slice/updateEngageNotificationSlice";
import { Cookies } from "react-cookie";
import Loading from "../../../common/Loading/Loading";
import Notify from "../../../common/Notify/notify";

const Engage = (props) => {
  const {
    getEngageData,
    engageContentData,
    updateEngageData,
    fetchEngageNotification,
    postEngageNotification,
    engageNotification,
    updatedEngage,
    updateNotification,
    adminStoreDetails,
  } = props;

  const cookies = new Cookies();
  const role = cookies.get("role");
  const merchantId = adminStoreDetails?.adminStoreDetails?.data?.merchant_id;
  useEffect(() => {
    if (role === "admin") {
      getEngageData(merchantId);
      fetchEngageNotification(merchantId);
    }
    if (role === "merchant") {
      getEngageData();
      fetchEngageNotification();
    }
  }, [getEngageData, fetchEngageNotification, merchantId, role]);

  const handleSubmit = async (dataSubmit) => {
    const { __v, _id, ...finalData } = dataSubmit;
    const updatePayload = {
      finalData: finalData,
      merchantId: merchantId,
    };
    const result = await updateEngageData(updatePayload);
    if (result.type === "updateEngageContent/fulfilled") {
      if (role === "admin") {
        await getEngageData(merchantId);
      }
      if (role === "merchant") {
        await getEngageData();
      }
      Notify("success", result.payload.message, "");
    }
  };

  const handleNotificationSubmit = async (data) => {
    const updatePayload = {
      data: data,
      merchantId: merchantId,
    };
    const result = await postEngageNotification(updatePayload);
    if (result.type === "updateEngageNotification/fulfilled") {
      Notify("success", result.payload.message, "");
    }
  };

  return (
    <>
      {(engageContentData.isLoading ||
        updateNotification.isLoading ||
        updatedEngage.isLoading) && (
        <Loading type="overlay" className="loader-icon" />
      )}
      {engageContentData?.engageContentList && (
        <EngagePage
          engageContentList={engageContentData?.engageContentList?.data}
          handleSubmit={handleSubmit}
          engageNoticationData={engageNotification?.notificationData?.data}
          handleNotificationSubmit={handleNotificationSubmit}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    engageContentData: state.engageContent,
    updatedEngage: state.updateEngage,
    engageNotification: state.engageNotification,
    updateNotification: state.updateNotification,
    adminStoreDetails: state.adminStoreDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEngageData: (props) => dispatch(engageContent(props)),
    updateEngageData: (props) => dispatch(updateEngageContent(props)),
    fetchEngageNotification: (props) => dispatch(getEngageNotification(props)),
    postEngageNotification: (props) =>
      dispatch(updateEngageNotification(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Engage);
