import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

export const toArrayBuffer = (buffer) => {
  var ab = new ArrayBuffer(buffer.length);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return ab;
};

export const splitAndMap = (inputString, inputLocation) => {
  let count = -1;
  return inputString.split("|>").map((item, index) => (
    <React.Fragment key={index}>
      {item.split("/!").map((part, index) => {
        if (index) {
          const content = part.split("!/")[0];
          const remainingContent = part.split("!/").slice(1).join("!/");
          count++;
          return (
            <React.Fragment key={index}>
              <Link to={inputLocation && inputLocation[count]}>
                {content && content}
              </Link>
              {remainingContent}
            </React.Fragment>
          );
        } else {
          return <React.Fragment key={index}>{part}</React.Fragment>;
        }
      })}
      <p></p>
    </React.Fragment>
  ));
};

export const getMonthName = (month, year) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (year) {
    return months[month - 1] + ` ${year}`;
  } else {
    return months[month - 1];
  }
};

export const openExternalLink = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const getLastMonthRange = () => {
  const startOfCurrentMonth = dayjs().startOf("month");
  const startOfLastMonth = startOfCurrentMonth.subtract(1, "month");
  const endOfLastMonth = startOfCurrentMonth.subtract(1, "day");

  return [startOfLastMonth, endOfLastMonth];
};

const getLast12MonthsRange = () => {
  const startOfCurrentMonth = dayjs().startOf("month");
  const startOfLast12Months = startOfCurrentMonth
    .subtract(12, "month")
    .startOf("month");
  const endOfLast12Months = dayjs().endOf("month");

  return [startOfLast12Months, endOfLast12Months];
};
const getLastYearRange = () => {
  const startOfLastYear = dayjs().subtract(1, "year").startOf("year");
  const endOfLastYear = dayjs().subtract(1, "year").endOf("year");

  return [startOfLastYear, endOfLastYear];
};
export const rangePresets = [
  {
    label: "Today",
    value: [dayjs().startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Yesterday",
    value: [
      dayjs().subtract(1, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
  {
    label: "Last 365 Days",
    value: [dayjs().add(-365, "d"), dayjs()],
  },
  {
    label: "Last Month",
    value: getLastMonthRange(),
  },
  {
    label: "Last 12 Months",
    value: getLast12MonthsRange(),
  },
  {
    label: "Last Year",
    value: getLastYearRange(),
  },
];

export const dateConvert= (date)=>{
  return moment(date).format("MMM DD, YYYY, hh:mm A")
}
