import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const getSetupGuide = createAsyncThunk("setupGuide", async () => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const response = await AxiosInstance.get(
      `${API_URL}/merchant/task/setup-list`,
      {
        headers: { "x-access-token": token },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const getSetupGuideSlice = createSlice({
  name: "setupGuide",
  initialState: {
    isLoading: false,
    setupGuide: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getSetupGuide.pending]: (state) => {
      state.isLoading = true;
    },
    [getSetupGuide.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.setupGuide = action.payload;
      state.errorMessage = "";
      state.isError = false;
    },
    [getSetupGuide.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.setupGuide = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default getSetupGuideSlice.reducer;
