import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import ConfigbarPage from "./configbarPage";
import Loading from "../../../common/Loading/Loading";
import { widgetSetting } from "../../../../redux/slice/widgetSettingSlice";
import { updateWidget } from "../../../../redux/slice/updateWidgetSlice";
import Notify from "../../../common/Notify/notify";
import { Cookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { adminStoreDetails } from "../../../../redux/slice/adminStoreDetailsSlice";

const ConfigBar = ({
  getwidget,
  widgetSetting,
  updateWidget,
  editWidget,
  loginData,
  adminStoreDetails,
  callAdminStoreDetails,
}) => {
  const cookies = new Cookies();
  const role = cookies.get("role");
  const merchantId = loginData?.loginData?.data?.merchantInfo?.id;
  const singleMerchantId = adminStoreDetails?.adminStoreDetails?.data?.id;
  const adminMerchantId =
    adminStoreDetails?.adminStoreDetails?.data?.merchant_id;
  const { id } = useParams();

  const configAPIcall = useCallback(async () => {
    await callAdminStoreDetails(id);
    singleMerchantId && (await getwidget(singleMerchantId));
  }, [id, singleMerchantId, callAdminStoreDetails, getwidget]);

  useEffect(() => {
    if (role === "admin") {
      configAPIcall();
    }
    if (role === "merchant") {
      getwidget(merchantId);
    }
  }, [id, singleMerchantId, configAPIcall, getwidget, role, merchantId]);

  //To update widget pass key = cart_page and for Configbar key = configure_bar
  const handleWidgetSave = async (widgetData) => {
    const updatePayload = {
      widgetData: widgetData,
      merchantId: adminMerchantId,
    };
    const result = await editWidget({ key: "cart_page", updatePayload });
    if (result.type === "updateWidget/fulfilled") {
      Notify("success", result.payload.message, "");
    }
  };

  return (
    <>
      {(widgetSetting.isLoading || updateWidget.isLoading) && (
        <Loading type="overlay" className="loader-icon" />
      )}
      {widgetSetting.widget && (
        <ConfigbarPage
          data={widgetSetting.widget}
          handleWidgetSave={handleWidgetSave}
          editWidget={editWidget}
          adminMerchantId={adminMerchantId}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    widgetSetting: state.widgetSetting,
    updateWidget: state.updateWidget,
    loginData: state.loginData,
    adminStoreDetails: state.adminStoreDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getwidget: (props) => dispatch(widgetSetting(props)),
    editWidget: (props) => dispatch(updateWidget(props)),
    callAdminStoreDetails: (props) => dispatch(adminStoreDetails(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigBar);
