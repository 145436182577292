import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { connect } from "react-redux";
const NotFound = (props) => {
  const { loginData } = props;
  const [isLoading, setIsLoading] = useState(true);

  const isModelOpen =
    loginData?.errorMessage !== "Failed to authenticate token.";
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/dashboard");
  };
  return (
    <>
      {!isLoading && isModelOpen && (
        <Modal
          centered
          open={true}
          onCancel={handleNavigation}
          footer={
            <div style={{ textAlign: "center" }}>
              <button
                className="primary_btn btn"
                onClick={() => handleNavigation()}
              >
                Back to Home
              </button>
            </div>
          }
        >
          <div className="message-modal">
            <img
              src="https://img.freepik.com/premium-vector/page-found-404-error-concept-illustration_270158-294.jpg?w=740"
              alt="warning"
            />
            <h1>Page Not Found!</h1>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
