import React from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import TaskPage from "./taskPage";
import Loading from "../../../common/Loading/Loading";
import AdminOnboard from "../adminPages/onboard";

const Task = (props) => {
  const cookies = new Cookies();
  const role = cookies.get("role");
  const { loginData } = props;
  return role === "merchant" ? (
    <>
      {loginData.isLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : (
        loginData.loginData && (
          <TaskPage
            link={loginData.loginData?.data?.merchantInfo?.domain}
            data={loginData.loginData?.data?.dashboardTask}
            username={loginData.loginData.data?.display_name}
          />
        )
      )}
    </>
  ) : (
    <div>
      <AdminOnboard />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
