import { DatePicker, Input, Modal, Select, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import approveIcon from "../../../../../assets/images/approve-icon.svg";
import closeIcon from "../../../../../assets/images/closeIcon.svg";
import processedIcon from "../../../../../assets/images/processed-icon.svg";
import resolvedIcon from "../../../../../assets/images/resolved-icon.svg";
import { ClaimIn } from "../../../../../assets/images/sidebar";
import { dateConvert, rangePresets } from "../../../../../utils/helper";
import DatePreset from "../../../../common/DatePreset/datePreset";
import Loading from "../../../../common/Loading/Loading";
import Notify from "../../../../common/Notify/notify";
import DashboardHeaderComponent from "../../../common/dashboardHeader/dashboradHeader";
import { RiRefund2Line } from "react-icons/ri";
import { TbReorder } from "react-icons/tb";
import { TbProgress } from "react-icons/tb";

const { RangePicker } = DatePicker;
const AdminClaimsPage = (props) => {
  const {
    adminClaimList,
    merchantList,
    callGetAdminClaims,
    searchData,
    callSearchOrder,
    callcreateNewClaim,
    adminNewClaimLoading,
    selectedMerchant,
    setSelectedMerchant,
    selectedDates,
    setSelectedDates,
    currentPage,
    setCurrentPage,
    isActive,
    setIsActive,
    searchValue,
    setSearchValue,
    status,
    setStatus,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [createClaimModal, setCreateClaimModal] = useState(false);
  const [searchShow, setSearchShow] = useState(true);
  const [newClaim, setNewClaim] = useState({
    oid: 0,
    mid: null,
    type: "",
    description: "",
    preference: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isDate, setIsDate] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const { Search } = Input;

  const columns = [
    {
      title: "Order #",
      dataIndex: "orderId",
      key: "orderId",
      render: (text) => {
        return {
          children: (
            <>
              <div className="table-header_orderID">
                <div className="table-header_orderID-svg"></div>
                {text}
              </div>
            </>
          ),
          props: {
            "data-label": "Order #",
          },
        };
      },
    },
    {
      title: "Merchant Name",
      dataIndex: "merchantName",
      key: "merchantName",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Merchant Name",
          },
        };
      },
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Customer Email",
          },
        };
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Created Date",
          },
        };
      },
    },
    {
      title: "Resolution Preference",
      dataIndex: "refundStatus",
      key: "refundStatus",
      render: (_, { refundStatus }) => {
        return {
          children: (
            <>
              <div
                style={{
                  color:
                    refundStatus === "processing"
                      ? "#89BA00"
                      : refundStatus === "reorder"
                      ? "#00B9B9"
                      : refundStatus === "refund"
                      ? "#02b574"
                      : refundStatus === "closed"
                      ? "#ee5d50"
                      : "#1a1e22",
                  fontWeight: 500,
                  fontSize: 16,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  textTransform: "uppercase",
                }}
              >
                {refundStatus === "processing" ? (
                  <TbProgress />
                ) : refundStatus === "reorder" ? (
                  <TbReorder />
                ) : refundStatus === "refund" ? (
                  <RiRefund2Line />
                ) : refundStatus === "closed" ? (
                  <img src={closeIcon} alt="" height={16} width={16} />
                ) : null}
                {refundStatus}
              </div>
            </>
          ),
          props: {
            "data-label": "Refund Status",
          },
        };
      },
    },
    {
      title: "Current Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "APPROVED",
          value: "APPROVED",
        },
        {
          text: "REVIEWING",
          value: "REVIEWING",
        },
        {
          text: "CLOSED",
          value: "CLOSED",
        },
        {
          text: "RESOLVED",
          value: "RESOLVED",
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      // onFilter: (value, record) =>
      //   record.status && record.status.startsWith(value),
      // filterSearch: true,
      // render: (text) => {
      //   return {
      //     children: moment(text).format('DD-MM-YY hh:mm A'),
      //     props: {
      //       "data-label": "Created Date",
      //     },
      //   };
      // },
      render: (_, { status }) => ({
        children: (
          <div
            style={{
              color:
                status === "APPROVED"
                  ? "#89BA00"
                  : status === "REVIEWING"
                  ? "#00B9B9"
                  : status === "CLOSED"
                  ? "#ee5d50"
                  : status === "RESOLVED"
                  ? "#02b574"
                  : "#1a1e22",
              fontWeight: 500,
              fontSize: 16,
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {status === "APPROVED" ? (
              <img src={approveIcon} alt="" height={16} width={16} />
            ) : status === "REVIEWING" ? (
              <img src={processedIcon} alt="" height={16} width={16} />
            ) : status === "CLOSED" ? (
              <img src={closeIcon} alt="" height={16} width={16} />
            ) : status === "RESOLVED" ? (
              <img src={resolvedIcon} alt="" height={16} width={16} />
            ) : null}
            {status}
          </div>
        ),
        props: {
          "data-label": "Current Status",
        },
      }),
    },
  ];

  const data =
    Array.isArray(adminClaimList?.data?.response) &&
    adminClaimList?.data?.response?.map((user, index) => ({
      key: (index + 1).toString(),
      merchantName: user.merchant_name,
      orderId: user.order_id,
      customerEmail: user.customer_email,
      createdAt: user.createdAt,
      refundStatus: user.refund_status,
      status: user.status,
      claimId: user._id,
    }));

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  let merchantOption = [];
  if (Array.isArray(merchantList?.data?.response)) {
    merchantOption = merchantList.data?.response.map((item) => ({
      value: item._id,
      label: item.name,
    }));
  }
  const merchantOptionsWithAll = [...merchantOption];
  const merchantOptions = [...merchantOption];

  const handleCancel = () => {
    setIsModalOpen(false);
    setSearchShow(true);
    setNewClaim({
      oid: 0,
      mid: null,
      type: "",
      description: "",
      preference: "",
    });
    setSearch("");
  };
  const handleOpenCreateClaim = (id) => {
    setIsModalOpen(false);
    setCreateClaimModal(true);
    const ClaimOrder = searchData?.data?.find((item) => item._id === id);
    setSelectedOrder(ClaimOrder);
    setNewClaim((prev) => ({
      ...prev,
      oid: ClaimOrder._id,
    }));
  };
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (search !== "") {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }, [search]);

  const handleSearchOrder = (event) => {
    setSearch(event.target.value);
    callSearchOrder({ merchantId: newClaim.mid, search: event.target.value });
  };
  const onChangeClaim = async (e) => {
    setSearchShow(false);
    setNewClaim((prev) => ({
      ...prev,
      mid: e,
    }));
    if (search) {
      callSearchOrder({ merchantId: e, search: search });
    }
  };
  const handleBackModal = () => {
    setFormErrors("");
    setCreateClaimModal(false);
    setIsModalOpen(true);
  };
  const handleCreateClaimCancel = () => {
    setNewClaim({
      oid: 0,
      mid: null,
      type: "",
      description: "",
      preference: "",
    });
    setFormErrors("");
    setCreateClaimModal(false);
    setIsModalOpen(false);
    setSearch("");
  };
  const { Option } = Select;
  const handleNavigateDetails = async (id) => {
    navigate(`/dashboard/claims/${id}`);
  };
  const handleCreateNewClaim = (event) => {
    const { name, value } = event.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setNewClaim((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmitNewClaim = async () => {
    const errors = {};
    if (!newClaim.type) {
      errors.type = "Claim Type is required";
    }
    if (!newClaim.description) {
      errors.description = "Detailed Explanation is required";
    }
    if (!newClaim.preference) {
      errors.preference = "User Preference is required";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const response = await callcreateNewClaim(newClaim);
      if (response.type === "adminNewClaim/fulfilled") {
        Notify("success", "Claim", "Your Claim Created");
        setNewClaim({
          oid: 0,
          mid: null,
          type: "",
          description: "",
          preference: "",
        });
        callGetAdminClaims();
        setCreateClaimModal(false);
        setSearch("");
        navigate("/dashboard/claims");
      }
    }
  };

  const handleMerchantSelect = (value) => {
    if (value) {
      params.set("selected_option", value);
    } else {
      params.delete("selected_option");
    }
    if (value === "all") {
      params.delete("selected_option");
      setSelectedMerchant(null);
    } else {
      navigate(`?${params.toString()}`);
      setSelectedMerchant(value);
    }
  };

  const handleDateSelect = async (dates) => {
    const formattedStartDate = dates && dates[0]?.format("M-DD-YYYY");
    const formattedEndDate = dates && dates[1]?.format("M-DD-YYYY");

    if (formattedStartDate) {
      params.set("start_date", formattedStartDate);
    } else {
      params.delete("start_date");
      params.delete("days");
    }

    if (formattedEndDate) {
      params.set("end_date", formattedEndDate);
    } else {
      params.delete("end_date");
      params.delete("days");
    }

    if (dates === null) {
      setIsActive("");
      setSelectedRange(null);
      setIsDate(false);
      // await callGetAdminClaims({});
    } else {
      const preset = rangePresets.find(
        (p) =>
          p.value[0].isSame(dates[0], "day") &&
          p.value[1].isSame(dates[1], "day")
      );
      if (preset) {
        params.set("days", preset.label);
        setIsActive(preset.label);
      } else {
        setIsActive("");
        params.delete("days");
      }
    }

    navigate(`?${params.toString()}`);
    setSelectedDates(dates);
  };

  const handlePresetClick = (preset) => {
    setIsActive(preset.label);
    setSelectedRange(preset.value);
    handleDateSelect(preset.value);
    setIsDate(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleFilterChange = (value) => {
    if (value) {
      setStatus(value);
    } else {
      setStatus(null);
    }
  };

  return (
    <>
      {adminNewClaimLoading && (
        <Loading type="overlay" className="loader-icon" />
      )}
      <div className="admin-claim-list-main">
        <div className="admin-claim-list-container">
          <DashboardHeaderComponent title="Claims" Logo={ClaimIn} />
          <div className="Admin-claim-page">
            <div className="admin-claim-title">
              <div className="merchant-list">
                <Select
                  value={selectedMerchant}
                  showSearch
                  size="large"
                  allowClear
                  placeholder="Select merchant"
                  onChange={handleMerchantSelect}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={merchantOptionsWithAll}
                  style={{ width: 240 }}
                />
                {/* <RangePicker
                  value={selectedDates}
                  onChange={handleDateSelect}
                  format="M-DD-YYYY"
                  presets={rangePresets}
                /> */}
                <RangePicker
                  onClick={() => setIsDate(true)}
                  open={isDate}
                  onOpenChange={(open) => {
                    if (!open) setIsDate(open);
                  }}
                  renderExtraFooter={() => (
                    <DatePreset
                      isActive={isActive}
                      setIsActive={setIsActive}
                      selectedRange={selectedRange}
                      setSelectedRange={setSelectedRange}
                      handlePresetClick={handlePresetClick}
                    />
                  )}
                  onChange={handleDateSelect}
                  format="M-DD-YYYY"
                  value={selectedDates}
                />
                <Search
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  enterButton
                  value={searchValue}
                />
              </div>
              <div className="claim-head-button">
                <button
                  className="primary_btn btn"
                  onClick={() => {
                    setIsModalOpen(!isModalOpen);
                  }}
                >
                  <span>+</span>
                  <p
                    style={{
                      fontSize: 15,
                      padding: 9,
                    }}
                  >
                    Add Claim
                  </p>
                </button>
              </div>
            </div>

            <div className="admin-claim-table">
              <Table
                dataSource={data}
                columns={columns}
                pagination={{
                  current: currentPage,
                  pageSize: 25,
                  total: adminClaimList?.data.totalRecords,
                  onChange: handlePageChange,
                }}
                onChange={(pagination, filters) => {
                  handleFilterChange(filters.status);
                }}
                locale={{ emptyText: "No claim found" }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleNavigateDetails(record.claimId);
                    },
                  };
                }}
              />
            </div>
            <Modal
              centered
              open={isModalOpen}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              onCancel={handleCancel}
              className="ant-modal-si"
            >
              <div className="onBoard-Model">
                <div className="onBoard-title">
                  <span>Create Claim</span>
                </div>
                <div className="create-admin-claim-body">
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select merchant"
                    onChange={onChangeClaim}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={newClaim.mid}
                    options={merchantOptions}
                    style={{ width: "100%" }}
                  />
                  <div>
                    <Input
                      size="large"
                      placeholder="Search order"
                      onChange={handleSearchOrder}
                      value={search}
                      disabled={searchShow}
                    />
                    <span>Search by email, name, or order number</span>
                  </div>
                </div>
                <div className="create-claim-searched">
                  {showSearch ? (
                    searchData?.message === "No Records found" ? (
                      <p> No Records found</p>
                    ) : (
                      searchData?.data?.map((item, index) => {
                        var color;
                        switch (item.swipe) {
                          case true:
                            color = "green";
                            break;
                          case false:
                            color = "red";
                            break;

                          default:
                            color = "grey";
                            break;
                        }
                        return (
                          <div
                            className="create-claim-searched-inner"
                            key={index}
                          >
                            {color === "green" ? (
                              item.claims.length > 0 ? (
                                <Link to={`/dashboard/claims/${item.claims}`}>
                                  <div
                                    className="create-claim-searched-order"
                                    style={{
                                      borderColor: color,
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div className="create-claim-search-id">
                                      <p>{item.name}</p>
                                    </div>
                                    <div className="create-claim-search-fav">
                                      <p>{item.order_number}</p>
                                    </div>
                                    <div className="create-claim-search-email">
                                      <p>{item.customer_email}</p>
                                    </div>
                                    <div className="create-claim-search-link">
                                      {item.claims.length > 0 ||
                                      color === "red" ? (
                                        <p>Click here to view claim!</p>
                                      ) : (
                                        <>{item.total_price}</>
                                      )}
                                    </div>
                                    {color === "red" && (
                                      <div
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          margin: "0",
                                          marginTop: "5px",
                                        }}
                                      >
                                        <p>{t("CLAIM_THIS_ORDER")}</p>
                                      </div>
                                    )}
                                  </div>
                                </Link>
                              ) : (
                                <div
                                  className="create-claim-searched-order"
                                  style={{
                                    borderColor: color,
                                    marginTop: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleOpenCreateClaim(item._id)
                                  }
                                >
                                  <div className="create-claim-search-id">
                                    <p>{item.name}</p>
                                  </div>
                                  <div className="create-claim-search-fav">
                                    <p>{item.order_number}</p>
                                  </div>
                                  <div className="create-claim-search-email">
                                    <p>{item.customer_email}</p>
                                  </div>
                                  <div className="create-claim-search-link">
                                    {item.claims.length > 0 ||
                                    color === "red" ? (
                                      <p>Click here to view claim!</p>
                                    ) : (
                                      <>{item.total_price}</>
                                    )}
                                  </div>
                                  {color === "red" && (
                                    <div
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        margin: "0",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <p>{t("CLAIM_THIS_ORDER")}</p>
                                    </div>
                                  )}
                                </div>
                              )
                            ) : (
                              <div
                                className="create-claim-searched-order"
                                style={{
                                  borderColor: color,
                                  marginTop: "20px",
                                  cursor: "default",
                                }}
                              >
                                <div className="create-claim-search-id">
                                  <p>{item.name}</p>
                                </div>
                                <div className="create-claim-search-fav">
                                  <p>{item.order_number}</p>
                                </div>
                                <div className="create-claim-search-email">
                                  <p>{item.customer_email}</p>
                                </div>
                                <div className="create-claim-search-link">
                                  {item.total_price}
                                </div>
                                {color === "red" && (
                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      margin: "0",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <p>{t("CLAIM_THIS_ORDER")}</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })
                    )
                  ) : null}
                </div>

                <div className="onBoard-button">
                  <button className="primary_btn btn" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              open={createClaimModal}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              onCancel={handleCreateClaimCancel}
            >
              <div className="onBoard-Model">
                <div className="onBoard-title">
                  <span>Create Claim</span>
                </div>
                <div className="claim-info">
                  <div className="claim-info-title">
                    <p>Claim Information</p>
                  </div>
                  <div
                    className="create-claim-searched-order"
                    style={{
                      borderColor: "green",
                    }}
                  >
                    <div className="create-claim-search-id">
                      <p>{selectedOrder.name}</p>
                    </div>
                    <div className="create-claim-search-fav">
                      <p>{selectedOrder.order_number}</p>
                    </div>
                    <div className="create-claim-search-email">
                      <p>{selectedOrder.customer_email}</p>
                    </div>
                    <div className="create-claim-search-link">
                      {selectedOrder.total_price}
                    </div>
                  </div>
                  <div className="claim-info-inputs">
                    <label htmlFor="select">Claim Type</label>
                    <Select
                      size="large"
                      className="ant-select"
                      name="type"
                      placeholder="Select Claim type"
                      value={newClaim.type || undefined}
                      onChange={(value) => {
                        setNewClaim((prev) => ({
                          ...prev,
                          type: value,
                        }));
                        setFormErrors((prev) => ({
                          ...prev,
                          type: "",
                        }));
                      }}
                    >
                      <Option value="Stolen">Stolen</Option>
                      <Option value="Lost/Missing">Lost/Missing</Option>
                      <Option value="Damaged">Damaged</Option>
                      <Option value="Returned to Sender">
                        Returned to Sender
                      </Option>
                      <Option value="Other">Other</Option>
                    </Select>
                    {formErrors.type && (
                      <div className="field-error error">{formErrors.type}</div>
                    )}
                    <label htmlFor="select">User Preference</label>
                    <Select
                      size="large"
                      className="ant-select"
                      placeholder="Select Preference"
                      name="type"
                      value={newClaim.preference || undefined}
                      onChange={(value) => {
                        setNewClaim((prev) => ({
                          ...prev,
                          preference: value,
                        }));
                        setFormErrors((prev) => ({
                          ...prev,
                          preference: "",
                        }));
                      }}
                    >
                      <Option value="Refund">Refund</Option>
                      <Option value="Replace">Replace</Option>
                    </Select>
                    {formErrors.preference && (
                      <div className="field-error error">
                        {formErrors.preference}
                      </div>
                    )}
                    <label htmlFor="textarea">Detailed Explanation</label>
                    <textarea
                      value={newClaim.description}
                      className="ant-textarea"
                      name="description"
                      onChange={handleCreateNewClaim}
                      placeholder="Explain any additional info..."
                    />
                    {formErrors.description && (
                      <div className="field-error error">
                        {formErrors.description}
                      </div>
                    )}
                  </div>
                  <div className="submit-back-buttons">
                    <button
                      className="primary_btn btn"
                      onClick={handleBackModal}
                    >
                      Back
                    </button>
                    <button
                      className="primary_btn btn"
                      onClick={handleSubmitNewClaim}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminClaimsPage;
