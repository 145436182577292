import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import ClaimsPage from "./claimsPage";
import { claimList } from "../../../../redux/slice/getClaimListSlice";
import { searchOrder } from "../../../../redux/slice/getSearchOrderSlice";
import Notify from "../../../common/Notify/notify";
import AdminClaim from "../adminPages/claims";
import { merchantNewClaim } from "../../../../redux/slice/merchantNewClaimSlice";
import dayjs from "dayjs";

const Claims = (props) => {
  const {
    claimListData,
    getClaimList,
    callSearchOrder,
    searchedOrder,
    callcreateNewClaim,
    merchantNewClaim,
  } = props;
  const cookies = new Cookies();
  const role = cookies.get("role");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedDates, setSelectedDates] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [createClaimModal, setCreateClaimModal] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [newClaim, setNewClaim] = useState({
    type: "",
    description: "",
    mid: "",
    oid: "",
    preference:''
  });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!params.size) {
      role === "merchant" && getClaimList({});
    }
  }, [role, getClaimList]);

  const handleSearchOrder = (event) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    if (search !== "") {
      callSearchOrder(search);
    }
  }, [callSearchOrder, search]);

  const handleNavigateDetails = async (id) => {
    navigate(`/dashboard/claims/${id}`);
  };
  const handleCreateNewClaim = (event) => {
    const { name, value } = event.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setNewClaim((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [formErrors, setFormErrors] = useState({});
  const handleSubmitNewClaim = async () => {
    const errors = {};
    if (!newClaim.type) {
      errors.type = "Claim Type is required";
    }
    if (!newClaim.description) {
      errors.description = "Detailed Explanation is required";
    }
    if (!newClaim.preference) {
      errors.preference = "User Preference is required";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const response = await callcreateNewClaim(newClaim);
      if (response.type === "merchantNewClaim/fulfilled") {
        Notify("success", "Claim", "Your Claim Created");
        setNewClaim({
          type: "",
          description: "",
          mid: "",
          oid: "",
          preference:""
        });
        getClaimList({});
        setCreateClaimModal(false);
        setSearch("");
        navigate("/dashboard/claims");
      }
    }
  };

   useEffect(() => {
    const params = new URLSearchParams(location.search);
    const start_date = params.get("start_date");
    const end_date = params.get("end_date");
    const days = params.get('days');

    if (days) {
      setIsActive(days);
    }
    if (start_date || end_date || searchValue || currentPage) {
      setSelectedDates([
        start_date ? dayjs(start_date, "M-DD-YYYY") : null,
        end_date ? dayjs(end_date, "M-DD-YYYY") : null,
      ]);
      const payload = {
        date: {
          startDate: start_date || null,
          endDate: end_date || null,
        },
        search: searchValue,
      };
      if (role === "merchant") {
        if(searchValue){
          const timeOut = setTimeout(() => {
            getClaimList({ payload, currentPage });
          }, 1000);
          return () => clearTimeout(timeOut);
        }else{
          getClaimList({ payload, currentPage })
        }
      }
    }
  }, [location.search, searchValue,currentPage]);
  
  return role === "merchant" ? (
    <>
      <ClaimsPage
        isLoading={claimListData.isLoading}
        claimListData={claimListData.claimList}
        handleSearchOrder={handleSearchOrder}
        search={search}
        searchData={searchedOrder.searchOrder}
        handleCreateNewClaim={handleCreateNewClaim}
        handleNavigateDetails={handleNavigateDetails}
        setSearch={setSearch}
        setNewClaim={setNewClaim}
        handleSubmitNewClaim={handleSubmitNewClaim}
        newClaim={newClaim}
        setFormErrors={setFormErrors}
        formErrors={formErrors}
        getClaimList={getClaimList}
        createClaimModal={createClaimModal}
        setCreateClaimModal={setCreateClaimModal}
        merchantNewClaimLoading={merchantNewClaim.isLoading}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
        isActive={isActive}
        setIsActive={setIsActive}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </>
  ) : (
    <div>
      <AdminClaim />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    claimListData: state.claimList,
    searchedOrder: state.searchedOrder,
    merchantNewClaim: state.merchantNewClaim,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClaimList: (...props) => dispatch(claimList(...props)),
    callSearchOrder: (search) => dispatch(searchOrder(search)),
    callcreateNewClaim: (data) => dispatch(merchantNewClaim(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Claims);
