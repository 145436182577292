import React, { useEffect } from "react";
import SettingsTabPage from "./settingsTab";
import { connect } from "react-redux";
import { updateSetting } from "../../../../../redux/slice/updateSettingsSlice";
import Loading from "../../../../common/Loading/Loading";
import { getSetting } from "../../../../../redux/slice/getSettingSlice";
import { Cookies } from "react-cookie";

const SettingsTab = (props) => {
  const {
    callUpdateSetting,
    loginData,
    callGetSetting,
    settings,
    updateSetting,
    adminStoreDetails,
  } = props;
  const cookies = new Cookies();
  const role = cookies.get("role");
  const merchantId = loginData?.data?.merchant;
  const singleMerchantId =
    adminStoreDetails?.adminStoreDetails?.data?.merchant_id;
  useEffect(() => {
    if (role === "admin") {
      callGetSetting(singleMerchantId);
    } else {
      callGetSetting(merchantId);
    }
  }, [callGetSetting, role, singleMerchantId, merchantId]);

  const updateAndGetData = async (payload) => {
    const payloadData = {
      payload: payload,
      merchantId: singleMerchantId,
    };
    await callUpdateSetting(payloadData);
    if (role === "admin") {
      callGetSetting(singleMerchantId);
    } else {
      callGetSetting(merchantId);
    }
  };
  return (
    <>
      {settings.isLoading && <Loading type="overlay" className="loader-icon" />}
      {settings?.settings && (
        <SettingsTabPage
          settings={settings.settings?.data}
          callUpdateSetting={callUpdateSetting}
          updateAndGetData={updateAndGetData}
          updateLoading={updateSetting?.isLoading}
          singleMerchantId={singleMerchantId}
          role={role}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    updateSetting: state.updateSetting,
    settings: state.settings,
    loginData: state.loginData.loginData,
    adminStoreDetails: state.adminStoreDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetSetting: (props) => dispatch(getSetting(props)),
    callUpdateSetting: (props) => dispatch(updateSetting(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTab);
