import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance  from "../../authServices/axiosInstance";

export const dashboardLogin = createAsyncThunk(
  "login",
  async ({ email, password }) => {
    try {
      const response = await AxiosInstance.post(`${API_URL}/v1/users/login`, {
        email,
        password,
      });
      return response.data;
    }  catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const dashboardLoginSlice = createSlice({
  name: "login",
  initialState: {
    isLoading: false,
    loginDetails: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [dashboardLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [dashboardLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.loginDetails = action.payload;
    },
    [dashboardLogin.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default dashboardLoginSlice.reducer;
