import { Checkbox, DatePicker, Select, Table } from "antd";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Store } from "../../../../../assets/images/sidebar";
import { rangePresets } from "../../../../../utils/helper";
import DashboardHeaderComponent from "../../../common/dashboardHeader/dashboradHeader";
import DatePreset from "../../../../common/DatePreset/datePreset";

const AdminStoreComponent = (props) => {
  const { RangePicker } = DatePicker;
  const {
    storeData,
    getStoreData,
    merchantList,
    selectedMerchant,
    setSelectedMerchant,
    isActive,
    setIsActive,
    currentPage,
    setCurrentPage,
    selectedDates,
    setSelectedDates,
  } = props;
  const navigate = useNavigate();
  const [isDate, setIsDate] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const handleNavigateDetails = (id) => {
    navigate(`/dashboard/storeDetail/${id}`);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const columns = [
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Store Name",
          },
        };
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Email",
          },
        };
      },
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Platform",
          },
        };
      },
    },
    {
      title: "Store Link",
      dataIndex: "domain",
      key: "domain",
      render: (text) => {
        return {
          children: (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`https://${text}/admin`}
              style={{
                fontWeight: 700,
                color: "#000",
                textDecorationLine: "underline",
              }}
              target="_blank"
            >
              {text}
            </Link>
          ),
          props: {
            "data-label": "Store Link",
          },
        };
      },
    },
    {
      title: "Owner",
      dataIndex: "shop_owner",
      key: "shop_owner",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Owner",
          },
        };
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (permissions) => {
        return {
          children: <Checkbox checked={permissions} disabled={true}></Checkbox>,
          props: {
            "data-label": "Owner",
          },
        };
      },
    },
  ];

  let merchantOption = [];
  if (Array.isArray(merchantList?.data?.response)) {
    merchantOption = merchantList.data.response.map((item) => ({
      value: item._id,
      label: item.name,
    }));
  }
  const merchantOptionsWithAll = [...merchantOption];
  const data =
    Array.isArray(storeData?.data?.response) &&
    storeData?.data?.response.map((item, index) => ({
      key: (index + 1).toString(),
      _id: item._id,
      name: item.name,
      email: item.email,
      domain: item.domain,
      platform: item.platform,
      shop_owner: item.shop_owner,
      is_active: item.is_active,
    }));

  const handleMerchantSelect = (value) => {
    if (value) {
      params.set("selected_option", value);
    } else {
      params.delete("selected_option");
    }
    if (value === "all") {
      params.delete("selected_option");
      setSelectedMerchant(null);
    } else {
      navigate(`?${params.toString()}`);
      setSelectedMerchant(value);
    }
  };

  const handleDateSelect = async (dates) => {
    const formattedStartDate = dates && dates[0]?.format("M-DD-YYYY");
    const formattedEndDate = dates && dates[1]?.format("M-DD-YYYY");

    if (formattedStartDate) {
      params.set("start_date", formattedStartDate);
    } else {
      params.delete("start_date");
      params.delete("days");
    }

    if (formattedEndDate) {
      params.set("end_date", formattedEndDate);
    } else {
      params.delete("end_date");
      params.delete("days");
    }
    setSelectedDates(dates);
    if (dates === null) {
      setIsActive("");
      setSelectedRange(null);
      setIsDate(false);
      // await getStoreData({});
    } else {
      const preset = rangePresets.find(
        (p) =>
          p.value[0].isSame(dates[0], "day") &&
          p.value[1].isSame(dates[1], "day")
      );
      if (preset) {
        params.set("days", preset.label);
        setIsActive(preset.label);
      } else {
        setIsActive("");
        params.delete("days");
      }
    }
    navigate(`?${params.toString()}`);

  };

  const handlePresetClick = (preset) => {
    setIsActive(preset.label);
    setSelectedRange(preset.value);
    handleDateSelect(preset.value);
    setIsDate(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="claim-main">
      <div className="claim-container">
        <DashboardHeaderComponent title="Store" Logo={Store} />
        <div className="claim-main-section">
          <div className="store-page-filter">
            <label>Search By Name: </label>
            <Select
              value={selectedMerchant}
              showSearch
              size="large"
              allowClear
              placeholder="Select merchant"
              onChange={handleMerchantSelect}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={merchantOptionsWithAll}
              style={{ width: 240 }}
            />
            <div className="or-text">Or</div>
            <label>Search By Date: </label>
            {/* <RangePicker
              onChange={handleDateSelect}
              format="M-DD-YYYY"
              presets={rangePresets}
              value={selectedDates}
            /> */}
            <RangePicker
              onClick={() => setIsDate(true)}
              open={isDate}
              onOpenChange={(open) => {
                if (!open) setIsDate(open);
              }}
              renderExtraFooter={() => (
                <DatePreset
                  isActive={isActive}
                  setIsActive={setIsActive}
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                  handlePresetClick={handlePresetClick}
                />
              )}
              onChange={handleDateSelect}
              format="M-DD-YYYY"
              value={selectedDates}
            />
          </div>
          <div className="claim-body">
            <div className="claim-box">
              <Table
                columns={columns}
                dataSource={data}
                pagination={{
                  current: currentPage,
                  pageSize: 25,
                  total: storeData?.length,
                  onChange: handlePageChange,
                }}
                locale={{ emptyText: "No store found" }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleNavigateDetails(record._id);
                    },
                  };
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStoreComponent;
