import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  Timeline,
} from "antd";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import UserTable from "./userTable";
import Loading from "../../../../../common/Loading/Loading";
import { ReactComponent as Inreview } from "../../../../../../assets/images/claimStatus/inreview.svg";
import { ReactComponent as Closed } from "../../../../../../assets/images/claimStatus/closed.svg";
import { ReactComponent as Total } from "../../../../../../assets/images/claimStatus/total.svg";
import { ReactComponent as Approved } from "../../../../../../assets/images/claimStatus/approved.svg";
import Notify from "../../../../../common/Notify/notify";
import moment from "moment";
import approveIcon from "../../../../../../assets/images/approve-icon.svg";
import processedIcon from "../../../../../../assets/images/processed-icon.svg";
import closeIcon from "../../../../../../assets/images/closeIcon.svg";
import resolvedIcon from "../../../../../../assets/images/resolved-icon.svg";
import { dateConvert, getMonthName } from "../../../../../../utils/helper";
import { ReactComponent as TaskCompletedPopup } from "../../../../../../assets/images/taskCompletedPopup.svg";
import { ReactComponent as TaskInCompletedPopup } from "../../../../../../assets/images/taskInCompletedPopup.svg";

const StoreDetailsComponent = (props) => {
  const { TextArea } = Input;
  const {
    handleEditTask,
    editTaskLoading,
    adminStoreDetails,
    handleSendBilling,
    merchantOrder,
    merchantActivity,
    monthlyClaim,
    revenue,
    accountManagerList,
    callAddManagerAssign,
    callAdminStoreDetails
  } = props;
  const statusData = [
    {
      title: "In review",
      count: adminStoreDetails.data.in_review,
      Icon: Inreview,
      backgroundColor: "#9BF5F5",
    },
    {
      title: "Closed",
      count: adminStoreDetails.data.closed,
      Icon: Closed,
      backgroundColor: "#E3FAA2",
    },
    {
      title: "Approved",
      count: adminStoreDetails.data.approved,
      Icon: Approved,
      backgroundColor: "#EDC6ED",
    },
    {
      title: "Total",
      count: adminStoreDetails.data.total,
      Icon: Total,
      backgroundColor: "#FDF399",
    },
  ];
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWidgetModalOpen, setIsWidgetModalOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [unCheckedModal, setUnCheckedModal] = useState(false);
  const [task, setTask] = useState("");
  const handleCancel = () => {
    setIsWidgetModalOpen(false);
    setIsModalOpen(false);
  };
  const [SelectedOption, setSelectedOption] = useState(
    adminStoreDetails?.data?.merchant_account_manager?.email
  );
  const [error, setError] = useState("");
  const { id } = useParams();

  const columns = [
    {
      title: "Order #",
      dataIndex: "orderId",
      key: "orderId",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Order #",
          },
        };
      },
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Customer Email",
          },
        };
      },
    },
    {
      title: "Claim Date",
      dataIndex: "claimDate",
      key: "claimDate",
      render: (text) => {
        return {
          children: dateConvert(text),
          props: {
            "data-label": "Claim Date",
          },
        };
      },
    },
    {
      title: "Previous Status",
      dataIndex: "lastStattus",
      key: "lastStattus",
      render: (text) => {
        return {
          children: (
            <span
              className={
                text === "IN_REVIEW"
                  ? "inreview"
                  : text === "PROCESSED"
                  ? "processed"
                  : text === "OTHER"
                  ? "other"
                  : "default-class"
              }
              style={{
                color:
                  text === "APPROVED"
                    ? "#89BA00"
                    : text === "REVIEWING"
                    ? "#00B9B9"
                    : text === "CLOSED"
                    ? "#ee5d50"
                    : text === "RESOLVED"
                    ? "#02b574"
                    : "#1a1e22",
                fontWeight: 500,
                fontSize: 16,
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {text === "APPROVED" ? (
                <img src={approveIcon} alt="" height={16} width={16} />
              ) : text === "REVIEWING" ? (
                <img src={processedIcon} alt="" height={16} width={16} />
              ) : text === "CLOSED" ? (
                <img src={closeIcon} alt="" height={16} width={16} />
              ) : text === "RESOLVED" ? (
                <img src={resolvedIcon} alt="" height={16} width={16} />
              ) : null}
              {text ? text : "N/A"}
            </span>
          ),
          props: {
            "data-label": "Previous Status",
          },
        };
      },
    },
    {
      title: "Current Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        return {
          children: (
            <>
              <div
                style={{
                  color:
                    status === "APPROVED"
                      ? "#02b574"
                      : status === "REVIEWING"
                      ? "#00B9B9"
                      : status === "CLOSED"
                      ? "#ee5d50"
                      : "#02b574",
                  fontWeight: 500,
                  fontSize: 16,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {status === "APPROVED" ? (
                  <img src={approveIcon} alt="" height={16} width={16} />
                ) : status === "REVIEWING" ? (
                  <img src={processedIcon} alt="" height={16} width={16} />
                ) : status === "CLOSED" ? (
                  <img src={closeIcon} alt="" height={16} width={16} />
                ) : status === "RESOLVED" ? (
                  <img src={resolvedIcon} alt="" height={16} width={16} />
                ) : null}
                {status}
              </div>
            </>
          ),
          props: {
            "data-label": "Current Status",
          },
        };
      },
    },
  ];

  const tableData =
    Array.isArray(adminStoreDetails?.data?.claim_order) &&
    adminStoreDetails?.data?.claim_order?.map((user, index) => ({
      key: (index + 1).toString(),
      name: user.name,
      orderId: user.order_id,
      customerEmail: user.customer_email,
      claimDate: user.createdAt,
      lastStattus: user.previous_status,
      status: user.status,
      _id: user._id,
      claimId: user.claim_id,
    }));

  const handleNavigateDetails = async (id) => {
    navigate(`/dashboard/claims/${id}`);
  };

  const overallRevenue =
    [revenue?.fees_collected, revenue?.net_revenue, revenue?.paid_out] || [];
  const revenueRecord = overallRevenue.every((record) => record !== 0);

  const overallRevenueLabel =
    ["Fees collected", "Net revenue", "Paid out"] || [];

  const axisCategories =
    monthlyClaim?.data?.map((entry) =>
      getMonthName(Number(entry.month), Number(entry.year))
    ) || [];

  const seriesData = monthlyClaim?.data?.map((entry) => entry.count) || [];

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
      dataLabels: {
        style: {
          colors: ["#1a1e22"],
        },
      },
    },
    xaxis: {
      categories: axisCategories,
    },
    colors: ["#9BF5F5"],
  };

  const series = [
    {
      name: "Sales",
      data: seriesData,
    },
  ];

  const onFinish = (values) => {
    if (merchantOrder?.length <= 0) {
      Notify("error", "No order found", "");
      form.resetFields();
      return;
    }
    form.resetFields();
  };
  const [form] = Form.useForm();
  let merchantOrderList = [];
  if (Array.isArray(merchantOrder)) {
    merchantOrderList = merchantOrder.map((item) => ({
      value: item._id,
      label: item.order_number,
    }));
  }

  let adminManagerOption = [];
  if (Array.isArray(accountManagerList?.data)) {
    adminManagerOption = accountManagerList.data.map((item) => ({
      value: item._id,
      label: item.email,
    }));
  }

  const adminManagerOptionWithAll = [...adminManagerOption];
  
  const handleFindOrder = (value) => {
    setSelectedOption(value);
    if (!value) {
      setError("Please select an option before assign account manager.");
    } else {
      setError("");
    }
  };

  const handleAddMerchant = async () => {
    if (!SelectedOption) {
      setError("Please select an option before assign account manager.");
    } else {
      setError("");
      const payload = {
        manager: SelectedOption,
      };
      const response = await await callAddManagerAssign({
        id: adminStoreDetails.data.merchant_id,
        data: payload,
      });
      if (response.type === "addManagerAssign/fulfilled") {
        Notify("success", response.payload.message, "");
        callAdminStoreDetails(id)
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
        }
      }
    }
  };

  return (
    <>
      {editTaskLoading && <Loading type="overlay" className="loader-icon" />}
      <div className="admin-store-details-section">
        <div className="admin-onboard-page">
          <div className="admin-merchant-name">
            <strong>
              Merchant: <span>{adminStoreDetails?.data?.merchant_name}</span>
            </strong>
            <div className="add-merchant">
              <span>Account Manager : </span>
              <div>
                <Select
                  value={SelectedOption}
                  showSearch
                  size="large"
                  allowClear
                  placeholder="Select By"
                  onChange={handleFindOrder}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={adminManagerOptionWithAll}
                  style={{ width: 240 }}
                />
                <button className="add-btn" onClick={handleAddMerchant}>
                Assign
                </button>
                {error && (
                  <div style={{ color: "red", fontSize: "12px" }}>{error}</div>
                )}
              </div>
            </div>
          </div>
          <div className="store-detail-overview">
            <div className="store-detail-overview-left">
              <div className="merchant-detail-title">
                <span>Merchant Details</span>
              </div>
              <div className="detail-box">
                <div className="box-title">Account Details</div>
                {[
                  {
                    title: "Full Name",
                    content: adminStoreDetails?.data?.merchant_name,
                  },
                  {
                    title: "Email",
                    content: adminStoreDetails?.data?.merchant_email,
                  },
                  {
                    title: "Phone",
                    content: adminStoreDetails?.data?.merchant_phone,
                  },
                  {
                    title: "Address",
                    content: adminStoreDetails?.data?.merchant_address1,
                  },
                  // {
                  //   title: "Card Details",
                  //   content: "b156-cd462-R4546",
                  // },
                ].map((item, index) => (
                  <div className="box-content" key={index}>
                    <div className="box-content-title">{item.title}</div>
                    <div className="box-content-content">
                      {item.content ? item.content : "--"}
                    </div>
                  </div>
                ))}
              </div>
              <div className="detail-box">
                <div className="box-title">Store Details</div>
                {[
                  {
                    title: "Platform",
                    content: adminStoreDetails?.data?.merchant_platform,
                  },
                  {
                    title: "Merchant Id",
                    content: adminStoreDetails?.data?.task?.merchant,
                  },
                ].map((item, index) => (
                  <div className="box-content" key={index}>
                    <div className="box-content-title">{item.title}</div>
                    <div className="box-content-content">
                      {item.content ? item.content : "--"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="store-detail-overview-right">
              <div className="merchant-detail-title">
                <span>Recent Activity</span>
              </div>
              <div className="detail-box">
                <div className="timeline-section">
                  {merchantActivity?.events?.length > 0 ? (
                    <Timeline
                      items={merchantActivity?.events?.map((item, index) => {
                        return {
                          children: (
                            <div className="activity-title">
                              <label>{item.title ? item.title : "-"}</label>
                              <div className="activity-time">
                                {dateConvert(item.ts * 1000)}
                              </div>
                            </div>
                          ),
                          dot: (
                            <div
                              className={`timeline-dots ${
                                index === 0 ? "active" : ""
                              }`}
                            ></div>
                          ),
                        };
                      })}
                    />
                  ) : (
                    <p className="no-record-found">No records found</p>
                  )}
                </div>
              </div>
              <div className="merchant-detail-title notes">
                <span>Leave Notes</span>
              </div>
              <div className="detail-box">
                <Form form={form} onFinish={onFinish} className="note-section">
                  <div>Select Order</div>
                  <div className="order-select">
                    <Form.Item
                      name="ordernumber"
                      rules={[
                        {
                          required: merchantOrder?.length > 0 && true,
                          message: "Please select order",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Select order"
                        options={merchantOrderList}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                  <div>Add Note</div>
                  <Form.Item
                    name="note"
                    rules={[
                      {
                        required: merchantOrder?.length > 0 && true,
                        message: "Please enter the note",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Write a Notes..."
                      rows={4}
                      autoSize={{ minRows: 4, maxRows: 5 }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className="note-button">
                      <button className="primary_btn btn">
                        Send to Internal Team
                      </button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          <Divider className="divider" />
          <div className="revenue-detail-main">
            <div className="revenue-detail-title">
              <span>Revenue Details</span>
            </div>
            <div className="box-section" style={{ marginTop: 20 }}>
              {statusData.map((status, index) => (
                <div className="first-section" key={index}>
                  <div className="content-view">
                    <div>
                      <h3>{status.title}</h3>
                      <p>{status.count}</p>
                    </div>
                    <div
                      className="icon-view"
                      style={{ backgroundColor: status.backgroundColor }}
                    >
                      <status.Icon height={20} width={20} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="revenue-chart-section">
              <div className="revenue-chart">
                <div className="chart-box">
                  <div className="chart-box-content">
                    <p>Revenue chart</p>
                    {revenueRecord ? (
                      <Chart
                        options={{
                          labels: overallRevenueLabel,
                          colors: ["#E3FAA2", "#EDC6ED", "#9BF5F5"],
                          dataLabels: {
                            style: {
                              colors: ["#1a1e22"],
                            },
                          },
                        }}
                        series={overallRevenue}
                        type="donut"
                        height={250}
                      />
                    ) : (
                      <div className="no-record-found">No Record Found</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="month-revenue-chart">
                <div className="chart-box">
                  <div className="chart-box-content">
                    <p>Monthly Claims</p>
                    {seriesData.length > 0 ? (
                      <Chart
                        options={options}
                        series={series}
                        type="bar"
                        height={200}
                      />
                    ) : (
                      <div className="no-record-found">No Record Found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider className="divider" />
        </div>
        <div className="store-details-body">
          <div className="onBoard-body">
            <div className="task-title">
              <span>Onboarding Task</span>
            </div>
            <div className="onBoard-box gap-box">
              <div className="onBoard-body-desc">
                <div>
                  <Checkbox
                    checked={adminStoreDetails?.data?.task?.install_task.done}
                    onClick={() => {
                      adminStoreDetails?.data?.task?.install_task.done
                        ? setUnCheckedModal(true)
                        : setConfirmModal(true);
                      setTask("install_task");
                    }}
                  >
                    {adminStoreDetails?.data?.task?.install_task.label}
                  </Checkbox>
                  {adminStoreDetails?.data?.task?.install_task.done ? (
                    ""
                  ) : (
                    <Link to={adminStoreDetails?.data?.task?.install_task.url}>
                      <strong>
                        {
                          adminStoreDetails?.data?.task?.install_task
                            .actionLabel
                        }
                      </strong>
                    </Link>
                  )}
                </div>
                <div>
                  <Checkbox
                    checked={adminStoreDetails?.data?.task?.onboard_task.done}
                    onClick={() => {
                      adminStoreDetails?.data?.task?.onboard_task.done
                        ? setUnCheckedModal(true)
                        : setConfirmModal(true);
                      setTask("onboard_task");
                    }}
                  >
                    {adminStoreDetails?.data?.task?.onboard_task.label}
                  </Checkbox>
                  {adminStoreDetails?.data?.task?.onboard_task.done ? (
                    ""
                  ) : (
                    <Link
                      to={"https://calendly.com/angelifmgx/30min?month=2023-07"}
                      target="_blank"
                    >
                      <strong>
                        {
                          adminStoreDetails?.data?.task?.onboard_task
                            .actionLabel
                        }
                      </strong>
                    </Link>
                  )}
                </div>
                <div>
                  <Checkbox
                    checked={adminStoreDetails?.data?.task?.partner_task.done}
                    onClick={() => {
                      adminStoreDetails?.data?.task?.partner_task.done
                        ? setUnCheckedModal(true)
                        : setConfirmModal(true);
                      setTask("partner_task");
                    }}
                  >
                    {adminStoreDetails?.data?.task?.partner_task.label}
                  </Checkbox>
                  {adminStoreDetails?.data?.task?.partner_task.done ? (
                    ""
                  ) : (
                    <strong onClick={() => setIsModalOpen(!isModalOpen)}>
                      {adminStoreDetails?.data?.task?.partner_task.actionLabel}
                    </strong>
                  )}
                </div>
                <div>
                  <Checkbox
                    checked={adminStoreDetails?.data?.task?.billing_task.done}
                    onClick={() => {
                      adminStoreDetails?.data?.task?.billing_task.done
                        ? setUnCheckedModal(true)
                        : setConfirmModal(true);
                      setTask("billing_task");
                    }}
                  >
                    {adminStoreDetails?.data?.task?.billing_task.label}
                  </Checkbox>
                  {adminStoreDetails?.data?.task?.billing_task.done ? (
                    ""
                  ) : (
                    <strong>
                      {adminStoreDetails?.data?.task?.billing_task.actionLabel}
                    </strong>
                  )}
                </div>
                <div>
                  <Checkbox
                    checked={adminStoreDetails?.data?.task?.widget_task.done}
                    onClick={() => {
                      adminStoreDetails?.data?.task?.widget_task.done
                        ? setUnCheckedModal(true)
                        : setConfirmModal(true);
                      setTask("widget_task");
                    }}
                  >
                    {adminStoreDetails?.data?.task?.widget_task.label}
                  </Checkbox>
                  {adminStoreDetails?.data?.task?.widget_task.done ? (
                    ""
                  ) : (
                    <strong
                      onClick={() => {
                        setIsWidgetModalOpen(!isWidgetModalOpen);
                      }}
                    >
                      {adminStoreDetails?.data?.task?.widget_task.actionLabel}
                    </strong>
                  )}
                </div>
              </div>
            </div>
            <Divider className="divider" />
            <div className="billing-title">
              <span>Billing Details</span>
            </div>
            <div className="billing-section">
              <div className="billing-data-section">
                <div className="is-billing">
                  Billing Status:
                  <span>
                    {adminStoreDetails?.data?.shopify_billing ? (
                      <Tag
                        color="success"
                        style={{
                          height: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        success
                      </Tag>
                    ) : (
                      <Tag
                        color="error"
                        style={{
                          height: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Pending
                      </Tag>
                    )}
                  </span>
                </div>
                <button
                  className={
                    adminStoreDetails?.data?.shopify_billing
                      ? "disable-btn"
                      : "primary_btn btn"
                  }
                  onClick={handleSendBilling}
                  disabled={adminStoreDetails?.data?.shopify_billing}
                >
                  Send Billing Link
                </button>
              </div>
            </div>
          </div>
          <Divider className="divider" />
          <div className="admin-onboard-page" style={{ padding: "0px" }}>
            <UserTable users={adminStoreDetails} merchantId={id} />
          </div>
          <Divider className="divider" />
          <div className="admin-onboard-page" style={{ padding: "0px" }}>
            <div className="store-claim-title">
              <span>Claims</span>
            </div>
            <div className="admin-onboard-table">
              <Table
                dataSource={tableData}
                columns={columns}
                pagination={tableData?.length > 10}
                locale={{ emptyText: "No claim found" }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleNavigateDetails(record.claimId);
                    },
                  };
                }}
              />
            </div>
          </div>
        </div>
        <Modal
          centered
          open={isModalOpen}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={handleCancel}
        >
          <div className="onBoard-Model">
            <div className="onBoard-title">
              <span>{adminStoreDetails?.data?.task?.partner_task.label}</span>
            </div>
            <div className="onBoard-welcome">
              <p>{adminStoreDetails?.data?.task?.partner_task.popupText}</p>
            </div>
            <div className="onBoard-form">
              <div className="onBoard-form-input"></div>
              <div className="onBoard-button">
                <button className="primary_btn btn" onClick={handleCancel}>
                  Got It!
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          centered
          open={isWidgetModalOpen}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={handleCancel}
        >
          <div className="onBoard-Model">
            <div className="onBoard-title">
              <span>{adminStoreDetails?.data?.task?.widget_task.label}</span>
            </div>
            <div className="onBoard-welcome">
              <p>{adminStoreDetails?.data?.task?.widget_task.popupText}</p>
            </div>
            <div className="onBoard-form">
              <div className="onBoard-form-input"></div>
              <div className="onBoard-button">
                <button className="primary_btn btn" onClick={handleCancel}>
                  Got It!
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          centered
          open={confirmModal}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={() => setConfirmModal(false)}
          className="approve-popup"
        >
          <div className="user-delete-confirm-model">
            <div className="image-container">
              <TaskCompletedPopup />
            </div>

            <div className="user-delete-text">
              Are you sure you make this task complete ?
            </div>
            <div>
              <Button
                type="primary"
                danger
                className="user-delete-button"
                onClick={() => setConfirmModal(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="user-delete-yes-button"
                onClick={() =>
                  handleEditTask({
                    id: task,
                    merchantId: adminStoreDetails?.data?.task?.merchant,
                    status: true,
                  })
                }
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          centered
          open={unCheckedModal}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={() => setUnCheckedModal(false)}
          className="approve-popup"
        >
          <div className="user-delete-confirm-model">
            <div className="image-container">
              <TaskInCompletedPopup />
            </div>

            <div className="user-delete-text">
              Are you sure you make this task as incomplete ?
            </div>
            <div>
              <Button
                type="primary"
                danger
                className="user-delete-button"
                onClick={() => setUnCheckedModal(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="user-delete-yes-button"
                onClick={() =>
                  handleEditTask({
                    id: task,
                    merchantId: adminStoreDetails?.data?.task?.merchant,
                    status: false,
                  })
                }
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default StoreDetailsComponent;
