import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../environment';
import AxiosInstance from '../../authServices/axiosInstance';
import { Cookies } from 'react-cookie';

export const widgetSetting = createAsyncThunk(
  'widgetSetting',
  async (merchantId) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get('token');
      const role = cookies.get('role');
      const response = await AxiosInstance.get(
        `${API_URL}/${role}/widget/${merchantId}`,
        {
          headers: { 'x-access-token': token },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const widgetSettingSlice = createSlice({
  name: 'widgetSetting',
  initialState: {
    isLoading: false,
    widget: null,
    isError: false,
    errorMessage: '',
  },
  extraReducers: {
    [widgetSetting.pending]: (state) => {
      state.isLoading = true;
    },
    [widgetSetting.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.widget = action.payload;
    },
    [widgetSetting.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default widgetSettingSlice.reducer;
