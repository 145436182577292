import React, { useEffect } from "react";
import SetupGuideComponent from "./setupGuide";
import { merchantSendBilling } from "../../../../redux/slice/merchantSendBillingSlice";
import { connect } from "react-redux";
import Loading from "../../../common/Loading/Loading";
import Notify from "../../../common/Notify/notify";
import { getSetupGuide } from "../../../../redux/slice/getSetupGuideSlice";
import { updateSetupGuide } from "../../../../redux/slice/updateSetupGuideSlice";

const SetupGuide = (props) => {
  const {
    callMerchantSendBilling,
    merchantSendBilling,
    loginData,
    fetchSetupGuide,
    setupGuide,
    setSetupGuide,
  } = props;

  useEffect(() => {
    fetchSetupGuide();
  }, [fetchSetupGuide]);

  const handleUpdateSetup = async (param) => {
    await setSetupGuide(param);
    await fetchSetupGuide();
  };

  const sendBilling = async () => {
    const payload = {
      shop_id: loginData?.loginData?.data?.merchantInfo?.shop_id,
    };
    const response = await callMerchantSendBilling(payload);
    if (response.type === "send-billing-merchant/fulfilled") {
      Notify("success", response.payload.message, "");
      window.open(response.payload.url, "_blank");
      // window.location.href = response.payload.url;
    } else if (response.error && response.error.message) {
      Notify("error", response.error.message, "");
    } else {
      Notify("error", "Something went wrong", "");
    }
  };
  return (
    <>
      {merchantSendBilling.isLoading ||
        (setupGuide.isLoading && (
          <Loading type="overlay" className="loader-icon" />
        ))}
      {setupGuide.setupGuide?.data && (
        <SetupGuideComponent
          sendBilling={sendBilling}
          setupGuide={setupGuide.setupGuide.data[0]}
          handleUpdateSetup={handleUpdateSetup}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    merchantSendBilling: state.merchantSendBilling,
    loginData: state.loginData,
    setupGuide: state.setupGuide,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callMerchantSendBilling: (props) => dispatch(merchantSendBilling(props)),
    fetchSetupGuide: () => dispatch(getSetupGuide()),
    setSetupGuide: (param) => dispatch(updateSetupGuide(param)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupGuide);
