import React from "react";
import "./App.css";
import "./style/style.scss";
import "./language";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/mainRoutes";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
function App() {
  const stripePromise = loadStripe(
    "pk_test_51NQyBSDcgoMRnDdZ9aonVkJGZhZWQ2hWvfCJVa9ppfnrX7gfdiMMajoNGjn9gyi2fi0rgBOyVoDqFwDZczaYdK4200ve9ANYjv"
  );
  return (
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </Elements>
    </React.StrictMode>
  );
}

export default App;
