import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance  from "../../authServices/axiosInstance";

export const getStoreData = createAsyncThunk("getStoreData", async ({payload,page}) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const response = await AxiosInstance.post(`${API_URL}/admin/merchant/store?limit=25&page=${page}`,payload, {
      headers: { "x-access-token": token },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const getStoreDataSlice = createSlice({
  name: "getStoreData",
  initialState: {
    isLoading: false,
    details: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getStoreData.pending]: (state) => {
      state.isLoading = true;
    },
    [getStoreData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.details = action.payload;
      state.errorMessage = "";
      state.isError = false;
    },
    [getStoreData.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.details = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default getStoreDataSlice.reducer;
