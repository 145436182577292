import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance  from "../../authServices/axiosInstance";

export const onResetPassword = createAsyncThunk(
  "reset-password",
  async (data) => {
    try {
      const response = await AxiosInstance.post(`${API_URL}/merchant/users/reset-password`, 
      data,
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const onResetPasswordSlice = createSlice({
  name: "reset-password",
  initialState: {
    isLoading: false,
    onResetPassword: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [onResetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [onResetPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.onResetPassword = action.payload;
    },
    [onResetPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default onResetPasswordSlice.reducer;
