import React, { useEffect } from "react";
import SidebarComponent from "./sidebar.jsx";
import { connect } from "react-redux";
import { loginData } from "../../../../redux/slice/getLoginDataSlice.js";
import { Cookies } from "react-cookie";

const Sidebar = (props) => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  const merchantName = cookies.get("merchantName");
  const userName = cookies.get("userName");
  const role = cookies.get("role");
  const { callLoginData, loginData } = props;

  // useEffect(() => {
  //   if (token) {
  //     callLoginData(token);
  //   }
  // }, [token, callLoginData]);
  return (
    <>
      {loginData.loginData && (
        <SidebarComponent
          user={loginData.loginData}
          merchantName={merchantName}
          userName={userName}
          role={role}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loginData: state.loginData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callLoginData: (data) => dispatch(loginData(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
