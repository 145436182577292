import axios from "axios";
import { Modal } from "antd";
import { AiOutlineExclamation } from "react-icons/ai";
import { Cookies } from "react-cookie";

const instance = axios.create();

let merchantNotExist = false;

let isModalOpen = false;

const cookies = new Cookies();

const handleCancel = () => {
  cookies.remove("token", { path: "/" });
  cookies.remove("userEmail", { path: "/" });
  cookies.remove("userName", { path: "/" });
  cookies.remove("role", { path: "/" });
  window.location.reload();
  Modal.destroyAll();
  window.location.href = "/";
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403 && !isModalOpen) {
      if (error.response.data.message === "Merchant does not exist.") {
        merchantNotExist = true;
      }
      isModalOpen = true; // Set isModalOpen to true to prevent multiple modals
      Modal.error({
        content: (
          <div className="session-Model">
            <div className="session-modal-logo">
              <div className="bd-logo">
                <AiOutlineExclamation />
              </div>
            </div>
            <div className="session-modal-text">
              {merchantNotExist ? (
                <h3>Merchant does not exist.</h3>
              ) : (
                <>
                  <h3>Session expired</h3>
                  <p>
                    Your current session has expired, and you have been logged
                    out. Please log in again to continue.
                  </p>
                </>
              )}
            </div>
            <div className="session-modal-btn">
              <button className="primary_btn btn" onClick={handleCancel}>
                Go Back to Login
              </button>
            </div>
          </div>
        ),
        centered: true,
        cancelButtonProps: { style: { display: "none" } },
        okButtonProps: { style: { display: "none" } },
        closable: false,
        onOk: () => {
          isModalOpen = false;
        },
        onCancel: () => {
          isModalOpen = false;
        },
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
