import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const editTask = createAsyncThunk(
  "editTask",
  async ({ id, merchantId, status }) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const response = await AxiosInstance.put(
        `${API_URL}/admin/merchant/task`,
        {
          merchant: merchantId,
          task: id,
          status: status,
        },
        {
          headers: { "x-access-token": token },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const editTaskSlice = createSlice({
  name: "editTask",
  initialState: {
    isLoading: false,
    editTask: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [editTask.pending]: (state) => {
      state.isLoading = true;
    },
    [editTask.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.editTask = action.payload;
    },
    [editTask.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default editTaskSlice.reducer;
