import {
  Button,
  Checkbox,
  ColorPicker,
  Input,
  Radio,
  Select,
  Space,
  Switch,
  Tabs,
} from "antd";

import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import LivePreviewComponent from "./livepriview";
import { fontFamily, fontSize } from "../../../../utils/commonUtils";
import { useTranslation } from "react-i18next";
import Notify from "../../../common/Notify/notify";

const ConfigTabComponent = (props) => {
  const { configInfo, editWidget, adminMerchantId } = props;
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const provinceData = ["USD", "CAD"];
  const [form, setForm] = useState({
    // General Settings (First Tab)
    location: configInfo?.data?.configure_bar?.location,
    offer_text: configInfo?.data?.configure_bar?.offer_text,
    content: "",
    close_button: configInfo?.data?.configure_bar?.close_button,
    spending_target: configInfo?.data?.configure_bar?.spending_target,
    stickiness: configInfo?.data?.configure_bar?.stickiness,
    spending_currency: configInfo?.data?.configure_bar?.spending_currency,

    // Style Settings (Second Tab)
    banner_background_color:
      configInfo?.data?.configure_bar?.banner_background_color,
    font_size: configInfo?.data?.configure_bar?.font_size,
    font_color: configInfo?.data?.configure_bar?.font_color,
    font_family: configInfo?.data?.configure_bar?.font_family,
    is_active: configInfo?.data?.configure_bar?.is_active,
  });
  useEffect(() => {
    setForm({
      // General Settings (First Tab)
      location: configInfo?.data?.configure_bar?.location,
      offer_text: configInfo?.data?.configure_bar?.offer_text,
      content: "",
      close_button: configInfo?.data?.configure_bar?.close_button,
      spending_target: configInfo?.data?.configure_bar?.spending_target,
      stickiness: configInfo?.data?.configure_bar?.stickiness,
      spending_currency: configInfo?.data?.configure_bar?.spending_currency,

      // Style Settings (Second Tab)
      banner_background_color:
        configInfo?.data?.configure_bar?.banner_background_color,
      font_size: configInfo?.data?.configure_bar?.font_size,
      font_color: configInfo?.data?.configure_bar?.font_color,
      font_family: configInfo?.data?.configure_bar?.font_family,
      is_active: configInfo?.data?.configure_bar?.is_active,
    });
  }, [configInfo]);

  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleStickNessChange = (option) => {
    const newStickNess = form.stickiness?.includes(option)
      ? form.stickiness.filter((item) => item !== option)
      : [...form.stickiness, option];

    handleChange("stickiness", newStickNess);
  };
  const handleSave = async () => {
    const updatePayload = {
      configData: form,
      merchantId: adminMerchantId,
    };
    const response = await editWidget({ key: "configure_bar", updatePayload });
    if (response.type === "updateWidget/fulfilled") {
      Notify("success", response.payload.message, "");
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };

  const radioOptions = [
    {
      label: t("GEN_OFFER_1"),
      value: "FREE Shipping for all orders",
      id: "first",
      text: t("GEN_OFFER_1_MAKE"),
    },
    {
      label: t("GEN_OFFER_2"),
      value: "FREE Shipping on orders over $99",
      id: "second",
      text: t("GEN_OFFER_2_MAKE"),
    },
    {
      label: t("GEN_OFFER_3"),
      value: "Free Shipping on all orders with optional",
      id: "third",
      text: t("GEN_OFFER_3_SWIPE"),
    },
    {
      label: t("GEN_OFFER_4"),
      value: "Free Shipping on orders over $99 with optional",
      id: "four",
      text: t("GEN_OFFER_4_SWIPE"),
    },
    {
      label: t("GEN_OFFER_5"),
      value: "Instant Shipping Issue Resolution available for all orders",
      id: "five",
      text: t("GEN_OFFER_5_SWIPE"),
    },
  ];

  const validateColor = useMemo(
    () => (selectedColor) => {
      return typeof selectedColor === "string"
        ? selectedColor
        : selectedColor?.toHexString();
    },
    []
  );

  return (
    <>
      <div className="configBar_main">
        <Tabs>
          <TabPane tab={<span>General Settings</span>} key="1">
            <div className="flexGeneral">
              <div className="generalStyleSetting">
                <div className="switchContainer">
                  <Switch
                    onChange={(value) => handleChange("is_active", value)}
                    value={form.is_active}
                  />
                  <span className="activeSlide">
                    {form.is_active ? "Active" : "Not Active"}
                  </span>
                </div>
                <p>{t("GEN_SET_PLEASE")}</p>
                <hr />
                <div className="shippingOfferContainer">
                  <div className="offerContainer">
                    <p className="offer">Offer</p>
                  </div>
                  <div className="offer-container">
                    <Radio.Group
                      onChange={(e) =>
                        handleChange("offer_text", e.target.value)
                      }
                      value={form.offer_text}
                    >
                      {radioOptions.map((option) => (
                        <Radio
                          key={option.id}
                          id={option.id}
                          value={option.value}
                          className="radio-button"
                        >
                          {option.label}
                          {form.content === option.id && (
                            <div className="someTextContainer">
                              <p className="someText-content">{option.text}</p>
                            </div>
                          )}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
                <div className="SpendingTargetContainer">
                  <div className="SpendingTitle-Container">
                    <span className="targetTitle">Spending Title</span>
                  </div>
                  <div className="spendingDepartment">
                    <div className="inputFieldsContainer">
                      <label className="label-text">Spending Target</label>
                      <Input
                        type="number"
                        className="input-text"
                        placeholder="Target"
                        value={form.spending_target}
                        onChange={(e) =>
                          handleChange("spending_target", e.target.value)
                        }
                      />
                    </div>
                    <div className="currencyContainer">
                      <label className="label-text">Currency</label>
                      <Space wrap>
                        <Select
                          placeholder="Select currency"
                          style={{ width: 150 }}
                          options={provinceData.map((province) => ({
                            label: province,
                            value: province,
                          }))}
                          value={form.spending_currency}
                          onChange={(value) =>
                            handleChange("spending_currency", value)
                          }
                        />
                      </Space>
                    </div>
                  </div>
                </div>
                <div className="location-Container">
                  <div className="LocationContainer">
                    <span>Location</span>
                  </div>
                  <Radio.Group
                    onChange={(e) => handleChange("location", e.target.value)}
                    value={form.location}
                  >
                    <div className="location-option">
                      <Radio value="top">Top of Browser Window</Radio>
                      <Radio value="bottom">Bottom of Browser Window</Radio>
                    </div>
                  </Radio.Group>
                </div>
                <div className="Stickiness-Container">
                  <div className="StickinessContainer">
                    <p className="StickinessTitle">Stickiness</p>
                  </div>
                  <div className="stickyContainerDiv">
                    <Checkbox
                      onChange={() => handleStickNessChange("Sticky")}
                      checked={form.stickiness?.includes("Sticky")}
                    >
                      Sticky
                    </Checkbox>
                    <Checkbox
                      onChange={() => handleStickNessChange("Hide on Scroll")}
                      checked={form.stickiness?.includes("Hide on Scroll")}
                    >
                      Hide on Scroll
                    </Checkbox>
                  </div>
                </div>
                <div className="Close-Button-container">
                  <div className="titleCloseDiv">
                    <p className="CloseButtonTitle">Close Button</p>
                  </div>
                  <div>
                    <Checkbox
                      checked={form.close_button}
                      onChange={(e) =>
                        handleChange("close_button", e.target.checked)
                      }
                    >
                      User can close
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tab={<span>Style Settings</span>} key="2">
            <p className="banner-title">Set Banner Background</p>
            <div className="styleContentSet-Container">
              <div>
                <label className="Background-text" style={{ display: "block" }}>
                  Background color
                </label>
                <div className="backColor-setter">
                  <Input
                    placeholder={validateColor(form.banner_background_color)}
                    className="Banner-Background"
                    value={validateColor(form.banner_background_color)}
                    disabled
                    size="large"
                    style={{ width: '200px' }}
                  />
                  <div className="colorPeckerContainer">
                    <ColorPicker
                      onChange={(color) =>
                        handleChange(
                          "banner_background_color",
                          validateColor(color)
                        )
                      }
                      className="colorPecker"
                    >
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: form.banner_background_color,
                        }}
                        className="colorBtn"
                      ></Button>
                    </ColorPicker>
                  </div>
                </div>
              </div>
            </div>
            <div className="fontSetContainer">
              <div className="sizeSelect-container">
                <p className="banner-title">Font</p>
                <label>Font Family</label>
                <div className="selectFontFamily">
                  <Select
                    options={fontFamily}
                    onChange={(value) => handleChange("font_family", value)}
                    placeholder="Select fontFamily"
                    value={form.font_family}
                  />
                </div>
              </div>

              <div>
                <label className="fontSizeLabel" style={{ display: "block" }}>
                  Font Size
                </label>
                <div>
                  <Select
                    placeholder="Select fontSize"
                    value={form.font_size}
                    style={{
                      width: "200px",
                    }}
                    type="number"
                    options={fontSize}
                    onChange={(value) => handleChange("font_size", value)}
                  />
                </div>
              </div>

              <div className="colorFontContainer">
                <div className="colorProtect">
                  <label className="fontColorLabel">Font Color</label>
                  <div className="colorFullFont">
                    <Input
                      placeholder={validateColor(form.font_color)}
                      type="text"
                      className="fontColorInput"
                      value={validateColor(form.font_color)}
                      disabled
                      size="large"
                      style={{ width: '200px' }}
                    />
                    <div className="fontColorPickerContainer">
                      <ColorPicker
                        onChange={(color) =>
                          handleChange("font_color", validateColor(color))
                        }
                        className="fontColorPicker"
                      >
                        <Button
                          type="primary"
                          className="fontBtn"
                          style={{ backgroundColor: form.font_color }}
                        ></Button>
                      </ColorPicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
        <LivePreviewComponent
          location={form.location}
          color={validateColor(form.banner_background_color)}
          fonts={form.font_size}
          fontcolor={validateColor(form.font_color)}
          fontfamily={form.font_family}
          check={form.offer_text}
          content={form.content}
        />
      </div>
      <div className="drawer-footer config-save">
        <button className="primary_btn btn" type="submit" onClick={handleSave}>
          Save
        </button>
      </div>
    </>
  );
};

export default ConfigTabComponent;
