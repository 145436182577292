import React, { useState } from "react";
import { Button, Input, Modal, Select, Table, Tooltip } from "antd";
import { Cookies } from "react-cookie";
import { AiOutlineInfoCircle } from "react-icons/ai";
import EditUser from "./editUser/editUser";
import Notify from "../../../../common/Notify/notify";
import Loading from "../../../../common/Loading/Loading";
import DashboardHeaderComponent from "../../../common/dashboardHeader/dashboradHeader";
import { ReactComponent as Edit } from "../../../../../assets/images/edit.svg";
import { ReactComponent as Password } from "../../../../../assets/images/password.svg";
import { ReactComponent as Delete } from "../../../../../assets/images/delete.svg";
import { Users } from "../../../../../assets/images/sidebar";
import { ReactComponent as DeleteUser } from "../../../../../assets/images/deleteUserPopup.svg";

const UsersPage = (props) => {
  const {
    users,
    handleCreate,
    handleInputChange,
    displayName,
    email,
    password,
    isModalOpen,
    setIsModalOpen,
    formErrors,
    handleCancel,
    callEditUserDetails,
    editUserDetails,
    callEditUser,
    callGetUsers,
    callDeleteUser,
    callResetPasswordLink,
    userAddLoading,
    userDeleteLoading,
    handleChangeRole,
  } = props;
  const id = editUserDetails?.editUserDetail?.data._id;
  const cookies = new Cookies();
  const token = cookies.get("token");

  const [onDeleteConfirmation, setOnDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");

  const handleDeleteIconClick = (id) => {
    setDeleteUserId(id);
    setOnDeleteConfirmation(true);
  };

  const handleDeleteUser = async () => {
    const response = await callDeleteUser(id);
    if (response.type === "adminDeleteUser/fulfilled") {
      Notify("success", response.payload.message, "");
      callGetUsers(token);
      setEditModel(false);
      setOnDeleteConfirmation(false);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };

  const handleConfirmDeleteUser = async () => {
    const response = await callDeleteUser(deleteUserId);
    if (response.type === "adminDeleteUser/fulfilled") {
      Notify("success", response.payload.message, "");
      callGetUsers(token);
      setEditModel(false);
      setOnDeleteConfirmation(false);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };

  const sendResetPasswordLink = async (userEmail) => {
    const email = editUserDetails.editUserDetail?.data.email;
    const response = await callResetPasswordLink(email || userEmail);
    if (response.type === "send-reset-password/fulfilled") {
      Notify("success", response.payload.message, "");
      setEditModel(false);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };
  const [editModel, setEditModel] = useState(false);
  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "No",
          },
        };
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text) => {
        return {
          children: (
            <div className="user-table-fl">
              <div className="user-logo">
                {text
                  .split(/\s/)
                  .slice(0, 2)
                  .map((word) => word.slice(0, 1))
                  .join("")}
              </div>
              <div className="user-name-text">{text}</div>
            </div>
          ),
          props: {
            "data-label": "User",
          },
        };
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Email",
          },
        };
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return {
          children: (
            <div className="user-action-row">
              <Tooltip title="Edit user">
                <div onClick={() => handleRowClick(record.id)}>
                  <Edit size={22} />
                </div>
              </Tooltip>
              <div>
                <Tooltip title="Please provide a reset password link upon clicking the designated icon.">
                  <Password
                    size={21}
                    onClick={() => sendResetPasswordLink(record.email)}
                  />
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Delete user">
                  <Delete
                    size={22}
                    onClick={() => handleDeleteIconClick(record.id)}
                  />
                </Tooltip>
              </div>
            </div>
          ),
          props: {
            "data-label": "Actions",
          },
        };
      },
    },
  ];

  const data =
    Array.isArray(users) &&
    users?.map((user, index) => ({
      key: (
        <div className="table-header_orderID">
          <div className="table-header_orderID-svg"></div>
          {(index + 1).toString()}
        </div>
      ),
      user: user.display_name,
      email: user.email,
      id: user._id,
    }));

  const handleRowClick = async (record) => {
    await callEditUserDetails(record);
    setEditModel(true);
  };
  const createUserRoleOption = [
    { label: "Super Admin", value: "super-admin" },
    { label: "Account Manager", value: "account-manager" },
    { label: "Claim Approve", value: "claim-approver" },
  ];

  return (
    <>
      {userAddLoading || userDeleteLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : null}
      <div className="user-main-dashboard">
        <div className="user-main-container">
          <DashboardHeaderComponent title="Users" Logo={Users} />
          <div className="user-main-section">
            <div className="user-head">
              <div className="user-title">
                <div className="user-right">
                  <button
                    className="primary_btn btn"
                    onClick={() => {
                      setIsModalOpen(!isModalOpen);
                    }}
                  >
                    Create New User
                  </button>
                </div>
              </div>
            </div>
            <div className="user-body">
              <div className="user-box">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={users?.length > 10}
                  style={{ cursor: "pointer" }}
                  locale={{ emptyText: "No user found" }}
                />
              </div>
            </div>
            <Modal
              open={isModalOpen}
              centered
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              onCancel={handleCancel}
            >
              <div className="onBoard-Model">
                <div className="onBoard-title">
                  <span>New User</span>
                </div>
                <div className="user-modal-body">
                  <Input
                    name="displayName"
                    placeholder="Display Name"
                    value={displayName}
                    onChange={handleInputChange}
                    className="displayname_input"
                  />
                  {formErrors.displayName && (
                    <div className="field-error">{formErrors.displayName}</div>
                  )}
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={handleInputChange}
                    suffix={
                      <Tooltip title="Uppercase is not allowed in email">
                        <AiOutlineInfoCircle color="#bcbcbc" />
                      </Tooltip>
                    }
                  />
                  {formErrors.email && (
                    <div className="field-error">{formErrors.email}</div>
                  )}
                  <Input.Password
                    name="password"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={handleInputChange}
                  />
                  {formErrors.password && (
                    <div className="field-error">{formErrors.password}</div>
                  )}
                  <Select
                    showSearch
                    size="large"
                    allowClear
                    placeholder="Select Role"
                    onChange={handleChangeRole}
                    options={createUserRoleOption}
                    style={{ width: "100%", height: "50px" }}
                  />
                </div>
                <div className="onBoard-button">
                  <button
                    className="primary_btn btn"
                    onClick={handleCreate}
                    disabled={userAddLoading}
                  >
                    Create
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <Modal
        centered
        open={onDeleteConfirmation}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setOnDeleteConfirmation(false)}
        className="approve-popup"
      >
        <div className="user-delete-confirm-model">
          <div className="image-container">
            <DeleteUser />
          </div>

          <div className="user-delete-text">
            Are you sure you want to delete this user ?
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={() => setOnDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => handleConfirmDeleteUser()}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      {editModel && (
        <EditUser
          isOpen={editModel}
          onClose={() => setEditModel(false)}
          editUserDetails={editUserDetails}
          callEditUser={callEditUser}
          callGetUsers={callGetUsers}
          setEditModel={setEditModel}
          callDeleteUser={callDeleteUser}
          callResetPasswordLink={callResetPasswordLink}
          sendResetPasswordLink={sendResetPasswordLink}
          handleDeleteUser={handleDeleteUser}
        />
      )}
    </>
  );
};

export default UsersPage;
