import { Button, Col, ColorPicker, Form, Input, Row, Switch, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import WidgetPreview from "./widgetPreview";

const WidgetComponents = ({ widgetInfo, handleWidgetSave }) => {
  const { data } = widgetInfo;
  const { t } = useTranslation();

  const [widgetData, setWidgetData] = useState({
    is_active: data?.cart_page?.is_active,
    enable_default_protection: data?.cart_page?.enable_default_protection,
    show_protection_amount: data?.cart_page?.show_protection_amount,
    title: data?.cart_page?.title,
    description: data?.cart_page?.description,
    first_tab_title: data?.cart_page?.first_tab_title,
    first_tab_description: data?.cart_page?.first_tab_description,
    second_tab_title: data?.cart_page?.second_tab_title,
    second_tab_description: data?.cart_page?.second_tab_description,
    third_tab_title: data?.cart_page?.third_tab_title,
    third_tab_description: data?.cart_page?.third_tab_description,
    background_color: data?.cart_page?.background_color,
    title_text_color: data?.cart_page?.title_text_color,
    description_text_color: data?.cart_page?.description_text_color,
    popup_title_color: data?.cart_page?.popup_title_color,
    popup_description_color: data?.cart_page?.popup_description_color,
  });

  const handleSave = () => {
    form
      .validateFields()
      .then(() => {
        handleWidgetSave(widgetData);
      })
      .catch((errorInfo) => {
        console.log("Form validation failed:", errorInfo);
      });
  };

  const handleChange = (name, value) => {
    setWidgetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(name);
    form.validateFields([name]);
  };
  const bgColor = useMemo(
    () => (selectedColor) => {
      return typeof selectedColor === "string"
        ? selectedColor
        : selectedColor?.toHexString();
    },
    []
  );

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Success:", values);
    handleSave();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    // Assuming your API data is stored in widgetData
    form.setFieldsValue({
      title: widgetData.title,
      description: widgetData.description,
      first_tab_title: widgetData.first_tab_title,
      first_tab_description: widgetData.first_tab_description,
      second_tab_title: widgetData.second_tab_title,
      second_tab_description: widgetData.second_tab_description,
      third_tab_title: widgetData.third_tab_title,
      third_tab_description: widgetData.third_tab_description,
      title_text_color: widgetData.title_text_color,
      description_text_color: widgetData.description_text_color,
      background_color: widgetData.background_color,
      popup_title_color: widgetData.popup_title_color,
      popup_description_color: widgetData.popup_description_color,
    });
  }, [widgetData]);

  return (
    <>
      <div className="widget-container">
        <Tabs className="tab-container">
          <TabPane tab={<span>General Settings</span>} key="1">
            <div className="is-active">
              <div className="switch-container">
                <Switch
                  checked={widgetData.is_active}
                  onChange={(checked) => handleChange("is_active", checked)}
                />
                <span className="active-text">
                  {widgetData.is_active ? "Active" : "Not Active"}
                </span>
              </div>
              <p className="active-info">{t("GEN_SET_PLEASE")}</p>
            </div>
            <Form
              name="basic"
              autoComplete="off"
              form={form}
              initialValues={widgetData}
            >
              <Form.Item label="Enable Default Protection">
                <Switch
                  checked={widgetData.enable_default_protection}
                  onChange={(checked) =>
                    handleChange("enable_default_protection", checked)
                  }
                />
              </Form.Item>
              <Form.Item label="Show Protection Amount">
                <Switch
                  checked={widgetData.show_protection_amount}
                  onChange={(checked) =>
                    handleChange("show_protection_amount", checked)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Title"
                name="title"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                rules={[{ required: true, message: "Please Enter Title!" }]}
              >
                <Input
                  name="title"
                  size="large"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={widgetData.title}
                />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: "Please Enter Description!" },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={300}
                  name="description"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={widgetData.description}
                  rows={4}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "1 <sup>st</sup> tab title",
                    }}
                  />
                }
                name="first_tab_title"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                rules={[{ required: true, message: "Please Enter Title!" }]}
              >
                <Input
                  size="large"
                  name="first_tab_title"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={widgetData.first_tab_title}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "1 <sup>st</sup> tab description",
                    }}
                  />
                }
                name="first_tab_description"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: "Please Enter Description!" },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={300}
                  name="first_tab_description"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={widgetData.first_tab_description}
                  rows={4}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "2 <sup>nd</sup> tab title",
                    }}
                  />
                }
                name="second_tab_title"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                rules={[{ required: true, message: "Please Enter Title!" }]}
              >
                <Input
                  size="large"
                  name="second_tab_title"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={widgetData.second_tab_title}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "2 <sup>nd</sup> tab description",
                    }}
                  />
                }
                name="second_tab_description"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: "Please Enter Description!" },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={300}
                  name="second_tab_description"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={widgetData.second_tab_description}
                  rows={4}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "3 <sup>rd</sup> tab title",
                    }}
                  />
                }
                name="third_tab_title"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                rules={[{ required: true, message: "Please Enter Title!" }]}
              >
                <Input
                  size="large"
                  name="third_tab_title"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={widgetData.third_tab_title}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "3 <sup>rd</sup> tab description",
                    }}
                  />
                }
                name="third_tab_description"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: "Please Enter Description!" },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={300}
                  name="third_tab_description"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={widgetData.third_tab_description}
                  rows={4}
                />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab={<span>Style Settings</span>} key="2">
            <Form name="basic" form={form} autoComplete="off">
              <Form.Item
                label="Title Text Color"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                rules={[{ required: true, message: "Please Select Color!" }]}
                name="title_text_color"
              >
                <Row>
                  <Col span={12}>
                    <Input
                      size="large"
                      placeholder="Enter Title Text Color"
                      value={bgColor(widgetData.title_text_color)}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      name="title_text_color"
                    />
                  </Col>
                  <Col span={2}>
                    <ColorPicker
                      value={widgetData.title_text_color}
                      onChange={(color) =>
                        handleChange("title_text_color", color.toHexString())
                      }
                      name="title_text_color"
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label="Description Text Color"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                rules={[{ required: true, message: "Please Select Color!" }]}
                name="description_text_color"
              >
                <Row>
                  <Col span={12}>
                    <Input
                      size="large"
                      placeholder="Enter Description Text color"
                      value={bgColor(widgetData.description_text_color)}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      name="description_text_color"
                    />
                  </Col>
                  <Col span={2}>
                    <ColorPicker
                      value={bgColor(widgetData.description_text_color)}
                      onChange={(color) =>
                        handleChange(
                          "description_text_color",
                          color.toHexString()
                        )
                      }
                      labelCol={{ span: 3 }}
                      name="description_text_color"
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label="Background Color"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                rules={[{ required: true, message: "Please Select Color!" }]}
                name="background_color"
              >
                <Row>
                  <Col span={12}>
                    <Input
                      size="large"
                      placeholder="Enter background color"
                      value={bgColor(widgetData.background_color)}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      name="background_color"
                    />
                  </Col>
                  <Col span={2}>
                    <ColorPicker
                      value={bgColor(widgetData.background_color)}
                      onChange={(color) =>
                        handleChange("background_color", color.toHexString())
                      }
                      labelCol={{ span: 3 }}
                      name="background_color"
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label="Popup Title Color"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                rules={[{ required: true, message: "Please Select Color!" }]}
                name="popup_title_color"
              >
                <Row>
                  <Col span={12}>
                    <Input
                      size="large"
                      placeholder="Popup Title Color"
                      value={bgColor(widgetData.popup_title_color)}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      name="popup_title_color"
                    />
                  </Col>
                  <Col span={2}>
                    <ColorPicker
                      value={bgColor(widgetData.popup_title_color)}
                      onChange={(color) =>
                        handleChange("popup_title_color", color.toHexString())
                      }
                      labelCol={{ span: 3 }}
                      name="popup_title_color"
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label="Popup Description Color"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                rules={[{ required: true, message: "Please Select Color!" }]}
                name="popup_description_color"
              >
                <Row>
                  <Col span={12}>
                    <Input
                      size="large"
                      placeholder="Popup Description Color"
                      value={bgColor(widgetData.popup_description_color)}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      name="popup_description_color"
                    />
                  </Col>
                  <Col span={2}>
                    <ColorPicker
                      value={bgColor(widgetData.popup_description_color)}
                      onChange={(color) =>
                        handleChange(
                          "popup_description_color",
                          color.toHexString()
                        )
                      }
                      labelCol={{ span: 3 }}
                      name="popup_description_color"
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
        <WidgetPreview widgetData={widgetData} />
      </div>
      <div className="save-btn">
        <button
          className="primary_btn btn"
          onClick={handleSave}
          type="primary"
          htmlType="submit"
        >
          Save
        </button>
      </div>
    </>
  );
};

export default WidgetComponents;
