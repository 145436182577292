import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance from "../../authServices/axiosInstance";
import { Cookies } from "react-cookie";

export const merchantSendBilling = createAsyncThunk(
  "send-billing-merchant",
  async (shopId) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const response = await AxiosInstance.post(
        `${API_URL}/merchant/billing/send-billing`,
        shopId,
        {
          headers: { "x-access-token": token },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const merchantSendBillingSlice = createSlice({
  name: "send-billing-merchant",
  initialState: {
    isLoading: false,
    merchantSendBilling: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [merchantSendBilling.pending]: (state) => {
      state.isLoading = true;
    },
    [merchantSendBilling.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.merchantSendBilling = action.payload;
    },
    [merchantSendBilling.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default merchantSendBillingSlice.reducer;
