import React from "react";
import MainDashboard from "../../components/dashboard/index";

const Dashboard = () => {
  return (
    <>
      <MainDashboard />
    </>
  );
};

export default Dashboard;
