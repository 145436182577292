import React, { useState } from "react";
import { ReactComponent as Edit } from "../../../../../assets/images/editIcons.svg";
import Protection from "./drawer/protection";
import Widget from "./drawer/widget";
// import BillingContact from "./drawer/billinngContact";
// import ClaimContact from "./drawer/claimContact";
import TrackLink from "./drawer/trackLink";
import { useNavigate } from "react-router-dom";
// import Reimbursement from "./drawer/reimbursement";
import Loading from "../../../../common/Loading/Loading";
import EditCommunication from "./Model/EditCommunication";

const SettingsTabPage = (props) => {
  const { settings, updateAndGetData, updateLoading, singleMerchantId, role } =
    props;
  const [protection, setProtection] = useState(false);
  const [widget, setWidget] = useState(false);
  // const [billingContact, setBillingContact] = useState(false);
  // const [claimContact, setClaimContact] = useState(false);
  const [trackLink, setTrackLink] = useState(false);
  // const [reimbursement, setReimbursement] = useState(false);
  const naviget = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editTitle, setEditTitle] = useState();

  const contactData = [
    {
      title: "BILLING CONTACT",
      email: settings?.billing_contact_email,
    },
    {
      title: "REIMBURSEMENTS CONTACT",
      email: settings?.reimbursement_contact_email,
    },
    {
      title: "CLAIMS CONTACT",
      email: settings?.claims_contact_email,
    },
  ];

  const handleClick = (title) => {
    // if (title === "BILLING CONTACT") {
    //   setBillingContact(true);
    // }
    // if (title === "CLAIMS CONTACT") {
    //   setClaimContact(true);
    // } else if (title === "REIMBURSEMENTS CONTACT") {
    //   setReimbursement(true);
    // }

    if (title === "BILLING CONTACT") {
      setIsModalOpen(true);
    }
    if (title === "CLAIMS CONTACT") {
      setIsModalOpen(true);
    } else if (title === "REIMBURSEMENTS CONTACT") {
      setIsModalOpen(true);
    }
    setEditTitle(title);
  };

  const handleClickWidget = () => {
    if (role === "merchant") {
      naviget("/dashboard/admin/configbar");
    } else if (role === "admin") {
      naviget(`/dashboard/storeDetail/${singleMerchantId}/configbar`);
    }
  };
  return (
    <>
      {updateLoading && <Loading type="overlay" className="loader-icon" />}
      <Protection
        onClose={() => setProtection(false)}
        visible={protection}
        data={settings}
        updateAndGetData={updateAndGetData}
      />
      <Widget
        onClose={() => setWidget(false)}
        visible={widget}
        data={settings}
        updateAndGetData={updateAndGetData}
      />
      <EditCommunication
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={settings}
        updateAndGetData={updateAndGetData}
        editTitle={editTitle}
      />
      {/* <BillingContact
        onClose={() => setBillingContact(false)}
        visible={billingContact}
        data={settings}
        updateAndGetData={updateAndGetData}
      />
      <ClaimContact
        onClose={() => setClaimContact(false)}
        visible={claimContact}
        data={settings}
        updateAndGetData={updateAndGetData}
      />
      <Reimbursement
        onClose={() => setReimbursement(false)}
        visible={reimbursement}
        data={settings}
        updateAndGetData={updateAndGetData}
      /> */}
      <TrackLink
        onClose={() => setTrackLink(false)}
        visible={trackLink}
        data={settings}
        updateAndGetData={updateAndGetData}
      />
      <div className="setting-main">
        <div className="setting-box">
          <div className="settings-box-title">Store Details</div>
          {[
            { title: "Platform", content: settings?.platform },
            { title: "Merchant Id", content: settings?._id },
            {
              title: "Public Token",
              content: "",
            },
            {
              title: "Secret Token",
              content: "",
            },
            {
              title: "Test-Secret Token",
              content: "",
            },
          ].map((item, index) => (
            <div className="store-details" key={index}>
              <div className="store-detail-title">{item.title}</div>
              <div className="store-detail-content">
                {item.content ? item.content : "--"}
              </div>
            </div>
          ))}
        </div>
        <div className="setting-box">
          <div className="settings-box-title">Merchant Communication</div>
          {contactData.map((contact, index) => (
            <div className="merchant-comm" key={index}>
              <div className="two-value-class">
                <div className="merchant-comm-title">{contact.title}</div>
                <div
                  className="edit-round"
                  onClick={() => handleClick(contact.title)}
                >
                  <Edit height={20} width={20} />
                </div>
              </div>
              {contact.email ? (
                <div className="two-value-class">
                  <div className="merchant-comm-title">Email</div>
                  <div className="merchant-email">
                    {contact.email ? contact.email : "--"}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
        <div className="setting-box">
          <div className="settings-box-title">Swipe Configuration</div>
          {[
            {
              title: "Swipe+ Package Protection",
              onClick: () => setProtection(true),
              text: settings?.route_plus_package_protection,
            },
            {
              title: "Protection Widget",
              onClick: () => setWidget(true),
              text: settings?.protection_widget,
            },
            {
              title: "Thank You Page Tracking Link",
              onClick: () => setTrackLink(true),
              text: settings?.thank_you_page_tracking_link,
            },
            {
              title: "Free Shipping & Protection Bar",
              onClick: () => handleClickWidget(),
              text: settings?.free_shipping_and_protection_bar,
            },
          ].map((item, index) => (
            <div className="store-details" key={index}>
              <div className="store-detail-title">{item.title}</div>
              <div className="swipe-configuration" onClick={item.onClick}>
                <div className="store-detail-content">{item.text}</div>
                <div className="edit-round">
                  <Edit height={20} width={20} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SettingsTabPage;
