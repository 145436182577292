import React, { useState, useEffect } from "react";
import { DatePicker, Modal, Select, Table, Tooltip } from "antd";
import moment from "moment/moment";
import { TbDownload } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { FaEye } from "react-icons/fa";
import Loading from "../../../../common/Loading/Loading";
import Notify from "../../../../common/Notify/notify";
import { monthOptions, yearOptions } from "../../../../../utils/commonUtils";
import DashboardHeaderComponent from "../../../common/dashboardHeader/dashboradHeader";
import { StatementsIn } from "../../../../../assets/images/sidebar";
import {
  dateConvert,
  rangePresets,
  toArrayBuffer,
} from "../../../../../utils/helper";
import DatePreset from "../../../../common/DatePreset/datePreset";
import dayjs from "dayjs";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AdminStatementPage = (props) => {
  const {
    adminStatementsList,
    callPreviewStatements,
    setFilter,
    merchantList,
    filter,
    callStatementOverview,
    statementOverview,
    statementLoading,
  } = props;
  const { RangePicker } = DatePicker;
  const { data } = adminStatementsList;
  const [pdfDataUrl, setPdfDataUrl] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState();
  const [renderNavButtons, setRenderNavButtons] = useState(false);
  const [visible, setVisible] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState({
    start_date: null,
    end_date: null,
  });
  const [isActive, setIsActive] = useState("");
  const [isDate, setIsDate] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);

  const handleDateSelect = (dates) => {
    if (dates === null) {
      setIsActive("");
      setSelectedRange(null);
      setIsDate(false);
      setSelectedDates({
        start_date: null,
        end_date: null,
      });
    } else if (dates && dates.length === 2) {
      const [start, end] = dates;
      setSelectedDates({
        start_date:
          start && dayjs(start).isValid()
            ? dayjs(start).format("YYYY-MM-DD")
            : null,
        end_date:
          end && dayjs(end).isValid() ? dayjs(end).format("YYYY-MM-DD") : null,
      });
      const preset = rangePresets.find(
        (p) =>
          p.value[0].isSame(dates[0], "day") &&
          p.value[1].isSame(dates[1], "day")
      );
      if (preset) {
        setIsActive(preset.label);
      } else {
        setIsActive("");
      }
    }
    setIsDate(false);
  };

  const handlePresetClick = (preset) => {
    setIsActive(preset.label);
    setSelectedRange(preset.value);
    handleDateSelect(preset.value);
    setIsDate(false);
  };

  const handleDownloadClick = async (exportUrl) => {
    try {
      const response = await fetch(exportUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "statement";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    if (
      selectedDates.start_date &&
      selectedDates.end_date &&
      filter.merchantId !== 0 &&
      filter.merchantId !== "all"
    ) {
      callStatementOverview({ ...selectedDates, merchant: filter.merchantId });
    }
  }, [selectedDates, filter, callStatementOverview]);

  const onSuccess = () => {
    setPageNumber(1);
    setRenderNavButtons(true);
  };

  const handlePreview = async (value) => {
    setPreviewLoading(true);
    const data = {
      merchantId: value.merchantId,
      value: value.statementMonth,
    };
    const response = await callPreviewStatements(data);
    if (response.type === "preview/fulfilled") {
      setPreviewLoading(false);
      setVisible(true);
      const arraydata2 = toArrayBuffer(response.payload.preview.data);
      setPdfDataUrl(arraydata2);
    } else {
      if (response.error && response.error.message) {
        setPreviewLoading(false);
        Notify("error", response.error.message, "");
      } else {
        Notify("error", "Something went wrong", "");
        setPreviewLoading(false);
      }
    }
  };
  const handleCloseModal = () => {
    setVisible(false);
  };
  const columns = [
    {
      title: "Merchant Name",
      dataIndex: "merchantName",
      key: "merchantName",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Merchant Name",
          },
        };
      },
    },
    {
      title: "Month",
      dataIndex: "statementMonth",
      key: "statementMonth",
      with: 60,
      render: (text) => {
        return {
          children: moment(text).format("MMM-YYYY"),
          props: {
            "data-label": "Month",
          },
        };
      },
    },
    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
      render: (text, record) => {
        return {
          children: (
            <span className="preview" onClick={() => handlePreview(record)}>
              <Tooltip title="Preview">
                <FaEye size={22} color="#1a2e4690" />
              </Tooltip>
            </span>
          ),
          props: {
            "data-label": "Preview",
          },
        };
      },
    },
    {
      title: "Download",
      dataIndex: "url",
      key: "url",
      render: (text, record) => {
        return {
          children: (
            <span
              style={{
                fontWeight: 600,
                textDecorationLine: "underline",
                fontSize: 14,
                color: "#000",
              }}
              onClick={() => handleDownloadClick(text)}
            >
              <Tooltip title="Download">
                <TbDownload size={24} color="#1a2e4690" />
              </Tooltip>
            </span>
          ),
          props: {
            "data-label": "Download",
          },
        };
      },
    },
  ];

  const overViewCredit = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      with: 60,
    },
    {
      title: "Order",
      dataIndex: "orderName",
      key: "orderName",
    },
    {
      title: "Claim ",
      dataIndex: "claimId",
      key: "claimId",
      render: (text) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          to={`/dashboard/claims/${text}`}
          style={{
            fontWeight: 700,
            color: "#000",
            textDecorationLine: "underline",
          }}
          target="_blank"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Credit Type",
      dataIndex: "credit_type",
      key: "credit_type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Created Date",
      dataIndex: "record_date",
      key: "record_date",
      render: (text) => dateConvert(text),
    },
  ];

  const overViewUsage = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      with: 60,
    },
    {
      title: "Order",
      dataIndex: "orderName",
      key: "orderName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Created Date",
      dataIndex: "record_date",
      key: "record_date",
      render: (text) => dateConvert(text),
    },
  ];

  const optionMerchant = Array.isArray(merchantList?.data?.response)
    ? merchantList.data?.response.map((item) => ({
        value: item._id,
        label: item.name,
      }))
    : [];

  const merchantOptions = [{ value: "all", label: "All" }, ...optionMerchant];

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  const adminStatements =
    Array.isArray(data) &&
    data?.map((item, index) => ({
      key: (index + 1).toString(),
      _id: item._id,
      statementMonth: item.statement_month,
      merchantName: item.merchant.name,
      merchantId: item.merchant._id,
      url: item.url,
    }));

  const overViewCreditData =
    Array.isArray(statementOverview?.data.credits) &&
    statementOverview?.data.credits.map((item, index) => ({
      key: (index + 1).toString(),
      credit_type: item.credit_type,
      type: item.type,
      amount: item.amount,
      record_date: item.record_date,
      createdAt: item.createdAt,
      claimId: item.claim,
      orderName: item.order_name,
    }));

  const overViewUsageData =
    Array.isArray(statementOverview?.data.usages) &&
    statementOverview?.data.usages.map((item, index) => ({
      key: (index + 1).toString(),
      type: item.type,
      amount: item.amount,
      record_date: item.record_date,
      createdAt: item.createdAt,
      orderName: item.order_name,
    }));

  return (
    <>
      {previewLoading && <Loading type="overlay" className="loader-icon" />}
      <div className="onBoard-main">
        <div className="onBoard-container">
          <DashboardHeaderComponent title="Finance" Logo={StatementsIn} />
          <div className="onBoard-main-section">
            <div className="filters">
              <Select
                size="large"
                showSearch
                placeholder="Select a merchant"
                onChange={(e) =>
                  setFilter((prev) => ({ ...prev, merchantId: e }))
                }
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={merchantOptions}
                style={{ width: 240 }}
              />
              {/* <RangePicker
                onChange={handleDateSelect}
                format="YYYY-MM-D"
                presets={rangePresets}
              /> */}
              <RangePicker
                onClick={() => setIsDate(true)}
                open={isDate}
                onOpenChange={(open) => {
                  if (!open) setIsDate(open);
                }}
                renderExtraFooter={() => (
                  <DatePreset
                    isActive={isActive}
                    setIsActive={setIsActive}
                    selectedRange={selectedRange}
                    setSelectedRange={setSelectedRange}
                    handlePresetClick={handlePresetClick}
                  />
                )}
                onChange={handleDateSelect}
                format="YYYY-MM-DD"
                value={[
                  selectedDates?.start_date
                    ? dayjs(selectedDates.start_date)
                    : null,
                  selectedDates?.end_date
                    ? dayjs(selectedDates.end_date)
                    : null,
                ]}
              />

              <Select
                size="large"
                placeholder="Select Month"
                onChange={(e) => setFilter((prev) => ({ ...prev, month: e }))}
                options={monthOptions}
                style={{ width: 240 }}
              />
              <Select
                size="large"
                placeholder="Select Year"
                onChange={(e) => setFilter((prev) => ({ ...prev, year: e }))}
                options={yearOptions}
                style={{ width: 240 }}
              />
            </div>
            <div className="onBoard-body">
              {selectedDates.start_date &&
                selectedDates.end_date &&
                filter.merchantId !== 0 &&
                filter.merchantId !== "all" && (
                  <div className="onBoard-box gap-box">
                    {statementLoading && (
                      <Loading type="overlay" className="loader-icon" />
                    )}
                    <div className="overview-statement">
                      <div className="overview-title">Finance Overview</div>
                      <div className="overview-data">
                        <p>
                          Fees Collected:{" "}
                          <span>
                            {statementOverview?.data.fees_collected
                              ? statementOverview?.data.fees_collected
                              : "N/A"}
                          </span>
                        </p>
                        <p>
                          Total Credits:{" "}
                          <span>
                            {statementOverview?.data.total_credit
                              ? statementOverview?.data.total_credit
                              : "N/A"}
                          </span>
                        </p>
                        <p>
                          Total Payable:{" "}
                          <span>
                            {statementOverview?.data.total_payable
                              ? statementOverview?.data.total_payable
                              : "N/A"}
                          </span>
                        </p>
                        <p>
                          Shopify Charge:{" "}
                          <span>
                            {statementOverview?.data.shopify_charge
                              ? statementOverview?.data.shopify_charge
                              : "N/A"}
                          </span>
                        </p>
                        <p>
                          Net Payable:{" "}
                          <span>
                            {statementOverview?.data.net_payable
                              ? statementOverview?.data.net_payable
                              : "N/A"}
                          </span>
                        </p>
                      </div>
                      <div className="credit-table">
                        <div className="table-title">Credits</div>
                        <Table
                          columns={overViewCredit}
                          dataSource={overViewCreditData}
                          pagination={false}
                          locale={{ emptyText: "No credit found" }}
                        />
                      </div>
                      <div className="usage-table">
                        <div className="table-title">Usage</div>
                        <Table
                          columns={overViewUsage}
                          dataSource={overViewUsageData}
                          pagination={true}
                          locale={{ emptyText: "No usage found" }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              <div className="onBoard-box">
                <div>
                  <Table
                    columns={columns}
                    dataSource={adminStatements}
                    pagination={false}
                    locale={{ emptyText: "No invoice found" }}
                  />
                </div>
              </div>
            </div>
            <Modal
              centered
              open={visible}
              onCancel={handleCloseModal}
              footer={
                renderNavButtons && (
                  <div>
                    <button
                      className="primary_btn btn"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    >
                      Previous Page
                    </button>
                    {"  "}
                    <button
                      className="primary_btn btn"
                      disabled={pageNumber === numPages}
                      onClick={nextPage}
                    >
                      Next Page
                    </button>
                  </div>
                )
              }
              width={900}
              bodyStyle={{ height: 800, overflow: "scroll" }}
            >
              {pdfDataUrl && (
                <div>
                  <Document
                    file={pdfDataUrl}
                    onLoadSuccess={({ numPages }) => {
                      setNumPages(numPages);
                      setRenderNavButtons(true);
                      onSuccess();
                    }}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>
              )}
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminStatementPage;
