import React, { useState,useEffect }  from 'react';
import RightDrawer from '../../../../common/drawer/drawer';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import {useLocation } from "react-router-dom";
import { optionAge, optionCategory, optionGender } from '../../../../../utils/commonUtils';

const MerchantCategory = ({
  onClose,
  open,
  handleSave,
  formData,
  setFormData
}) => {
  const [redirectModal,setRedirectModal] = useState(false)
  const { t } = useTranslation();
  const location = useLocation();

  const handleSelectionChange = (selectedValues, category) => {
    setFormData({
      ...formData,
      [category]: selectedValues
    });
  };

  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("modal");
    if(tabParam === "true"){
      setRedirectModal(true)
    }else{
      setRedirectModal(false)
    }
  },[location])

  return (
    <RightDrawer
      title="Merchant Categories"
      onClose={onClose}
      visible={open || redirectModal}
      buttonTitle="Save"
      onSubmit={() => handleSave(1, false)}
      width={500}
    >
      <div className="merchant-category-drawer">
        <p className="info">{t('MERCHANT_CAT_STR_1')}</p>
        <div className="category-type-box">
          <p className="title">Categories</p>
          <p className="description">{t('MERCHANT_CAT_STR_2')}</p>
          <div className="checkbox-container">
            <Checkbox.Group
              options={optionCategory}
              onChange={(selectedValues) =>
                handleSelectionChange(selectedValues, 'merchant_categories')
              }
              value={formData?.merchant_categories}
              name="merchant_categories"
              className="merchant-checkbox-group"
            />
          </div>
        </div>
        <div className="category-type-box">
          <p className="title">Gender</p>
          <p className="description">{t('MERCHANT_CAT_STR_3')}</p>
          <div className="checkbox-container">
            <Checkbox.Group
              options={optionGender}
              onChange={(selectedValues) =>
                handleSelectionChange(
                  selectedValues,
                  'merchant_categories_gender'
                )
              }
              value={formData?.merchant_categories_gender}
              name="merchant_categories_gender"
              className="merchant-checkbox-group"
            />
          </div>
        </div>
        <div className="category-type-box">
          <p className="title">Age</p>
          <p className="description">{t('MERCHANT_CAT_STR_4')}</p>
          <div className="checkbox-container">
            <Checkbox.Group
              options={optionAge}
              onChange={(selectedValues) =>
                handleSelectionChange(selectedValues, 'merchant_categories_age')
              }
              value={formData?.merchant_categories_age}
              className="merchant_categories_age"
            />
          </div>
        </div>
      </div>
    </RightDrawer>
  );
};

export default MerchantCategory;
