import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance  from "../../authServices/axiosInstance";

export const exportOrderList = createAsyncThunk("exportOrderList",async ({ payload}) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const role = cookies.get("role");
    let url = `${API_URL}/${role}/order/export?`;
    const queryParams = [];

    if (
      payload?.protect !== undefined &&
      payload?.protect !== null &&
      payload?.protect !== false
    ) {
      queryParams.push(`is_protected=${encodeURIComponent(payload.protect)}`);
    }
    if (payload?.id) {
      queryParams.push(`merchant=${encodeURIComponent(payload.id)}`);
    }

    if (payload?.date.start_date && payload?.date.end_date) {
      queryParams.push(
        `start_date=${encodeURIComponent(payload.date.start_date)}`
      );
      queryParams.push(
        `end_date=${encodeURIComponent(payload.date.end_date)}`
      );
    }
    if (payload?.search) {
      queryParams.push(`search=${encodeURIComponent(payload.search)}`);
    }
    
    if (queryParams.length > 0) {
      url += "&" + queryParams.join("&");
    }
    const response = await AxiosInstance.get(url, {
      headers: { "x-access-token": token },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
});

const exportOrderListSlice = createSlice({
  name: "exportOrderList",
  initialState: {
    isLoading: false,
    exportOrderList: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [exportOrderList.pending]: (state) => {
      state.isLoading = true;
    },
    [exportOrderList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.exportOrderList = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [exportOrderList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.claimList = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default exportOrderListSlice.reducer;
