import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, Modal, Table, Tooltip } from "antd";
import { Cookies } from "react-cookie";
import { AiOutlineInfoCircle } from "react-icons/ai";
import EditUser from "./editUser/editUser";
import Notify from "../../../common/Notify/notify";
import Loading from "../../../common/Loading/Loading";
import { ReactComponent as Edit } from "../../../../assets/images/edit.svg";
import { ReactComponent as Password } from "../../../../assets/images/password.svg";
import { ReactComponent as Delete } from "../../../../assets/images/delete.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteUser } from "../../../../assets/images/deleteUserPopup.svg";

const UsersPage = (props) => {
  const {
    users,
    handleCreate,
    handleCheckboxChange,
    handleInputChange,
    displayName,
    email,
    password,
    permissions,
    isModalOpen,
    setIsModalOpen,
    formErrors,
    handleCancel,
    callEditUserDetails,
    editUserDetails,
    callEditUser,
    callGetUsers,
    callDeleteUser,
    callResetPasswordLink,
    deleteUserLoading,
    editUserLoading,
  } = props;
  const { t } = useTranslation();
  const id = editUserDetails?.editUserDetail?.data._id;
  const cookies = new Cookies();
  const token = cookies.get("token");

  const [onDeleteConfirmation, setOnDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");

  const handleDeleteIconClick = (id) => {
    setDeleteUserId(id);
    setOnDeleteConfirmation(true);
  };

  const handleDeleteUser = async () => {
    const response = await callDeleteUser(id);
    if (response.type === "delete/fulfilled") {
      Notify("success", response.payload.message, "");
      callGetUsers();
      setEditModel(false);
      setOnDeleteConfirmation(false);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };

  const handleConfirmDeleteUser = async () => {
    const response = await callDeleteUser(deleteUserId);
    if (response.type === "delete/fulfilled") {
      Notify("success", response.payload.message, "");
      callGetUsers();
      setEditModel(false);
      setOnDeleteConfirmation(false);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };

  const permissionStatic = ["billing", "claims", "dashboard", "users"];
  const permissionNames = {
    billing: "Billing",
    claims: "Claims",
    dashboard: "Dashboard",
    users: "Users",
  };

  const sendResetPasswordLink = async (userEmail) => {
    const email = editUserDetails.editUserDetail?.data.email;
    const response = await callResetPasswordLink(email || userEmail);
    if (response.type === "send-reset-password/fulfilled") {
      Notify("success", response.payload.message, "");
      setEditModel(false);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };
  const [editModel, setEditModel] = useState(false);
  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "No",
          },
        };
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text) => {
        return {
          children: (
            <div className="user-table-fl">
              <div className="user-logo">
                {text
                  .split(/\s/)
                  .slice(0, 2)
                  .map((word) => word.slice(0, 1))
                  .join("")}
              </div>
              <div className="user-name-text">{text}</div>
            </div>
          ),
          props: {
            "data-label": "User",
          },
        };
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => {
        return {
          children: text,
          props: {
            "data-label": "Email",
          },
        };
      },
    },
    ...permissionStatic.map((permission) => ({
      title: permissionNames[permission],
      dataIndex: "permissions",
      key: permission,
      render: (permissions) => {
        return {
          children: (
            <Checkbox
              checked={permissions.includes(permission)}
              disabled={true}
            ></Checkbox>
          ),
          props: {
            "data-label": permissionNames[permission],
          },
        };
      },
    })),
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return {
          children: (
            <div className="user-action-row">
              <Tooltip title="Edit user">
                <div onClick={() => handleRowClick(record.id)}>
                  <Edit size={22} />
                </div>
              </Tooltip>
              <div>
                <Tooltip title="Please provide a reset password link upon clicking the designated icon.">
                  <Password
                    size={21}
                    onClick={() => sendResetPasswordLink(record.email)}
                  />
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Delete user">
                  <Delete
                    size={22}
                    onClick={() => handleDeleteIconClick(record.id)}
                  />
                </Tooltip>
              </div>
            </div>
          ),
          props: {
            "data-label": "Actions",
          },
        };
      },
    },
  ];


  const data =
    Array.isArray(users?.response) &&
    users?.response.map((user, index) => ({
      key: (
        <div className="table-header_orderID">
          <div className="table-header_orderID-svg"></div>
          {(index + 1).toString()}
        </div>
      ),
      user: user.display_name,
      email: user.email,
      id: user._id,
      permissions: user.permissions,
    }));

  const handleRowClick = async (record) => {
    await callEditUserDetails(record);
    setEditModel(true);
  };

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (currentPage !== null) {
      callGetUsers(currentPage);
    }
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {deleteUserLoading || editUserLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : null}
      <div className="user-main-section">
        <div className="user-head">
          <div className="user-title">
            <div className="user-right">
              <button
                className="primary_btn btn"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                }}
              >
                Create New User
              </button>
            </div>
          </div>
        </div>
        <div className="user-body">
          <div className="user-box">
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                current: currentPage,
                pageSize: 25,
                total: users?.totalRecords,
                onChange: handlePageChange,
              }}
              style={{ cursor: "pointer" }}
              locale={{ emptyText: "No user found" }}
            />
          </div>
        </div>
        <Modal
          open={isModalOpen}
          centered
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={handleCancel}
        >
          <div className="onBoard-Model">
            <div className="onBoard-title">
              <span>New User</span>
            </div>
            <div className="user-modal-body">
              <Input
                name="displayName"
                placeholder="Display Name"
                value={displayName}
                onChange={handleInputChange}
              />
              {formErrors.displayName && (
                <div className="field-error">{formErrors.displayName}</div>
              )}
              <Input
                name="email"
                placeholder="Email"
                type="email"
                value={email}
                onChange={handleInputChange}
                suffix={
                  <Tooltip title="Uppercase is not allowed in email">
                    <AiOutlineInfoCircle color="#bcbcbc" />
                  </Tooltip>
                }
              />
              {formErrors.email && (
                <div className="field-error">{formErrors.email}</div>
              )}
              <Input.Password
                name="password"
                placeholder="Password"
                type="password"
                value={password}
                onChange={handleInputChange}
              />
              {formErrors.password && (
                <div className="field-error">{formErrors.password}</div>
              )}
            </div>
            <div className="user-modal-permission">
              <div className="user-modal-permission-title">
                <span>Permissions</span>
              </div>
              <div className="user-modal-permission-option">
                <Checkbox
                  value="account_manager"
                  checked={permissions.includes("account_manager")}
                  onChange={() => handleCheckboxChange("account_manager")}
                >
                  Account Management
                </Checkbox>
                <Checkbox
                  value="billing"
                  checked={permissions.includes("billing")}
                  onChange={() => handleCheckboxChange("billing")}
                >
                  Billing
                </Checkbox>
                <Checkbox
                  value="claims"
                  checked={permissions.includes("claims")}
                  onChange={() => handleCheckboxChange("claims")}
                >
                  Claims
                </Checkbox>
                <Checkbox
                  value="dashboard"
                  checked={permissions.includes("dashboard")}
                  onChange={() => handleCheckboxChange("dashboard")}
                >
                  Dashboard
                </Checkbox>
                <Checkbox
                  value="users"
                  checked={permissions.includes("users")}
                  onChange={() => handleCheckboxChange("users")}
                >
                  Users
                </Checkbox>
              </div>
            </div>
            <div className="onBoard-button">
              <button className="primary_btn btn" onClick={handleCreate}>
                Create
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        centered
        open={onDeleteConfirmation}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setOnDeleteConfirmation(false)}
        className="approve-popup"
      >
        <div className="user-delete-confirm-model">
          <div className="image-container">
            <DeleteUser />
          </div>

          <div className="user-delete-text">{t("DEL_USER")}</div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={() => setOnDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => handleConfirmDeleteUser()}
              disabled={deleteUserLoading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      {editModel && (
        <EditUser
          isOpen={editModel}
          onClose={() => setEditModel(false)}
          editUserDetails={editUserDetails}
          callEditUser={callEditUser}
          callGetUsers={callGetUsers}
          setEditModel={setEditModel}
          callDeleteUser={callDeleteUser}
          callResetPasswordLink={callResetPasswordLink}
          sendResetPasswordLink={sendResetPasswordLink}
          handleDeleteUser={handleDeleteUser}
          editUserLoading={editUserLoading}
        />
      )}
    </>
  );
};

export default UsersPage;
