import React, { useState } from "react";
import RightDrawer from "../../../../../common/drawer/drawer";
import { Divider, List, Radio } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Protection = (props) => {
  const { onClose, visible, data, updateAndGetData } = props;
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("enable");

  const check =
    data.route_plus_package_protection === "on" ? "enable" : "disable";
  useEffect(() => {
    setSelectedOption(check);
  }, [data.route_plus_package_protection, check]);

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleCancel = () => {
    setSelectedOption(check);
    onClose();
  };

  const handleSave = () => {
    const payload = {
      route_plus_package_protection: selectedOption === "enable" ? "on" : "off",
    };
    updateAndGetData(payload);
    onClose();
  };

  const dataStatic = [
    t("PROTECT_DRW_STR_1"),
    t("PROTECT_DRW_STR_2"),
    t("PROTECT_DRW_STR_3"),
  ];

  return (
    <>
      <RightDrawer
        title="Swipe Protection"
        onClose={handleCancel}
        visible={visible}
        buttonTitle="Save"
        onSubmit={() => handleSave()}
      >
        <div className="swipe-protection-drawer">
          <List
            size="small"
            dataSource={dataStatic}
            renderItem={(item) => (
              <List.Item style={{ fontSize: 13 }}>{item}</List.Item>
            )}
          />
          <Divider style={{ color: "red" }} />
          <div className="option">
            <Radio
              className="checkbox"
              value="enable"
              onChange={handleRadioChange}
              checked={selectedOption === "enable"}
            >
              Enable Swipe Plus
            </Radio>
            <Radio
              className="checkbox"
              value="disable"
              onChange={handleRadioChange}
              checked={selectedOption === "disable"}
            >
              Disable Swipe Plus
            </Radio>
          </div>
          <Divider />
        </div>
      </RightDrawer>
    </>
  );
};

export default Protection;
