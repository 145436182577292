import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UsersPage from "./usersPage";
import { Cookies } from "react-cookie";
import { getUsers } from "../../../../redux/slice/getUsersSlice";
import Loading from "../../../common/Loading/Loading";
import { addUser } from "../../../../redux/slice/addUserSlice";
import Notify from "../../../common/Notify/notify";
import { editUserDetails } from "../../../../redux/slice/getUserDetailsSlice";
import { editUser } from "../../../../redux/slice/editUserSlice";
import { deleteUser } from "../../../../redux/slice/deleteuserSlice";
import { resetPasswordLink } from "../../../../redux/slice/resetPasswordLinkSlice";
import { validEmail, validPassword } from "../../../../utils/regexs";

const Users = (props) => {
  const {
    callGetUsers,
    users,
    callAddUser,
    callEditUserDetails,
    editUserDetails,
    callEditUser,
    callDeleteUser,
    callResetPasswordLink,
    deleteUser,
    editUser,
  } = props;
  const cookies = new Cookies();
  const token = cookies.get("token");
  const role = cookies.get("role");
  // useEffect(() => {
  //   callGetUsers();
  // }, [callGetUsers, token]);
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    const { name, value } = e.target;
    if (name === "displayName") {
      setDisplayName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  const handleCheckboxChange = (value) => {
    const updatedPermissions = permissions.includes(value)
      ? permissions.filter((permission) => permission !== value)
      : [...permissions, value];
    setPermissions(updatedPermissions);
  };
  const handleCreate = async () => {
    const errors = {};

    if (!displayName) {
      errors.displayName = "Name is required";
    }
    if (!validEmail.test(email)) {
      if (!email) {
        errors.email = "Email is required";
      } else {
        errors.email = "Please Enter Valid Email";
      }
    }
    if (!validPassword.test(password)) {
      if (!password) {
        errors.password = "Password is required";
      } else {
        errors.password = "Please create strong password";
      }
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const userData = {
        display_name: displayName,
        email,
        password,
        role,
        permissions,
      };
      const response = await callAddUser(userData);
      if (response.type === "users/fulfilled") {
        setIsModalOpen(false);
        setDisplayName("");
        setEmail("");
        setPassword("");
        setPermissions("");
        callGetUsers();
        Notify("success", response.payload.message, "");
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
        }
      }
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setDisplayName("");
    setEmail("");
    setPassword("");
    setPermissions([]);
    setFormErrors("");
  };
  return (
    <div>
      {users.isLoading && <Loading type="overlay" className="loader-icon" />}
      <UsersPage
        users={users?.allUsers?.data}
        handleInputChange={handleInputChange}
        handleCheckboxChange={handleCheckboxChange}
        handleCreate={handleCreate}
        displayName={displayName}
        email={email}
        password={password}
        permissions={permissions}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        formErrors={formErrors}
        handleCancel={handleCancel}
        callEditUserDetails={callEditUserDetails}
        editUserDetails={editUserDetails}
        callEditUser={callEditUser}
        callGetUsers={callGetUsers}
        callDeleteUser={callDeleteUser}
        callResetPasswordLink={callResetPasswordLink}
        deleteUserLoading={deleteUser.isLoading}
        editUserLoading={editUser.isLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    addUserResponse: state.addUser,
    editUserDetails: state.editUserDetails,
    deleteUser: state.deleteUser,
    editUser: state.editUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetUsers: (props) => dispatch(getUsers(props)),
    callAddUser: (...props) => dispatch(addUser(...props)),
    callEditUserDetails: (...props) => dispatch(editUserDetails(...props)),
    callEditUser: (...props) => dispatch(editUser(...props)),
    callDeleteUser: (...props) => dispatch(deleteUser(...props)),
    callResetPasswordLink: (...props) => dispatch(resetPasswordLink(...props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
