import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { editUserDetails } from "../../../../../../../redux/slice/getUserDetailsSlice";
import { resetPasswordLink } from "../../../../../../../redux/slice/resetPasswordLinkSlice";
import UserTableComponent from "./useraTable";
import Notify from "../../../../../../common/Notify/notify";
import { addMerchantUser } from "../../../../../../../redux/slice/addMerchantUserSlice";
import { editMerchantUser } from "../../../../../../../redux/slice/editMerchantUserSlice";
import { deleteMerchantUser } from "../../../../../../../redux/slice/deleteMerchantUserSlice";
import { adminStoreDetails } from "../../../../../../../redux/slice/adminStoreDetailsSlice";
import { validEmail, validPassword } from "../../../../../../../utils/regexs";

const Users = (props) => {
  const {
    callAdminStoreDetails,
    users,
    callAddUser,
    callEditUserDetails,
    editUserDetails,
    callEditUser,
    callDeleteUser,
    callResetPasswordLink,
    addMerchantUser,
    deleteMerchantUser,
  } = props;

  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const handleInputChange = (e) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    const { name, value } = e.target;
    if (name === "displayName") {
      setDisplayName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  const handleCheckboxChange = (value) => {
    const updatedPermissions = permissions.includes(value)
      ? permissions.filter((permission) => permission !== value)
      : [...permissions, value];
    setPermissions(updatedPermissions);
  };
  const { id } = useParams();
  const handleCreate = async () => {
    const errors = {};

    if (!displayName) {
      errors.displayName = "Name is required";
    }
    if (!validEmail.test(email)) {
      if (!email) {
        errors.email = "Email is required";
      } else {
        errors.email = "Please Enter Valid Email";
      }
    }
    if (!validPassword.test(password)) {
      if (!password) {
        errors.password = "Password is required";
      } else {
        errors.password = "Please Enter Valid Password";
      }
    }

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const userData = {
        display_name: displayName,
        email,
        password,
        merchant: id,
        role: "merchant",
        permissions,
      };
      const response = await callAddUser(userData);
      if (response.type === "addMerchantUser/fulfilled") {
        setIsModalOpen(false);
        setDisplayName("");
        setEmail("");
        setPassword("");
        setPermissions("");
        callAdminStoreDetails(id);
        Notify("success", response.payload.message, "");
      } else {
        if (response.error && response.error.message) {
          Notify("error", response.error.message, "");
        }
      }
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setDisplayName("");
    setEmail("");
    setPassword("");
    setPermissions([]);
    setFormErrors("");
  };
  return (
    <div>
      <UserTableComponent
        users={users?.data?.user}
        handleInputChange={handleInputChange}
        handleCheckboxChange={handleCheckboxChange}
        handleCreate={handleCreate}
        displayName={displayName}
        email={email}
        password={password}
        permissions={permissions}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        formErrors={formErrors}
        handleCancel={handleCancel}
        callEditUserDetails={callEditUserDetails}
        editUserDetails={editUserDetails}
        callEditUser={callEditUser}
        callAdminStoreDetails={callAdminStoreDetails}
        callDeleteUser={callDeleteUser}
        callResetPasswordLink={callResetPasswordLink}
        merchantId={id}
        addMerchantUserLoading={addMerchantUser.isLoading}
        deleteMerchantUserLoading={deleteMerchantUser.isLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addUserResponse: state.addUser,
    editUserDetails: state.editUserDetails,
    addMerchantUser: state.addMerchantUser,
    deleteMerchantUser: state.deleteMerchantUser
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callAdminStoreDetails: (props) => dispatch(adminStoreDetails(props)),
    callAddUser: (...props) => dispatch(addMerchantUser(...props)),
    callEditUserDetails: (...props) => dispatch(editUserDetails(...props)),
    callEditUser: (...props) => dispatch(editMerchantUser(...props)),
    callDeleteUser: (...props) => dispatch(deleteMerchantUser(...props)),
    callResetPasswordLink: (...props) => dispatch(resetPasswordLink(...props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
