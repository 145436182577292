import React, { useState } from "react";
import { Modal, Select, Table, Tooltip } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { FaEye } from "react-icons/fa";
import { TbDownload } from "react-icons/tb";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Notify from "../../../../common/Notify/notify";
import Loading from "../../../../common/Loading/Loading";
import { monthOptions, yearOptions } from "../../../../../utils/commonUtils";
import { toArrayBuffer } from "../../../../../utils/helper";
import moment from "moment";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StatementsPage = (props) => {
  const { statements, merchantId, callPreviewStatements, setFilter } = props;
  const [pdfDataUrl, setPdfDataUrl] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState();
  const [renderNavButtons, setRenderNavButtons] = useState(false);
  const [visible, setVisible] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const { data } = statements;
  const onSuccess = () => {
    setPageNumber(1);
    setRenderNavButtons(true);
  };

  const handlePreview = async (value) => {
    setPreviewLoading(true);
    const data = {
      merchantId,
      value,
    };
    const response = await callPreviewStatements(data);
    if (response.type === "preview/fulfilled") {
      setPreviewLoading(false);
      setVisible(true);
      const arraydata2 = toArrayBuffer(response.payload.preview.data);
      setPdfDataUrl(arraydata2);
    } else {
      if (response.error && response.error.message) {
        setPreviewLoading(false);
        Notify("error", response.error.message, "");
      } else {
        Notify("error", "Something went wrong", "");
        setPreviewLoading(false);
      }
    }
  };
  const handleCloseModal = () => {
    setVisible(false);
  };
  const columns = [
    {
      title: "Month",
      dataIndex: "statementMonth",
      key: "statementMonth",
      render: (text) => {
        return {
          children: moment(text).format('MMM-YYYY'),
          props: {
            "data-label": "Month",
          },
        };
      },
    },
    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
      render: (text, record) => {
        return {
          children: (
            <span onClick={() => handlePreview(record.statementMonth)}>
              <Tooltip title="Preview">
                <FaEye size={22} color="#1a2e4690" />
              </Tooltip>
            </span>
          ),
          props: {
            "data-label": "Preview",
          },
        };
      },
    },
    {
      title: "Download",
      dataIndex: "url",
      key: "url",
      render: (text, record) => {
        return {
          children: (
            <a
              href={text}
              download="document.pdf"
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 600,
                textDecorationLine: "underline",
                fontSize: 14,
                color: "#000",
              }}
            >
              <Tooltip title="Download">
                <TbDownload size={24} color="#1a2e4690" />
              </Tooltip>
            </a>
          ),
          props: {
            "data-label": "Download",
          },
        };
      },
    },
  ];
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  const merchantStatements =
    Array.isArray(data) &&
    data?.map((item, index) => ({
      key: (index + 1).toString(),
      _id: item._id,
      statementMonth: item.statement_month,
      url: item.url,
    }));

  return (
    <>
      {previewLoading && <Loading type="overlay" className="loader-icon" />}
      <div className="onBoard-main-section">
        <div className="statement-well-back">
          <div className="filters">
            <Select
              size="large"
              placeholder="Select Month"
              onChange={(e) => setFilter((prev) => ({ ...prev, month: e }))}
              options={monthOptions}
              style={{ width: 240 }}
            />
            <Select
              size="large"
              placeholder="Select Year"
              onChange={(e) => setFilter((prev) => ({ ...prev, year: e }))}
              options={yearOptions}
              style={{ width: 240 }}
            />
          </div>
        </div>
        <div className="onBoard-body">
          <div className="onBoard-box">
            <div className="statement-body-desc">
              <Table
                columns={columns}
                dataSource={merchantStatements}
                pagination={false}
                locale={{ emptyText: "No invoice found" }}
              />
            </div>
          </div>
        </div>
        <Modal
          centered
          open={visible}
          onCancel={handleCloseModal}
          footer={
            renderNavButtons && (
              <div>
                <button
                  className="primary_btn btn"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Previous Page
                </button>
                <button
                  className="primary_btn btn"
                  disabled={pageNumber === numPages}
                  onClick={nextPage}
                >
                  Next Page
                </button>
              </div>
            )
          }
          width={900}
          bodyStyle={{ height: 800, overflow: "scroll" }}
        >
          {pdfDataUrl && (
            <div>
              <Document
                file={pdfDataUrl}
                onLoadSuccess={({ numPages }) => {
                  setNumPages(numPages);
                  setRenderNavButtons(true);
                  onSuccess();
                }}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default StatementsPage;
