import React, { useEffect, useState } from "react";
import DetailsPage from "./details";
import { connect } from "react-redux";
import { getMerchantClaimDetails } from "../../../../../../redux/slice/getMerchantClaimDetailsSlice";
import { useParams } from "react-router-dom";
import Loading from "../../../../../common/Loading/Loading";
import { addEvents } from "../../../../../../redux/slice/addEventsSlice";
import { changeClaimStatus } from "../../../../../../redux/slice/changeClaimStatusSlice";
import { onApproved } from "../../../../../../redux/slice/approvedSlice";
import ModalMessage from "../../../../../common/modalMessage/messageModal";
import { addNote } from "../../../../../../redux/slice/addNoteSlice";
import Notify from "../../../../../common/Notify/notify";
import { getNotes } from "../../../../../../redux/slice/getNotesSlice";

const Details = (props) => {
  const {
    getMerchantClaimDetails,
    ClaimDetails,
    callAddEvents,
    callChangeClaimStatus,
    callOnApproved,
    onApproved,
    claimEvents,
    callAddNote,
    callGetNotes,
    addNote,
    notes,
  } = props;
  const { id } = useParams();

  const apiCalledRef = React.useRef(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    if (!apiCalledRef.current && id) {
      getMerchantClaimDetails(id);
      apiCalledRef.current = true;
    }
    callGetNotes(id);
  }, [id, getMerchantClaimDetails, callGetNotes]);

  const handleNoteChange = (e) => {
    const value = e.target.value;
    setNote(value);
  };
  const handleAddNote = async () => {
    if (note.trim() === "") {
      return;
    }
    const merchantId = ClaimDetails?.details?.Claims?.merchant._id;
    const payload = {
      merchant: merchantId,
      claim: id,
      content: note,
    };
    const response = await callAddNote(payload);
    if (response.type === "comment/fulfilled") {
      Notify("success", response.payload.message, "");
      await callGetNotes(id);
      setNote("");
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
        setNote("");
      }
    }
  };
  return (
    <div>
      {ClaimDetails.isLoading || addNote.isLoading || notes.isLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : ClaimDetails?.errorMessage ||
        ClaimDetails?.details?.message === "No Records found" ? (
        <ModalMessage
          message={ClaimDetails?.errorMessage || ClaimDetails?.details?.message}
          buttonTitle="Claims"
          route="/dashboard/claims"
        />
      ) : (
        ClaimDetails.details && (
          <DetailsPage
            ClaimDetails={ClaimDetails.details}
            callAddEvents={callAddEvents}
            callChangeClaimStatus={callChangeClaimStatus}
            getMerchantClaimDetails={getMerchantClaimDetails}
            callOnApproved={callOnApproved}
            onApprovedLoading={onApproved.isLoading}
            claimEventsLoading={claimEvents.isLoading}
            note={note}
            handleNoteChange={handleNoteChange}
            handleAddNote={handleAddNote}
            notes={notes}
          />
        )
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ClaimDetails: state.merchantClaimDetail,
    onApproved: state.onApproved,
    claimEvents: state.claimEvents,
    notes: state.notes,
    addNote: state.addNote,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMerchantClaimDetails: (id) => dispatch(getMerchantClaimDetails(id)),
    callAddEvents: (data) => dispatch(addEvents(data)),
    callChangeClaimStatus: (...props) => dispatch(changeClaimStatus(...props)),
    callOnApproved: (...props) => dispatch(onApproved(...props)),
    callAddNote: (props) => dispatch(addNote(props)),
    callGetNotes: (props) => dispatch(getNotes(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
