import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const addNote = createAsyncThunk("comment", async (data) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const role = cookies.get("role");
    const response = await AxiosInstance.post(
      `${API_URL}/${role}/claims/comment`,
      data,
      {
        headers: { "x-access-token": token },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
});

const addNoteSlice = createSlice({
  name: "comment",
  initialState: {
    isLoading: false,
    addNote: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [addNote.pending]: (state) => {
      state.isLoading = true;
    },
    [addNote.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.addNote = action.payload;
    },
    [addNote.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default addNoteSlice.reducer;
