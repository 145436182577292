import React from "react";
import { Route, Routes } from "react-router-dom";
import ClaimsPage from "./pages/claims";
import OnboardPage from "./pages/onboard";
import NotFound from "../notFound/notFound";
import { useSelector } from "react-redux";
import Loading from "../common/Loading/Loading";
import ClaimDetail from "./pages/claimDetail";
import { Cookies } from "react-cookie";
import AdminStore from "./pages/adminPages/store";
import AdminClaimDetails from "./pages/adminPages/onboard/details";
import OrdersPage from "./pages/orders/index";
import Engage from "./pages/engage";
import AdminTab from "./pages/adminTab/adminTab";
import Account from "./pages/account";
import Users from "./pages/adminPages/users";
import SetupGuide from "./pages/setupGuide";
import ConfigBar from "./pages/configBar";
import AdminStoreTab from "./pages/adminPages/storeTab/storeTab";
import AdminStatementsPage from "./pages/adminPages/statements/index";
import StatementTab from "./pages/statementTab/statementTab";
import OrderDetail from "./pages/adminPages/orderDetail";

const DashboardComponent = () => {
  const state = useSelector((state) => state.loginData);
  const isOptionAllowed = (option) => {
    return state.loginData?.userTabPermission.includes(option);
  };
  const cookies = new Cookies();
  const role = cookies.get("role");
  const token = cookies.get("token");
  return (
    <div className="dashboard-content-container"
    >
      {state.isLoading ? (
        <Loading type="overlay" className="loader-icon" />
      ) : (
        <Routes>
          {role === "merchant" && (
            <Route path="/setup-guide" element={<SetupGuide />} />
          )}
          {isOptionAllowed("onboard") && (
            <Route path="/" element={<OnboardPage />} />
          )}
          {isOptionAllowed("claims") && (
            <Route path="/claims" element={<ClaimsPage />} />
          )}
            {isOptionAllowed("statements") && (
            <Route
            path="/finance"
            element={role === "merchant" ? <StatementTab /> : <AdminStatementsPage />}
            />
          )}

          <Route path="/orders" element={<OrdersPage />} />
          {role === "admin" && (
            <Route path="/orders/:id" element={<OrderDetail />} />
          )}
           {role === "merchant" && (
            <Route path="/orders/:id" element={<OrderDetail />} />
          )}

          {role === "merchant" && <Route path="/engage" element={<Engage />} />}
          {role === "merchant" && (
            <Route path="/admin" element={<AdminTab />} />
          )}
          {role === "admin" && isOptionAllowed("users") && (
            <Route path="/users" element={<Users />} />
          )}
          {isOptionAllowed("account") && (
            <Route path="/account" element={<Account />} />
          )}
          {role === "admin" ? (
            <Route path="/store" element={<AdminStore />} />
          ) : (
            <Route path="/" element={<OnboardPage />} />
          )}
          {role === "merchant" && (
            <Route path="/claims/:id" element={<ClaimDetail />} />
          )}
          {role === "admin" && (
            <Route path="/claims/:id" element={<AdminClaimDetails />} />
          )}
          {role === "admin" && (
            <Route path="/storeDetail/:id" element={<AdminStoreTab />} />
          )}
          {token && <Route path="*" element={<NotFound />} />}
          <Route
            path={`/${
              role === "admin" ? "storeDetail/:id/" : "admin/"
            }configbar`}
            element={<ConfigBar />}
          />
        </Routes>
      )}
    </div>
  );
};
export default DashboardComponent;
