import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import AdminOnboardPage from "./onboard";
import Loading from "../../../../common/Loading/Loading";
import { Statistics } from "../../../../../redux/slice/statisticsSlice";
import { AdminActivity } from "../../../../../redux/slice/adminActivitySlice";

const AdminOnboard = (props) => {
  const { callGetStatistics, statistics, callGetAdminActivity, adminActivity } =
    props;
  const cookies = new Cookies();
  const role = cookies.get("role");
  useEffect(() => {
    if (role === "admin") {
      callGetStatistics();
      callGetAdminActivity();
    }
  }, [callGetStatistics, callGetAdminActivity, role]);
  const loading = statistics.isLoading && adminActivity.isLoading;
  const data = statistics?.statistics?.data && adminActivity?.adminActivity;
  return (
    <div>
      {loading && <Loading type="overlay" className="loader-icon" />}
      {data && (
        <AdminOnboardPage
          statistics={statistics.statistics.data}
          adminActivity={adminActivity.adminActivity}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    statistics: state.statistics,
    adminActivity: state.adminActivity,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetStatistics: () => dispatch(Statistics()),
    callGetAdminActivity: (props) => dispatch(AdminActivity(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminOnboard);
