import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import OrderDetailComponent from "./orderDetail";
import { getMerchantOrderDetails } from "../../../../../redux/slice/getMerchantOrderDetailsSlice";
import { connect } from "react-redux";
import Loading from "../../../../common/Loading/Loading";
const OrderDetail = (props) => {
  const { OrderDetails, callGetMerchantOrderDetails } = props;
  const { id } = useParams();

  useEffect(() => {
    callGetMerchantOrderDetails(id);
  }, [id, callGetMerchantOrderDetails]);

  return (
    <>
    {(OrderDetails.isLoading ) && (
      <Loading type="overlay" className="loader-icon" />
    )}
    {OrderDetails.details && (
      <OrderDetailComponent
        OrderDetails={OrderDetails.details}
      />
    )}
  </>
  );
};

const mapStateToProps = (state) => {
  return {
    OrderDetails: state.merchantOrderDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetMerchantOrderDetails: (id) => dispatch(getMerchantOrderDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
