export const ClaimStatus = [
  {
    value: "APPROVED",
    label: "APPROVE",
  },
  {
    value: "REVIEWING",
    label: "IN REVIEW",
  },
  {
    value: "CLOSED",
    label: "REJECT",
  },
];
export const RefundOption = [
  {
    value: "REFUND",
    label: "REFUND",
  },
  {
    value: "REORDER",
    label: "REORDER",
  },
];

export const monthOptions = [
  { value: "all", label: "All" },
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];
export const yearOptions = [
  { value: "all", label: "All" },
  { value: 2020, label: 2020 },
  { value: 2021, label: 2021 },
  { value: 2022, label: 2022 },
  { value: 2023, label: 2023 },
  { value: 2024, label: 2024 },
  { value: 2025, label: 2025 },
  { value: 2026, label: 2026 },
  { value: 2027, label: 2027 },
  { value: 2028, label: 2028 },
  { value: 2029, label: 2029 },
  { value: 2030, label: 2030 },
];

export const fontFamily = [
  {
    value: "Arial",
    label: "Arial",
  },
  {
    value: "Verdana",
    label: "Verdana",
  },
  {
    value: "Tahoma",
    label: "Tahoma",
  },
  {
    value: "Trebuchet MS",
    label: "Trebuchet MS",
  },
];

export const fontSize = [
  {
    label: "8",
    value: "8",
  },
  {
    label: "9",
    value: "9",
  },
  {
    label: "10",
    value: "10",
  },
  {
    label: "11",
    value: "11",
  },
  {
    label: "12",
    value: "12",
  },
  {
    label: "13",
    value: "13",
  },
  {
    label: "14",
    value: "14",
  },
];

export const accountHolder = [
  { label: "Company", value: "company" },
  { label: "Individual", value: "individual" },
];

export const validateSetupGuideTab = [
  "welcome",
  "Protect_Resolve",
  "track",
  "widget",
  "branding_profile",
  "finance",
  "admin",
];

export const optionCategory = [
  {
    label: 'Adult Toys & Entertainment',
    value: 'Adult Toys & Entertainment'
  },
  { label: 'Babycare & Maternity', value: 'Babycare & Maternity' },
  { label: 'Beauty & Wellness', value: 'Beauty & Wellness' },
  { label: 'CBD & Cannabis', value: 'CBD & Cannabis' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Fashion', value: 'Fashion' },
  { label: 'Food & Beverage', value: 'Food & Beverage' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Home & Garden', value: 'Home & Garden' },
  { label: 'Luggage & Bags', value: 'Luggage & Bags' },
  { label: 'Media', value: 'Media' },
  { label: 'Pet Supplies', value: 'Pet Supplies' },
  { label: 'Sports & Outdoors', value: 'Sports & Outdoors' },
  { label: 'Toys & Games', value: 'Toys & Games' },
  {
    label: 'Vapes & Smoking Accessories',
    value: 'Vapes & Smoking Accessories'
  },
  { label: 'Vehicle & Parts', value: 'Vehicle & Parts' }
];
export const optionGender = [
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Genderless', value: 'Genderless' },
  { label: 'Unisex', value: 'Unisex' }
];
export const optionAge = [
  { label: 'Adult', value: 'Adult' },
  { label: 'Children', value: 'Children' }
];

export const merchantCauses = [
  { label: "Cruelty-free", value: "Cruelty-free" },
  { label: "Plant-based", value: "Plant-based" },
  { label: "Female-founded", value: "Female-founded" },
  { label: "Ethically Made", value: "Ethically-Made" },
  { label: "Sustainable", value: "Sustainable" },
  { label: "Handmade", value: "Handmade" },
  { label: "Brands That Give Back", value: "Brands That Give Back" },
  { label: "Clean Beauty", value: "Clean Beauty" },
  { label: "BIPOC-owned", value: "BIPOC-owned" },
];

export const widgetStep = [
  {
    title: "From your Shopify admin, go to Online Store > Themes.",
  },
  {
    title: "Find the theme that you want to edit, and then click Customize.",
  },
  {
    title:
      "Navigate to the cart page(this block is only accessible on the cart template).",
  },
  {
    title: "On the sidebar, click Add block.",
  },
  {
    title: `From the drop-down menu, in the Apps section, select the "Swipe Cart Block" app block that you want to add to the section or click the Search bar and enter a search term to search through your installed apps.`,
  },
  {
    title: `Optional: Click and drag the ⋮⋮ icon to move the block to another available location on the page. You can also customize the block using any available settings.`,
  },
  {
    title: "Click Save",
  },
];
