import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const claimList = createAsyncThunk(
  "claims",
  async ({ payload,currentPage=1}) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      let url = `${API_URL}/merchant/claims/list?limit=25&page=${currentPage}`;

      if (payload?.date?.startDate && payload?.date?.endDate) {
        url += `&start_date=${payload.date.startDate}&end_date=${payload.date.endDate}`;
      }
      if (payload?.search) {
         url +=`&search=${payload.search}`;
      }
      const response = await AxiosInstance.get(url, {
        headers: { "x-access-token": token },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
);

const getClaimListSlice = createSlice({
  name: "claims",
  initialState: {
    isLoading: false,
    claimList: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [claimList.pending]: (state) => {
      state.isLoading = true;
    },
    [claimList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.claimList = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [claimList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.claimList = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default getClaimListSlice.reducer;
