import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance from "../../authServices/axiosInstance";
import { Cookies } from "react-cookie";

export const Statistics = createAsyncThunk("statistics", async () => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const response = await AxiosInstance.post(
      `${API_URL}/admin/statistics/list`,
      {},
      {
        headers: { "x-access-token": token },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

const statisticsSlice = createSlice({
  name: "statistics",
  initialState: {
    isLoading: false,
    statistics: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [Statistics.pending]: (state) => {
      state.isLoading = true;
    },
    [Statistics.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.statistics = action.payload;
    },
    [Statistics.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default statisticsSlice.reducer;
