import DashboardHeaderComponent from "../../common/dashboardHeader/dashboradHeader";
import { EngageIn } from "../../../../assets/images/sidebar";
import { Tabs } from "antd";
import BrandedContentTab from "./BrandedContentTab";
import ShippingNotificationTab from "./ShippingNotificationTab";
import { useState } from "react";
import { useEffect } from "react";
import { Cookies } from "react-cookie";

const EngagePage = ({
  handleSubmit,
  engageContentList,
  engageNoticationData,
  handleNotificationSubmit,
}) => {
  const [formData, setFormData] = useState(engageContentList);
  const [defaultData, setDefaultData] = useState(engageContentList);
  const onChange = (key) => {};
  const handleClose = () => {
    setFormData(engageContentList);
  };
  const items = [
    {
      key: "1",
      label: "Branded Content",
      children: (
        <BrandedContentTab
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
          defaultData={defaultData}
          handleClose={handleClose}
        />
      ),
    },
    {
      key: "2",
      label: "Shipping Notifications",
      children: (
        <ShippingNotificationTab
          engageNoticationData={engageNoticationData}
          handleNotificationSubmit={handleNotificationSubmit}
        />
      ),
    },
  ];
  useEffect(() => {
    setDefaultData(engageContentList);
    setFormData(engageContentList);
  }, [engageContentList]);

  const cookies = new Cookies();
  const role = cookies.get("role");

  return (
    <div className={role === "merchant" && "engage-main"}>
      <div className={role === "merchant" && "engage-container"}>
        <div className="engage-main-section">
          {role === "merchant" && (
            <DashboardHeaderComponent title="Engage" Logo={EngageIn} />
          )}
          <div className="tabs">
            <Tabs
              defaultActiveKey="1"
              items={items}
              onChange={onChange}
              type="card"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngagePage;
