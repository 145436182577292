import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import AxiosInstance from "../../authServices/axiosInstance";
import { Cookies } from "react-cookie";

export const getSetting = createAsyncThunk("settinglist", async (merchantId) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const role = cookies.get("role");
    const merchantSettingApiUrl = `${API_URL}/merchant/setting/setting-list/${merchantId}`
    const singleMerchantSettingApiUrl = `${API_URL}/admin/merchant/settinglist/${merchantId}`
    const url = role === 'admin' ? singleMerchantSettingApiUrl : merchantSettingApiUrl
    const response = await AxiosInstance.get(url,
      {
        headers: { "x-access-token": token },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

const getSettingSlice = createSlice({
  name: "settinglist",
  initialState: {
    isLoading: false,
    settings: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getSetting.pending]: (state) => {
      state.isLoading = true;
    },
    [getSetting.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.settings = action.payload;
    },
    [getSetting.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default getSettingSlice.reducer;
