import React from 'react';

const ResourceCardBox = (props) => {
  const { title, extra, details } = props;
  return (
    <>
      <div className="resource-card-box">
        <div className="card-a">
          <div className="r-card-head">
            <div className="card-head-wrapper">
              <div className="card-head-title">{title}</div>
            </div>
          </div>
          <div className="r-card-body">
            <div className="r-body-content">
              <div className="texts-body">
                <span className="r-texts">{details}</span>
              </div>
              <div className="card-head-extra">{extra}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceCardBox;
