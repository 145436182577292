import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import StoreDetailsComponent from "./storeDetails";
import Loading from "../../../../../common/Loading/Loading";
import { editTask } from "../../../../../../redux/slice/editTaskSlice";
import Notify from "../../../../../common/Notify/notify";
import { adminStoreDetails } from "../../../../../../redux/slice/adminStoreDetailsSlice";
import { sendBilling } from "../../../../../../redux/slice/sendBillingSlice";
import ModalMessage from "../../../../../common/modalMessage/messageModal";
import { getMerchantOrder } from "../../../../../../redux/slice/getMerchantOrderSlice";
import { AdminActivity } from "../../../../../../redux/slice/adminActivitySlice";
import { merchantClaimStatistics } from "../../../../../../redux/slice/merchantClaimStatisticSlice";
import { merchantRevenue } from "../../../../../../redux/slice/merchantRevenueSlice";
import { merchantList } from "../../../../../../redux/slice/merchantListSlice";
import { adminManagerList } from "../../../../../../redux/slice/adminManagerList";
import { addManagerAssign } from "../../../../../../redux/slice/addManagerAssignSlice";

const StoreDetails = (props) => {
  const {
    callEditTask,
    editTask,
    callAdminStoreDetails,
    adminStoreDetails,
    callSendBilling,
    sendBilling,
    callGetMerchantOrder,
    merchantOrder,
    callGetAdminActivity,
    adminActivity,
    callMerchantClaimStatistics,
    merchantClaimStatistics,
    callMerchantRevenue,
    merchantRevenue,
    accountManagerList,
    callGetAdminManagerList,
    callAddManagerAssign,
    addManagerAssign
  } = props;
  const { id } = useParams();

  const shopId = adminStoreDetails.adminStoreDetails?.data?.shop_id;
  const apiCalledRef = React.useRef(false);
  useEffect(() => {
    if (!apiCalledRef.current && id) {
      callAdminStoreDetails(id);
      callGetMerchantOrder(id);
      apiCalledRef.current = true;
      callGetAdminActivity(id);
      callMerchantClaimStatistics(id);
      callMerchantRevenue(id);
      callGetAdminManagerList({});
    }
  }, [
    id,
    callAdminStoreDetails,
    callGetMerchantOrder,
    callGetAdminActivity,
    callMerchantClaimStatistics,
    callMerchantRevenue,
  ]);

  const handleEditTask = async (props) => {
    const response = await callEditTask(props);
    if (response.type === "editTask/fulfilled") {
      Notify("success", response.payload.message, "");
      await callAdminStoreDetails(id);
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };

  const handleSendBilling = async () => {
    const response = await callSendBilling({ shop_id: shopId });
    if (response.type === "send-billing/fulfilled") {
      Notify("success", response.payload.message, "");
    } else {
      if (response.error && response.error.message) {
        Notify("error", response.error.message, "");
      }
    }
  };
  
  return adminStoreDetails.isLoading || addManagerAssign.isLoading|| sendBilling.isLoading ? (
    <Loading type="overlay" className="loader-icon" />
  ) : adminStoreDetails?.errorMessage === "The id field is not valid id" ? (
    <ModalMessage
      message={adminStoreDetails?.errorMessage}
      buttonTitle="Store"
      route="/dashboard/store"
    />
  ) : (
    adminStoreDetails.adminStoreDetails && (
      <StoreDetailsComponent
        adminStoreDetails={adminStoreDetails.adminStoreDetails}
        handleEditTask={handleEditTask}
        editTaskLoading={editTask.isLoading}
        handleSendBilling={handleSendBilling}
        merchantOrder={merchantOrder?.merchantOrder?.data}
        merchantActivity={adminActivity.adminActivity}
        monthlyClaim={merchantClaimStatistics?.merchantClaimStatistics}
        revenue={merchantRevenue?.merchantRevenue?.data[0]}
        accountManagerList={accountManagerList.adminManager}
        callAddManagerAssign={callAddManagerAssign}
        callAdminStoreDetails={callAdminStoreDetails}
      />
    )
  );
};

const mapStateToProps = (state) => {
  return {
    editTask: state.editTask,
    adminStoreDetails: state.adminStoreDetails,
    sendBilling: state.sendBilling,
    merchantOrder: state.merchantOrder,
    adminActivity: state.adminActivity,
    merchantClaimStatistics: state.merchantClaimStatistics,
    merchantRevenue: state.merchantRevenue,
    accountManagerList: state.accountManagerList,
    addManagerAssign: state.addManagerAssign,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callEditTask: (...props) => dispatch(editTask(...props)),
    callAdminStoreDetails: (props) => dispatch(adminStoreDetails(props)),
    callSendBilling: (props) => dispatch(sendBilling(props)),
    callGetMerchantOrder: (props) => dispatch(getMerchantOrder(props)),
    callGetAdminActivity: (props) => dispatch(AdminActivity(props)),
    callMerchantClaimStatistics: (props) =>
      dispatch(merchantClaimStatistics(props)),
    callMerchantRevenue: (props) => dispatch(merchantRevenue(props)),
    callGetAdminManagerList: () => dispatch(adminManagerList()),
    callAddManagerAssign: (props) => dispatch(addManagerAssign(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetails);
