import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const merchantRevenue = createAsyncThunk("revenue", async (id) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const response = await AxiosInstance.get(
      `${API_URL}/admin/dashboard/statistic/revenue/${id}`,
      {
        headers: { "x-access-token": token },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const merchantRevenueSlice = createSlice({
  name: "revenue",
  initialState: {
    isLoading: false,
    merchantRevenue: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [merchantRevenue.pending]: (state) => {
      state.isLoading = true;
    },
    [merchantRevenue.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.merchantRevenue = action.payload;
      state.errorMessage = "";
      state.isError = false;
    },
    [merchantRevenue.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.merchantRevenue = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default merchantRevenueSlice.reducer;
