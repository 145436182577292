import React, { useState, useEffect } from "react";
import RightDrawer from "../../../../../common/drawer/drawer";
import { Divider, List, Switch } from "antd";
import { useTranslation } from "react-i18next";

const TrackLink = (props) => {
  const { onClose, visible, data, updateAndGetData } = props;
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(data.thank_you_page_tracking_link);

  const check = data.thank_you_page_tracking_link === "on" ? true : false;

  useEffect(() => {
    setIsChecked(check);
  }, [data.thank_you_page_tracking_link, check]);

  const handleChange = (checked) => {
    if (checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const handleCancel = () => {
    setIsChecked(check);
    onClose();
  };

  const dataStatic = [
    t("TRACK_DRW_STR_1"),
    t("TRACK_DRW_STR_2"),
    t("TRACK_DRW_STR_3"),
  ];
  const handleSave = () => {
    const payload = {
      thank_you_page_tracking_link: isChecked === true ? "on" : "off",
    };
    updateAndGetData(payload);
    onClose();
  };

  return (
    <>
      <RightDrawer
        title="Thank You Page Tracking Link"
        onClose={handleCancel}
        visible={visible}
        buttonTitle="Save"
        onSubmit={() => handleSave()}
      >
        <div className="trackLink-drawer">
          <List
            size="small"
            dataSource={dataStatic}
            renderItem={(item) => (
              <List.Item style={{ fontSize: 13 }}>{item}</List.Item>
            )}
          />
          <Divider style={{ color: "red" }} />
          <div className="option">
            <div>Tracking Link</div>
            <div>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                checked={isChecked}
                onChange={handleChange}
              />
            </div>
          </div>
          <Divider />
        </div>
      </RightDrawer>
    </>
  );
};

export default TrackLink;
