import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../environment";
import { Cookies } from "react-cookie";
import AxiosInstance from "../../authServices/axiosInstance";

export const getProtect = createAsyncThunk(
  "protect",
  async ({ startDate, endDate }) => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      let url = `${API_URL}/merchant/dashboard/claim/statistic`;

      if (startDate && endDate) {
        url += `?start_date=${startDate}&end_date=${endDate}`;
      }
      const response = await AxiosInstance.get(url, {
        headers: { "x-access-token": token },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const getProtectSlice = createSlice({
  name: "protect",
  initialState: {
    isLoading: false,
    protect: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getProtect.pending]: (state) => {
      state.isLoading = true;
    },
    [getProtect.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.protect = action.payload;
      state.errorMessage = "";
      state.isError = false;
    },
    [getProtect.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.protect = null;
      state.errorMessage = action.error.message;
    },
  },
});

export default getProtectSlice.reducer;
