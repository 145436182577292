import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import DashboardHeaderComponent from "../../common/dashboardHeader/dashboradHeader";
import Statement from "../statementTab/statements/index";
// import AccountSetting from "./accountSetting";
import { useNavigate, useLocation } from "react-router-dom";

const StatementTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("payments");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");
    const validTabs = [
      "payments",
      // "account-settings"
    ];
    const defaultTab = validTabs.includes(tabParam) ? tabParam : "payments";
    navigate(`/dashboard/finance?tab=${defaultTab}`);
    setActiveTab(defaultTab);
  }, [location.search, navigate]);

  const onChange = (key) => {
    navigate(`/dashboard/finance?tab=${key}`);
    setActiveTab(key);
  };

  const items = [
    {
      key: "payments",
      label: "Payments",
      children: <Statement />,
    },
    // {
    //   key: "account-settings",
    //   label: "Account Settings",
    //   children: <AccountSetting />,
    // },
  ];

  return (
    <div className="admin-tab-main">
      <div className="admin-tab-container">
        <DashboardHeaderComponent title="Finance" />
        <div className="tabs">
          <Tabs
            activeKey={activeTab}
            items={items}
            type="card"
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default StatementTab;
